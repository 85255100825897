// ! THIS FILE WILL BE REMOVED ONCE WE HAVE
// ! THE ENTIRE APP MOVED INTO AN SPA

import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext
} from 'react'
import { useLocation, useMatch } from 'react-router-dom'
import { useBuildHandoffUrl } from './useBuildHandoffUrl'
import { useLegacyRoutes } from './useLegacyRoutes'

function getSlugFromPath(path: string) {
  const [slug] = path.split('/').filter(Boolean)
  // This matches how the backend treats the root route
  return slug || '/'
}

const RoutingContext = createContext<{
  isLegacy: (path: string) => boolean
  buildHandoffUrl: (path: string) => string
}>({
  isLegacy: () => true,
  buildHandoffUrl: () => ''
})

export const useHandoff = () => useContext(RoutingContext)

export function HandoffProvider({ children }: PropsWithChildren<unknown>) {
  const location = useLocation()
  const buildHandoffUrl = useBuildHandoffUrl()
  const slug = getSlugFromPath(location.pathname)
  const { data } = useLegacyRoutes()

  // ! The `hasLiveTours` is a temporary solution until CMA is a SPA
  const hasLiveTours = !!data?.features?.hasLiveTours
  const isNewTourRoute = useMatch('/tours/new')
  const isTourRoute = useMatch('/tours/:id')
  const isRouteEdit = useMatch('/tours/:id/edit')
  const isValidTourPage = isNewTourRoute || isTourRoute || isRouteEdit

  // One-off homepage redirect because it's not complete yet.
  const shouldRedirectHomepage =
    ['staging', 'production'].includes(process.env.NODE_ENV) &&
    location.pathname === '/'

  // Legacy route redirects
  const shouldRedirectToLegacy = hasLiveTours
    ? location.pathname === '/tours'
      ? true
      : isValidTourPage
      ? false
      : process.env.VITE_APP_ENABLE_HANDOFF === 'true' && !!data?.slugs[slug]
    : process.env.VITE_APP_ENABLE_HANDOFF === 'true' && !!data?.slugs[slug]

  const isLegacy = useCallback(
    (path: string) => {
      if (shouldRedirectToLegacy) return false
      const slug = getSlugFromPath(path)
      return !!(data?.slugs || {})[slug]
    },
    [data?.slugs, shouldRedirectToLegacy]
  )

  if (shouldRedirectHomepage || shouldRedirectToLegacy) {
    window.open(buildHandoffUrl(location.pathname), '_self')
    throw new Promise(() => {
      // This is to display the spinner in suspense while the app redirects
    })
  }

  return (
    <RoutingContext.Provider value={{ isLegacy, buildHandoffUrl }}>
      {children}
    </RoutingContext.Provider>
  )
}
