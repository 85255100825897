export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  FileUpload: any;
  ISO8601DateTime: any;
  JSON: any;
};

/** Autogenerated return type of AcceptTos */
export type AcceptTosPayload = {
  errors?: Maybe<Array<Scalars['String']>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ActorInterface = {
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type AddOn = {
  billing_cycle?: Maybe<Scalars['Int']>;
  discounts?: Maybe<Array<Discount>>;
  id: Scalars['ID'];
  key: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
  tagline: Scalars['String'];
};


export type AddOnDiscountsArgs = {
  code?: InputMaybe<Scalars['String']>;
};

export type AddressComponents = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  postal_code_ext?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  prefix2?: Maybe<Scalars['String']>;
  redundant_street_type?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  street_type?: Maybe<Scalars['String']>;
  street_type2?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  suffix2?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  unit_prefix?: Maybe<Scalars['String']>;
};

export type Agent = {
  active?: Maybe<Scalars['Boolean']>;
  agent_id?: Maybe<Scalars['String']>;
  ao_board_id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  listings?: Maybe<Array<Listing>>;
  office?: Maybe<OfficeList>;
};

export type AgentList = {
  agent_id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type AuthenticatedMutation = {
  acceptTos: AcceptTosPayload;
  cancelAccount?: Maybe<User>;
  cancelAddOnSubscription?: Maybe<Subscription>;
  changeUserPlan?: Maybe<Subscription>;
  checkUserCancellationDetails?: Maybe<CancellationDetails>;
  checkUserProductStatus?: Maybe<CheckUserProductStatusPayload>;
  connectProduct?: Maybe<ConnectProductPayload>;
  createConsumer: CreateConsumerPayload;
  createEvent: CreateEventPayload;
  createListingDraft?: Maybe<ListingDraft>;
  createSearch: CreateSearchPayload;
  deleteListingDraft?: Maybe<Scalars['Boolean']>;
  deleteSearch: Scalars['Boolean'];
  importContacts: ImportContactsPayload;
  importSearch: ImportSearchPayload;
  issueGuestPass?: Maybe<GuestPass>;
  productLogin?: Maybe<ProductLoginPayload>;
  provisionDraftAccounts?: Maybe<ProvisionDraftAccountsPayload>;
  provisionPlatformProduct?: Maybe<ProvisionPlatformProductPayload>;
  provisionProduct?: Maybe<ProvisionedAccount>;
  touchSearch: TouchSearchPayload;
  trackEvent?: Maybe<TrackEventPayload>;
  updateIntercom?: Maybe<User>;
  updateListingDraft?: Maybe<ListingDraft>;
  updateSearch: UpdateSearchPayload;
  updateUser?: Maybe<User>;
  updateUserBilling?: Maybe<User>;
};


export type AuthenticatedMutationAcceptTosArgs = {
  id: Scalars['ID'];
};


export type AuthenticatedMutationCancelAddOnSubscriptionArgs = {
  subscription_id?: InputMaybe<Scalars['ID']>;
};


export type AuthenticatedMutationChangeUserPlanArgs = {
  discount_id?: InputMaybe<Scalars['ID']>;
  guest_pass_conversion?: InputMaybe<Scalars['Boolean']>;
  guest_pass_id?: InputMaybe<Scalars['ID']>;
  mls_id?: InputMaybe<Scalars['ID']>;
  payment_method_nonce?: InputMaybe<Scalars['String']>;
  sku: SkuInput;
  source: ChangePlanSource;
};


export type AuthenticatedMutationCheckUserProductStatusArgs = {
  product_id: Scalars['ID'];
};


export type AuthenticatedMutationConnectProductArgs = {
  service?: InputMaybe<ProductKey>;
};


export type AuthenticatedMutationCreateConsumerArgs = {
  consumer_settings?: InputMaybe<ConsumerSettings>;
  recipient_id: Scalars['ID'];
  recipient_type: Scalars['String'];
  search_id: Scalars['ID'];
};


export type AuthenticatedMutationCreateEventArgs = {
  event_input: EventInput;
};


export type AuthenticatedMutationCreateListingDraftArgs = {
  data: Scalars['JSON'];
};


export type AuthenticatedMutationCreateSearchArgs = {
  search_input: SearchInput;
};


export type AuthenticatedMutationDeleteListingDraftArgs = {
  id: Scalars['ID'];
};


export type AuthenticatedMutationDeleteSearchArgs = {
  id: Scalars['ID'];
};


export type AuthenticatedMutationImportContactsArgs = {
  contacts: Array<ContactInput>;
};


export type AuthenticatedMutationImportSearchArgs = {
  from: ImportedFrom;
  import: Array<SearchInput>;
};


export type AuthenticatedMutationIssueGuestPassArgs = {
  jwt: Scalars['String'];
};


export type AuthenticatedMutationProductLoginArgs = {
  product_id: Scalars['ID'];
  redirect_to?: InputMaybe<Scalars['String']>;
};


export type AuthenticatedMutationProvisionPlatformProductArgs = {
  provisioned_account_id: Scalars['ID'];
};


export type AuthenticatedMutationProvisionProductArgs = {
  product_id: Scalars['ID'];
};


export type AuthenticatedMutationTouchSearchArgs = {
  id: Scalars['ID'];
};


export type AuthenticatedMutationTrackEventArgs = {
  app_name: Scalars['String'];
  event_name: Scalars['String'];
  meta_data: Scalars['JSON'];
};


export type AuthenticatedMutationUpdateListingDraftArgs = {
  data: Scalars['JSON'];
  id: Scalars['ID'];
};


export type AuthenticatedMutationUpdateSearchArgs = {
  id: Scalars['ID'];
  search_input: SearchInput;
};


export type AuthenticatedMutationUpdateUserArgs = {
  user: UserRegistrationInput;
};


export type AuthenticatedMutationUpdateUserBillingArgs = {
  payment_method_nonce: Scalars['String'];
};

export type AuthenticatedQuery = {
  address_components?: Maybe<AddressComponents>;
  address_suggestions?: Maybe<Array<GeocodedAddress>>;
  agent?: Maybe<Agent>;
  contact?: Maybe<Contact>;
  contacts?: Maybe<Array<Contact>>;
  events?: Maybe<Array<EventInterface>>;
  last_imported?: Maybe<Scalars['ISO8601DateTime']>;
  listing?: Maybe<Listing>;
  listing_draft?: Maybe<ListingDraft>;
  listing_drafts?: Maybe<Array<ListingDraft>>;
  pins?: Maybe<Array<MapPin>>;
  search?: Maybe<Search>;
  search_fields?: Maybe<Array<SearchField>>;
  searches?: Maybe<Array<Search>>;
  searches_count?: Maybe<Scalars['Int']>;
  subscription?: Maybe<Subscription>;
  suggestions: Array<Suggestion>;
  tax_data?: Maybe<TaxData>;
  user?: Maybe<User>;
};


export type AuthenticatedQueryAddress_ComponentsArgs = {
  address: Scalars['String'];
};


export type AuthenticatedQueryAddress_SuggestionsArgs = {
  query: Scalars['String'];
};


export type AuthenticatedQueryAgentArgs = {
  id: Scalars['ID'];
};


export type AuthenticatedQueryContactArgs = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};


export type AuthenticatedQueryContactsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<QuerySortInput>;
};


export type AuthenticatedQueryLast_ImportedArgs = {
  from: ImportedFrom;
};


export type AuthenticatedQueryListingArgs = {
  id: Scalars['ID'];
};


export type AuthenticatedQueryListing_DraftArgs = {
  id: Scalars['ID'];
};


export type AuthenticatedQueryPinsArgs = {
  coordinates: Scalars['JSON'];
  precision: Scalars['Int'];
};


export type AuthenticatedQuerySearchArgs = {
  id: Scalars['ID'];
};


export type AuthenticatedQuerySearchesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  roles?: InputMaybe<Array<SearchRole>>;
  sort?: InputMaybe<QuerySortInput>;
};


export type AuthenticatedQuerySearches_CountArgs = {
  roles?: InputMaybe<Array<SearchRole>>;
};


export type AuthenticatedQuerySuggestionsArgs = {
  scope?: InputMaybe<Scalars['String']>;
  search: Scalars['String'];
};


export type AuthenticatedQueryTax_DataArgs = {
  address: Scalars['String'];
};

export type Avatar = {
  large?: Maybe<Scalars['String']>;
  small?: Maybe<Scalars['String']>;
  tiny?: Maybe<Scalars['String']>;
};

export type AvatarCropInput = {
  avatar_crop_height?: InputMaybe<Scalars['Float']>;
  avatar_crop_width?: InputMaybe<Scalars['Float']>;
  avatar_crop_x?: InputMaybe<Scalars['Float']>;
  avatar_crop_y?: InputMaybe<Scalars['Float']>;
};

export type Bundle = {
  billing_cycle?: Maybe<Scalars['Int']>;
  discounts?: Maybe<Array<Discount>>;
  group_key?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  products?: Maybe<Array<Product>>;
  tagline?: Maybe<Scalars['String']>;
  virtual?: Maybe<Scalars['Boolean']>;
};

export type CancellationDetails = {
  product_details?: Maybe<Scalars['String']>;
  save_sku?: Maybe<Sku>;
  sku?: Maybe<Sku>;
};

export type Capture = {
  created_at?: Maybe<Scalars['ISO8601DateTime']>;
  external_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  property?: Maybe<Property>;
  source?: Maybe<CaptureSource>;
  source_data?: Maybe<Scalars['JSON']>;
  source_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['ISO8601DateTime']>;
};

export type CaptureInput = {
  created_at?: InputMaybe<Scalars['ISO8601DateTime']>;
  external_id?: InputMaybe<Scalars['ID']>;
  property_attributes?: InputMaybe<PropertyInput>;
  source: CaptureSource;
  source_data?: InputMaybe<Scalars['JSON']>;
  source_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['ISO8601DateTime']>;
};

export enum CaptureSource {
  Imported = 'imported',
  LandingPage = 'landing_page',
  Zillow = 'zillow'
}

export type Change = {
  changes?: Maybe<Array<ChangeDetail>>;
  timestamp?: Maybe<Scalars['ISO8601DateTime']>;
};

export type ChangeDetail = {
  field?: Maybe<Scalars['String']>;
  new_value?: Maybe<Scalars['String']>;
  old_value?: Maybe<Scalars['String']>;
};

export enum ChangePlanSource {
  Cancel = 'cancel',
  ChangePlan = 'change_plan'
}

/** Autogenerated return type of CheckUserProductStatus */
export type CheckUserProductStatusPayload = {
  connect_path?: Maybe<Scalars['String']>;
  user_exists?: Maybe<Scalars['Boolean']>;
};

export type Comment = EventInterface & {
  action?: Maybe<Scalars['String']>;
  actor?: Maybe<ActorInterface>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  listing?: Maybe<Listing>;
  recipients?: Maybe<Array<ActorInterface>>;
  role?: Maybe<Scalars['String']>;
  search: Search;
};

/** Autogenerated return type of ConnectProduct */
export type ConnectProductPayload = {
  acknowledged: Scalars['Boolean'];
  redirect_to?: Maybe<Scalars['String']>;
};

export type Consumer = {
  daily_digest?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  market_update?: Maybe<Scalars['Boolean']>;
  monthly_digest?: Maybe<Scalars['Boolean']>;
  realtime?: Maybe<Scalars['Boolean']>;
  recipient?: Maybe<ActorInterface>;
  search?: Maybe<Search>;
  sms?: Maybe<Scalars['Boolean']>;
  weekly_digest?: Maybe<Scalars['Boolean']>;
};

export type ConsumerSettings = {
  daily_digest?: InputMaybe<Scalars['Boolean']>;
  market_update?: InputMaybe<Scalars['Boolean']>;
  monthly_digest?: InputMaybe<Scalars['Boolean']>;
  realtime?: InputMaybe<Scalars['Boolean']>;
  sms?: InputMaybe<Scalars['Boolean']>;
  weekly_digest?: InputMaybe<Scalars['Boolean']>;
};

export type Contact = ActorInterface & {
  captures?: Maybe<Array<Capture>>;
  created_at?: Maybe<Scalars['ISO8601DateTime']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['ISO8601DateTime']>;
};

export type ContactInput = {
  captures?: InputMaybe<Array<CaptureInput>>;
  created_at?: InputMaybe<Scalars['ISO8601DateTime']>;
  email: Scalars['String'];
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated return type of CreateConsumer */
export type CreateConsumerPayload = {
  consumer?: Maybe<Consumer>;
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of CreateEvent */
export type CreateEventPayload = {
  errors?: Maybe<Array<Scalars['String']>>;
  event?: Maybe<EventInterface>;
};

/** Autogenerated return type of CreateSearch */
export type CreateSearchPayload = {
  errors?: Maybe<Array<Scalars['String']>>;
  search?: Maybe<Search>;
};

export type DailyDigest = EventInterface & {
  action?: Maybe<Scalars['String']>;
  actor?: Maybe<ActorInterface>;
  alerts_count?: Maybe<Scalars['Int']>;
  comments_count?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  likes_count?: Maybe<Scalars['Int']>;
  listing?: Maybe<Listing>;
  recipients?: Maybe<Array<ActorInterface>>;
  role?: Maybe<Scalars['String']>;
  search: Search;
  views_count?: Maybe<Scalars['Int']>;
};

export type Discount = {
  add_ons?: Maybe<Array<Maybe<AddOn>>>;
  amount: Scalars['Float'];
  bundles?: Maybe<Array<Maybe<Bundle>>>;
  code: Scalars['String'];
  id: Scalars['ID'];
};

export type EventInput = {
  content?: InputMaybe<Scalars['String']>;
  listing_id?: InputMaybe<Scalars['ID']>;
  role: EventRole;
  search_id: Scalars['ID'];
};

export type EventInterface = {
  action?: Maybe<Scalars['String']>;
  actor?: Maybe<ActorInterface>;
  created_at?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  listing?: Maybe<Listing>;
  recipients?: Maybe<Array<ActorInterface>>;
  role?: Maybe<Scalars['String']>;
  search: Search;
};

export enum EventRole {
  Comment = 'comment',
  DailyDigest = 'daily_digest',
  Dislike = 'dislike',
  Like = 'like',
  ListingUpdate = 'listing_update',
  Recommendation = 'recommendation'
}

export type FilterGroupInput = {
  circle?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Array<Scalars['String']>>;
  field: Scalars['String'];
  gte?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  matches?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<Array<Scalars['String']>>;
  proximity?: InputMaybe<Scalars['String']>;
  should?: InputMaybe<Array<Scalars['String']>>;
  should_eq?: InputMaybe<Array<Scalars['String']>>;
  should_gte?: InputMaybe<Scalars['String']>;
  should_lte?: InputMaybe<Scalars['String']>;
  should_match?: InputMaybe<Scalars['String']>;
  within?: InputMaybe<Scalars['String']>;
};

export type FilterInput = {
  circle?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Array<Scalars['String']>>;
  field: Scalars['String'];
  filter_group?: InputMaybe<Array<FilterGroupInput>>;
  gte?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  matches?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<Array<Scalars['String']>>;
  proximity?: InputMaybe<Scalars['String']>;
  should?: InputMaybe<Array<Scalars['String']>>;
  should_eq?: InputMaybe<Array<Scalars['String']>>;
  should_gte?: InputMaybe<Scalars['String']>;
  should_lte?: InputMaybe<Scalars['String']>;
  should_match?: InputMaybe<Scalars['String']>;
  within?: InputMaybe<Scalars['String']>;
};

export type GeocodedAddress = {
  address?: Maybe<Scalars['String']>;
  address_components?: Maybe<AddressComponents>;
  lat?: Maybe<Scalars['String']>;
  lon?: Maybe<Scalars['String']>;
};

export type GuestPass = {
  id?: Maybe<Scalars['ID']>;
  skuable?: Maybe<Sku>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Autogenerated return type of ImportContacts */
export type ImportContactsPayload = {
  errors?: Maybe<Array<Scalars['String']>>;
  id_map?: Maybe<Scalars['JSON']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of ImportSearch */
export type ImportSearchPayload = {
  errors?: Maybe<Array<Scalars['String']>>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum ImportedFrom {
  Attract = 'attract',
  Cma = 'cma',
  Mlx = 'mlx',
  Streams = 'streams'
}

export type ItemableInterface = {
  id: Scalars['ID'];
};

export type Like = EventInterface & {
  action?: Maybe<Scalars['String']>;
  actor?: Maybe<ActorInterface>;
  created_at?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  listing?: Maybe<Listing>;
  recipients?: Maybe<Array<ActorInterface>>;
  role?: Maybe<Scalars['String']>;
  search: Search;
};

export type Listing = {
  attributes?: Maybe<ListingResult>;
  comments?: Maybe<Array<Comment>>;
  id?: Maybe<Scalars['ID']>;
  likes?: Maybe<Array<Like>>;
  recommendations?: Maybe<Array<Recommendation>>;
};

export type ListingAlert = EventInterface & {
  action?: Maybe<Scalars['String']>;
  actor?: Maybe<ActorInterface>;
  created_at?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  listing?: Maybe<Listing>;
  recipients?: Maybe<Array<ActorInterface>>;
  role?: Maybe<Scalars['String']>;
  search: Search;
};

export type ListingDraft = {
  created_at?: Maybe<Scalars['ISO8601DateTime']>;
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  updated_at?: Maybe<Scalars['ISO8601DateTime']>;
};

export type ListingResult = {
  acres?: Maybe<Scalars['Float']>;
  /** the street address of the property */
  address?: Maybe<Scalars['String']>;
  /** the agent representing the seller */
  agent_list?: Maybe<AgentList>;
  /** the agent representing the buyer */
  agent_sell?: Maybe<AgentList>;
  area?: Maybe<Scalars['String']>;
  assoc_fee?: Maybe<Scalars['Float']>;
  baths?: Maybe<Scalars['Float']>;
  baths_full?: Maybe<Scalars['Float']>;
  baths_half?: Maybe<Scalars['Float']>;
  baths_quarter?: Maybe<Scalars['Float']>;
  baths_search?: Maybe<Scalars['Float']>;
  baths_three_quarter?: Maybe<Scalars['Float']>;
  beds?: Maybe<Scalars['Int']>;
  /** Cumulative days on market, the total time spent in an active state */
  cdom?: Maybe<Scalars['String']>;
  /** as updates are pulled in, keep track of price and status changes */
  changes?: Maybe<Array<Change>>;
  city?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  date_expired?: Maybe<Scalars['String']>;
  date_list?: Maybe<Scalars['String']>;
  date_offmarket?: Maybe<Scalars['String']>;
  date_pending?: Maybe<Scalars['String']>;
  date_sold?: Maybe<Scalars['String']>;
  directions?: Maybe<Scalars['String']>;
  /** Days on Market, consecutive days spent in an active state. most mlses will reset this when going pending and back to active */
  dom?: Maybe<Scalars['String']>;
  /** An arbitrary set of listing features */
  features?: Maybe<Scalars['JSON']>;
  garages?: Maybe<Scalars['String']>;
  /** the primary key given my the mls, not the same as mlsnum */
  id?: Maybe<Scalars['ID']>;
  lat?: Maybe<Scalars['Float']>;
  lockbox_location?: Maybe<Scalars['String']>;
  lockbox_type?: Maybe<Scalars['String']>;
  lon?: Maybe<Scalars['Float']>;
  /** lot dimensions */
  lotdim?: Maybe<Scalars['String']>;
  /** can be in either acres or sqft, so should not be used for calculations */
  lotsize?: Maybe<Scalars['Float']>;
  /** the prop type mapped to a known value, can be used to render a specific set of data. TODO: convert to ENUM */
  mapped_prop_type?: Maybe<Scalars['String']>;
  /** the status mapped to a known value, can be used to render UI specific for that status. TODO: convert to ENUM */
  mapped_status?: Maybe<Scalars['String']>;
  mlsnum?: Maybe<Scalars['String']>;
  /** the broker representing the seller */
  office_list?: Maybe<OfficeList>;
  /** the broker representing the buyer */
  office_sell?: Maybe<OfficeList>;
  photo_count?: Maybe<Scalars['String']>;
  /** photo urls */
  photos?: Maybe<Array<Scalars['String']>>;
  photos_updated_at?: Maybe<Scalars['String']>;
  /** uses the sold price if available, list price of not */
  price?: Maybe<Scalars['Int']>;
  price_list?: Maybe<Scalars['Int']>;
  price_list_low?: Maybe<Scalars['Int']>;
  price_list_orig?: Maybe<Scalars['Int']>;
  price_list_per_sqft?: Maybe<Scalars['Float']>;
  price_sold?: Maybe<Scalars['Int']>;
  price_sold_per_sqft?: Maybe<Scalars['Float']>;
  prop_sub_type?: Maybe<Scalars['String']>;
  prop_type?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  remarks_private?: Maybe<Scalars['String']>;
  sale_rent?: Maybe<Scalars['String']>;
  school_district?: Maybe<Scalars['String']>;
  school_elementary?: Maybe<Scalars['String']>;
  school_high?: Maybe<Scalars['String']>;
  school_middle?: Maybe<Scalars['String']>;
  showing_inst?: Maybe<Scalars['String']>;
  /** the total living space of the property */
  sqft?: Maybe<Scalars['Int']>;
  sqft_source?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
  subdivision?: Maybe<Scalars['String']>;
  taxes?: Maybe<Scalars['Float']>;
  /** reflects the timestamp from the MLS, not when we pulled the update */
  updated_at?: Maybe<Scalars['String']>;
  /** can be in the future for unbuilt properties */
  year_built?: Maybe<Scalars['Int']>;
  /** the 5 digit zipcode */
  zipcode?: Maybe<Scalars['String']>;
};


export type ListingResultPhotosArgs = {
  max?: InputMaybe<Scalars['Int']>;
};

export enum LwaStatus {
  Connected = 'Connected',
  NotConnected = 'NotConnected'
}

export type MapPin = {
  count?: Maybe<Scalars['Int']>;
  geohash?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  records?: Maybe<Array<PinResult>>;
};

export type Mls = {
  active?: Maybe<Scalars['Boolean']>;
  bundles?: Maybe<Array<Bundle>>;
  code?: Maybe<Scalars['String']>;
  display_upsell_options?: Maybe<Scalars['Boolean']>;
  enable_app_switcher?: Maybe<Scalars['Boolean']>;
  forge_config?: Maybe<Scalars['JSON']>;
  has_homebeat?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  is_child?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  platform_products?: Maybe<Array<PlatformProduct>>;
  products?: Maybe<Array<Product>>;
  state?: Maybe<Scalars['String']>;
  territory_code?: Maybe<Scalars['String']>;
};

export type MlsCredential = {
  active?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type MlsCredentialInput = {
  code: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export enum ObjectTypeEnum {
  /** represents a field, can be used to scope further requests for suggestions */
  Field = 'field',
  /** represents a particular listing (like an address result) */
  Listing = 'listing',
  /** represents an option from within a field */
  Lookup = 'lookup'
}

export type OfficeList = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type Payment = {
  card_type?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  last_4?: Maybe<Scalars['String']>;
  masked_number?: Maybe<Scalars['String']>;
};

export type PinResult = {
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type PlatformAddOn = ItemableInterface & {
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  tagline?: Maybe<Scalars['String']>;
};

export type PlatformBundle = ItemableInterface & {
  billing_frequency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  products?: Maybe<Array<PlatformProduct>>;
  site_license?: Maybe<Scalars['Boolean']>;
};

export type PlatformDiscount = {
  code?: Maybe<Scalars['String']>;
  discount_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
};

export type PlatformLineItem = {
  created_at?: Maybe<Scalars['ISO8601DateTime']>;
  external_user_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  itemable?: Maybe<ItemableInterface>;
  itemable_id?: Maybe<Scalars['Int']>;
  itemable_type?: Maybe<Scalars['String']>;
  locked_until?: Maybe<Scalars['ISO8601DateTime']>;
  plan_name?: Maybe<Scalars['String']>;
  platform_discount?: Maybe<PlatformDiscount>;
  platform_discount_id?: Maybe<Scalars['Int']>;
  platform_subscription_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['ISO8601DateTime']>;
};

export type PlatformProduct = ItemableInterface & {
  add_ons?: Maybe<Array<PlatformAddOn>>;
  id: Scalars['ID'];
  key: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
  tagline: Scalars['String'];
};

export type PlatformSubscription = {
  balance?: Maybe<Scalars['Float']>;
  braintree_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['ISO8601DateTime']>;
  current_billing_cycle?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['ISO8601DateTime']>;
  external_user_id?: Maybe<Scalars['Int']>;
  first_billing_date?: Maybe<Scalars['ISO8601DateTime']>;
  first_successful_transaction_date?: Maybe<Scalars['ISO8601DateTime']>;
  id?: Maybe<Scalars['ID']>;
  locked_until?: Maybe<Scalars['ISO8601DateTime']>;
  next_bill_date?: Maybe<Scalars['ISO8601DateTime']>;
  next_billing_period_amount?: Maybe<Scalars['Float']>;
  paid_through_date?: Maybe<Scalars['ISO8601DateTime']>;
  plan_name?: Maybe<Scalars['String']>;
  platform_line_items?: Maybe<Array<PlatformLineItem>>;
  price?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['ISO8601DateTime']>;
  user_id?: Maybe<Scalars['Int']>;
};

export type PlatformTransaction = {
  amount?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['ISO8601DateTime']>;
  plan_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type Product = {
  add_ons?: Maybe<Array<AddOn>>;
  discounts?: Maybe<Array<Discount>>;
  external_login_route?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
  provisioned_account?: Maybe<ProvisionedAccount>;
  site_license?: Maybe<Scalars['Boolean']>;
  sort_order?: Maybe<Scalars['Int']>;
  subscribed?: Maybe<Scalars['Boolean']>;
  switch_route?: Maybe<Scalars['String']>;
  tagline: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
};

export enum ProductKey {
  /** Cloud Attract */
  CloudAttract = 'cloud_attract',
  /** Cloud CMA */
  CloudCma = 'cloud_cma',
  /** Cloud MLX */
  CloudMlx = 'cloud_mlx',
  /** Cloud Streams */
  CloudStreams = 'cloud_streams',
  /** Lion Desk */
  LionDesk = 'lion_desk',
  /** Spacio */
  Spacio = 'spacio'
}

/** Autogenerated return type of ProductLogin */
export type ProductLoginPayload = {
  login_path?: Maybe<Scalars['String']>;
};

export type Property = {
  acres?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  baths?: Maybe<Scalars['Float']>;
  beds?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  garages?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  lot_dims?: Maybe<Scalars['String']>;
  lot_size?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  price_estimate?: Maybe<Scalars['Int']>;
  prop_sub_type?: Maybe<Scalars['String']>;
  prop_type?: Maybe<Scalars['String']>;
  sqft?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
  subdivision?: Maybe<Scalars['String']>;
  taxes?: Maybe<Scalars['Int']>;
  year_built?: Maybe<Scalars['Int']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type PropertyInput = {
  acres?: InputMaybe<Scalars['String']>;
  address: Scalars['String'];
  area?: InputMaybe<Scalars['String']>;
  baths?: InputMaybe<Scalars['Float']>;
  beds?: InputMaybe<Scalars['Int']>;
  city?: InputMaybe<Scalars['String']>;
  garages?: InputMaybe<Scalars['Int']>;
  lat?: InputMaybe<Scalars['Float']>;
  lon?: InputMaybe<Scalars['Float']>;
  lot_dims?: InputMaybe<Scalars['String']>;
  lot_size?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  price_estimate?: InputMaybe<Scalars['Int']>;
  prop_sub_type?: InputMaybe<Scalars['String']>;
  prop_type?: InputMaybe<Scalars['String']>;
  sqft?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
  style?: InputMaybe<Scalars['String']>;
  subdivision?: InputMaybe<Scalars['String']>;
  taxes?: InputMaybe<Scalars['Int']>;
  year_built?: InputMaybe<Scalars['Int']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of ProvisionDraftAccounts */
export type ProvisionDraftAccountsPayload = {
  errors?: Maybe<Array<Scalars['String']>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of ProvisionPlatformProduct */
export type ProvisionPlatformProductPayload = {
  errors?: Maybe<Array<Scalars['String']>>;
  provisioned_account?: Maybe<ProvisionedAccount>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ProvisionedAccount = {
  account_metadata?: Maybe<Scalars['JSON']>;
  activate_link?: Maybe<Scalars['String']>;
  existing_account?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  login_link?: Maybe<Scalars['String']>;
  platform_product?: Maybe<PlatformProduct>;
  product?: Maybe<Product>;
  provisioned_at?: Maybe<Scalars['String']>;
  remote_user_email?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type QuerySortInput = {
  dir?: InputMaybe<SortDirection>;
  field?: InputMaybe<Scalars['String']>;
};

export type Recommendation = EventInterface & {
  action?: Maybe<Scalars['String']>;
  actor?: Maybe<ActorInterface>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  listing?: Maybe<Listing>;
  recipients?: Maybe<Array<ActorInterface>>;
  role?: Maybe<Scalars['String']>;
  search: Search;
};

export type Search = {
  consumers?: Maybe<Array<Consumer>>;
  created_at?: Maybe<Scalars['ISO8601DateTime']>;
  filters?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['ID']>;
  limit?: Maybe<Scalars['Int']>;
  listing?: Maybe<Listing>;
  listings?: Maybe<Array<Listing>>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<SearchRole>;
  slug?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['JSON']>;
  total?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['ISO8601DateTime']>;
  user?: Maybe<User>;
};


export type SearchListingArgs = {
  id: Scalars['ID'];
};


export type SearchListingsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  overrides?: InputMaybe<SearchOverridesInput>;
  page?: InputMaybe<Scalars['Int']>;
};

export type SearchField = {
  category_name: SearchFieldCategory;
  /** to display in UI */
  display_name: Scalars['String'];
  /** can be used to determine what kind of UI to present for input */
  input_type: SearchFieldInput;
  /** the key to be used in filters */
  key: Scalars['String'];
  lookups?: Maybe<Array<Scalars['String']>>;
};

export enum SearchFieldCategory {
  Features = 'features',
  Root = 'root',
  Standard = 'standard'
}

export enum SearchFieldInput {
  Multi = 'multi',
  Range = 'range',
  Single = 'single'
}

export type SearchInput = {
  created_at?: InputMaybe<Scalars['ISO8601DateTime']>;
  filters?: InputMaybe<Array<FilterInput>>;
  mls_key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  role: SearchRole;
  sort?: InputMaybe<QuerySortInput>;
  updated_at?: InputMaybe<Scalars['ISO8601DateTime']>;
};

export type SearchOverridesInput = {
  filters?: InputMaybe<Array<FilterInput>>;
  sort?: InputMaybe<QuerySortInput>;
};

export enum SearchRole {
  /** A search the user has started and can resume later */
  Adhoc = 'adhoc',
  /** A collection of listing ids */
  Collection = 'collection',
  /** Allow the agent to pick what the clients get */
  Curated = 'curated',
  /** A saved map polygon */
  Polygon = 'polygon',
  /** The most vanilla of all search types. */
  SavedSearch = 'saved_search',
  /** A search of exactly 1 listing, used to share a single listing */
  Singleton = 'singleton',
  /** A search for inviting clients to get updates */
  Stream = 'stream'
}

export enum SignupSource {
  Sso = 'sso',
  SuiteFastrack = 'suite_fastrack'
}

export type SignupSourceInput = {
  signup_source?: InputMaybe<SignupSource>;
};

export type Sku = {
  billing_cycle?: Maybe<Scalars['Int']>;
  group_key?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  tagline?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SkuInput = {
  id: Scalars['ID'];
  type: SkyTypeEnum;
};

export enum SkyTypeEnum {
  AddOn = 'AddOn',
  Bundle = 'Bundle',
  Product = 'Product'
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Subscription = {
  balance?: Maybe<Scalars['Float']>;
  can_cancel?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['String']>;
  discount?: Maybe<Discount>;
  external_user_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  next_bill_date?: Maybe<Scalars['String']>;
  next_billing_period_amount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  products?: Maybe<Array<Product>>;
  sku?: Maybe<Sku>;
  status?: Maybe<SubscriptionStatusEnum>;
  transactions?: Maybe<Array<Transaction>>;
  user?: Maybe<User>;
};

export enum SubscriptionStatusEnum {
  /** An account in good standing */
  Active = 'active',
  /** An account in past due standing */
  PastDue = 'pastDue',
  /** Within a trial mode */
  Pending = 'pending'
}

export type Suggestion = {
  field?: Maybe<SearchField>;
  listing?: Maybe<Listing>;
  object_type?: Maybe<ObjectTypeEnum>;
  value?: Maybe<Scalars['String']>;
};

export type TaxData = {
  baths?: Maybe<Scalars['String']>;
  beds?: Maybe<Scalars['String']>;
  garage_car_count?: Maybe<Scalars['String']>;
  lot_size_acres?: Maybe<Scalars['String']>;
  lot_size_sqft?: Maybe<Scalars['String']>;
  sqft?: Maybe<Scalars['String']>;
  stories?: Maybe<Scalars['String']>;
  year_built?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of TouchSearch */
export type TouchSearchPayload = {
  errors?: Maybe<Array<Scalars['String']>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of TrackEvent */
export type TrackEventPayload = {
  recorded?: Maybe<Scalars['Boolean']>;
};

export type Transaction = {
  amount?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['String']>;
  discount_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  sku?: Maybe<Sku>;
  subscription?: Maybe<Subscription>;
  type?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateSearch */
export type UpdateSearchPayload = {
  errors?: Maybe<Array<Scalars['String']>>;
  search?: Maybe<Search>;
};

export type User = ActorInterface & {
  add_on_subscriptions?: Maybe<Array<Subscription>>;
  avatar?: Maybe<Avatar>;
  avatar_url?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  company_address_1?: Maybe<Scalars['String']>;
  company_address_2?: Maybe<Scalars['String']>;
  company_avatar?: Maybe<Scalars['FileUpload']>;
  company_avatar_url?: Maybe<Scalars['String']>;
  company_city?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  company_phone?: Maybe<Scalars['String']>;
  company_state?: Maybe<Scalars['String']>;
  company_zip?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  guest_passes?: Maybe<Array<GuestPass>>;
  has_agreed_to_tos?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  initials?: Maybe<Scalars['String']>;
  intercom_user_hash?: Maybe<Scalars['String']>;
  jwt?: Maybe<Scalars['String']>;
  lwa_id?: Maybe<Scalars['String']>;
  lwa_jwt?: Maybe<Scalars['String']>;
  mls?: Maybe<Mls>;
  mls_credential?: Maybe<MlsCredential>;
  name: Scalars['String'];
  new_user?: Maybe<Scalars['Boolean']>;
  payment?: Maybe<Payment>;
  phone_number?: Maybe<Scalars['String']>;
  platform_subscription?: Maybe<PlatformSubscription>;
  platform_transactions?: Maybe<Array<PlatformTransaction>>;
  products?: Maybe<Array<Product>>;
  provisioned_accounts?: Maybe<Array<ProvisionedAccount>>;
  signup_source?: Maybe<Scalars['String']>;
  sso_params?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  subscription?: Maybe<Subscription>;
  tos_id?: Maybe<Scalars['ID']>;
  website?: Maybe<Scalars['String']>;
};

export type UserRegistrationInput = {
  avatar?: InputMaybe<Scalars['FileUpload']>;
  avatar_crop?: InputMaybe<AvatarCropInput>;
  city?: InputMaybe<Scalars['String']>;
  company_address_1?: InputMaybe<Scalars['String']>;
  company_address_2?: InputMaybe<Scalars['String']>;
  company_avatar?: InputMaybe<Scalars['FileUpload']>;
  company_avatar_crop?: InputMaybe<AvatarCropInput>;
  company_city?: InputMaybe<Scalars['String']>;
  company_name?: InputMaybe<Scalars['String']>;
  company_phone?: InputMaybe<Scalars['String']>;
  company_state?: InputMaybe<Scalars['String']>;
  company_zip?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  lwa_id?: InputMaybe<Scalars['String']>;
  lwa_status?: InputMaybe<Array<LwaStatus>>;
  mls_credential?: InputMaybe<MlsCredentialInput>;
  mls_credential_attributes?: InputMaybe<MlsCredentialInput>;
  mls_id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  signup_source?: InputMaybe<SignupSourceInput>;
  state?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type ConnectProductMutationVariables = Exact<{
  service?: InputMaybe<ProductKey>;
}>;


export type ConnectProductMutation = { connectProduct?: { acknowledged: boolean } | null };
