import { Modal } from '@cma/common'

interface CustomizeTomFerryPacksProps {
  isOpen: boolean
  onClose: () => void
}

export function CustomizeTomFerryPacksPacks({
  isOpen,
  onClose
}: CustomizeTomFerryPacksProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Title>Tom Ferry Power Packs</Modal.Title>
      <div className="mt-2">Tom Ferry Packs video / checkout</div>
    </Modal>
  )
}
