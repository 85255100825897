import { api } from '@cma/app'
import {
  CreateReportListingMutation,
  CreateReportListingMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useCreateReportListing() {
  const queryClient = useQueryClient()
  return useMutation<
    CreateReportListingMutation,
    Error,
    CreateReportListingMutationVariables
  >(
    ({ reportId, listing }) => {
      if (!listing.mlsnum && !listing.address) {
        throw new Error('You must include either an mls number or an address')
      }

      const query = gql`
        mutation CreateReportListing($reportId: ID!, $listing: ListingInput!) {
          createListing(reportId: $reportId, input: $listing) {
            listing {
              id
            }
          }
        }
      `
      const variables = { reportId, listing }
      return api<CreateReportListingMutation>({
        query,
        variables
      })
    },
    {
      async onSuccess(_, { reportId }) {
        await queryClient.invalidateQueries(['report', reportId])
      }
    }
  )
}
