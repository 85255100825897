import { ListingPartsFragment } from '@cma/generated/graphql'

export function mapPosition(listings: ListingPartsFragment[]) {
  return listings.map((listing, index) => ({ ...listing, position: index }))
}

export function sortByProperty(
  listings: ListingPartsFragment[],
  sortBy: string,
  sortDir: 'asc' | 'desc'
) {
  return [...listings].sort(
    (
      // Modifying the index signature to accept any value
      // so we can dynamically sort
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      a: ListingPartsFragment & { [key: string]: any },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      b: ListingPartsFragment & { [key: string]: any }
    ) => {
      // Transform snake_case before sorting on the front end
      let transformedSortBy = sortBy
      if (sortBy === 'status_as_letter') transformedSortBy = 'status'
      if (sortBy === 'price_list') transformedSortBy = 'priceList'
      if (sortBy === 'price_sold') transformedSortBy = 'priceSold'
      if (sortBy === 'price_per_sqft') transformedSortBy = 'pricePerSqft'
      if (sortBy === 'prop_type') transformedSortBy = 'propType'
      if (sortBy === 'date_list') transformedSortBy = 'dateList'
      if (sortBy === 'date_sold') transformedSortBy = 'dateSold'

      const aValue = a[transformedSortBy] || ''
      const bValue = b[transformedSortBy] || ''

      let aString = aValue.toLocaleString()
      let bString = bValue.toLocaleString()

      if (sortBy === 'date_list' || sortBy === 'date_sold') {
        const aDate = Date.parse(aValue)
        const bDate = Date.parse(bValue)

        if (!Number.isNaN(aDate)) aString = aDate.toLocaleString()
        if (!Number.isNaN(bDate)) bString = bDate.toLocaleString()

        return sortDir === 'desc'
          ? bString.localeCompare(aString)
          : aString.localeCompare(bString)
      }

      if (
        sortBy === 'price_list' ||
        sortBy === 'price_sold' ||
        sortBy === 'price_pre_sqft'
      ) {
        const priceA = Number(aValue)
        const priceB = Number(bValue)

        if (!Number.isNaN(priceA) && !Number.isNaN(priceB)) {
          if (a > b) return 1
          if (a < b) return -1
          return 0
        }
      }

      return sortDir === 'desc'
        ? bString.localeCompare(aString)
        : aString.localeCompare(bString)
    }
  )
}

export function sortByPosition(listings: ListingPartsFragment[]) {
  return [...listings].sort((a, b) => {
    const posA = a.position || 0
    const posB = b.position || 0
    if (posA > posB) return 1
    if (posA < posB) return -1
    return 0
  })
}
