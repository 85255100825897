import { classNames, formatCurrency } from '@cma/common'
import { PropsWithChildren } from 'react'

type Direction = 'increase' | 'decrease'

export function ListingCard({ children }: PropsWithChildren<unknown>) {
  return <div className="divide-y bg-white">{children}</div>
}

ListingCard.Address = ListingCardAddress
ListingCard.Header = ListingCardHeader
ListingCard.Compare = ListingCardCompare

interface ListingCardAddressProps {
  street?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
  price?: number | null
  status?: string | null
  mappedStatus?: string | null
  direction?: Direction | null
}

function ListingCardAddress({
  street,
  city,
  state,
  zip,
  price,
  status,
  mappedStatus,
  direction
}: ListingCardAddressProps) {
  const cityStateZip = [city, [state, zip].filter(Boolean).join('')]
    .filter(Boolean)
    .join(', ')

  return (
    <div className="space-y-0.5 p-3">
      <div className="flex items-baseline justify-between gap-2">
        <div
          className="truncate font-semibold text-gray-900"
          title={street || 'No address'}>
          {street || 'No address'}
        </div>
        <div className="flex min-w-0 items-baseline gap-1 font-semibold text-gray-900">
          {direction && (
            <div
              className={classNames({
                'text-green-600': direction === 'increase',
                'text-red-600': direction === 'decrease'
              })}>
              {direction === 'increase' && '↑'}
              {direction === 'decrease' && '↓'}
            </div>
          )}
          <div className="truncate" title={price ? formatCurrency(price) : ''}>
            {price ? formatCurrency(price) : '-'}
          </div>
        </div>
      </div>
      <div className="flex items-baseline justify-between gap-2">
        <div className="truncate text-xs text-gray-800" title={cityStateZip}>
          {cityStateZip || <span className="text-gray-500">No location</span>}
        </div>
        <div
          className={classNames('truncate text-xs font-bold', {
            'text-green-600': mappedStatus === 'active',
            'text-yellow-600': mappedStatus === 'pending',
            'text-red-600':
              mappedStatus === 'closed' || mappedStatus === 'deleted',
            'text-sky-600': mappedStatus === 'backup',
            'text-gray-600': ![
              'active',
              'pending',
              'closed',
              'deleted',
              'backup'
            ].includes(mappedStatus || '')
          })}
          title={status || ''}>
          {status || '-'}
        </div>
      </div>
    </div>
  )
}

function ListingCardHeader({ children }: PropsWithChildren<unknown>) {
  return (
    <div className="px-3 pt-4 pb-3 font-semibold text-gray-900">{children}</div>
  )
}

interface ListingCardCompareProps {
  label: string
  value: string
  change?: string
  direction?: Direction
  highlight?: boolean
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

function ListingCardCompare({
  label,
  value,
  change,
  direction,
  highlight = false,
  onMouseEnter,
  onMouseLeave
}: ListingCardCompareProps) {
  return (
    <div
      className={classNames(
        'flex items-center justify-between gap-3 p-3 transition-colors',
        {
          'bg-gray-50': highlight
        }
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <div
        className="flex items-baseline gap-1 truncate text-sm text-gray-800"
        title={label}>
        <div>{label}</div>
        {direction && (
          <div
            className={classNames(
              'flex min-w-0 items-center gap-1 text-xs font-bold',
              {
                'text-green-600': direction === 'increase',
                'text-red-600': direction === 'decrease'
              }
            )}>
            {direction === 'increase' && <span className="shrink-0">↑</span>}
            {direction === 'decrease' && <span className="shrink-0">↓</span>}
            {change && <div className="min-w-0 truncate">{change}</div>}
          </div>
        )}
      </div>
      <div
        className="shrink-0 truncate text-sm font-bold text-gray-900"
        title={value}>
        {value || '-'}
      </div>
    </div>
  )
}
