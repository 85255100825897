import { api } from '@cma/app'
import {
  AddContactItemMutation,
  MutationCreateContactItemArgs
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useAddContactItem() {
  const queryClient = useQueryClient()
  return useMutation<
    AddContactItemMutation,
    Error,
    MutationCreateContactItemArgs
  >(
    ({ input }) => {
      const query = gql`
        mutation AddContactItem($input: ContactItemInput!) {
          createContactItem(input: $input) {
            contactItem {
              id
            }
            errors
          }
        }
      `
      const variables = { input }
      return api<AddContactItemMutation>({ query, variables })
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries(['contactItems'])
      }
    }
  )
}
