import { api } from '@cma/app'
import {
  InviteBrokerAgentsMutation,
  InviteBrokerAgentsMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useInviteBrokerAgents() {
  const queryClient = useQueryClient()
  return useMutation<
    InviteBrokerAgentsMutation,
    Error,
    InviteBrokerAgentsMutationVariables
  >(
    ({ accountId, agentIds }) => {
      const query = gql`
        mutation InviteBrokerAgents($accountId: ID!, $agentIds: [ID!]) {
          sendAgentInvites(accountId: $accountId, userIds: $agentIds) {
            errors
          }
        }
      `
      const variables = { accountId, agentIds }
      return api({ query, variables })
    },
    {
      async onSuccess(_, { accountId }) {
        await Promise.all([
          queryClient.invalidateQueries(['broker', accountId]),
          queryClient.invalidateQueries(['brokerAgents', accountId]),
          queryClient.invalidateQueries(['brokerStats', accountId])
        ])
      }
    }
  )
}
