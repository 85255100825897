import { api } from '@cma/app'
import {
  RequestResetPasswordMutation,
  RequestResetPasswordMutationVariables
} from '@cma/generated/graphql'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useRequestResetPassword() {
  return useMutation<
    RequestResetPasswordMutation,
    Error,
    RequestResetPasswordMutationVariables
  >(({ email }) => {
    const query = gql`
      mutation RequestResetPassword($email: String!) {
        requestResetPassword(email: $email)
      }
    `
    const variables = { email }
    return api<RequestResetPasswordMutation>({ query, variables })
  })
}
