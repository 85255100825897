import { Button, FormField, Input, Modal } from '@cma/common'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { CustomizeButton } from './CustomizeButton'

const schema = yup.object({
  description: yup.string()
})

interface CustomizeDescriptionProps {
  description?: string
  loading?: boolean
  error?: string
  onUpdate: (description?: string) => Promise<void>
}

export function CustomizeDescription({
  description,
  loading,
  error,
  onUpdate
}: CustomizeDescriptionProps) {
  const [showModal, setShowModal] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      description: description
    }
  })

  useEffect(() => {
    if (showModal) {
      setTimeout(() => setFocus('description', { shouldSelect: true }))
    }
  }, [setFocus, showModal])

  return (
    <>
      <CustomizeButton
        error={error}
        loading={loading}
        icon={
          <svg viewBox="-4 -2 24 24" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
              <path d="M-4-2h24v24H-4z" />
              <path
                fill="currentColor"
                d="M10 0H2C.9 0 .01.9.01 2L0 18c0 1.1.89 2 1.99 2H14c1.1 0 2-.9 2-2V6l-6-6zm2 16H4v-2h8v2zm0-4H4v-2h8v2zM9 7V1.5L14.5 7H9z"
              />
            </g>
          </svg>
        }
        title="Description"
        subtitle={description || 'No Description'}
        onClick={() => setShowModal(true)}
      />

      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <Modal.Title>Description</Modal.Title>
        <form
          className="mt-3 space-y-4"
          onSubmit={handleSubmit(async (data) => {
            await onUpdate(data.description)
            setShowModal(false)
          })}>
          <FormField
            label="Enter Description"
            error={errors.description?.message || error}>
            <Input as="textarea" rows={5} {...register('description')} />
          </FormField>
          <div className="flex justify-center">
            <Button loading={loading}>Update Description</Button>
          </div>
        </form>
      </Modal>
    </>
  )
}
