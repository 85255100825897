import { useCurrentUser } from '@cma/app'
import {
  Button,
  classNames,
  formatDate,
  FormField,
  getGoogleAddress,
  Input,
  Tooltip,
  useTimedState
} from '@cma/common'
import {
  ListingDescription,
  ListingDisclaimer,
  ListingFeatures,
  ListingFullscreenPhotos,
  ListingHeader,
  ListingHistory,
  ListingPhotos,
  ListingPrice,
  ListingRemarks,
  ListingSchools,
  ListingStats
} from '@cma/features/listing'
import { TourAppointment } from '@cma/generated/graphql'
import {
  ArrowLeftIcon,
  DirectionIcon,
  HeartIcon,
  HeartOutlineIcon,
  NoteIcon
} from '@cma/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { AnimatePresence, motion } from 'framer-motion'
import { useImperativeHandle, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import TextareaAutosize from 'react-textarea-autosize'
import * as yup from 'yup'
import { useTour } from './useTour'
import { useUpdateTourListing } from './useUpdateTourListing'

interface TourAppointmentDetailsProps {
  tourId?: string
  appointment?: TourAppointment
  isFullscreen?: boolean
  isPrivate?: boolean
  isOpen: boolean
  onClose: () => void
}

export function TourAppointmentDetails({
  tourId,
  appointment,
  isFullscreen,
  isPrivate,
  isOpen,
  onClose
}: TourAppointmentDetailsProps) {
  return (
    <AnimatePresence>
      {appointment && isOpen && (
        <>
          <motion.div
            className="absolute inset-0 bg-white/40 backdrop-blur-sm"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: isFullscreen ? 0 : undefined }}
          />
          <motion.div
            className={classNames(
              'absolute z-10 mx-auto flex flex-col overflow-y-auto bg-white',
              {
                'top-8 left-8 right-8 bottom-0 rounded-t-xl shadow-xl':
                  !isFullscreen,
                'inset-0': isFullscreen
              }
            )}
            initial={{ scale: 0.97, translateY: 25, opacity: 0 }}
            animate={{ scale: 1, translateY: 0, opacity: 1 }}
            exit={{ scale: 0.97, translateY: 25, opacity: 0 }}
            transition={{ duration: isFullscreen ? 0 : 0.25 }}>
            <Details
              tourId={tourId}
              key={appointment.listing.id}
              appointment={appointment}
              isPrivate={isPrivate}
              onClose={onClose}
            />
          </motion.div>
        </>
      )}
    </AnimatePresence>
  )
}

const schema = yup.object({
  note: yup.string().required('A note is required')
})

interface DetailsProps {
  tourId?: string
  appointment: TourAppointment
  isPrivate?: boolean
  onClose: () => void
}

const variants = {
  toggle: (note: string) => ({
    height: note ? 'auto' : 0,
    opacity: note ? 1 : 0
  })
}

function Details({ tourId, appointment, isPrivate, onClose }: DetailsProps) {
  const { data: { currentUser } = {} } = useCurrentUser()
  const { data: { tour } = {} } = useTour({ id: tourId })
  const [isLiked, setIsLiked] = useState(
    appointment.listing.rating ? true : false
  )
  const notesRef = useRef<HTMLTextAreaElement>()
  const { mutate: updateListingLike } = useUpdateTourListing()
  const { mutate: updateListingNote, isLoading: isUpdatingNote } =
    useUpdateTourListing()
  const [fullscreenPhotoPage, setFullscreenPhotoPage] = useState<number | null>(
    null
  )
  const [noteUpdated, startNoteUpdateTimer] = useTimedState()
  const cityStateZip = [
    appointment.listing.city,
    [appointment.listing.state, appointment.listing.zip]
      .filter(Boolean)
      .join(' ')
  ]
    .filter(Boolean)
    .join(', ')
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      note: appointment.listing.comment || ''
    }
  })

  const { ref: registerRef, ...rest } = register('note')
  useImperativeHandle(registerRef, () => notesRef.current)

  const googleAddress = getGoogleAddress({
    address: appointment.listing.address,
    city: appointment.listing.city,
    state: appointment.listing.state,
    zip: appointment.listing.zip
  })

  return (
    <>
      <ListingHeader
        address={appointment.listing.address}
        city={appointment.listing.city}
        state={appointment.listing.state}
        zip={appointment.listing.zip}
        icon={fullscreenPhotoPage !== null ? <ArrowLeftIcon /> : undefined}
        onClose={() =>
          fullscreenPhotoPage !== null
            ? setFullscreenPhotoPage(null)
            : onClose()
        }>
        <div className="flex items-center gap-2 md:flex">
          {!!tourId && (
            <>
              <div className="hidden md:block">
                <Button
                  disabled={!tourId}
                  variant="secondary"
                  onClick={() => {
                    setFullscreenPhotoPage(null)
                    setTimeout(() => {
                      notesRef.current?.scrollIntoView({ behavior: 'smooth' })
                      notesRef.current?.focus()
                    })
                  }}>
                  <NoteIcon className="h-5 w-5" />
                  <div className="sr-only">Add note</div>
                </Button>
              </div>
              <div className="block md:hidden">
                <Button
                  disabled={!tourId}
                  variant="secondary"
                  size="sm"
                  onClick={() => {
                    setFullscreenPhotoPage(null)
                    notesRef.current?.scrollIntoView({ behavior: 'smooth' })
                    notesRef.current?.focus()
                  }}>
                  <NoteIcon className="h-4 w-4" />
                  <div className="sr-only">Add note</div>
                </Button>
              </div>
              <div className="hidden md:block">
                <Button
                  disabled={!tourId}
                  variant="secondary"
                  onClick={() => {
                    if (tourId) {
                      updateListingLike({
                        tourId,
                        id: appointment.listing.id,
                        input: {
                          rating: isLiked ? 0 : 1,
                          comment: appointment.listing.comment
                        }
                      })
                      setIsLiked((isLiked) => !isLiked)
                    }
                  }}>
                  {isLiked ? (
                    <HeartIcon className="h-5 w-5 text-red-500" />
                  ) : (
                    <HeartOutlineIcon className="h-5 w-5" />
                  )}
                  <div className="sr-only">{isLiked ? 'Unlike' : 'Like'}</div>
                </Button>
              </div>
              <div className="block md:hidden">
                <Button
                  disabled={!tourId}
                  variant="secondary"
                  size="sm"
                  onClick={() => {
                    if (tourId) {
                      updateListingLike({
                        tourId,
                        id: appointment.listing.id,
                        input: {
                          rating: isLiked ? 0 : 1,
                          comment: appointment.listing.comment
                        }
                      })
                      setIsLiked((isLiked) => !isLiked)
                    }
                  }}>
                  {isLiked ? (
                    <HeartIcon className="h-4 w-4 text-red-500" />
                  ) : (
                    <HeartOutlineIcon className="h-4 w-4" />
                  )}
                  <div className="sr-only">{isLiked ? 'Unlike' : 'Like'}</div>
                </Button>
              </div>
            </>
          )}
          <div className="hidden md:block">
            <Button
              as="a"
              target="_blank"
              rel="noreferrer"
              href={`https://www.google.com/maps/dir//${googleAddress}`}
              leftIcon={<DirectionIcon />}>
              <span className="whitespace-nowrap">Get Directions</span>
            </Button>
          </div>
          <div className="block md:hidden">
            <Button
              as="a"
              target="_blank"
              rel="noreferrer"
              href={`https://www.google.com/maps/dir//${googleAddress}`}
              leftIcon={<DirectionIcon />}
              size="sm">
              <span className="whitespace-nowrap">Get Directions</span>
            </Button>
          </div>
        </div>
      </ListingHeader>
      {fullscreenPhotoPage === null ? (
        <>
          <ListingPhotos
            virtualTourLink={appointment.listing.features?.['Virtual Tour']}
            photos={appointment.listing.photos || []}
            onPhotoClick={(index) => setFullscreenPhotoPage(index)}
          />
          <div className="mx-auto flex w-full max-w-5xl flex-col items-start justify-between gap-6 p-6 xl:flex-row">
            {(appointment.listing.address || cityStateZip) && (
              <div className="font-semibold md:hidden">
                {appointment.listing.address && (
                  <div
                    className="truncate text-2xl text-gray-900"
                    title={appointment.listing.address}>
                    {appointment.listing.address}
                  </div>
                )}
                {cityStateZip && (
                  <div className="truncate text-gray-500" title={cityStateZip}>
                    {cityStateZip}
                  </div>
                )}
              </div>
            )}
            <div>
              <motion.div
                className="overflow-hidden"
                variants={variants}
                custom={appointment.note}
                initial={false}
                animate="toggle"
                transition={{
                  height: { ease: 'easeInOut', duration: 0.15 },
                  opacity: { delay: 0.1, duration: 0.15 }
                }}>
                <div className="pb-6 md:pb-12">
                  <ListingRemarks
                    remarks={appointment.note || ''}
                    agentName={tour?.id ? tour?.agent?.name : currentUser?.name}
                    agentAvatar={
                      tour?.id ? tour?.agent?.avatar : currentUser?.avatar
                    }
                  />
                </div>
              </motion.div>
              <div className="grid grid-cols-1 gap-6 md:gap-12">
                <ListingStats
                  beds={appointment.listing.beds}
                  baths={appointment.listing.baths}
                  sqft={appointment.listing.sqft}
                  yearBuilt={appointment.listing.yearBuilt}
                  garages={
                    appointment.listing.garages
                      ? Number(appointment.listing.garages)
                      : null
                  }
                  lotSize={appointment.listing.lotsize}
                />
                {!!appointment.listing.remarks && (
                  <ListingDescription
                    description={appointment.listing.remarks}
                  />
                )}
                {isPrivate && !!appointment.listing.remarksPrivate && (
                  <ListingRemarks
                    isPrivate
                    remarks={appointment.listing.remarksPrivate}
                    agentName={appointment.listing.agentList?.name}
                    agentCompany={appointment.listing.officeList?.name}
                    agentEmail={appointment.listing.agentList?.email}
                    agentPhone={appointment.listing.agentList?.phone}
                  />
                )}
                {!!appointment.listing.features && (
                  <div className="space-y-6">
                    <h1 className="text-2xl font-semibold">Details</h1>
                    <ListingFeatures features={appointment.listing.features} />
                  </div>
                )}
                {!!appointment.listing.schools?.length && (
                  <div className="space-y-6">
                    <h1 className="text-2xl font-semibold">Schools</h1>
                    <ListingSchools schools={appointment.listing.schools} />
                  </div>
                )}

                {!!appointment.listing.changes?.length && (
                  <div className="space-y-6">
                    <h1 className="text-2xl font-semibold">History</h1>
                    <ListingHistory history={appointment.listing.changes} />
                    <div className="text-xs italic text-gray-400">
                      Last Updated:{' '}
                      {appointment.listing.updatedAt
                        ? formatDate(
                            appointment.listing.updatedAt,
                            "MM/dd/yy 'at' h:mma"
                          )
                        : '-'}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="sticky top-6 w-full shrink-0 space-y-4 xl:max-w-[22.5rem]">
              <div className=" divide-y rounded bg-white shadow-raised">
                <div className="py-3 px-4">
                  <ListingPrice
                    price={appointment.listing.price}
                    pricePerSqft={appointment.listing.pricePerSqft}
                    originalPriceList={appointment.listing.priceListOrig}
                    priceList={appointment.listing.priceList}
                    priceSold={appointment.listing.priceSold}
                    beds={appointment.listing.beds}
                    baths={appointment.listing.baths}
                    sqft={appointment.listing.sqft}
                    dateList={appointment.listing.dateList}
                    dateSold={appointment.listing.dateSold}
                  />
                </div>
                <div className="py-4 px-3">
                  <form
                    className="space-y-2"
                    onSubmit={handleSubmit((data) => {
                      if (tour?.id) {
                        updateListingNote(
                          {
                            tourId: tour.id,
                            id: appointment.listing.id,
                            input: {
                              rating: appointment.listing.rating || 0,
                              comment: data.note
                            }
                          },
                          {
                            onSuccess() {
                              startNoteUpdateTimer()
                            }
                          }
                        )
                      }
                    })}>
                    <FormField
                      label="Showing Notes"
                      tip="Anyone with a link can edit."
                      error={errors.note?.message}>
                      <Input
                        disabled={!tour?.id}
                        as={TextareaAutosize}
                        ref={(e: HTMLTextAreaElement) => {
                          registerRef(e)
                          notesRef.current = e
                        }}
                        {...rest}
                      />
                    </FormField>
                    <Tooltip
                      content="You must save your Tour before you can leave a note on a listing"
                      disabled={!!tour?.id}>
                      <div>
                        <Button
                          variant="secondary"
                          fullWidth
                          size="sm"
                          loading={isUpdatingNote}
                          disabled={!tour?.id}>
                          {noteUpdated ? 'Note saved!' : 'Save Note'}
                        </Button>
                      </div>
                    </Tooltip>
                  </form>
                </div>
              </div>
              {appointment.listing.mlsnum && (
                <ListingDisclaimer
                  mlsNumber={appointment.listing.mlsnum}
                  brokerageName={appointment.listing.officeList?.name}
                  lastUpdated={appointment.listing.updatedAt}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <ListingFullscreenPhotos
          key={appointment.listing.id}
          photos={appointment.listing.photos || []}
          initialPage={fullscreenPhotoPage}
        />
      )}
    </>
  )
}
