import { api } from '@cma/app'
import {
  ResetPasswordMutation,
  ResetPasswordMutationVariables
} from '@cma/generated/graphql'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useResetPassword() {
  return useMutation<
    ResetPasswordMutation,
    Error,
    ResetPasswordMutationVariables
  >(({ password, passwordConfirmation, resetPasswordToken }) => {
    const query = gql`
      mutation ResetPassword(
        $password: String!
        $passwordConfirmation: String!
        $resetPasswordToken: String!
      ) {
        resetPassword(
          password: $password
          passwordConfirmation: $passwordConfirmation
          resetPasswordToken: $resetPasswordToken
        ) {
          user {
            id
          }
          errors
        }
      }
    `
    const variables = { password, passwordConfirmation, resetPasswordToken }
    return api<ResetPasswordMutation>({ query, variables })
  })
}
