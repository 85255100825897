export function EmailIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      {...props}>
      <path
        d="M1.25 4A.75.75 0 0 1 2 3.25h16a.75.75 0 0 1 .75.75v12a.75.75 0 0 1-.75.75H2a.75.75 0 0 1-.75-.75V4zm2.746.75L10 10.003l6.004-5.253H3.996zm13.254.903-6.756 5.911a.75.75 0 0 1-.988 0L2.75 5.653v9.597h14.5V5.653z"
        clipRule="evenodd"
        fill="currentColor"
      />
    </svg>
  )
}
