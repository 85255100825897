import { api } from '@cma/app'
import { TranslationsQuery } from '@cma/generated/graphql'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

// The locale is determined on the backend through the
// users mls and will default to `en` if not found.
export function useTranslations() {
  return useQuery<TranslationsQuery, Error>(['translations'], () => {
    const query = gql`
      query Translations {
        translations {
          mls {
            label
          }
          listing {
            county
          }
        }
      }
    `
    return api<TranslationsQuery>({ query })
  })
}
