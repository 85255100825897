import { api } from '@cma/app'
import { ReportTemplatesQuery } from '@cma/generated/graphql'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function getReportTemplates(id?: string) {
  const query = gql`
    query ReportTemplates($id: ID!) {
      reportTemplates: userReportTemplates(reportId: $id) {
        pageTemplates {
          id
          title
          pageCount
          isCustom
        }
      }
    }
  `
  const variables = { id }
  return api<ReportTemplatesQuery>({ query, variables })
}

export function useReportTemplates(id?: string) {
  return useQuery<ReportTemplatesQuery, Error>(['reportTemplates', id], () =>
    getReportTemplates(id)
  )
}
