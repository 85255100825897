import { api } from '@cma/app'
import {
  RemoveIntegrationMutation,
  RemoveIntegrationMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useRemoveIntegration() {
  const queryClient = useQueryClient()
  return useMutation<
    RemoveIntegrationMutation,
    Error,
    RemoveIntegrationMutationVariables
  >(
    ({ key }) => {
      const query = gql`
        mutation RemoveIntegration($key: IntegrationTypeEnum!) {
          disconnectIntegration(key: $key) {
            result
            errors
          }
        }
      `
      const variables = { key }
      return api<RemoveIntegrationMutation>({ query, variables })
    },
    {
      async onSuccess() {
        await Promise.all([
          queryClient.invalidateQueries(['integrations']),
          queryClient.invalidateQueries(['currentUser'])
        ])
      }
    }
  )
}
