const STORAGE_KEY = 'accessToken'
const IMPERSONATE_STORAGE_KEY = 'impersonateAccessToken'

export function getAccessTokens() {
  const accessToken = window.localStorage.getItem(STORAGE_KEY)
  const impersonateToken = window.sessionStorage.getItem(
    IMPERSONATE_STORAGE_KEY
  )
  const currentToken = impersonateToken || accessToken

  return {
    accessToken,
    impersonateToken,
    currentToken
  }
}

export function setAccessTokens(
  token?: string | null,
  impersonateToken?: string | null
) {
  if (token) {
    window.localStorage.setItem(STORAGE_KEY, token)
  }

  if (impersonateToken) {
    window.sessionStorage.setItem(IMPERSONATE_STORAGE_KEY, impersonateToken)
  }
}

export function removeAccessTokens() {
  window.localStorage.removeItem(STORAGE_KEY)
  window.sessionStorage.removeItem(IMPERSONATE_STORAGE_KEY)
}
