export function ErrorIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.384 2.573a1.958 1.958 0 0 0-2.769 0L2.573 8.615a1.958 1.958 0 0 0 0 2.77l6.042 6.042a1.958 1.958 0 0 0 2.77 0l6.042-6.043a1.958 1.958 0 0 0 0-2.769l-6.043-6.042Zm-1.377 9.329a.725.725 0 0 0 .723-.678l.335-5.16A1 1 0 0 0 10.067 5h-.12a1 1 0 0 0-.998 1.065l.335 5.16a.725.725 0 0 0 .723.677Zm-.777 1.246a1.067 1.067 0 0 0-.322.784c0 .308.108.57.322.784.215.215.472.322.77.322.308 0 .57-.107.784-.322.224-.215.336-.476.336-.784 0-.308-.112-.57-.336-.784a1.045 1.045 0 0 0-.784-.336 1.03 1.03 0 0 0-.77.336Z"
        fill="currentColor"
      />
    </svg>
  )
}
