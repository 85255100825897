import { api } from '@cma/app'
import {
  PotentialUsersQuery,
  PotentialUsersQueryVariables
} from '@cma/generated/graphql'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function usePotentialUser(variables: PotentialUsersQueryVariables) {
  return useQuery<PotentialUsersQuery, Error>(
    ['potentialUsers', variables],
    () => {
      const query = gql`
        fragment PotentialUsersParts on User {
          id
          name
          email
          belongsToBroker
          companyName
          account {
            key
            name
          }
          mlsCredential {
            id
            code
            mlsName
            name
          }
          stats {
            buyerCount
            cmaCount
            flyerCount
            propertyCount
          }
          lastSignInAt
        }

        query PotentialUsers($token: String!) {
          potentialUsers(token: $token) {
            ...PotentialUsersParts
          }
        }
      `
      return api<PotentialUsersQuery, PotentialUsersQueryVariables>({
        query,
        variables
      })
    }
  )
}
