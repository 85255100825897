import { api } from '@cma/app'
import {
  CreateCustomPdfMutation,
  CreateCustomPdfMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useCreateCustomPdf() {
  const queryClient = useQueryClient()
  return useMutation<
    CreateCustomPdfMutation,
    Error,
    CreateCustomPdfMutationVariables
  >(
    ({ input }) => {
      const query = gql`
        mutation CreateCustomPdf($input: CustomPdfInput!) {
          createCustomPdf(input: $input) {
            customPdf {
              id
            }
            errors
          }
        }
      `
      const variables = { input }
      return api<CreateCustomPdfMutation>({ query, variables })
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries(['customPdfs'])
      }
    }
  )
}
