import { School } from '@cma/generated/graphql'

interface ListingSchoolsProps {
  schools: School[]
}

export function ListingSchools({ schools }: ListingSchoolsProps) {
  if (!schools.length) {
    return null
  }

  const formattedSchools = schools.map((school) => {
    const levels = school.level?.split(',') || ''
    return {
      ...school,
      distance: school?.distance ? `${school.distance.toFixed(3)} mi` : '',
      level:
        levels?.length > 1
          ? `${levels?.[0]}-${levels?.[levels?.length - 1]}`
          : levels
    }
  })

  return (
    <ul className="space-y-2.5">
      {formattedSchools.map(({ name, distance, level }) => (
        <li
          key={`${name}-${level}`}
          className="flex justify-between text-sm text-gray-900">
          <div>
            <strong className="font-semibold">{name}</strong>
          </div>
          <div className="flex gap-10 whitespace-nowrap">
            <span>{level}</span>
            <span>{distance}</span>
          </div>
        </li>
      ))}
    </ul>
  )
}
