export function PhotoIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2.25A1.75 1.75 0 0 0 1.25 4v12c0 .966.784 1.75 1.75 1.75h14A1.75 1.75 0 0 0 18.75 16V4A1.75 1.75 0 0 0 17 2.25H3ZM2.75 4A.25.25 0 0 1 3 3.75h14a.25.25 0 0 1 .25.25v6.798L14.488 8.43a.75.75 0 0 0-1.036.057l-7 7.5a.747.747 0 0 0-.16.262H3a.25.25 0 0 1-.25-.25V4Zm14.262 8.57a.747.747 0 0 0 .238.137V16a.25.25 0 0 1-.25.25H8.26l5.798-6.213 2.954 2.532ZM6.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
        fill="currentColor"
      />
    </svg>
  )
}
