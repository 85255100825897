import { MlsPartsFragment } from '@cma/generated/graphql'

export function useMlsesByState(mlses: MlsPartsFragment[], state?: string) {
  const mlsesByStates = mlses.reduce<Record<string, MlsPartsFragment[]>>(
    (byState, mls) => {
      mls.states?.forEach((state) => {
        byState = { ...byState, [state]: [...(byState[state] ?? []), mls] }
      })
      return byState
    },
    {}
  )

  const flatStates = [...new Set(Object.values(mlsesByStates).flat())]
  const mlsesByState = state ? mlsesByStates[state] || flatStates : flatStates

  return { mlsesByStates, mlsesByState }
}
