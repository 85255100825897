import { api } from '@cma/app'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

interface LegacyRoute {
  path: string
}

interface LegacyRouteFeatures {
  hasLiveTours: boolean | null
}

interface LegacyRoutes {
  slugs: Record<string, string>
  features: LegacyRouteFeatures | null
}

export function useLegacyRoutes() {
  return useQuery<LegacyRoutes, Error>(['legacyRoutes'], async () => {
    const query = gql`
      query LegacyRoutes {
        currentUser {
          features {
            hasLiveTours
          }
          legacyRoutes {
            path
          }
        }
      }
    `
    const res = await api<{
      currentUser: {
        features: LegacyRouteFeatures | null
        legacyRoutes: LegacyRoute[] | null
      } | null
    }>({ query })
    return {
      features: res.currentUser?.features || null,
      slugs: (res.currentUser?.legacyRoutes || [])?.reduce((hash, route) => {
        return { ...hash, [route.path]: route.path }
      }, {})
    }
  })
}
