import { copyTextToClipboard, Link } from '@cma/common'
import { EmailReport } from '@cma/features/report'
import { ReportPartsFragment } from '@cma/generated/graphql'
import { CopyIcon, EmailIcon, HomebeatHeartIcon } from '@cma/icons'
import { useEffect, useState } from 'react'

interface PublishShareProps {
  report: ReportPartsFragment
}

export function ReportPublishShare({ report }: PublishShareProps) {
  const [isEmailing, setIsEmailing] = useState(false)
  const [copiedPdf, setCopiedPdf] = useState(false)
  const [copiedLive, setCopiedLive] = useState(false)

  useEffect(() => {
    if (!copiedPdf) return

    const timeout = setTimeout(() => {
      setCopiedPdf(false)
    }, 2000)

    return () => {
      clearTimeout(timeout)
    }
  }, [copiedPdf])

  useEffect(() => {
    if (!copiedLive) return

    const timeout = setTimeout(() => {
      setCopiedLive(false)
    }, 2000)

    return () => {
      clearTimeout(timeout)
    }
  }, [copiedLive])

  return (
    <ul className="space-y-2 rounded-md bg-gray-50 p-6 text-sm text-gray-700">
      <li>
        <button
          className="flex items-center space-x-2"
          onClick={() => setIsEmailing(true)}>
          <EmailIcon className="h-5 w-5 text-gray-600" />
          <span>Email Report</span>
        </button>
        <EmailReport
          report={report}
          isOpen={isEmailing}
          onClose={() => setIsEmailing(false)}
        />
      </li>
      {report?.pdfPermalink && (
        <li>
          <button
            className="flex items-center space-x-2"
            onClick={() => {
              copyTextToClipboard(report.pdfPermalink || '')
              setCopiedPdf(true)
            }}>
            <CopyIcon className="h-5 w-5 text-gray-600" />
            <span>{copiedPdf ? 'PDF Link Copied!' : 'Copy PDF Link'}</span>
          </button>
        </li>
      )}
      {report?.__typename === 'CmaReport' && (
        <>
          <li>
            <button
              className="flex items-center space-x-2"
              onClick={() => {
                copyTextToClipboard(
                  `${process.env.VITE_APP_CMA_URL}/live/${report?.guid}`
                )
                setCopiedLive(true)
              }}>
              <CopyIcon className="h-5 w-5 text-gray-600" />
              <span>{copiedLive ? 'Live Link Copied!' : 'Copy Live Link'}</span>
            </button>
          </li>
          <li>
            <Link
              className="flex items-center space-x-2"
              to={`/homebeats/new?${new URLSearchParams({
                client: report?.title || '',
                address: report?.addressParts?.join(', ') || '',
                beds: String(report?.subjectProperty?.beds || ''),
                baths: String(report?.subjectProperty?.baths || ''),
                sqft: String(report?.subjectProperty?.sqft || ''),
                lot_size: report?.subjectProperty?.lotSize || '',
                garages: report?.subjectProperty?.garages?.toString() || '',
                prop_type: report?.subjectProperty?.propType || '',
                prop_sub_type: report?.subjectProperty?.propSubType || ''
              }).toString()}`}>
              <HomebeatHeartIcon className="h-5 w-5 text-gray-600" />
              <span>Create Homebeat</span>
            </Link>
          </li>
        </>
      )}
    </ul>
  )
}
