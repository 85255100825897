import { ElementType, ForwardedRef, forwardRef } from 'react'
import {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef
} from './polymorphic'
import { classNames } from './utils'

const defaultElement = 'h3'

interface TitleProps {
  align?: 'left' | 'right' | 'center'
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
}

export const Title: PolymorphicForwardRefExoticComponent<
  TitleProps,
  typeof defaultElement
> = forwardRef(function Title<T extends ElementType = typeof defaultElement>(
  {
    as,
    align = 'center',
    size = '2xl',
    ...props
  }: PolymorphicPropsWithoutRef<TitleProps, T>,
  ref: ForwardedRef<Element>
) {
  const Element: ElementType = as || defaultElement

  return (
    <Element
      ref={ref}
      {...props}
      className={classNames(
        `text-center font-semibold leading-6`,
        {
          'text-left': align === 'left',
          'text-center': align === 'center',
          'text-right': align === 'right'
        },
        {
          'text-lg': size === 'lg',
          'text-2xl': size === '2xl',
          'text-xl': size === 'xl',
          'text-sm': size === 'sm',
          'text-md': size === 'md'
        }
      )}>
      {props.children}
    </Element>
  )
})
