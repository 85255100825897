import { api } from '@cma/app'
import {
  EmailHomeWorthMutation,
  EmailHomeWorthMutationVariables
} from '@cma/generated/graphql'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useEmailHomeWorth() {
  return useMutation<
    EmailHomeWorthMutation,
    Error,
    EmailHomeWorthMutationVariables
  >(({ input }) => {
    const query = gql`
      mutation EmailHomeWorth($input: CmaWidgetInput!) {
        cmaWidget(input: $input) {
          errors
        }
      }
    `
    const variables = { input }
    return api({ query, variables })
  })
}
