import { api } from '@cma/app'
import {
  CreateFlyerMutation,
  CreateFlyerMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { getReport } from '../report'

export function useCreateFlyer() {
  const queryClient = useQueryClient()
  return useMutation<CreateFlyerMutation, Error, CreateFlyerMutationVariables>(
    ({ input }) => {
      const mutation = gql`
        mutation CreateFlyer($input: FlyerInput!) {
          createFlyer(input: $input) {
            flyer {
              id
            }
            errors
          }
        }
      `
      const variables = { input }
      return api<CreateFlyerMutation>({ query: mutation, variables })
    },
    {
      async onSuccess(data) {
        const id = data.createFlyer?.flyer?.id
        await queryClient.prefetchQuery(['report', id], () => getReport(id))
      }
    }
  )
}
