import { Pagination, usePaginate, Wrapper } from '@cma/common'
import {
  CreateReport,
  ReportGettingStarted,
  ReportsHeader,
  useReports
} from '@cma/features/report'
import { SwitchToClassicTour, TourBanner, TourCard } from '@cma/features/tour'
import { ReportTypeEnum } from '@cma/generated/graphql'
import { Fragment, Suspense } from 'react'
import { Helmet } from 'react-helmet-async'

export default function Tours() {
  const paginate = usePaginate({ limit: 27 })

  return (
    <>
      <TourBanner />
      <Wrapper>
        <Helmet>
          <title>Buyer Tours - Cloud CMA</title>
        </Helmet>
        <ReportsHeader
          title="Buyer Tours"
          placeholder="Search Buyer Tours"
          onSubmit={(data) => {
            paginate.setFirst(paginate.limit)
            paginate.setLast(undefined)
            paginate.setAfter(undefined)
            paginate.setBefore(undefined)
            paginate.setSearch(data.search)
          }}
        />
        <Suspense
          fallback={
            <>
              <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                <CreateReport to="/tours/new">Create Buyer Tour</CreateReport>
                {new Array(5).fill('').map((_, index) => (
                  <TourCard.Skeleton key={index} />
                ))}
              </div>
              <Pagination disablePrev={true} disableNext={true} />
              <SwitchToClassicTour />
            </>
          }>
          <TourCards paginate={paginate} />
          <SwitchToClassicTour />
        </Suspense>
      </Wrapper>
    </>
  )
}

interface TourCardsProps {
  paginate: ReturnType<typeof usePaginate>
}

function TourCards({ paginate }: TourCardsProps) {
  const { data: { reports } = {} } = useReports({
    types: [ReportTypeEnum.Tour],
    first: paginate.first,
    last: paginate.last,
    after: paginate.after,
    before: paginate.before,
    search: paginate.search
  })

  return (
    <>
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        <CreateReport to="/tours/new">Create Buyer Tour</CreateReport>
        {reports?.nodes?.map((report) => (
          <Fragment key={report?.id}>
            {report && <TourCard report={report} />}
          </Fragment>
        ))}
        {(reports?.total ?? 0) <= 4 && (
          <ReportGettingStarted videoId="gqq9vojx7d">
            Getting Started with Buyer Tours
          </ReportGettingStarted>
        )}
      </div>
      <Pagination
        disablePrev={!reports?.pageInfo.hasPreviousPage}
        disableNext={!reports?.pageInfo.hasNextPage}
        onPrevClick={() => {
          paginate.setBefore(reports?.pageInfo.startCursor ?? undefined)
          paginate.setAfter(undefined)
          paginate.setFirst(undefined)
          paginate.setLast(paginate.limit)
        }}
        onNextClick={() => {
          paginate.setBefore(undefined)
          paginate.setAfter(reports?.pageInfo?.endCursor ?? undefined)
          paginate.setFirst(paginate.limit)
          paginate.setLast(undefined)
        }}
      />
    </>
  )
}
