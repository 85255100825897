export function DownloadIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3a.75.75 0 0 1 .75.75V11.39l1.72-1.72a.75.75 0 1 1 1.06 1.06L10 14.26l-3.53-3.53a.75.75 0 1 1 1.06-1.06l1.72 1.72V3.75A.75.75 0 0 1 10 3Zm-7 8a.75.75 0 0 1 .75.75V16h12.5v-4.25a.75.75 0 0 1 1.5 0v5a.75.75 0 0 1-.75.75H3a.75.75 0 0 1-.75-.75v-5A.75.75 0 0 1 3 11Z"
        fill="currentColor"
      />
    </svg>
  )
}
