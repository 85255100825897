import { useCurrentUser } from '@cma/app'
import { NotFound } from '@cma/common'
import { useLogout } from '@cma/features/auth'
import { useConnectProduct } from '@cma/features/settings/useConnectProduct'
import {
  ConnectProductMutationVariables,
  ProductKey
} from '@cma/generated/authenticated'
import { CloudCmaVerticalIcon } from '@cma/icons'
import { HelixIcon } from '@helix/helix-icon'
import {
  arrow_down as ArrowDownIcon,
  arrow_right as ArrowRightIcon,
  wolfie as WolfieIcon
} from '@helix/helix-icon/outlined'
import { useSearchParams } from 'react-router-dom'

export default function Connection() {
  const { data } = useCurrentUser()
  const [searchParams] = useSearchParams()
  const { mutate: connectProduct } = useConnectProduct()
  const logout = useLogout()

  const nameFromParams = searchParams.get('name') || 'N/A'
  const emailFromParams = searchParams.get('email') || 'N/A'

  const onConnectClick = (service: ConnectProductMutationVariables) => {
    connectProduct(service)
  }

  return (
    <div className="helix-container--fluid helix-80vh">
      <div className="helix-d-flex helix-w-100-percent helix-justify--center helix-px-4">
        <div className="helix-row helix-100vh d-flex helix-align--middle helix-justify--center">
          <div className="helix-card helix-p-4">
            <div className="helix-card__body">
              <div className="helix-w-100-percent helix-d-flex helix-justify--center">
                <HelixIcon
                  icon={WolfieIcon}
                  className="helix-svg-fill--gray-900 mx-auto mb-3 h-14 w-14"
                />
              </div>
              <h1 className="helix-text-gray-900 helix-display-small helix-mb-6 helix-text--center">
                Connect this account
              </h1>

              <div className="helix-d-flex helix-d-sm-block helix-justify--center helix-mb-6">
                <div className="helix-d-sm-flex helix-d-block">
                  <div className="helix-card helix-w-300">
                    <div className="helix-card__body helix-text--center">
                      <CloudCmaVerticalIcon className="mx-auto mb-4 h-[4.375rem] w-24 text-cma-500" />
                      <div className="helix-body--strong helix-mb-1">
                        {data?.currentUser?.name}
                      </div>
                      <div className="helix-text-gray-500 helix-small">
                        {data?.currentUser?.email}
                      </div>
                    </div>
                  </div>
                  <div className="helix-d-flex helix-text-gray-900 helix-justify--center helix-align--middle helix-p-4">
                    <HelixIcon
                      icon={ArrowRightIcon}
                      className="helix-d-none helix-d-sm-block"
                    />
                    <HelixIcon
                      icon={ArrowDownIcon}
                      className="helix-d-block helix-d-sm-none"
                    />
                  </div>
                  <div className="helix-card helix-w-300">
                    <div className="helix-card__body helix-text--center helix-p-6">
                      <HelixIcon
                        icon={WolfieIcon}
                        className="helix-svg-fill--red-900 mx-auto mb-4 h-[4.375rem] w-[4.375rem]"
                      />
                      <div className="helix-body--strong helix-mb-1">
                        {nameFromParams}
                      </div>

                      <div className="helix-text-gray-500 helix-small">
                        {emailFromParams}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="helix-d-flex helix-mb-2 helix-align--middle helix-justify--center">
                <div className="helix-w-400">
                  <button
                    onClick={() =>
                      onConnectClick({ service: ProductKey.CloudCma })
                    }
                    className="helix-btn helix-btn--primary helix-w-100-percent helix-d-block helix-text--center helix-mb-3 hover:no-underline">
                    Connect to Lone Wolf account
                  </button>
                  <button
                    onClick={() => onConnectClick({ service: null })}
                    className="helix-btn helix-btn--tertiary helix-w-100-percent helix-d-block helix-text--center helix-mb-3 hover:no-underline">
                    Nevermind, don't connect
                  </button>
                  <button
                    onClick={() => logout({ shouldUseCmaToLogout: true })}
                    className="helix-btn helix-text-blue-600 helix-btn--ghost helix-w-100-percent helix-d-block helix-text--center hover:no-underline">
                    Not you?
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
