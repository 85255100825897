import { useCurrentUser } from '@cma/app'
import { Button, Checkbox, FormField, Input, Modal, toast } from '@cma/common'
import { ErrorIcon, LeadingIcon } from '@cma/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useImportAgents } from './useImportAgents'

interface AddAgentProps {
  brokerId: string
  brokerName: string
  canSendInvite: boolean
  isOpen: boolean
  onClose: () => void
}

const schema = yup.object({
  firstname: yup.string().required('First name is required'),
  lastname: yup.string().required('Last name is required'),
  email: yup.string().required('Email is required'),
  sendInvitation: yup.boolean()
})

export function AddAgent({
  brokerId,
  brokerName,
  canSendInvite,
  isOpen,
  onClose
}: AddAgentProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset: resetForm
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema)
  })
  const {
    mutate: importAgents,
    isLoading,
    isSuccess,
    reset: resetMutation,
    error
  } = useImportAgents()
  const { data: { currentUser } = {} } = useCurrentUser()

  return (
    <Modal
      size="full-screen"
      isOpen={isOpen}
      onClose={onClose}
      onAfterClose={() => {
        resetForm()
        resetMutation()
      }}>
      <Modal.Title
        agentName={currentUser?.name}
        agentAvatar={currentUser?.avatar || undefined}>
        <span className="hidden text-center leading-none sm:block">
          <span className="truncate text-xl font-semibold leading-6 text-gray-900">
            Add Agent
          </span>
          <span className="block truncate text-xs font-semibold uppercase text-gray-700">
            {brokerName}
          </span>
        </span>
      </Modal.Title>
      <div className="mx-auto my-8 w-full max-w-xl rounded-lg bg-white p-8 shadow">
        <h1 className="mb-4 text-xl font-semibold">Enter agent information</h1>
        <form
          className="space-y-6"
          onSubmit={handleSubmit((data) => {
            importAgents(
              {
                brokerId,
                agents: [
                  {
                    user: {
                      firstname: data.firstname,
                      email: data.email,
                      lastname: data.lastname
                    }
                  }
                ],
                sendInvitation: data.sendInvitation || false
              },
              {
                onSuccess() {
                  onClose()
                  toast(<strong>Agent added successfully</strong>, {
                    variant: 'success'
                  })
                }
              }
            )
          })}>
          <div className="flex justify-between space-x-3">
            <div className="w-full">
              <FormField
                label="First name"
                error={errors.firstname?.message}
                required>
                <Input
                  {...register('firstname')}
                  placeholder="Enter first name"
                />
              </FormField>
            </div>
            <div className="w-full">
              <FormField
                label="Last name"
                error={errors.lastname?.message}
                required>
                <Input
                  {...register('lastname')}
                  placeholder="Enter last name"
                />
              </FormField>
            </div>
          </div>
          <FormField
            label="Email address"
            error={errors.email?.message}
            required>
            <Input {...register('email')} placeholder="Enter email address" />
          </FormField>
          <div>
            {canSendInvite && (
              <div className="mt-5 mb-2 ml-2">
                <FormField>
                  <Checkbox
                    variant="primary"
                    defaultChecked={false}
                    {...register('sendInvitation')}>
                    Send invitation to agent (you can do this later)
                  </Checkbox>
                </FormField>
              </div>
            )}
            <Button
              fullWidth
              variant={!error ? 'primary' : 'danger'}
              type="submit"
              loading={isLoading}
              leftIcon={
                error ? <ErrorIcon /> : isSuccess ? <LeadingIcon /> : undefined
              }>
              {!!error && <>An Error Occured. Try again?</>}
              {!error && (
                <>
                  {isLoading
                    ? 'Adding Agent'
                    : isSuccess
                    ? 'Success'
                    : `Add Agent`}
                </>
              )}
            </Button>
          </div>
          {error && (
            <p role="alert" aria-live="polite" className="text-sm text-red-600">
              {error.message}
            </p>
          )}
        </form>
      </div>
    </Modal>
  )
}
