import { api } from '@cma/app'
import {
  ReportStatusQuery,
  ReportStatusQueryVariables
} from '@cma/generated/graphql'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useReportStatus({
  id,
  status
}: ReportStatusQueryVariables & { status: string }) {
  const queryClient = useQueryClient()
  return useQuery<ReportStatusQuery, Error>(
    ['reportStatus', id],
    () => {
      const query = gql`
        query ReportStatus($id: ID!) {
          report(id: $id) {
            id
            status
          }
        }
      `
      const variables = { id }
      return api<ReportStatusQuery>({ query, variables })
    },
    {
      cacheTime: 0,
      staleTime: 0,
      suspense: false,
      enabled: status === 'active-report',
      refetchInterval: 4000,
      async onSuccess(data) {
        if (data.report?.status !== 'active-report') {
          await Promise.all([
            queryClient.invalidateQueries(['report', data?.report?.id]),
            queryClient.invalidateQueries(['reports']),
            queryClient.invalidateQueries(['tour', data?.report?.id])
          ])
        }
      }
    }
  )
}
