import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from '@heroicons/react/outline'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import styles from './DatePicker.module.css'
import { Input } from './Input'

export function DatePicker(
  props: Omit<
    ReactDatePickerProps,
    'icon' | 'previousMonthbuttonLabel' | 'nextMonthButtonLabel'
  >
) {
  return (
    <div className={styles.container}>
      <Input
        {...props}
        as={ReactDatePicker}
        leftIcon={<CalendarIcon />}
        previousMonthButtonLabel={<ChevronLeftIcon className="h-5 w-5" />}
        nextMonthButtonLabel={<ChevronRightIcon className="h-5 w-5" />}
      />
    </div>
  )
}
