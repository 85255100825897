import { api } from '@cma/app'
import {
  ReportQuery,
  ToggleReportListingsMutation,
  ToggleReportListingsMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

interface ToggleReportListingsContext {
  previousReport?: ReportQuery
}

export function useToggleReportListings() {
  const queryClient = useQueryClient()
  return useMutation<
    ToggleReportListingsMutation,
    Error,
    ToggleReportListingsMutationVariables,
    ToggleReportListingsContext
  >(
    ({ reportId, visible }) => {
      const query = gql`
        mutation ToggleReportListings($reportId: ID!, $visible: Boolean!) {
          updateReportListingsVisibility(
            reportId: $reportId
            visible: $visible
          ) {
            errors
          }
        }
      `
      const variables = { reportId, visible }
      return api({ query, variables })
    },
    {
      onMutate: async ({ reportId, visible }) => {
        await queryClient.cancelQueries(['report', reportId])
        const previousReport = queryClient.getQueryData<ReportQuery>([
          'report',
          reportId
        ])

        if (previousReport && previousReport.report) {
          queryClient.setQueryData<ReportQuery>(['report', reportId], {
            ...previousReport,
            report: {
              ...previousReport.report,
              listings: previousReport.report.listings?.map((listing) => ({
                ...listing,
                hide: !visible
              }))
            }
          })
        }

        return { previousReport }
      },
      onError(_, { reportId }, context) {
        if (context?.previousReport) {
          queryClient.setQueryData<ReportQuery>(
            ['report', reportId],
            context.previousReport
          )
        }
      }
    }
  )
}
