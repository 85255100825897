import { api } from '@cma/app'
import {
  StoreRedirectUrlMutation,
  StoreRedirectUrlMutationVariables
} from '@cma/generated/graphql'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useStoreRedirectUrl() {
  return useMutation<
    StoreRedirectUrlMutation,
    Error,
    StoreRedirectUrlMutationVariables
  >((variables) => {
    const query = gql`
      mutation StoreRedirectUrl($scope: String!, $url: String!) {
        storeRedirectUrl(scope: $scope, url: $url) {
          success
        }
      }
    `
    return api({ query, variables })
  })
}
