import { formatCurrency, formatDate } from '@cma/common'
import { useBilling } from './useBilling'

export function BillingSubscription() {
  const { data: billing } = useBilling()

  if (billing?.account?.isSmallBroker) {
    return (
      <>
        <p
          className="text-center text-sm text-gray-500"
          data-testid="small-broker-subscription">
          Your Cloud CMA subscription is currently part of the{' '}
          <strong>{billing.account.admin?.companyName}</strong> Broker License
          for{' '}
          <strong>
            {billing?.account?.subscription?.subscriptionPlan?.description ||
              billing?.account?.subscription?.subscriptionPlan?.name ||
              'N/A'}
          </strong>
          .
        </p>
        <BillingSubscriptionDetails />
      </>
    )
  }

  if (
    !!billing?.account?.isSiteLicensed ||
    billing?.account?.subscription?.isFree
  ) {
    return (
      <>
        <p
          className="text-center text-sm text-gray-500"
          data-testid="free-subscription">
          Your Cloud CMA subscription is currently <strong>FREE</strong>{' '}
          courtesy of{' '}
          <strong>
            {billing?.account?.subscription?.subscriptionPlan?.description ||
              billing?.account?.admin?.companyName ||
              'N/A'}
          </strong>
          .
        </p>
        <BillingSubscriptionDetails />
      </>
    )
  }

  if (billing?.user?.hasCardOnFile) {
    return (
      <>
        <p
          className="text-center text-sm text-gray-500"
          data-testid="paid-subscription">
          Your Cloud CMA subscription is currently on the{' '}
          <strong>
            {billing?.account?.subscription?.subscriptionPlan?.name}
          </strong>{' '}
          plan
          {billing?.account?.subscription?.discount?.code && (
            <>
              {' '}
              and you signed up using the promo code{' '}
              <strong>{billing.account.subscription.discount.code}</strong>
            </>
          )}
          .
        </p>
        <BillingSubscriptionDetails />
      </>
    )
  }

  if (billing?.user?.isCasUser) {
    return null
  }

  return (
    <>
      <p
        className="text-center text-sm text-gray-500"
        data-testid="no-subscription">
        No subscription found
      </p>
    </>
  )
}

function BillingSubscriptionDetails() {
  const { data: billing } = useBilling()
  const isFree = !!(
    billing?.account?.isSiteLicensed || billing?.account?.subscription?.isFree
  )

  const canEditPlan =
    !billing?.user?.isCasUser && !!billing?.user?.isAccountAdmin && !isFree

  if (!canEditPlan) return null

  return (
    <div
      className="space-y-6 text-center text-sm text-gray-500 lg:px-16"
      data-testid="subscription-details">
      <p>
        Your next renewal date is{' '}
        <strong className="underline">
          {formatDate(
            billing?.account?.subscription?.nextRenewalAt || '',
            'MMMM dd, yyyy'
          )}
        </strong>{' '}
        at which time you will be charged{' '}
        <strong className="underline">
          {formatCurrency(billing?.account?.subscription?.amount || 0)}
        </strong>{' '}
        on the credit card below.
      </p>
      <ul>
        <li className="space-x-1">
          <span>Credit card number:</span>
          <strong>{billing?.account?.subscription?.cardNumber || 'N/A'}</strong>
        </li>
        <li className="space-x-1">
          <span>Expiration date:</span>
          <strong>
            {billing?.account?.subscription?.cardExpiration || 'N/A'}
          </strong>
        </li>
      </ul>
    </div>
  )
}
