export function ArrowLeftLongIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.841 6.53a.75.75 0 0 0-1.06-1.06l-4 4-.531.53.53.53 4 4a.75.75 0 0 0 1.061-1.06l-2.72-2.72h14.19a.75.75 0 0 0 0-1.5H4.12l2.72-2.72Z"
        fill="currentColor"
      />
    </svg>
  )
}
