export function CloseIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.53 2.47a.75.75 0 0 1 1.061 0L10 8.879l6.409-6.41a.75.75 0 0 1 1.06 1.061l-6.408 6.41 6.47 6.469a.75.75 0 0 1-1.061 1.06L10 11l-6.47 6.47a.75.75 0 1 1-1.06-1.061l6.47-6.47L2.53 3.53a.75.75 0 0 1 0-1.06Z"
        fill="currentColor"
      />
    </svg>
  )
}
