import {
  Button,
  Checkbox,
  classNames,
  formatCurrency,
  formatDate,
  formatNumber,
  FormField,
  ImageSlider,
  Input,
  Label,
  Select
} from '@cma/common'
import { ListingPartsFragment, SortDirectionEnum } from '@cma/generated/graphql'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ExcludeIcon,
  IncludeIcon,
  SortIcon
} from '@cma/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import isUrl from 'is-url'
import pluralize from 'pluralize'
import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { AddEditReportListing } from './AddEditReportListing'
import { useReorderReportListings } from './useReorderReportListings'
import { useSortReportListings } from './useSortReportListings'
import { useToggleReportListing } from './useToggleReportListing'
import { useToggleReportListings } from './useToggleReportListings'
import { useUpdateReportListing } from './useUpdateReportListing'
import { sortByPosition } from './utils'

interface ReportListingsProps {
  reportId: string
  reportType: string
  listings: ListingPartsFragment[]
}

export function ReportListings({
  reportId,
  reportType,
  listings
}: ReportListingsProps) {
  const [isAddingListing, setIsAddingListing] = useState(false)
  const [sortDir, setSortDir] = useState<SortDirectionEnum>(
    SortDirectionEnum.Asc
  )
  const [sortBy, setSortBy] = useState('')
  const { mutate: sortListings } = useSortReportListings()
  const { mutate: toggleListings } = useToggleReportListings()
  const { mutate: reorderListings } = useReorderReportListings()
  const isDropDisabled = listings.length <= 1

  const sortedListings = sortByPosition(listings)

  return (
    <>
      <div className="mb-3 space-y-4">
        <h2 className="text-center text-2xl text-gray-700 sm:text-left">
          Listings
        </h2>
        <div className="flex flex-col items-center space-y-6 sm:flex-row sm:space-y-0">
          <div className="flex items-center">
            <div className="mr-3 flex items-center space-x-2 sm:mr-6">
              <div className="hidden sm:block">
                <Label htmlFor="sort">Sort Listings</Label>
              </div>
              <Select
                id="sort"
                value={sortBy}
                onChange={(e) => {
                  setSortBy(e.target.value)
                  sortListings({
                    reportId,
                    sortBy: e.target.value,
                    sortDir
                  })
                }}>
                <option value="" hidden>
                  Select Sort Option
                </option>
                <option value="status_as_letter">Status</option>
                <option value="beds">Beds</option>
                <option value="baths">Baths</option>
                <option value="dom">DOM</option>
                <option value="price_list">List Price</option>
                <option value="price_sold">Sold Price</option>
                <option value="price">Price</option>
                <option value="price_per_sqft">Price per Sqft</option>
                <option value="prop_type">Property Type</option>
                <option value="sqft">Square Feet</option>
                <option value="date_list">List Date</option>
                <option value="date_sold">Sold Date</option>
                <option value="zip">Zip</option>
              </Select>
            </div>
            {!!sortBy && (
              <div className="mr-0 divide-x divide-gray-300 rounded border border-gray-300 bg-white text-gray-500 shadow-sm sm:mr-6">
                <button
                  aria-label="Sort ascending"
                  className={classNames('p-2 sm:p-1.5', {
                    'text-green-500': sortDir === SortDirectionEnum.Asc
                  })}
                  onClick={() => {
                    setSortDir(SortDirectionEnum.Asc)
                    sortListings({
                      reportId,
                      sortBy,
                      sortDir: SortDirectionEnum.Asc
                    })
                  }}>
                  <SortIcon className="h-6 w-6" />
                </button>
                <button
                  aria-label="Sort descending"
                  className={classNames('p-2 sm:p-1.5', {
                    'text-green-500': sortDir === SortDirectionEnum.Desc
                  })}
                  onClick={() => {
                    setSortDir(SortDirectionEnum.Desc)
                    sortListings({
                      reportId,
                      sortBy,
                      sortDir: SortDirectionEnum.Desc
                    })
                  }}>
                  <SortIcon className="h-6 w-6 -scale-y-100" />
                </button>
              </div>
            )}
          </div>
          <div className="flex items-center space-x-8 text-sm font-medium text-gray-500 sm:ml-auto sm:text-xs">
            <button
              className="flex items-center space-x-2"
              onClick={() => toggleListings({ reportId, visible: true })}>
              <IncludeIcon className="h-[0.875rem] w-[1.3125rem]" />
              <span>Include All</span>
            </button>
            <button
              className="flex items-center space-x-2"
              onClick={() => toggleListings({ reportId, visible: false })}>
              <ExcludeIcon className="h-4 w-[1.125rem]" />
              <span>Exclude All</span>
            </button>
          </div>
        </div>
      </div>
      <DragDropContext
        onDragEnd={(result) => {
          if (!result.destination) return

          reorderListings({
            reportId,
            listings: sortedListings,
            startIndex: result.source.index,
            endIndex: result.destination.index
          })
        }}>
        <Droppable isDropDisabled={isDropDisabled} droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {sortedListings.map((listing, index) => (
                <ReportListing
                  key={listing.id}
                  reportId={reportId}
                  reportType={reportType}
                  index={index}
                  listing={listing}
                  disabledDrag={isDropDisabled}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {!isAddingListing && (
        <button
          className="mt-2.5 flex h-[4.25rem] w-full items-center justify-center space-x-2 rounded border border-dashed border-gray-300 px-4 text-sm text-gray-600 transition-colors hover:border-gray-400"
          onClick={() => setIsAddingListing(true)}>
          <svg
            fill="currentColor"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M14 10H2v2h12v-2zm0-4H2v2h12V6zm4 8v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zM2 16h8v-2H2v2z"></path>
          </svg>
          <span className="text-gray-500">Add a Listing</span>
        </button>
      )}
      {isAddingListing && (
        <Card isOpen={isAddingListing}>
          <AddEditReportListing
            reportId={reportId}
            onCancel={() => setIsAddingListing(false)}
            onAdd={() => setIsAddingListing(false)}
            onUpdate={() => setIsAddingListing(false)}
          />
        </Card>
      )}
    </>
  )
}

interface ReportListingProps {
  reportId: string
  reportType: string
  index: number
  listing: ListingPartsFragment
  disabledDrag: boolean
}

const updateReportListingSchema = yup.object({
  description: yup.string()
})

function ReportListing({
  reportId,
  reportType,
  index,
  listing,
  disabledDrag
}: ReportListingProps) {
  const { mutate: toggleListing } = useToggleReportListing()
  const [isOpen, setIsOpen] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const cityStateZip = [
    [listing.city, listing.state].filter(Boolean).join(' '),
    listing.zip
  ]
    .filter(Boolean)
    .join(', ')
  const details = [
    `${formatNumber(listing.beds || 0)} ${pluralize('Bds', listing.beds || 0)}`,
    `${listing.formattedBaths} Ba`,
    `${formatNumber(listing.sqft || 0)} sqft`,
    listing.yearBuilt
  ].filter(Boolean)
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<yup.InferType<typeof updateReportListingSchema>>({
    resolver: yupResolver(updateReportListingSchema),
    defaultValues: {
      description: listing?.remarks?.replace(/<[^>\s+]+>/gi, '')
    }
  })

  const {
    mutate: updateReportListing,
    error: updateError,
    isLoading: isUpdating
  } = useUpdateReportListing()
  const [isDescriptionUpdated, setIsDescriptionUpdated] = useState(false)
  return (
    <Draggable
      isDragDisabled={isOpen || isEditing || disabledDrag}
      draggableId={listing.id}
      index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          data-testid="listing">
          {isEditing && (
            <Card isOpen={isEditing}>
              <AddEditReportListing
                reportId={reportId}
                listing={listing}
                onCancel={() => setIsEditing(false)}
                onAdd={() => setIsEditing(false)}
                onUpdate={() => setIsEditing(false)}
              />
            </Card>
          )}
          {!isEditing && (
            <Card isOpen={isOpen}>
              <div className="flex flex-col px-4 py-2.5 sm:flex-row">
                <div
                  title={[listing.address, cityStateZip]
                    .filter(Boolean)
                    .join(', ')}
                  className={classNames(
                    'order-2 mb-2 min-w-0 space-y-0.5 truncate text-gray-400 sm:order-1 sm:mb-0',
                    {
                      'opacity-50': !!listing.hide
                    }
                  )}>
                  <div className="truncate leading-7">
                    <span
                      className="text-gray-700"
                      data-testid="listing-address">
                      {listing.address}
                    </span>
                    {cityStateZip && <>&nbsp;</>}
                    <span
                      className="text-sm font-light text-gray-400"
                      data-testid="listing-city-state-zip">
                      {cityStateZip}
                    </span>
                  </div>
                  <div
                    className="truncate text-xs font-light text-gray-400"
                    title={details.length ? details.join(', ') : 'No Details'}
                    dangerouslySetInnerHTML={{
                      __html: details.length
                        ? details.join(' &bull; ')
                        : 'No Details'
                    }}
                  />
                </div>
                <div
                  className={classNames(
                    'order-1 flex items-baseline space-x-1 space-y-0.5 sm:order-2 sm:ml-auto sm:block sm:space-x-0 sm:pl-8 sm:text-right',
                    {
                      'opacity-50': !!listing.hide
                    }
                  )}>
                  <div
                    title={formatCurrency(listing.price || 0)}
                    className="text-lg text-gray-700">
                    {formatCurrency(listing.price || 0)}
                  </div>
                  <div
                    title={listing.status || ''}
                    className="text-xs font-light"
                    style={{ color: `#${listing.statusAsColor || '000'}` }}>
                    {listing.status}
                  </div>
                </div>
                <div className="order-3 flex flex-shrink-0 items-center justify-between space-x-2 space-y-0.5 sm:block sm:space-x-0 sm:pl-8 sm:text-right">
                  <div className="space-x-2">
                    {listing.source === 'manual' && (
                      <button
                        className="-translate-y-1.5 text-xs text-gray-700"
                        onClick={() => setIsEditing(true)}>
                        Edit
                      </button>
                    )}
                    <Checkbox
                      size="lg"
                      checked={!listing.hide}
                      onChange={() => {
                        toggleListing({ reportId, id: listing.id })
                      }}
                    />
                  </div>
                  <button
                    aria-label={
                      isOpen ? 'Hide listing content' : 'Show listing content'
                    }
                    className="flex items-center space-x-0.5 text-xs text-gray-700 underline"
                    onClick={() => setIsOpen(!isOpen)}>
                    {reportType === 'CmaReport' && (
                      <span>Details &amp; Adjustments</span>
                    )}
                    {reportType !== 'CmaReport' && <span>Details</span>}
                    {!isOpen && <ChevronDownIcon className="h-5 w-5" />}
                    {isOpen && <ChevronUpIcon className="h-5 w-5" />}
                  </button>
                </div>
              </div>
              {isOpen && (
                <div className="space-y-4 p-4" data-testid="listing-content">
                  <div className="flex flex-wrap">
                    <div className="w-full md:w-1/2">
                      <div className="overflow-hidden rounded">
                        <ImageSlider>
                          {listing?.photos?.map((item, i) => (
                            <img
                              key={item}
                              src={item}
                              alt={`${listing.address} ${i}`}
                              className="h-[16.25rem] w-[25.625rem] object-cover"
                            />
                          ))}
                        </ImageSlider>
                      </div>
                    </div>
                    <div className="w-full columns-2 p-4 md:w-1/2">
                      {listing.status && (
                        <div className="text-sm font-light text-gray-700">
                          <strong>Status</strong>: {listing.status}
                        </div>
                      )}
                      {listing.source && (
                        <div className="text-sm font-light text-gray-700">
                          <strong>Source</strong>: {listing.source}
                        </div>
                      )}
                      {listing.mlsnum && (
                        <div className="text-sm font-light text-gray-700">
                          <strong>MLS #</strong>: {listing.mlsnum}
                        </div>
                      )}
                      {listing.county && (
                        <div className="text-sm font-light text-gray-700">
                          <strong>County</strong>: {listing.county}
                        </div>
                      )}
                      {listing.lotsize && (
                        <div className="text-sm font-light text-gray-700">
                          <strong>Lot Size</strong>: {listing.lotsize}
                        </div>
                      )}
                      {listing.taxes && (
                        <div className="text-sm font-light text-gray-700">
                          <strong>Taxes</strong>: {listing.taxes}
                        </div>
                      )}
                      {listing.garages && (
                        <div className="text-sm font-light text-gray-700">
                          <strong>Garages</strong>: {listing.garages}
                        </div>
                      )}
                      {listing.area && (
                        <div className="text-sm font-light text-gray-700">
                          <strong>Area</strong>: {listing.area}
                        </div>
                      )}
                      {listing.subdivision && (
                        <div className="text-sm font-light text-gray-700">
                          <strong>Subdivision</strong>: {listing.subdivision}
                        </div>
                      )}
                      {listing.style && (
                        <div className="text-sm font-light text-gray-700">
                          <strong>Style</strong>: {listing.style}
                        </div>
                      )}
                      {listing.beds && (
                        <div className="text-sm font-light text-gray-700">
                          <strong>Beds</strong>: {listing.beds}
                        </div>
                      )}
                      {listing.bathsHalf && (
                        <div className="text-sm font-light text-gray-700">
                          <strong>Half Baths</strong>: {listing.bathsHalf}
                        </div>
                      )}
                      {listing.bathsFull && (
                        <div className="text-sm font-light text-gray-700">
                          <strong>Full Baths</strong>: {listing.bathsFull}
                        </div>
                      )}
                      {listing.dom && (
                        <div className="text-sm font-light text-gray-700">
                          <strong>DOM</strong>: {listing.dom}
                        </div>
                      )}
                      {listing.dateList && (
                        <div className="text-sm font-light text-gray-700">
                          <strong>List Date</strong>:{' '}
                          {formatDate(listing.dateList, 'M/d/yyyy')}
                        </div>
                      )}
                      {listing.dateSold && (
                        <div className="text-sm font-light text-gray-700">
                          <strong>Sold Date</strong>:{' '}
                          {formatDate(listing.dateSold, 'M/d/yyyy')}
                        </div>
                      )}
                      {listing.dateOffmarket && (
                        <div className="text-sm font-light text-gray-700">
                          <strong>Off-Market Date</strong>:{' '}
                          {formatDate(listing.dateOffmarket, 'M/d/yyyy')}
                        </div>
                      )}
                      {listing.updatedAt && (
                        <div className="text-sm font-light text-gray-700">
                          <strong>Updated</strong>:{' '}
                          {formatDate(listing.updatedAt, 'M/d/yyyy')}
                        </div>
                      )}
                      {listing.priceList && (
                        <div className="text-sm font-light text-gray-700">
                          <strong>List Price</strong>:{' '}
                          {formatCurrency(listing.priceList)}
                        </div>
                      )}
                      {listing.priceListOrig && (
                        <div className="text-sm font-light text-gray-700">
                          <strong>Original List Price</strong>:{' '}
                          {formatCurrency(listing.priceListOrig)}
                        </div>
                      )}
                      {listing.priceSold && (
                        <div className="text-sm font-light text-gray-700">
                          <strong>Sold Price</strong>:{' '}
                          {formatCurrency(listing.priceSold)}
                        </div>
                      )}
                    </div>
                  </div>
                  <form
                    onSubmit={handleSubmit((data) => {
                      updateReportListing(
                        {
                          listingId: listing.id,
                          listing: {
                            address: listing.address || undefined,
                            remarks: data.description
                          }
                        },
                        {
                          onSuccess: () => {
                            setIsDescriptionUpdated(true)
                            setTimeout(
                              () => setIsDescriptionUpdated(false),
                              1000
                            )
                          }
                        }
                      )
                    })}>
                    <label
                      htmlFor="description"
                      className="leading-sxl mb-4 inline-block font-normal text-gray-700">
                      Description
                    </label>
                    <FormField
                      id="description"
                      error={
                        errors?.description?.message || updateError?.message
                      }>
                      <Input
                        as="textarea"
                        {...register('description')}
                        rows={6}
                      />
                    </FormField>
                    <div className="mt-2 ml-auto text-right">
                      <Button loading={isUpdating}>
                        {!isUpdating && !isDescriptionUpdated && 'Update'}
                        {isUpdating && 'Updating'}
                        {isDescriptionUpdated && 'Updated!'}
                      </Button>
                    </div>
                  </form>
                  {listing?.features && (
                    <div>
                      <h3 className="leading-sxl mb-4 font-normal text-gray-700">
                        Features
                      </h3>
                      <div className="columns-2">
                        {(
                          Object.entries(listing.features) as unknown as [
                            string,
                            any
                          ]
                        ).map(([key, value]) => (
                          <div
                            key={key}
                            className="text-sm font-light text-gray-700">
                            <strong>{key}</strong>:{' '}
                            {isUrl(value) ? (
                              <a
                                href={value}
                                target="_blank"
                                rel="noreferrer"
                                className="text-green-500">
                                View
                              </a>
                            ) : (
                              value
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Card>
          )}
        </div>
      )}
    </Draggable>
  )
}

interface CardProps {
  isOpen: boolean
}

function Card({ isOpen, children }: PropsWithChildren<CardProps>) {
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (isOpen) {
      containerRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isOpen])

  return (
    <div
      ref={containerRef}
      className="mt-2.5 scroll-m-14 rounded bg-white shadow">
      {children}
    </div>
  )
}
