import { useMagicLink } from '@cma/features/sso'
import { HelixIcon } from '@helix/helix-icon'
import { spinner as SpinnerIcon } from '@helix/helix-icon/outlined'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import * as yup from 'yup'

const schema = yup.object({
  email: yup
    .string()
    .email('Invalid email')
    .required('You must enter a valid email')
})

export default function SsoMaginLogin() {
  const [searchParams] = useSearchParams()
  const email = searchParams.get('email') || ''
  const token = searchParams.get('token') || ''
  const {
    mutate: sendMagicLink,
    isLoading,
    isSuccess: hasSentEmail,
    error,
    reset
  } = useMagicLink()
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    reValidateMode: 'onSubmit',
    defaultValues: {
      email
    }
  })

  if (hasSentEmail) {
    return (
      <div className="helix-card helix-w-100-percent max-w-[26.25rem]">
        <div className="helix-pt-7 helix-pb-5">
          <div className="helix-px-2 helix-mb-5">
            <div className="helix-mb-6 helix-text--center mx-auto h-[1.875rem] w-10">
              <CheckmarkIcon />
            </div>
            <h1 className="helix-display-small helix-mb-2 helix-text--center">
              Magic Link Sent
            </h1>
            <p className="helix-body helix-text--center px-6">
              We sent an email to{' '}
              <strong className="helix-d-inline-block helix-truncate helix-max-w-300 -mb-[0.3rem]">
                {email || watch('email')}.
              </strong>
            </p>
            <p className="helix-body helix-text--center px-6">
              It has a magic link that'll sign you in.
            </p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="helix-card helix-w-100-percent max-w-[26.25rem]">
      <form
        className="helix-py-7"
        onSubmit={handleSubmit((data) => {
          sendMagicLink({ email: data.email, token })
        })}>
        <div className="helix-px-2 helix-mb-5">
          <div className="helix-mb-6 helix-text--center mx-auto h-[1.875rem] w-10">
            <MaginLinkIcon />
          </div>
          <h1 className="helix-display-small helix-text-black helix-mb-2 helix-text--center">
            Magic Sign In Link
          </h1>
          <p className="helix-heading helix-text-gray-600 helix-text--center helix-px-7">
            Get a magic link sent to your email that will sign you in instantly
          </p>
        </div>
        <div className="px-[2.625rem]">
          <div className="helix-mb-4">
            <label
              className="helix-body--strong helix-mb-1 helix-d-inline-block helix-text-gray-700"
              htmlFor="email">
              Email
            </label>
            <input
              autoFocus
              type="text"
              className="helix-text-input"
              id="email"
              placeholder="emmamckenna@acme.com"
              {...register('email', {
                onChange() {
                  reset()
                }
              })}
            />
            {(errors.email?.message || error?.message) && (
              <div
                role="alert"
                aria-live="polite"
                className="helix-small helix-text--error helix-mt-1">
                {errors.email?.message || error?.message}
              </div>
            )}
          </div>
          <button
            className="helix-btn helix-btn--large helix-btn--primary helix-w-100-percent"
            disabled={!isValid || isLoading}>
            {!isLoading ? (
              'Send Magic Link'
            ) : (
              <span className="mx-auto block h-5 w-5 animate-spin">
                <HelixIcon icon={SpinnerIcon} />
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

function CheckmarkIcon() {
  return (
    <svg
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="44" height="44" rx="22" fill="#D1FAE5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.9962 17.4376C28.3068 17.7117 28.3364 18.1856 28.0624 18.4962L20.5624 26.9962C20.4254 27.1515 20.2303 27.2432 20.0234 27.2496C19.8165 27.2561 19.6161 27.1767 19.4697 27.0303L15.9697 23.5303C15.6768 23.2374 15.6768 22.7626 15.9697 22.4697C16.2626 22.1768 16.7374 22.1768 17.0303 22.4697L19.9658 25.4052L26.9376 17.5038C27.2117 17.1932 27.6856 17.1636 27.9962 17.4376Z"
        fill="#10B981"
      />
    </svg>
  )
}

function MaginLinkIcon() {
  return (
    <svg
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="44" height="44" rx="22" fill="#EDE9FE" />
      <path
        d="M17.4761 21.2843C17.5444 21.2843 17.606 21.2296 17.6128 21.1066C17.811 18.9122 17.9888 18.6183 19.4312 18.4406C19.561 18.4269 19.5952 18.3654 19.5952 18.297C19.5952 18.2081 19.5542 18.1534 19.4175 18.1329C17.9819 17.9552 17.811 17.6476 17.6128 15.6857C17.5991 15.5626 17.5376 15.5216 17.4761 15.5216C17.394 15.5216 17.3325 15.5626 17.3188 15.6925C17.1206 17.6544 16.9497 17.9552 15.521 18.1329C15.3843 18.1534 15.3433 18.2081 15.3433 18.297C15.3433 18.3654 15.3843 18.4269 15.5073 18.4406C16.936 18.6183 17.1206 18.9054 17.3188 21.1066C17.3325 21.2296 17.394 21.2843 17.4761 21.2843ZM23.7378 27.9015C23.8267 27.9015 23.9019 27.8536 23.9224 27.7101C24.4624 22.9318 24.9546 22.4464 28.4341 21.9064C28.5776 21.8859 28.6392 21.8175 28.6392 21.7286C28.6392 21.6329 28.5776 21.5714 28.4272 21.5509C24.9546 21.0245 24.4624 20.5255 23.8882 16.2052C23.8608 16.048 23.8062 15.9865 23.7036 15.9865C23.6147 15.9865 23.5396 16.048 23.5259 16.1915C22.9927 20.5187 22.4595 21.0177 18.98 21.5509C18.8296 21.5714 18.7749 21.6329 18.7749 21.7286C18.7749 21.8175 18.8296 21.8859 18.9731 21.9064C22.4595 22.4464 22.9858 22.9249 23.5601 27.7101C23.5737 27.8468 23.6489 27.9015 23.7378 27.9015ZM19.5405 28.5782C19.6226 28.5782 19.6704 28.5099 19.6841 28.3937C19.8823 26.1993 20.0532 25.9054 21.4956 25.7277C21.6187 25.714 21.6665 25.6593 21.6665 25.5841C21.6665 25.5089 21.6323 25.4406 21.4956 25.4269C20.0669 25.2423 19.8823 24.9689 19.6841 22.9728C19.6704 22.8566 19.6089 22.8156 19.5405 22.8156C19.4585 22.8156 19.4038 22.8497 19.3901 22.9796C19.1919 24.9552 19.0142 25.2423 17.5718 25.4269C17.4556 25.4406 17.4077 25.5021 17.4077 25.5841C17.4077 25.6593 17.4556 25.714 17.5718 25.7277C19.0073 25.9054 19.1919 26.1993 19.3901 28.3937C19.397 28.5167 19.4585 28.5782 19.5405 28.5782Z"
        fill="#7C3AED"
      />
    </svg>
  )
}
