import {
  Button,
  classNames,
  Flash,
  FormField,
  Input,
  Select,
  Spinner,
  Tooltip
} from '@cma/common'
import {
  SettingsCard,
  useAddContactItem,
  useContactItems,
  useContactMethods,
  useDestroyContactItem,
  useSortContactItems
} from '@cma/features/settings'
import { ContactItemPartsFragment } from '@cma/generated/graphql'
import { ExclamationIcon, TrashIcon } from '@heroicons/react/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { Suspense, useRef } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from 'react-beautiful-dnd'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

const schema = yup.object({
  method: yup.string().required('The contact method is required'),
  value: yup.string().required('The contact value is required')
})

function ContactInfo() {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      method: ''
    }
  })
  const { data: contactMethodsQuery } = useContactMethods()
  const contactMethods = contactMethodsQuery?.currentUser?.contactMethods
  const { data: contactItemsQuery } = useContactItems()
  const contactItems = contactItemsQuery?.currentUser?.contactItems?.sort(
    (a, b) => {
      if (!a.position || !b.position) return 0
      if (a.position > b.position) return 1
      if (a.position < b.position) return -1
      return 0
    }
  )
  const {
    mutate: addContactItem,
    isLoading: isAdding,
    error: addingError
  } = useAddContactItem()
  const { mutate: sortContactItems, error: sortingError } =
    useSortContactItems()

  const disableDrag = (contactItems?.length ?? 0) <= 1
  const methodRef = useRef<HTMLSelectElement | null>(null)
  const { ref: registerMethodRef, ...registerMethodProps } = register('method')

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || !contactItems) return
    const reorderedContactItems = Array.from(contactItems)
    const [removed] = reorderedContactItems.splice(result.source.index, 1)
    reorderedContactItems.splice(result.destination.index, 0, removed)
    sortContactItems({
      ids: reorderedContactItems.map((contactItem) => contactItem.id)
    })
  }

  return (
    <SettingsCard>
      <SettingsCard.Group>
        <SettingsCard.Title>Contact Info</SettingsCard.Title>
        <p className="text-sm text-gray-500">
          Your Contact info will appear at the bottom of each report page.
          Include all your methods of contact. Keep in mind that your contact
          items will appear in the order shown below. You can re-order your list
          by clicking and dragging items.
        </p>
      </SettingsCard.Group>
      <SettingsCard.Group>
        <SettingsCard.Title>Add New Contact</SettingsCard.Title>
        <div>
          <form
            className="grid grid-cols-2 gap-3"
            onSubmit={handleSubmit((data) => {
              addContactItem(
                {
                  input: {
                    key: data.method,
                    value: data.value,
                    position: contactItems?.length || 0
                  }
                },
                {
                  onSuccess() {
                    setValue('method', '')
                    setValue('value', '')
                    methodRef.current?.focus()
                  }
                }
              )
            })}>
            <FormField label="Contact Method" error={errors.method?.message}>
              <Select
                {...registerMethodProps}
                ref={(node) => {
                  registerMethodRef(node)
                  methodRef.current = node
                }}>
                <option value="" hidden>
                  Select a Method
                </option>
                {contactMethods?.map((contactMethod) => (
                  <option key={contactMethod.key} value={contactMethod.key}>
                    {contactMethod.prettyKey}
                  </option>
                ))}
              </Select>
            </FormField>
            <FormField label="Contact Value" error={errors.value?.message}>
              <Input {...register('value')} />
            </FormField>
            <div className="col-span-2">
              <Button
                fullWidth
                loading={isAdding}
                disabled={!watch('method') || !watch('value')}>
                Add
              </Button>
            </div>
            {addingError && (
              <div className="col-span-2">
                <Flash variant="error">{addingError.message}</Flash>
              </div>
            )}
          </form>
          <div className="mt-2 text-center text-xs italic text-gray-400">
            Tip: Enter a method of contact and click 'Add' to add to your
            contact list.
          </div>
        </div>
      </SettingsCard.Group>
      <SettingsCard.Group>
        <SettingsCard.Title>Contact List</SettingsCard.Title>
        <div>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="contact-list" isDropDisabled={disableDrag}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  data-testid="contact-list">
                  {contactItems?.map((contactItem, index) => (
                    <ContactItem
                      key={contactItem.id}
                      index={index}
                      contactItem={contactItem}
                      disableDrag={disableDrag}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {sortingError && (
            <div className="my-2">
              <Flash variant="error">{sortingError.message}</Flash>
            </div>
          )}
          <div className="mt-2 text-center text-xs italic text-gray-400">
            {!contactItems?.length
              ? 'No contact items found. Try adding a new contact item above.'
              : 'Tip: Drag and drop the items in the list to change their order of appearance.'}
          </div>
        </div>
      </SettingsCard.Group>
    </SettingsCard>
  )
}

interface ContactItemProps {
  index: number
  contactItem: ContactItemPartsFragment
  disableDrag: boolean
}

function ContactItem({ index, contactItem, disableDrag }: ContactItemProps) {
  const {
    mutate: destroyContactItem,
    isLoading,
    error
  } = useDestroyContactItem()

  return (
    <Draggable
      index={index}
      draggableId={contactItem.id}
      isDragDisabled={disableDrag}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          data-testid="contact-item"
          className={classNames(
            'flex flex-row space-x-2 rounded border-t bg-white p-2 transition-shadow first:border-transparent',
            {
              'border-transparent': snapshot.isDragging,
              'shadow-lg': snapshot.isDragging
            }
          )}>
          <div className="w-1/3 text-right text-sm text-gray-500">
            {contactItem.prettyKey}:
          </div>
          <div className="w-2/3 text-left text-sm font-bold text-gray-700">
            {contactItem.value}
          </div>
          <div className="flex w-1/3 place-content-end">
            <Tooltip content={error?.message} disabled={!error}>
              <button
                aria-label="Remove Contact"
                className="h-5 w-5 text-gray-500"
                disabled={isLoading}
                onClick={() => destroyContactItem({ id: contactItem.id })}>
                {error ? (
                  <ExclamationIcon data-testid="error-removing-contact-item" />
                ) : isLoading ? (
                  <span data-testid="removing-contact-item">
                    <Spinner />
                  </span>
                ) : (
                  <TrashIcon />
                )}
              </button>
            </Tooltip>
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default function ContactInfoProvider() {
  return (
    <>
      <Helmet>
        <title>Contact Info - Account Settings - Cloud CMA</title>
      </Helmet>
      <Suspense
        fallback={
          <SettingsCard>
            <SettingsCard.Group>
              <SettingsCard.Title>Contact Info</SettingsCard.Title>
              <div className="flex items-center justify-center space-x-2 text-sm text-gray-500">
                <div className="h-4 w-4">
                  <Spinner />
                </div>
                <div>Loading Contact Information</div>
              </div>
            </SettingsCard.Group>
          </SettingsCard>
        }>
        <ContactInfo />
      </Suspense>
    </>
  )
}
