import { api, useCurrentUser } from '@cma/app'
import { getAccessTokens } from '@cma/features/auth'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

interface Product {
  key:
    | 'cloud_cma'
    | 'cloud_streams'
    | 'cloud_mlx'
    | 'cloud_attract'
    | 'launchpad'
    | null
  name: string | null
  url: string | null
  tagline: string | null
  subscribed: boolean | null
  createUrl: string | null
  externalLoginRoute: string | null
}

interface ProductAvailability {
  enabled: boolean
  products: Product[]
}

export function useProductAvailability() {
  const { data: { currentUser } = {} } = useCurrentUser()

  return useQuery<ProductAvailability | null, Error>(
    ['productAvailability'],
    async () => {
      try {
        if (currentUser?.isAuthSso) {
          return {
            enabled: false,
            products: []
          }
        }

        const query = gql`
          mutation ProductAvailability($user: ExternalUserInputType!) {
            userProductAvailability(external_user: $user) {
              enabled: enable_app_switcher
              products {
                key
                name
                tagline
                url: switch_route
                subscribed
                externalLoginRoute: external_login_route
              }
            }
          }
        `
        const variables = {
          user: {
            application_key: 'cloud_cma',
            id: currentUser?.id,
            email: currentUser?.email,
            cas_user_id: currentUser?.casUserId,
            mls_code: currentUser?.mlsCredential?.code
          }
        }
        const res = await api<{
          userProductAvailability: ProductAvailability
        }>({
          url: `${process.env.VITE_APP_CAS_URL}/graphql/protected`,
          query,
          variables
        })

        const productsWithLaunchpad = res.userProductAvailability.products
        const enableLaunchpad = !!currentUser?.casUserId

        if (enableLaunchpad) {
          productsWithLaunchpad.push({
            key: 'launchpad',
            name: 'Launchpad',
            tagline: 'Learn and manage billing',
            url: `${process.env.VITE_APP_CAS_URL}/launchpad`,
            subscribed: null,
            createUrl: null,
            externalLoginRoute: null
          })
        }

        const accessTokens = getAccessTokens()

        return {
          enabled: res.userProductAvailability.enabled,
          products: productsWithLaunchpad.map((product) => ({
            ...product,
            url: `${product.url}?jwt=${accessTokens?.currentToken}`,
            createUrl: `${product.externalLoginRoute}?jwt=${accessTokens?.currentToken}&continue_to=/${product.key}/new`
          }))
        }
      } catch (error) {
        // If we failed to load the network, disable the app switcher
        return {
          enabled: false,
          products: []
        }
      }
    },
    {
      retry: false,
      suspense: false
    }
  )
}
