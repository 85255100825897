import { api } from '@cma/app'
import { ReportQuery, ReportQueryVariables } from '@cma/generated/graphql'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function getReport(id?: string) {
  const query = gql`
    fragment ListingParts on Listing {
      id
      address
      city
      state
      zip
      price
      priceListOrig
      priceList
      priceSold
      pricePerSqft
      lat: geoLat
      lon: geoLon
      hide
      status
      statusAsColor
      statusAsLetter
      photos
      beds
      baths
      bathsFull
      bathsHalf
      formattedBaths
      sqft
      yearBuilt
      propType
      dateList
      dateSold
      dom
      position
      remarks
      lotsize
      lotdim
      taxes
      garages
      area
      subdivision
      style
      source
      updatedAt
      county
      mlsnum
      features
      dateOffmarket
    }

    fragment ReportParts on ReportInterface {
      __typename
      id
      title
      notes
      address
      addressParts: reportAddressParts
      pdfPermalink
      status
      guid
      isModern
      description
      thumbnail: thumbnailUrlWithTimestamp
      theme {
        key
        value
      }
      themes {
        key
        value
        preview
        color {
          primary
          secondary
        }
        type
      }
      font {
        key
        value
      }
      fonts {
        key
        url
        value
      }
      layout {
        key
        value
      }
      layouts {
        key
        value
        preview
        type
      }
      ... on FlyerReport {
        headline {
          key
          value
        }
        headlines {
          key
          value
        }
      }
      ... on CmaReport {
        bedsMin
        bedsMax
        bathsMin
        bathsMax
        sqftMin
        sqftMax
        lotsizeMin
        lotsizeMax
        priceListMin
        priceListMax
        proximitySearch
        minListings
        monthsBack
      }
      listings {
        ...ListingParts
      }
      subjectProperty {
        address
        lat
        lon
        beds
        baths
        sqft
        propType
        propSubType
        photo
        lotSize
        landMeasurement
        yearBuilt
        taxes
        garages
        area
        subdivision
        style
      }
      ... on TourReport {
        listingCount
        cities
      }
      # Not yet ready
      # Also, could possibly live in it's own hook as it's
      # A rather large call? We'll experiment when the
      # backend is in place
      # TODO: Experiment with useReport and useCustomizeReport
      # pages {
      #   key
      #   title
      #   active
      #   parent
      #   isParent
      #   isCustom
      #   position
      #   group
      # }
    }

    query Report($id: ID!) {
      report(id: $id) {
        ...ReportParts
      }
    }
  `
  const variables = { id }
  return api<ReportQuery>({ query, variables })
}

export function useReport({ id }: ReportQueryVariables) {
  return useQuery<Awaited<ReturnType<typeof getReport>>, Error>(
    ['report', id],
    () => getReport(id),
    {
      enabled: !!id
    }
  )
}

/*
{
    ...res.report,
    listings: sortByPosition(res.report?.listings || []),
    // TODO: Remove `pages` when hooks up to the backend.
    // Also ensure we have a position from the backend
    pages: TEMP_CUSTOMIZE_PAGES.map((page, index) => ({
      ...page,
      position: index
    }))
  }
*/
