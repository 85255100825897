import { classNames } from '@cma/common'
import { MinusCircleIcon } from '@heroicons/react/outline'
import { Children, PropsWithChildren } from 'react'
import {
  DragDropContext,
  DragDropContextProps,
  Draggable,
  Droppable
} from 'react-beautiful-dnd'
import { CustomizePageEdit } from './CustomizePageEdit'
import { CustomizePagePreview } from './CustomizePagePreview'
import { CustomizePageWithPages } from './useCustomizePages'

export function CustomizeActivePages({
  children,
  ...props
}: DragDropContextProps) {
  return (
    <DragDropContext {...props}>
      <CustomizeActivePages_>{children}</CustomizeActivePages_>
    </DragDropContext>
  )
}

CustomizeActivePages.Page = CustomizeActivePage

interface CustomizeActivePagesProps {
  droppableId?: string
  type?: string
}

function CustomizeActivePages_({
  droppableId = 'pages',
  type = 'pages',
  children
}: PropsWithChildren<CustomizeActivePagesProps>) {
  const hasChildren = !!Children.toArray(children).length

  if (!hasChildren) {
    return <p className="text-sm text-gray-700">No pages selected.</p>
  }

  return (
    <Droppable droppableId={droppableId} type={type}>
      {(provided) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          data-testid={`customize-active-${type}`}>
          <div className="-mb-2">
            {children}
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  )
}

interface CustomizeActivePageProps {
  page: CustomizePageWithPages
  index: number
  onDelete: (pageKey: string) => void
}

function CustomizeActivePage({
  page,
  index,
  onDelete
}: CustomizeActivePageProps) {
  return (
    <>
      <Draggable key={page.key} draggableId={page.key} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            className={classNames('relative -ml-10 mb-2 pl-10', {
              group: !page.pages?.length
            })}
            {...provided.draggableProps}
            {...provided.dragHandleProps}>
            {!page.pages?.length && (
              <button
                type="button"
                aria-label={`Remove ${page.title}`}
                className="absolute left-0 top-1/2 hidden h-10 w-10 -translate-y-1/2 items-center justify-center group-focus-within:flex group-hover:flex"
                onClick={() => onDelete(page.key)}>
                <MinusCircleIcon className="h-6 w-6 text-red-500" />
              </button>
            )}
            <div
              className={classNames(
                'rounded border bg-white px-4 py-3 text-sm text-gray-700 transition-shadow',
                {
                  shadow: !snapshot.isDragging,
                  'shadow-md': snapshot.isDragging
                }
              )}>
              <div className="flex items-center justify-between space-x-4">
                <CustomizePagePreview page={page} />
                <CustomizePageEdit page={page} />
              </div>
              {/* <CustomizePagePreview page={page} /> */}
              {!!page.pages?.length && (
                <div className="pt-4 pl-6">
                  <CustomizeActivePages_
                    droppableId={page.key}
                    type={`${page.key}-sub-pages`}>
                    {page.pages.map((page, index) => (
                      <CustomizeActivePage
                        key={page.key}
                        index={index}
                        page={page}
                        onDelete={onDelete}
                      />
                    ))}
                  </CustomizeActivePages_>
                </div>
              )}
            </div>
          </div>
        )}
      </Draggable>
    </>
  )
}
