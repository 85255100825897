import { api } from '@cma/app'
import {
  ReportQuery,
  ToggleReportListingMutation,
  ToggleReportListingMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

interface ToggleReportListingContext {
  previousReport?: ReportQuery
}

export function useToggleReportListing() {
  const queryClient = useQueryClient()
  return useMutation<
    ToggleReportListingMutation,
    Error,
    ToggleReportListingMutationVariables & { reportId: string },
    ToggleReportListingContext
  >(
    ({ id }) => {
      const query = gql`
        mutation ToggleReportListing($id: ID!) {
          toggleListingVisibility(id: $id) {
            errors
          }
        }
      `
      const variables = { id }
      return api({ query, variables })
    },
    {
      onMutate: async ({ reportId, id }) => {
        await queryClient.cancelQueries(['report', reportId])
        const previousReport = queryClient.getQueryData<ReportQuery>([
          'report',
          reportId
        ])

        if (previousReport?.report?.listings) {
          queryClient.setQueryData<ReportQuery>(['report', reportId], {
            ...previousReport,
            report: {
              ...previousReport.report,
              listings: previousReport.report.listings.map((listing) => {
                if (listing.id === id) {
                  return { ...listing, hide: !listing.hide }
                }
                return listing
              })
            }
          })
        }

        return { previousReport }
      },
      onError(_, { reportId }, context) {
        if (context?.previousReport) {
          queryClient.setQueryData<ReportQuery>(
            ['report', reportId],
            context.previousReport
          )
        }
      }
    }
  )
}
