import { Modal } from '@cma/common'

interface CustomizePowerPacksProps {
  isOpen: boolean
  onClose: () => void
}

export function CustomizePowerPacks({
  isOpen,
  onClose
}: CustomizePowerPacksProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Title>Power Packs</Modal.Title>
      <div className="mt-2">Power Packs video / checkout</div>
    </Modal>
  )
}
