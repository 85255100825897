export function CopyIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 20"
      {...props}>
      <path
        fillRule="evenodd"
        d="M14.556 6.5H8.444c-.57 0-.944.434-.944.864v8.272c0 .43.375.864.944.864h6.112c.57 0 .944-.434.944-.864V7.364c0-.43-.375-.864-.944-.864zM8.444 5C7.094 5 6 6.058 6 7.364v8.272C6 16.942 7.094 18 8.444 18h6.112C15.906 18 17 16.942 17 15.636V7.364C17 6.058 15.906 5 14.556 5H8.444z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M3.22 2.22A.75.75 0 013.75 2h7a.75.75 0 010 1.5H4.5v7.75a.75.75 0 11-1.5 0v-8.5a.75.75 0 01.22-.53z"
        clipRule="evenodd"
      />
    </svg>
  )
}
