export function IconLayoutFlyer4(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 233 303" {...props}>
      <path
        fill="currentColor"
        d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
      />
      <path
        fill="currentColor"
        d="M184.74 182.14h23.59v3h-23.59zm-92.89 0h79.98v3H91.85zm0 18.41h113.47v3H91.85zm0-36.76h116.48v3H91.85zm0 73.01h23.59v3H91.85zm36.49 0h72.98v3h-72.98zm-36.49 18.41h116.48v3H91.85zm0-36.75h116.48v3H91.85zM26.44 23.75h36.83v4H26.44zm56.99 0h124.9v4H83.43zM33.49 162.76h36.83v4H33.49zm-3.44 19.79h43.71v4H30.05zm8.48 17.52h26.75v4H38.53z"
      />
      <rect
        fill="#e1e5e9"
        x="90.8"
        y="43.53"
        width="116.43"
        height="102"
        rx="5"
        ry="5"
        transform="rotate(-180 149.01 94.535)"
      />
      <path
        fill="currentColor"
        d="M202.22 147H95.8a6.5 6.5 0 0 1-6.5-6.5v-92a6.51 6.51 0 0 1 6.5-6.5h106.42a6.51 6.51 0 0 1 6.5 6.5v92a6.5 6.5 0 0 1-6.5 6.5zM95.8 45a3.5 3.5 0 0 0-3.5 3.5v92a3.5 3.5 0 0 0 3.5 3.5h106.42a3.5 3.5 0 0 0 3.5-3.5v-92a3.5 3.5 0 0 0-3.5-3.5z"
      />
      <rect
        fill="#e1e5e9"
        x="25.78"
        y="43.53"
        width="51.53"
        height="44.22"
        rx="5"
        ry="5"
        transform="rotate(-180 51.545 65.64)"
      />
      <path
        fill="currentColor"
        d="M72.31 89.25H30.78a6.51 6.51 0 0 1-6.5-6.5V48.53a6.51 6.51 0 0 1 6.5-6.5h41.53a6.51 6.51 0 0 1 6.5 6.5v34.22a6.51 6.51 0 0 1-6.5 6.5zM30.78 45a3.5 3.5 0 0 0-3.5 3.5v34.25a3.5 3.5 0 0 0 3.5 3.5h41.53a3.5 3.5 0 0 0 3.5-3.5V48.53a3.5 3.5 0 0 0-3.5-3.5z"
      />
      <rect
        fill="#e1e5e9"
        x="25.78"
        y="100.86"
        width="51.53"
        height="44.22"
        rx="5"
        ry="5"
        transform="rotate(-180 51.545 122.975)"
      />
      <path
        fill="currentColor"
        d="M72.31 146.58H30.78a6.51 6.51 0 0 1-6.5-6.5v-34.21a6.5 6.5 0 0 1 6.5-6.5h41.53a6.51 6.51 0 0 1 6.5 6.5v34.21a6.51 6.51 0 0 1-6.5 6.5zm-41.53-44.21a3.5 3.5 0 0 0-3.5 3.5v34.21a3.5 3.5 0 0 0 3.5 3.5h41.53a3.5 3.5 0 0 0 3.5-3.5v-34.21a3.5 3.5 0 0 0-3.5-3.5z"
      />
      <path
        fill="#fff"
        d="m196.526 51.416 3.014-2.631 1.972 2.26-3.013 2.63zM108.81 132l-2-2.26 6.41-5.6 2 2.26zm12.81-11.19-2-2.26 6.4-5.59 2 2.25zm12.81-11.19-2-2.26 6.41-5.59 2 2.26zm12.82-11.19-2-2.26 6.41-5.59 2 2.26zm12.81-11.18-2-2.26 6.41-5.6 2 2.26zm12.82-11.19-2-2.26 6.41-5.59 2 2.26zm12.81-11.19-2-2.26 6.4-5.59 2 2.26zM97.401 137.947l3.014-2.631 1.973 2.26-3.014 2.63zM196.298 137.84l1.985-2.25 3 2.648-1.986 2.249z"
      />
      <path
        fill="#fff"
        d="m189.92 132.21-6.38-5.63 2-2.25 6.36 5.67zM177.17 121l-6.38-5.63 2-2.25 6.38 5.63zm-12.76-11.26-6.41-5.67 2-2.25 6.38 5.62zm-12.75-11.3-6.38-5.63 2-2.25 6.38 5.63zm-12.75-11.26-6.38-5.63 2-2.25 6.37 5.63zm-12.76-11.26-6.37-5.62 2-2.25 6.38 5.62zM113.4 64.67 107 59l2-2.25 6.38 5.63zM97.649 50.77l1.985-2.25 3 2.647-1.986 2.25z"
      />
    </svg>
  )
}
