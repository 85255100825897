import { Modal } from '@cma/common'

interface CustomizeClassicPowerPacks {
  isOpen: boolean
  onClose: () => void
}

export function CustomizeClassicPowerPacks({
  isOpen,
  onClose
}: CustomizeClassicPowerPacks) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Title>Classic Power Packs</Modal.Title>
      <div className="mt-2">Classic Power Packs video / checkout</div>
    </Modal>
  )
}
