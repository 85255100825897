import { api } from '@cma/app'
import { BrokersQuery, BrokersQueryVariables } from '@cma/generated/graphql'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useBrokers({ filters }: BrokersQueryVariables) {
  return useQuery<BrokersQuery, Error>(['brokers', filters], () => {
    const query = gql`
      query Brokers($filters: [BrokerFilterInput!]) {
        brokers(filters: $filters) {
          id
          name
          key
          seatsUsed
          activeAgentCount
          status
          admin {
            name
            email
          }
        }
      }
    `
    const variables = { filters }
    return api<BrokersQuery>({ query, variables })
  })
}
