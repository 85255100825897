export function CarIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.47 2.25a4.75 4.75 0 0 0-4.703 4.078l-.51 3.566a.752.752 0 0 0-.002.19 2.268 2.268 0 0 0-.005.146V13c0 .476.089.931.25 1.35V18a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1.283c.164.022.33.033.5.033h10c.17 0 .336-.011.5-.033V18a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-3.65a3.74 3.74 0 0 0 .25-1.35v-2.77c0-.049-.002-.097-.005-.145a.754.754 0 0 0-.002-.191l-.51-3.566a4.75 4.75 0 0 0-4.702-4.078H6.469Zm10.49 5.777-.212-1.487a3.25 3.25 0 0 0-3.217-2.79H6.469a3.25 3.25 0 0 0-3.217 2.79L3.04 8.027l.019-.004 2.92-.584a9.75 9.75 0 0 1 1.912-.189h4.218a9.75 9.75 0 0 1 1.912.19l2.92.583.02.004Zm-9.069.723a8.25 8.25 0 0 0-1.618.16l-2.92.584a.75.75 0 0 0-.603.736v.801A1 1 0 0 1 3 11h2.5A1.5 1.5 0 0 1 7 12.5a.5.5 0 0 1-.5.5H2.75A2.25 2.25 0 0 0 5 15.25h10A2.25 2.25 0 0 0 17.25 13H13.5a.5.5 0 0 1-.5-.5 1.5 1.5 0 0 1 1.5-1.5H17a1 1 0 0 1 .25.031v-.801a.75.75 0 0 0-.603-.736l-2.92-.584a8.25 8.25 0 0 0-1.618-.16H7.89Z"
        fill="currentColor"
      />
    </svg>
  )
}
