export function LeadingIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.996 4.938a.75.75 0 0 1 .066 1.058l-7.5 8.5a.75.75 0 0 1-1.092.034l-3.5-3.5a.75.75 0 1 1 1.06-1.06l2.936 2.935 6.972-7.901a.75.75 0 0 1 1.058-.066Z"
        fill="currentColor"
      />
    </svg>
  )
}
