import { Badge } from '@cma/common'

interface TourBadgeProps {
  status?: string | null
  mappedStatus?: string | null
}

export function TourBadge({ status, mappedStatus }: TourBadgeProps) {
  return (
    <Badge
      variant={
        mappedStatus === 'active'
          ? 'success'
          : mappedStatus === 'pending'
          ? 'warning'
          : mappedStatus === 'closed' || mappedStatus === 'deleted'
          ? 'danger'
          : mappedStatus === 'backup'
          ? 'info'
          : 'default'
      }>
      {status || 'N/A'}
    </Badge>
  )
}
