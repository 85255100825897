import { api } from '@cma/app'
import { CustomPdfsQuery } from '@cma/generated/graphql'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useCustomPdfs() {
  return useQuery<CustomPdfsQuery, Error>(['customPdfs'], () => {
    const query = gql`
      fragment CustomPdfParts on CustomPdf {
        id
        title
        url
        image
      }

      query CustomPdfs {
        currentUser {
          customPdfs {
            ...CustomPdfParts
          }
        }
      }
    `
    return api<CustomPdfsQuery>({ query })
  })
}
