import { api } from '@cma/app'
import { IntegrationsQuery, IntegrationTypeEnum } from '@cma/generated/graphql'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

interface Integration {
  key: IntegrationTypeEnum | null
  name: string | null
  password: string | null
}

type Integrations = Record<IntegrationTypeEnum, Integration | null>

export function useIntegrations() {
  return useQuery<Integrations | null, Error>(['integrations'], async () => {
    const query = gql`
      query Integrations {
        currentUser {
          integrations {
            key
            name
            password
          }
        }
      }
    `
    const res = await api<IntegrationsQuery>({ query })
    const integrations = res.currentUser?.integrations?.reduce<Integrations>(
      (integrations, integration) => {
        if (!integration.key) return integrations

        return {
          ...integrations,
          [integration.key]: integration
        }
      },
      {
        ratedagent: null,
        reach150: null,
        realsatisfied: null,
        testimonialtree: null,
        zillow: null,
        titlefy: null,
        transaction_desk: null,
        zip_form: null
      }
    )

    return integrations || null
  })
}
