import { FormField, classNames, useBuildHandoffUrl } from '@cma/common'
import { HelixIcon } from '@helix/helix-icon'
import { spinner as SpinnerIcon } from '@helix/helix-icon/outlined'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import * as yup from 'yup'
import { useSsoActivate } from './useSsoActivate'
import { useSsoUser } from './useSsoUser'

const schema = yup.object({
  firstName: yup.string().required('Your first name is required'),
  lastName: yup.string().required('Your last name is required'),
  email: yup.string().email('Invalid email').required('Your email is required'),
  mlsId: yup.string().required('Your mls id is required'),
  phoneNumber: yup.string(),
  companyName: yup.string(),
  ouid: yup.string().required()
})

export function AccountSignUp() {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token') || ''

  const { data: { partnershipUser } = {} } = useSsoUser(
    { token },
    { enabled: !!token }
  )

  const buildHandoffUrl = useBuildHandoffUrl()
  const {
    mutate: activate,
    isLoading: isActivating,
    error: activateError
  } = useSsoActivate({
    async onSuccess(data) {
      await new Promise(() => {
        window.location.href =
          data.partnershipUserSignup?.redirectUrl || buildHandoffUrl('/')
      })
    }
  })

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName:
        searchParams.get('first_name') || partnershipUser?.firstName || '',
      lastName:
        searchParams.get('last_name') || partnershipUser?.lastName || '',
      email: searchParams.get('email') || partnershipUser?.email || '',
      companyName:
        searchParams.get('company_name') || partnershipUser?.companyName || '',
      mlsId: searchParams.get('code') || partnershipUser?.mlsId || '',
      ouid:
        partnershipUser?.mlsOptions?.find(
          (mls) => mls.mlsId === partnershipUser?.mlsId
        )?.ouid || ''
    }
  })

  const hasMultipleMlsOptions = (partnershipUser?.mlsOptions?.length || 0) > 1

  return (
    <form
      className="helix-card max-w-[32.375rem] space-y-5 p-5 sm:p-8"
      onSubmit={handleSubmit((data) => {
        activate({
          token,
          input: {
            mlsId: data.mlsId,
            firstname: data.firstName,
            lastname: data.lastName,
            email: data.email,
            phoneNumber: data.phoneNumber,
            companyName: data.companyName
          },
          ouid: data.ouid
        })
      })}>
      <div className="space-y-1">
        <div className="helix-heading text-base">Choose your default MLS</div>
        <div className="helix-text-gray-700 helix-body text-sm">
          You can switch between these easily in Cloud CMA
        </div>
      </div>
      <div
        className={classNames('grid grid-cols-1 gap-2.5', {
          'grid-cols-2': hasMultipleMlsOptions
        })}>
        {partnershipUser?.mlsOptions?.map((mls) => {
          if (mls.mlsKey) {
            return (
              <button
                key={mls.mlsKey}
                type="button"
                className={classNames(
                  'helix-card helix-radio--inline helix-text-gray-600 helix-border relative h-full text-left shadow-none',
                  {
                    'helix-border--blue-600 helix-text-gray-900':
                      watch('mlsId') === mls.mlsKey
                  }
                )}
                onClick={() => {
                  setValue('mlsId', mls.mlsKey || '')
                  setValue('ouid', mls.ouid || '')
                }}>
                <label
                  htmlFor={mls.mlsKey}
                  className="flex h-full cursor-pointer flex-col justify-between px-4 py-2.5">
                  <input
                    type="radio"
                    value={mls.mlsKey}
                    className={classNames('absolute right-5 top-7', {
                      'right-5 top-8': hasMultipleMlsOptions
                    })}
                    {...register('mlsId')}
                  />
                  <div
                    className={classNames(
                      'helix-heading mb-1 w-10/12 text-sm capitalize sm:text-base',
                      {
                        'w-full': !hasMultipleMlsOptions
                      }
                    )}>
                    {mls.mlsName || 'Unknown MLS'}
                  </div>
                  <div className="helix-text-gray-500 helix-body text-sm">
                    {mls.mlsKey}
                  </div>
                </label>
              </button>
            )
          }
        })}
      </div>
      <div className="space-y-4 pb-4 sm:pr-10">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <FormField label="First name" error={errors?.firstName?.message}>
            {(props) => (
              <input
                type="text"
                placeholder="First name"
                className="helix-text-input"
                {...props}
                {...register('firstName')}
              />
            )}
          </FormField>
          <FormField label="Last name" error={errors?.lastName?.message}>
            {(props) => (
              <input
                placeholder="Last name"
                className="helix-text-input"
                {...props}
                {...register('lastName')}
              />
            )}
          </FormField>
        </div>
        <div className="flex space-x-4">
          <div className="w-full sm:w-9/12">
            <FormField label="Email" error={errors?.email?.message}>
              {(props) => (
                <input
                  readOnly
                  disabled
                  type="email"
                  placeholder="Email"
                  className="helix-text-input helix-bg-gray-50 helix-text-gray-400"
                  {...props}
                  {...register('email')}
                />
              )}
            </FormField>
          </div>
          <div>
            <FormField label="Phone number">
              {(props) => (
                <input
                  type="tel"
                  placeholder="555-555-5555"
                  className="helix-text-input"
                  {...props}
                  {...register('phoneNumber')}
                />
              )}
            </FormField>
          </div>
        </div>
        <div className="sm:w-8/12">
          <FormField label="Company name">
            {(props) => (
              <input
                placeholder="Company name"
                className="helix-text-input"
                {...props}
                {...register('companyName')}
              />
            )}
          </FormField>
        </div>
      </div>
      <button
        disabled={!isValid || isActivating}
        className="helix-btn helix-btn--primary w-full">
        {!isActivating ? (
          'Activate your Account'
        ) : (
          <span className="mx-auto block h-5 w-5 animate-spin">
            <HelixIcon icon={SpinnerIcon} />
          </span>
        )}
      </button>
      {activateError && (
        <div
          role="alert"
          aria-live="polite"
          className="helix-text-red-500 helix-small helix-text--center mb-4">
          {activateError?.message}
        </div>
      )}
    </form>
  )
}
