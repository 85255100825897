interface ListingDescriptionProps {
  description: string
}

export function ListingDescription({ description }: ListingDescriptionProps) {
  return (
    <div
      className="text-sm text-gray-900"
      dangerouslySetInnerHTML={{ __html: description }}
    />
  )
}
