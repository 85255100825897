import { api } from '@cma/app'
import { TourQuery } from '@cma/generated/graphql'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

interface UseTourArgs {
  id?: string
}

export function getTour(id?: string) {
  const query = gql`
    query Tour($id: ID!) {
      tour(id: $id) {
        id
        title
        tourDate
        status
        pdfPermalink
        pageTemplate
        agent {
          id
          avatar
          name
          officePhone
          mobilePhone
          companyLogoUrl
        }
        appointments {
          endTime
          id
          listing {
            changes {
              timestamp
              changes {
                oldValue
                newValue
                field
              }
            }
            acres
            address
            agentList {
              agentId
              email
              id
              name
              phone
            }
            area
            baths
            bathsFull
            bathsHalf
            bathsQuarter
            bathsThreeQuarter
            beds
            city
            comment
            county
            data
            dateList
            dateOffmarket
            dateSold
            dom
            features
            formattedBaths
            garages
            geoLat
            geoLon
            hide
            id
            lat
            lon
            lotdim
            lotsize
            mappedStatus
            mlsnum
            officeList {
              email
              id
              name
              phone
            }
            photos
            position
            price
            priceList
            priceListOrig
            pricePerSqft
            priceRaw
            priceSold
            propType
            rating
            remarks
            remarksPrivate
            schoolElementary
            schoolHigh
            schoolMiddle
            schools {
              name
              distance
              level
            }
            source
            sqft
            state
            status
            statusAsColor
            statusAsLetter
            street
            style
            subdivision
            taxes
            updatedAt
            yearBuilt
            zip
          }
          note
          position
          startTime
        }
      }
    }
  `
  const variables = { id }
  return api<TourQuery>({ query, variables })
}

export function useTour({ id }: UseTourArgs) {
  return useQuery<Awaited<ReturnType<typeof getTour>>, Error>(
    ['tour', id],
    () => getTour(id),
    {
      enabled: !!id
    }
  )
}
