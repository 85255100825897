export function CsvIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 1A.75.75 0 0 1 2 .25h11a.75.75 0 0 1 .53.22l3 3c.141.14.22.331.22.53v3H17a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1h-.25v2a.75.75 0 0 1-.75.75H2a.75.75 0 0 1-.75-.75v-2H1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h.25V1Zm14 3.31V7H2.75V1.75h9.94l2.56 2.56ZM2.75 16.25V15h12.5v1.25H2.75Zm4.63-4.393c.023.627.477 1.216 1.608 1.216 1.078 0 1.644-.533 1.644-1.292 0-.73-.486-1.031-1.096-1.151l-.58-.12c-.34-.065-.536-.197-.536-.41 0-.255.21-.449.598-.449.398 0 .62.217.653.454h.89c-.023-.618-.541-1.18-1.54-1.18-.926 0-1.56.477-1.56 1.245 0 .662.429 1.005 1.064 1.137l.566.123c.392.085.592.199.592.448 0 .264-.211.46-.657.46-.436 0-.697-.217-.73-.48H7.38Zm-2.918-1.075c0-.677.281-1.057.762-1.057.419 0 .68.304.7.712h.917v-.112c-.02-.838-.695-1.394-1.623-1.394-1.114 0-1.738.712-1.738 1.851v.445c0 1.137.619 1.846 1.738 1.846.926 0 1.602-.55 1.623-1.37v-.115h-.917c-.02.398-.276.688-.7.688-.484 0-.762-.372-.762-1.046v-.448ZM13.477 13H12.27l-1.275-3.999h1.12l.764 2.982h.044l.753-2.982h1.06L13.476 13Z"
        fill="currentColor"
      />
    </svg>
  )
}
