export function ArrowSmallDownIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M9.312 13.187a1 1 0 0 0 1.518 0l3.894-4.536A1 1 0 0 0 13.966 7h-7.79a1 1 0 0 0-.758 1.651l3.894 4.536Z"
        fill="currentColor"
      />
    </svg>
  )
}
