import { Wrapper } from '@cma/common'
import { Helmet } from 'react-helmet-async'

export default function Homebeat() {
  return (
    <Wrapper>
      <Helmet>
        <title>Homebeat - Homebeats - Cloud CMA</title>
      </Helmet>
      <h1>Homebeat</h1>
    </Wrapper>
  )
}
