import { api } from '@cma/app'
import {
  InviteBrokerMutation,
  InviteBrokerMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useInviteBroker() {
  const queryClient = useQueryClient()
  return useMutation<
    InviteBrokerMutation,
    Error,
    InviteBrokerMutationVariables
  >(
    ({ accountId }) => {
      const query = gql`
        mutation InviteBroker($accountId: ID!) {
          inviteBroker(accountId: $accountId) {
            errors
          }
        }
      `
      const variables = { accountId }
      return api({ query, variables })
    },
    {
      async onSuccess(_, { accountId }) {
        await Promise.all([
          queryClient.invalidateQueries(['broker', accountId]),
          queryClient.invalidateQueries(['brokerAgents', accountId]),
          queryClient.invalidateQueries(['brokerStats', accountId])
        ])
      }
    }
  )
}
