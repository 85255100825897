import { api } from '@cma/app'
import {
  ListingSearchPartsFragment,
  ListingSearchQuery,
  ListingSearchQueryVariables
} from '@cma/generated/graphql'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { gql } from 'graphql-request'

const MIN_CHAR_LIMIT = 1

export function useListingSearch(
  variables: ListingSearchQueryVariables,
  options?: Omit<
    UseQueryOptions<ListingSearchPartsFragment[], Error>,
    'queryKey' | 'queryFn' | 'refetchOnWindowFocus'
  >
) {
  return useQuery<ListingSearchPartsFragment[], Error>(
    ['listingSearch', variables],
    async () => {
      const query = gql`
        fragment ListingSearchParts on Listing {
          acres
          address
          agentList {
            agentId
            email
            id
            name
            phone
          }
          area
          baths
          bathsFull
          bathsHalf
          bathsQuarter
          bathsThreeQuarter
          beds
          city
          changes {
            timestamp
            changes {
              oldValue
              newValue
              field
            }
          }
          county
          data
          dateList
          dateOffmarket
          dateSold
          dom
          features
          formattedBaths
          garages
          geoLat
          geoLon
          hide
          id
          lat
          lon
          lotdim
          lotsize
          mappedStatus
          mlsnum
          officeList {
            email
            id
            name
            phone
          }
          photos
          position
          price
          priceList
          priceListOrig
          pricePerSqft
          priceRaw
          priceSold
          propType
          remarks
          remarksPrivate
          schoolElementary
          schoolMiddle
          schoolHigh
          schools {
            name
            distance
            level
          }
          source
          sqft
          state
          status
          statusAsColor
          statusAsLetter
          street
          style
          subdivision
          taxes
          updatedAt
          yearBuilt
          zip
        }

        query ListingSearch($query: String!) {
          listingsByAddressOrMlsNums(query: $query) {
            listings {
              ...ListingSearchParts
            }
          }
        }
      `
      const res = await api<ListingSearchQuery>({ query, variables })
      return res.listingsByAddressOrMlsNums?.listings || []
    },
    {
      suspense: false,
      ...options,
      refetchOnWindowFocus: false,
      enabled: options?.enabled ?? variables.query.length >= MIN_CHAR_LIMIT,
      keepPreviousData:
        options?.keepPreviousData ?? variables.query.length >= MIN_CHAR_LIMIT
    }
  )
}
