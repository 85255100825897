import { api } from '@cma/app'
import { CustomPagesQuery } from '@cma/generated/graphql'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useCustomPages() {
  return useQuery<CustomPagesQuery, Error>(
    ['customPages'],
    () => {
      const query = gql`
        fragment CustomPageParts on CustomPage {
          canModify
          content
          id
          isDefault
          key
          title
        }

        query CustomPages {
          currentUser {
            customPages {
              ...CustomPageParts
            }
          }
        }
      `
      return api<CustomPagesQuery>({ query })
    },
    {
      refetchOnWindowFocus: false
    }
  )
}
