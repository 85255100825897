import { useCurrentUser, UserRole } from '@cma/app'
import {
  Avatar,
  Button,
  classNames,
  Flash,
  FormField,
  generateKey,
  ImageCropperModal,
  Input,
  isCorrectFileSize,
  isSupportedFileType,
  Modal,
  NotFound,
  Select,
  Spinner,
  states,
  SUPPORTED_IMAGE_FORMATS,
  Title,
  Wrapper
} from '@cma/common'
import {
  ImportAgents,
  useBroker,
  useUpdateBrokerAccount
} from '@cma/features/admin/broker'
import {
  useMlses,
  useUpdateMlsCredential,
  useUpdatePassword,
  useUpdateProfile
} from '@cma/features/settings'
import { MlsPartsFragment } from '@cma/generated/graphql'
import { CompanyLogoIcon } from '@cma/icons'
import { CheckIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChangeEvent, Suspense, useCallback, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

const userProfileSchema = yup.object({
  name: yup.string().required('Full name is required'),
  mobilePhone: yup.string(),
  city: yup.string(),
  state: yup.string(),
  email: yup.string().required('Email is required'),
  password: yup.string(),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], "The passwords don't match")
})

const mlsCredentialSchema = yup.object({
  code: yup.string().required('MLS Provider is required'),
  name: yup.string(),
  password: yup.string()
})

const companyuserProfileSchema = yup.object({
  officeAddress: yup.string(),
  officePhone: yup.string()
})

interface OnboardingProps {
  isOpen: boolean
  onClose: () => void
}

export default function Onboarding({ isOpen, onClose }: OnboardingProps) {
  const { data: { currentUser } = {} } = useCurrentUser()
  const { data: { broker } = {} } = useBroker({
    id: currentUser?.account?.id as string
  })
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const [isMlsTabDisabled, setIsMlsTabDisabled] = useState(true)
  const [isCompanyProfileTabDisabled, setIsCompanyProfileTabDisabled] =
    useState(true)
  const [isAddAgentClicked, setIsAddAgentClicked] = useState(false)
  const [isShowingImportModal, setIsShowingImportModal] = useState(false)
  const [photoKey, setPhotoKey] = useState(() => generateKey())
  const [photoPreview, setPhotoPreview] = useState(currentUser?.avatar ?? '')
  const [photoError, setPhotoError] = useState<string>()
  const [showPhotoCropper, setShowPhotoCropper] = useState(false)
  const [photoCropDimensions, setPhotoCropDimensions] = useState<string>()
  const [photoFile, setPhotoFile] = useState<File | null>()
  const [logoKey, setLogoKey] = useState(() => generateKey())
  const [logoPreview, setLogoPreview] = useState(
    currentUser?.companyLogoUrl ??
      currentUser?.account?.admin?.companyLogoUrl ??
      ''
  )
  const [logoFile, setLogoFile] = useState<File | null>()
  const [logoError, setLogoError] = useState<string>()
  const [showLogoCropper, setShowLogoCropper] = useState(false)
  const [logoCropDimensions, setLogoCropDimensions] = useState<string>()
  const { data: { mlses } = {} } = useMlses()
  const [mlsState, setMlsState] = useState('')
  const mlsesByStates = (mlses ?? []).reduce<
    Record<string, MlsPartsFragment[]>
  >((byState, mls) => {
    mls.states?.forEach((mlsState) => {
      byState = {
        ...byState,
        [mlsState]: [...(byState[mlsState] ?? []), mls]
      }
    })
    return byState
  }, {})
  const mlsesByState = mlsesByStates[mlsState] || [
    ...new Set(Object.values(mlsesByStates).flat())
  ]
  const doesStateIncludeMls = mlsesByState.some(
    (mls) => mls.key === currentUser?.mlsCredential?.code
  )

  const handlePhotoChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    const [file] = files || []

    if (file) {
      if (!isSupportedFileType(files)) {
        setPhotoError('Unsupported file type')
        return
      }

      if (!isCorrectFileSize(files)) {
        setPhotoError('Your file must be smaller than 5MB')
        return
      }

      setShowPhotoCropper(true)
      setPhotoFile(file)
    } else {
      setPhotoKey(generateKey())
      setPhotoCropDimensions(undefined)
    }
  }

  const handleLogoChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    const [file] = files || []

    if (file) {
      if (!isCorrectFileSize(files)) {
        setLogoError('Your file must be smaller than 5MB')
        return
      }

      if (!isSupportedFileType(files)) {
        setLogoError('Unsupported file type')
        return
      }

      setShowLogoCropper(true)
      setLogoFile(file)
    } else {
      setLogoKey(generateKey())
      setLogoCropDimensions(undefined)
    }
  }

  const isBrokerAdmin =
    (currentUser?.role || 0) >= UserRole.ADMIN_PLUS ||
    currentUser?.email === currentUser?.account?.admin?.email
      ? true
      : false

  const doNavigate = useCallback(() => {
    isBrokerAdmin ? navigate('/dashboard') : navigate('/')
  }, [isBrokerAdmin, navigate])

  const {
    register: userProfileRegister,
    handleSubmit: handleUserProfileSubmit,
    getValues,
    formState: { errors: userProfileErrors, isValid: isUserProfileValid }
  } = useForm<yup.InferType<typeof userProfileSchema>>({
    mode: 'onChange',
    resolver: yupResolver(userProfileSchema),
    defaultValues: {
      name: currentUser?.name ?? '',
      email: currentUser?.email ?? '',
      city: currentUser?.city ?? '',
      state: currentUser?.state ?? '',
      mobilePhone: currentUser?.mobilePhone ?? '',
      password: currentUser?.mlsCredential?.password ?? '',
      passwordConfirmation: currentUser?.mlsCredential?.password ?? ''
    }
  })
  const {
    register: mlsCredentialRegister,
    setValue: setMlsValue,
    getValues: getMlsValues,
    handleSubmit: handleMlsSubmit,
    formState: { errors: mlsErrors, isValid: isMlsCredentialValid }
  } = useForm<yup.InferType<typeof mlsCredentialSchema>>({
    resolver: yupResolver(mlsCredentialSchema),
    mode: 'onChange',
    defaultValues: {
      code: doesStateIncludeMls ? currentUser?.mlsCredential?.code ?? '' : '',
      name: currentUser?.mlsCredential?.name ?? '',
      password: currentUser?.mlsCredential?.password ?? ''
    }
  })

  const {
    register: companyProfileRegister,
    handleSubmit: handleCompanyProfileSubmit,
    formState: { errors: companyProfileErrors, isValid: isCompanyProfileValid }
  } = useForm<yup.InferType<typeof companyuserProfileSchema>>({
    mode: 'onChange',
    resolver: yupResolver(companyuserProfileSchema),
    defaultValues: {
      officeAddress: currentUser?.officeAddress ?? '',
      officePhone: currentUser?.officePhone ?? ''
    }
  })

  const {
    mutateAsync: updateProfile,
    isLoading: isUpdatingProfile,
    error: updateProfileError
  } = useUpdateProfile()
  const {
    mutate: updatePassword,
    error: updatePasswordError,
    isLoading: isUpdatingPassword
  } = useUpdatePassword()
  const {
    mutate: updateMlsCredential,
    isLoading: isUpdatingMlsCredential,
    error: mlsUpdateError
  } = useUpdateMlsCredential()
  const {
    mutateAsync: updateBrokerAccount,
    isLoading: isUpdatingBrokerAccount,
    error: updateBrokerAccountError
  } = useUpdateBrokerAccount()

  if (!broker) {
    return (
      <Wrapper>
        <NotFound />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Modal
        isOpen={isOpen}
        size="full-screen"
        onClose={
          currentPage === 2
            ? () => setCurrentPage(1)
            : currentPage === 3
            ? () => setCurrentPage(2)
            : onClose
        }
        hideClose={currentPage === 1}
        closeLabel={'Back'}>
        <Modal.Title>
          <Title as="div">Complete Onboarding</Title>
          <div className="text-center text-xs font-semibold leading-4 text-gray-500">
            {broker?.name}
          </div>
        </Modal.Title>

        <div className="m-auto mb-4 flex max-w-md flex-row justify-between text-center text-xs font-normal text-gray-400 ">
          <button
            className="flex cursor-pointer flex-row items-center justify-center gap-x-2"
            onClick={() => setCurrentPage(1)}>
            <div
              className={classNames(
                'min-h-6 m-auto flex h-6 w-6 items-center justify-center rounded-full border-2 leading-4 ',
                {
                  'border-green-600 bg-green-600 text-white': currentPage === 1,
                  'border-black bg-black text-white': currentPage > 1
                }
              )}>
              {currentPage > 1 ? <CheckIcon className="w-4" /> : 1}
            </div>
            <div
              className={classNames({
                'border-green font-semibold text-green-600': currentPage === 1
              })}>
              Your Profile
            </div>
          </button>

          <button
            onClick={() => setCurrentPage(2)}
            disabled={isMlsTabDisabled}
            className={classNames(
              'flex flex-row  items-center justify-center gap-x-2',
              {
                'cursor-not-allowed': isMlsTabDisabled,
                'cursor-pointer': !isMlsTabDisabled
              }
            )}>
            <div
              className={classNames(
                'min-h-6 m-auto flex h-6 w-6 items-center  justify-center rounded-full border-2 font-semibold leading-4',
                {
                  'border-green-600 bg-green-600 text-white': currentPage === 2,
                  'border-gray-400': currentPage < 2,
                  'border-black bg-black text-white': currentPage > 2
                }
              )}>
              {currentPage > 2 ? <CheckIcon className="w-4 " /> : 2}
            </div>
            <div
              className={classNames({
                'border-green-600 font-semibold text-green-600 ':
                  currentPage === 2
              })}>
              MLS information
            </div>
          </button>

          <button
            className={classNames(
              'flex flex-row  items-center justify-center gap-x-2',
              {
                'cursor-not-allowed': isCompanyProfileTabDisabled,
                'cursor-pointer': !isCompanyProfileTabDisabled
              }
            )}
            onClick={() => setCurrentPage(3)}
            disabled={isCompanyProfileTabDisabled}>
            <div
              className={classNames(
                'min-h-6 m-auto flex h-6 w-6 items-center  justify-center rounded-full border-2 font-semibold leading-4',
                {
                  'border-gray-400': currentPage < 3,
                  'border-green-600 bg-green-600 text-white': currentPage === 3
                }
              )}>
              3
            </div>
            <div
              className={classNames({
                'font-semibold  text-green-600 ': currentPage === 3
              })}>
              Company Profile
            </div>
          </button>
        </div>
        <div className="w-md m-auto flex max-w-md flex-col items-start rounded-lg border bg-white p-8 shadow">
          {currentPage == 1 ? (
            <>
              <Helmet>
                <title>Your Profile - Broker Onboarding - Cloud CMA</title>
              </Helmet>

              <div className="m-auto flex flex-col items-center gap-2 text-center">
                <Title>
                  {currentUser?.name.split(' ')[0]}, complete your profile
                </Title>
                <div className="mt-6">
                  <Avatar
                    src={photoPreview}
                    name={currentUser?.name ?? ''}
                    size="2xl"
                  />
                </div>
                <input
                  key={photoKey}
                  id="photo"
                  type="file"
                  accept={SUPPORTED_IMAGE_FORMATS.join(',')}
                  className="peer h-0 w-0 overflow-hidden"
                  onChange={handlePhotoChange}
                />
                <div className="rounded-md outline-2 [outline-color:-webkit-focus-ring-color] peer-focus-visible:outline">
                  <Button
                    as="label"
                    htmlFor="photo"
                    variant="tertiary"
                    size="xs">
                    {photoPreview ? 'Change Photo' : 'Add Photo'}
                  </Button>
                </div>
                {photoError && (
                  <div
                    className="text-xs text-red-500"
                    role="alert"
                    aria-live="polite">
                    {photoError}
                  </div>
                )}
                {photoPreview && (
                  <button
                    className="text-sm text-green-500"
                    onClick={() => {
                      setPhotoPreview('')
                      setPhotoFile(null)
                      setPhotoCropDimensions(undefined)
                    }}>
                    Remove my avatar and show initials instead
                  </button>
                )}
              </div>
              <form
                onSubmit={handleUserProfileSubmit(async (data) => {
                  const updateProfileMutation = await updateProfile({
                    input: {
                      name: data.name,
                      email: data.email,
                      mobilePhone: data.mobilePhone,
                      city: data.city,
                      state: data.state,
                      photo: photoFile,
                      photoCropDimensions
                    }
                  })

                  if (
                    !currentUser?.passwordSetAt &&
                    data.password &&
                    data.passwordConfirmation
                  ) {
                    updatePassword({
                      password: data.password,
                      passwordConfirmation: data.passwordConfirmation
                    })
                  }

                  setPhotoPreview(
                    updateProfileMutation.updateUserProfile?.user?.avatar || ''
                  )
                  setIsMlsTabDisabled(false)
                  setCurrentPage(2)
                })}
                className="mt-9 flex w-full flex-col gap-y-4">
                <FormField
                  label="Full Name"
                  aria-label="Full Name"
                  error={userProfileErrors.name?.message}
                  required>
                  <Input {...userProfileRegister('name')} />
                </FormField>
                <FormField
                  label="Email"
                  aria-label="Email"
                  error={userProfileErrors.email?.message}
                  required>
                  <Input
                    {...userProfileRegister('email')}
                    placeholder="Enter email"
                  />
                </FormField>
                <FormField
                  label="Password"
                  aria-label="Password"
                  error={userProfileErrors.password?.message}
                  required={!currentUser?.passwordSetAt}>
                  <Input
                    {...userProfileRegister('password')}
                    type="password"
                    disabled={!!currentUser?.passwordSetAt}
                  />
                </FormField>
                <FormField
                  label="Confirm Password"
                  aria-label="Confirm Password"
                  error={userProfileErrors.passwordConfirmation?.message}
                  required={!currentUser?.passwordSetAt}>
                  <Input
                    {...userProfileRegister('passwordConfirmation')}
                    type="password"
                    disabled={!!currentUser?.passwordSetAt}
                  />
                </FormField>
                <div className="flex w-full">
                  <div className="w-1/2 ">
                    <FormField
                      label="Mobile Phone"
                      aria-label="Mobile Phone"
                      error={userProfileErrors.mobilePhone?.message}>
                      <Input
                        {...userProfileRegister('mobilePhone')}
                        placeholder="555-555-5555"
                      />
                    </FormField>
                  </div>
                </div>
                <div className="mb-6 flex justify-between space-x-3">
                  <div className="w-1/2 ">
                    <FormField
                      label="City"
                      aria-label="City"
                      error={userProfileErrors.city?.message}>
                      <Input
                        {...userProfileRegister('city')}
                        placeholder="Los Angeles"
                      />
                    </FormField>
                  </div>
                  <div className="w-1/2 ">
                    <FormField
                      label="State or Province"
                      error={userProfileErrors.state?.message}>
                      <Select {...userProfileRegister('state')}>
                        <option value="">Select State or Province</option>
                        {states?.map((state) => {
                          return (
                            <option key={state.value} value={state.value}>
                              {state.name}
                            </option>
                          )
                        })}
                      </Select>
                    </FormField>
                  </div>
                </div>
                <Button
                  type="submit"
                  fullWidth
                  loading={isUpdatingProfile || isUpdatingPassword}
                  disabled={
                    !isUserProfileValid ||
                    (!currentUser?.passwordSetAt && !getValues('password'))
                  }>
                  Next: Connect to Your MLS
                </Button>
                {updateProfileError && (
                  <Flash variant="error">{updateProfileError?.message}</Flash>
                )}
                {updatePasswordError && (
                  <Flash variant="error">{updatePasswordError?.message}</Flash>
                )}
              </form>
              <ImageCropperModal
                rounded
                image={photoFile || undefined}
                isOpen={showPhotoCropper}
                onClose={() => {
                  setShowPhotoCropper(false)
                  setPhotoKey(generateKey())
                  setPhotoFile(undefined)
                }}
                onConfirm={({ data, preview }) => {
                  setPhotoPreview(preview || '')
                  setShowPhotoCropper(false)
                  setPhotoCropDimensions(JSON.stringify(data))
                }}
              />
            </>
          ) : currentPage == 2 ? (
            <>
              <Helmet>
                <title>MLS information - Broker Onboarding - Cloud CMA</title>
              </Helmet>
              <Suspense
                fallback={
                  <div className="flex items-center justify-center space-x-2 text-sm text-gray-500">
                    <div className="h-4 w-4">
                      <Spinner />
                    </div>
                    <div>Loading MLS Credentials</div>
                  </div>
                }>
                <div className="flex flex-col gap-y-6">
                  <Title>Connect to your MLS</Title>
                  <p className="text-center text-sm text-gray-600">
                    By connecting your MLS, you will be able to create reports
                    from your account.
                  </p>
                </div>
                <form
                  className="mt-9 flex w-full flex-col gap-y-4"
                  onSubmit={handleMlsSubmit((data) =>
                    updateMlsCredential(
                      { input: data },
                      {
                        onSuccess: () => {
                          setIsCompanyProfileTabDisabled(false)
                          setCurrentPage(3)
                        }
                      }
                    )
                  )}>
                  <div className="w-1/2">
                    <FormField label="State or Province">
                      <Select
                        value={mlsState}
                        onChange={(e) => {
                          setMlsState(e.target.value)
                          setMlsValue('code', '')
                        }}>
                        <option value="">Select State or Province</option>
                        {Object.keys(mlsesByStates)
                          .sort()
                          .map((state) => (
                            <option key={state} value={state}>
                              {state}
                            </option>
                          ))}
                      </Select>
                    </FormField>
                  </div>
                  <div className="w-1/2">
                    <FormField
                      label="MLS Provider"
                      error={mlsErrors.code?.message}>
                      <Select
                        {...mlsCredentialRegister('code')}
                        value={getMlsValues('code')}>
                        <option value="" disabled>
                          Select an MLS
                        </option>
                        {mlsesByState
                          .sort((a, b) =>
                            (a.title ?? '').localeCompare(b.title ?? '')
                          )
                          .map((mls) => (
                            <option key={mls.key} value={mls.key ?? ''}>
                              {mls.title}
                            </option>
                          ))}
                      </Select>
                    </FormField>
                  </div>
                  <FormField
                    label="MLS User ID"
                    error={mlsErrors.name?.message}>
                    <Input {...mlsCredentialRegister('name')} />
                  </FormField>
                  <div className="mb-6 w-1/2">
                    <FormField
                      label="MLS Password"
                      error={mlsErrors.password?.message}>
                      <Input
                        type="password"
                        {...mlsCredentialRegister('password')}
                      />
                    </FormField>
                  </div>
                  <Button
                    fullWidth
                    loading={isUpdatingMlsCredential}
                    size="lg"
                    type="submit"
                    disabled={!isMlsCredentialValid}>
                    Next: Setup Company Profile
                  </Button>
                  <Button
                    fullWidth
                    loading={isUpdatingMlsCredential}
                    variant="tertiary"
                    size="lg"
                    onClick={() => {
                      setIsCompanyProfileTabDisabled(false)
                      setCurrentPage(3)
                    }}>
                    Skip
                  </Button>
                  {mlsUpdateError && (
                    <Flash variant="error">{mlsUpdateError?.message}</Flash>
                  )}
                </form>
              </Suspense>
            </>
          ) : currentPage == 3 ? (
            <>
              <Helmet>
                <title>Company Profile - Broker Onboarding - Cloud CMA</title>
              </Helmet>

              <div className="m-auto flex flex-col items-center gap-y-4 text-center">
                <div className="flex flex-col gap-y-2">
                  <Title>Complete company profile</Title>
                  {isBrokerAdmin && (
                    <p className="text-center text-sm text-gray-600">
                      Agents you invite will automatically get this company
                      profile and they will have the opportunity to change it.
                    </p>
                  )}
                </div>
                <div className="mt-6">
                  {logoPreview ? (
                    <img
                      src={logoPreview}
                      className="h-24 object-contain"
                      alt="company-logo"
                    />
                  ) : (
                    <div className="flex h-24 w-24 flex-col items-center justify-center rounded-lg bg-gray-300 p-6 text-white">
                      <CompanyLogoIcon />
                    </div>
                  )}
                </div>
                <input
                  key={logoKey}
                  id="logo"
                  type="file"
                  accept={SUPPORTED_IMAGE_FORMATS.join(',')}
                  onChange={handleLogoChange}
                  className="sr-only"
                />
                <div className="rounded-md outline-2 [outline-color:-webkit-focus-ring-color] peer-focus-visible:outline">
                  <Button
                    as="label"
                    htmlFor="logo"
                    variant="tertiary"
                    size="xs">
                    {logoPreview ? 'Change Logo' : 'Add Logo'}
                  </Button>
                </div>
                {logoError && (
                  <div
                    className="text-xs text-red-500"
                    role="alert"
                    aria-live="polite">
                    {logoError}
                  </div>
                )}
                {logoPreview && (
                  <button
                    className="text-sm text-green-500"
                    onClick={() => {
                      setLogoPreview('')
                      setLogoFile(null)
                      setLogoCropDimensions(undefined)
                    }}>
                    Remove Company Logo
                  </button>
                )}
              </div>

              <form
                className="mt-9 flex w-full flex-col gap-y-4"
                onSubmit={handleCompanyProfileSubmit(async (data) => {
                  if (
                    currentUser?.account?.admin?.email === currentUser?.email
                  ) {
                    await updateBrokerAccount({
                      input: {
                        officeAddress: data.officeAddress,
                        phone: data.officePhone,
                        id: broker?.id,
                        logo: logoFile,
                        logoCropDimensions
                      }
                    })
                  }
                  await updateProfile({
                    input: {
                      name: currentUser?.name || '',
                      email: currentUser?.email || '',
                      logo: logoFile,
                      logoCropDimensions,
                      officeAddress: data.officeAddress,
                      officePhone: data.officePhone
                    }
                  })
                  isAddAgentClicked
                    ? setIsShowingImportModal(true)
                    : doNavigate()
                })}>
                <FormField
                  label="Office Address"
                  error={companyProfileErrors.officeAddress?.message}>
                  <Input
                    {...companyProfileRegister('officeAddress')}
                    placeholder="123 Main St"
                  />
                </FormField>
                <div className="mb-6 w-1/2">
                  <FormField
                    label="Office Phone Number"
                    error={companyProfileErrors.officePhone?.message}>
                    <Input
                      {...companyProfileRegister('officePhone')}
                      placeholder="555-555-5555"
                    />
                  </FormField>
                </div>
                <Button
                  fullWidth
                  loading={isUpdatingProfile || isUpdatingBrokerAccount}
                  size="lg"
                  disabled={!isCompanyProfileValid}
                  onClick={() => setIsAddAgentClicked(false)}>
                  Done
                </Button>
                {isBrokerAdmin && (
                  <Button
                    fullWidth
                    loading={isUpdatingProfile || isUpdatingBrokerAccount}
                    size="lg"
                    variant="tertiary"
                    onClick={() => setIsAddAgentClicked(true)}
                    disabled={!isCompanyProfileValid}>
                    Next: Add Agents
                  </Button>
                )}
              </form>
              {(updateProfileError || updateBrokerAccountError) && (
                <Flash variant="error">
                  {(updateProfileError || updateBrokerAccountError)?.message}
                </Flash>
              )}
              <ImageCropperModal
                image={logoFile || undefined}
                isOpen={showLogoCropper}
                onClose={() => {
                  setShowLogoCropper(false)
                  setLogoKey(generateKey())
                  setLogoFile(undefined)
                }}
                onConfirm={({ data, preview }) => {
                  setLogoPreview(preview || '')
                  setShowLogoCropper(false)
                  setLogoCropDimensions(JSON.stringify(data))
                }}
              />
              <ImportAgents
                brokerId={broker.id}
                brokerName={broker.name}
                isOpen={isShowingImportModal}
                canSendInvite={broker.admin?.status === 'complete'}
                onAfterSuccess={() => doNavigate()}
                onClose={() => {
                  setIsShowingImportModal(false)
                }}
              />
            </>
          ) : null}
        </div>
      </Modal>
    </Wrapper>
  )
}
