import { api } from '@cma/app'
import {
  DestroyFlyerMutation,
  DestroyFlyerMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useDestroyFlyer() {
  const queryClient = useQueryClient()
  return useMutation<
    DestroyFlyerMutation,
    Error,
    DestroyFlyerMutationVariables
  >(
    async ({ id }) => {
      const query = gql`
        mutation DestroyFlyer($id: ID!) {
          destroyFlyer(id: $id)
        }
      `
      const variables = { id }
      const res = await api<DestroyFlyerMutation>({ query, variables })

      if (!res || !res.destroyFlyer) {
        throw new Error('An error occurred. Please try again.')
      }

      return res
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries(['reports'])
      }
    }
  )
}
