// ! THIS FILE WILL BE REMOVED ONCE WE HAVE
// ! THE ENTIRE APP MOVED INTO AN SPA

import {
  NavLink as ReactRouterNavLink,
  NavLinkProps as ReacterRouterNavLinkProps
} from 'react-router-dom'
import { HandoffLink } from './HandoffLink'
import { useHandoff } from './HandoffProvider'

// * When a NavLink is marked as legacy and is active,
// * the active state never appears because you would never
// * have an active state since legacy will always redirect.
export function NavLink({ children, to, ...props }: ReacterRouterNavLinkProps) {
  const { isLegacy } = useHandoff()

  if (
    process.env.VITE_APP_ENABLE_HANDOFF === 'true' &&
    isLegacy(to.toString())
  ) {
    return (
      <HandoffLink {...props} to={to}>
        {children}
      </HandoffLink>
    )
  }

  return (
    <ReactRouterNavLink {...props} to={to}>
      {children}
    </ReactRouterNavLink>
  )
}
