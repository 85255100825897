import { HandoffProvider } from '@cma/common'
import { BrowserRouter } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'
import { AppProvider } from './AppProvider'
import { AppRoutes } from './AppRoutes'

export function App() {
  return (
    <AppProvider>
      <BrowserRouter>
        <HandoffProvider>
          <IntercomProvider appId={process.env.VITE_APP_INTERCOM_APP_ID || ''}>
            <AppRoutes />
          </IntercomProvider>
        </HandoffProvider>
      </BrowserRouter>
    </AppProvider>
  )
}
