import { api } from '@cma/app'
import { Parcel, QueryParcelArgs } from '@cma/generated/graphql'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useTaxData(
  { address }: QueryParcelArgs,
  options?: Omit<
    UseQueryOptions<{ parcel: Parcel }>,
    | 'queryKey'
    | 'queryFn'
    | 'suspense'
    | 'refetchOnWindowFocus'
    | 'keepPreviousData'
  >
) {
  return useQuery<{ parcel: Parcel }>(
    ['taxData', address],
    () => {
      const query = gql`
        query TaxData($address: String!) {
          parcel(address: $address) {
            acres
            address
            baths
            beds
            cooling
            garages
            heating
            sqft
            stories
            taxes
            yearBuilt
            lostSize: lotsize
            parsedAddress {
              city
              number
              postalCode
              redundantStreetType
              state
              street
              streetType
            }
          }
        }
      `

      const variables = { address }
      return api<{ parcel: Parcel }>({ query, variables })
    },
    {
      ...options,
      suspense: false,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false
    }
  )
}
