import { api } from '@cma/app'
import {
  EmailReportMutation,
  EmailReportMutationVariables
} from '@cma/generated/graphql'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useEmailReport() {
  return useMutation<EmailReportMutation, Error, EmailReportMutationVariables>(
    ({ id, input }) => {
      const query = gql`
        mutation EmailReport($id: ID!, $input: EmailReportInput!) {
          emailReport(id: $id, input: $input) {
            errors
          }
        }
      `
      const variables = { id, input }
      return api({ query, variables })
    }
  )
}
