import { Button, NotFound, Wrapper } from '@cma/common'
import {
  ReportHeader,
  ReportPublishButton,
  useReport
} from '@cma/features/report'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

export default function DocumentCustomize() {
  const { id } = useParams()
  const { data: { report } = {} } = useReport({ id: id as string })

  if (!report) {
    return (
      <Wrapper>
        <NotFound />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Helmet>
        <title>Customize Report - Document - Cloud CMA</title>
      </Helmet>
      <ReportHeader
        reportId={report.id}
        title="Organize Listings"
        breadcrumbs={[
          { title: 'Criteria', url: `/documents/${report.id}/edit` },
          { title: 'Listings', url: `/documents/${report.id}` },
          { title: 'Customize', url: `/documents/${report.id}/customize` },
          {
            title: 'Publish',
            url: `/documents/${report.id}/publish`,
            publish: true
          }
        ]}>
        <ReportPublishButton
          reportId={report.id}
          to={`/documents/${report.id}/publish`}>
          Publish Report
        </ReportPublishButton>
      </ReportHeader>
      <main>
        <h1>TODO</h1>
        <div className="text-center">
          <ReportPublishButton
            size="lg"
            reportId={report.id}
            to={`/documents/${report.id}/publish`}>
            Publish Report
          </ReportPublishButton>
        </div>
      </main>
    </Wrapper>
  )
}
