import { Button, Flash, FormField, Input, Link } from '@cma/common'
import { useRequestResetPassword } from '@cma/features/auth'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

const schema = yup.object({
  email: yup.string().email().required('Your email is required')
})

// !This doesn't work locally - passes on staging. Backend is aware.
// !Pull reset password URL from CMA backend log
export default function PasswordRequestReset() {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema)
  })
  const {
    mutate: requestResetPassword,
    isLoading,
    error,
    isSuccess
  } = useRequestResetPassword()

  return (
    <>
      <div className="space-y-4 rounded-lg bg-white px-8 py-6 shadow">
        <h1 className="mb-6 text-center text-2xl font-medium">
          Need your password reset?
        </h1>
        <p className="text-xs">
          Please enter your email and we'll send you instructions on how to
          reset your password
        </p>
        <form
          className="space-y-4"
          onSubmit={handleSubmit((data) => requestResetPassword(data))}>
          <FormField label="Email" error={errors.email?.message}>
            <Input
              placeholder="john@acmerealty.com"
              {...register('email')}
              type="email"
            />
          </FormField>
          <div className="flex justify-center">
            <Button loading={isLoading}>Email Instructions</Button>
          </div>
          {error && <Flash variant="error">{error?.message}</Flash>}
          {isSuccess && (
            <Flash variant="success">
              You will receive an email with instructions about how to reset
              your password in a few minutes.
            </Flash>
          )}
        </form>
      </div>
      <div className="flex">
        <Link
          to="/login"
          className="mx-auto text-xs font-medium text-green-500 hover:no-underline">
          Return to Login
        </Link>
      </div>
    </>
  )
}
