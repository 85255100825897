import { NavLink, NavLinkProps } from '@cma/common'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { Children, cloneElement, PropsWithChildren } from 'react'
import { classNames } from './utils'

export function Breadcrumbs({ children }: PropsWithChildren<unknown>) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        {Children.map(children, (child, index) =>
          cloneElement(child as any, { _isFirst: index === 0 })
        )}
      </ol>
    </nav>
  )
}

Breadcrumbs.Crumb = Breadcrumb

interface BreadcrumbProps extends Omit<NavLinkProps, 'className'> {
  disabled?: boolean
}

function Breadcrumb({
  // @ts-ignore Ignore _isFirst because we're only using it internally
  _isFirst,
  disabled,
  children,
  ...props
}: BreadcrumbProps) {
  return (
    <li>
      <div className="flex items-center">
        {!_isFirst && (
          <ChevronRightIcon
            className="h-5 w-5 flex-shrink-0 text-gray-400"
            aria-hidden="true"
          />
        )}
        <div
          className={classNames('text-sm', {
            'ml-4': !_isFirst,
            'text-green-500 hover:text-green-600': !disabled,
            'cursor-not-allowed text-gray-400': disabled
          })}>
          {!disabled && (
            <NavLink
              {...props}
              className={({ isActive }) =>
                classNames('hover:no-underline', {
                  'text-gray-800 hover:text-gray-900': isActive,
                  'text-green-500': !isActive
                })
              }
              end>
              {children}
            </NavLink>
          )}
          {disabled && <>{children}</>}
        </div>
      </div>
    </li>
  )
}
