export default function InvitationExpired() {
  return (
    <div className="m-auto text-center">
      <h1 className="text-xl font-semibold leading-5 text-gray-900">
        Invitation expired
      </h1>
      <p className="mt-3 leading-6 text-gray-600">
        Contact the system administrator
      </p>
    </div>
  )
}
