export function TransactionIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 8c0 .414.336.75.75.75h11a.75.75 0 0 0 .53-1.28l-4-4a.75.75 0 0 0-1.06 1.06l2.72 2.72H5a.75.75 0 0 0-.75.75ZM5.81 12.75H15a.75.75 0 0 0 0-1.5H4a.75.75 0 0 0-.53 1.28l4 4a.75.75 0 0 0 1.06-1.06l-2.72-2.72Z"
        fill="currentColor"
      />
    </svg>
  )
}
