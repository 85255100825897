import { Change } from '@cma/generated/graphql'
import { classNames, formatDate } from '@cma/common'
import { transformHistory } from './utils'

interface ListingHistoryProps {
  history?: Change[] | null
}

export function ListingHistory({ history }: ListingHistoryProps) {
  return (
    <div className="flex w-full flex-col gap-5 text-xs text-gray-900">
      <>
        {history?.map((history) => {
          return history.changes?.map((change, index) => {
            const HistoryIcon = transformHistory(change)?.icon

            return (
              <div className="flex" key={index}>
                <div className="flex w-1/3 items-center">
                  {history?.timestamp
                    ? formatDate(history.timestamp, 'MMMM dd, yyyy')
                    : '-'}
                </div>
                <div className="flex w-2/3 items-center gap-2">
                  <div
                    className={classNames(
                      'flex h-4 w-4 items-center justify-center',
                      {
                        'text-green-600': change?.field === 'price_list',
                        'text-purple-600': change?.field === 'status',
                        'text-blue-600': change?.field === 'photos'
                      }
                    )}>
                    <HistoryIcon />
                  </div>
                  <div className="flex flex-1">
                    {transformHistory(change)?.description}
                  </div>
                </div>
              </div>
            )
          })
        })}
      </>
    </div>
  )
}
