import { api } from '@cma/app'
import {
  CurrentUserQuery,
  UpdateUserConfigMutation,
  UpdateUserConfigMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

interface UpdateUserConfigContext {
  previousCurrentUser?: CurrentUserQuery
}

export function useUpdateUserConfig() {
  const queryClient = useQueryClient()
  return useMutation<
    UpdateUserConfigMutation,
    Error,
    UpdateUserConfigMutationVariables,
    UpdateUserConfigContext
  >(
    ({ input }) => {
      const query = gql`
        mutation UpdateUserConfig($input: UserConfigInput!) {
          updateUserConfig(input: $input) {
            user {
              id
            }
            errors
          }
        }
      `
      const variables = { input }
      return api<UpdateUserConfigMutation>({ query, variables })
    },
    {
      onMutate: async ({ input }) => {
        await queryClient.cancelQueries(['currentUser'])

        const previousCurrentUser = queryClient.getQueryData<CurrentUserQuery>([
          'currentUser'
        ])

        if (previousCurrentUser?.currentUser) {
          queryClient.setQueryData<CurrentUserQuery>(['currentUser'], {
            ...previousCurrentUser,
            currentUser: {
              ...previousCurrentUser.currentUser,
              ...input
            }
          })
        }

        return { previousCurrentUser }
      },
      onError: (_, __, context) => {
        queryClient.setQueryData(['currentUser'], context?.previousCurrentUser)
      },
      onSettled() {
        queryClient.invalidateQueries(['currentUser'])
      }
    }
  )
}
