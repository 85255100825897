export function HeartIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m10 17-1.088-1.003C5.05 12.43 2.5 10.077 2.5 7.197 2.5 4.842 4.311 3 6.625 3 7.93 3 9.182 3.618 10 4.59 10.818 3.619 12.07 3 13.375 3 15.689 3 17.5 4.843 17.5 7.196c0 2.88-2.55 5.234-6.412 8.8L10 17Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
