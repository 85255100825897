import { classNames } from './utils'

export function Table(
  props: Omit<JSX.IntrinsicElements['table'], 'className'>
) {
  return (
    <table
      {...props}
      className="min-w-full !divide-y divide-gray-200 !border-solid"
    />
  )
}

Table.Head = TableHead
Table.Body = TableBody
Table.Row = TableRow
Table.Header = TableHeader
Table.Data = TableData

function TableHead(props: Omit<JSX.IntrinsicElements['thead'], 'className'>) {
  return <thead {...props} className="bg-white" />
}

function TableBody(props: Omit<JSX.IntrinsicElements['tbody'], 'className'>) {
  return (
    <tbody
      {...props}
      className="!divide-y divide-gray-200 !border-solid bg-white"
    />
  )
}

interface TableRowProps extends Omit<JSX.IntrinsicElements['tr'], 'className'> {
  highlightOnHover?: boolean
}

function TableRow({
  highlightOnHover,
  onClick,
  onClickCapture,
  ...props
}: TableRowProps) {
  return (
    <tr
      {...props}
      onClick={onClick}
      onClickCapture={onClickCapture}
      className={classNames('!border-solid', {
        group: highlightOnHover,
        'cursor-pointer': !!onClick || !!onClickCapture
      })}
    />
  )
}

interface TableHeaderProps
  extends Omit<JSX.IntrinsicElements['th'], 'className' | 'scope'> {
  align?: 'left' | 'center' | 'right'
}

function TableHeader({ align, ...props }: TableHeaderProps) {
  return (
    <th
      {...props}
      scope="col"
      className={classNames(
        'px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500',
        {
          'text-left': align === 'left',
          'text-center': align === 'center',
          'text-right': align === 'right'
        }
      )}
    />
  )
}

interface TableDataProps
  extends Omit<JSX.IntrinsicElements['td'], 'className'> {
  align?: 'left' | 'center' | 'right'
}

function TableData({ align = 'left', ...props }: TableDataProps) {
  return (
    <td
      {...props}
      className={classNames(
        'whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900 group-hover:bg-black-alpha-50',
        {
          'text-left': align === 'left',
          'text-center': align === 'center',
          'text-right': align === 'right'
        }
      )}
    />
  )
}
