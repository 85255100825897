import { useCurrentUser, useUpdateUserConfig } from '@cma/app'
import { SwitchIcon } from '@cma/icons'

export function SwitchToClassicTour() {
  const { data: { currentUser } = {} } = useCurrentUser()
  const { mutate: updateUserConfig } = useUpdateUserConfig()

  if (!currentUser?.liveToursEnabled) return null

  return (
    <button
      className="mx-auto flex items-center justify-center gap-3 text-sm text-gray-900"
      onClick={() =>
        updateUserConfig(
          {
            input: {
              liveToursEnabled: false
            }
          },
          {
            onSuccess() {
              window.open(`${process.env.VITE_APP_CMA_URL}/tours`, '_self')
            }
          }
        )
      }>
      <SwitchIcon className="h-6 w-6" />
      <span>Switch to Classic Buyer Tours</span>
    </button>
  )
}
