import { api } from '@cma/app'
import {
  BillingQuery,
  BillingOrderPartsFragment,
  BillingAccountPartsFragment
} from '@cma/generated/graphql'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

interface BillingOrders {
  recurring: BillingOrderPartsFragment[]
  oneTime: BillingOrderPartsFragment[]
}

export interface Billing {
  user: BillingQuery['currentUser'] | null
  account: BillingAccountPartsFragment | null
  orders: BillingOrders | null
}

export function useBilling() {
  return useQuery<Billing, Error>(['billing'], async () => {
    const query = gql`
      fragment BillingAccountParts on Account {
        isSiteLicensed
        isSmallBroker
        isShared
        admin {
          companyName
        }
        subscription {
          affiliate {
            name
            token
          }
          amount
          cardExpiration
          cardNumber
          discount {
            amount
            code
            id
          }
          id
          isFree
          isPurchasedFromOutsideStore
          nextRenewalAt
          state
          subscriptionPlan {
            description
            id
            label
            name
          }
        }
      }

      fragment BillingOrderParts on Order {
        billingUrl
        chargeDate
        id
        isPaid
        isRecurring
        name
        price
      }

      query Billing {
        currentUser {
          id
          isAccountAdmin
          isCasUser
          hasCardOnFile
          name
          email
          companyName
          lwaId
          eligiblePlans {
            description
            id
            label
            name
          }
          orders {
            ...BillingOrderParts
          }
          account {
            ...BillingAccountParts
          }
        }
      }
    `
    const res = await api<BillingQuery>({ query })
    const orders = res.currentUser?.orders?.reduce<BillingOrders>(
      (orders, order) => {
        if (order.isRecurring) {
          return {
            ...orders,
            recurring: [...(orders.recurring || []), order]
          }
        }
        return {
          ...orders,
          oneTime: [...(orders.oneTime || []), order]
        }
      },
      {
        recurring: [],
        oneTime: []
      }
    )

    return {
      user: res.currentUser || null,
      orders: orders || null,
      account: res.currentUser?.account || null
    }
  })
}
