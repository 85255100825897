export function CmaThumbnailIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 233 303"
      aria-hidden="true"
      {...props}>
      <path
        fill="#576474"
        d="M30.33 205.43a1.5 1.5 0 0 1-1.5-1.5v-22.62h-4a1.5 1.5 0 0 1-1.41-1 1.48 1.48 0 0 1 .45-1.66l29.67-24.78a1.49 1.49 0 0 1 1.93 0L63.8 161v-3.3a1.5 1.5 0 0 1 1.5-1.5h6.54a1.5 1.5 0 0 1 1.5 1.5v11.14l11.76 9.82a1.5 1.5 0 0 1-1 2.65h-4v20.11a1.5 1.5 0 0 1-3 0v-21.61a1.5 1.5 0 0 1 1.5-1.5H80l-9.13-7.62a1.51 1.51 0 0 1-.53-1.15V159.2H66.8v5.1a1.5 1.5 0 0 1-2.48 1.14L54.45 157l-25.51 21.31h1.39a1.5 1.5 0 0 1 1.5 1.5v24.12a1.5 1.5 0 0 1-1.5 1.5z"></path>
      <path fill="#e1e5e9" d="M59.54 202.54V186.3H49.39v16.24"></path>
      <path
        fill="#576474"
        d="M59.54 204a1.5 1.5 0 0 1-1.5-1.5v-14.7h-7.15v14.74a1.5 1.5 0 0 1-3 0V186.3a1.5 1.5 0 0 1 1.5-1.5h10.15a1.5 1.5 0 0 1 1.5 1.5v16.24a1.5 1.5 0 0 1-1.5 1.46zM151.34 197.56a1.5 1.5 0 0 1-1.5-1.5v-22.61h-4a1.5 1.5 0 0 1-1-2.66L174.52 146a1.5 1.5 0 0 1 1.93 0l8.35 7.15v-3.34a1.5 1.5 0 0 1 1.5-1.5h6.54a1.5 1.5 0 0 1 1.5 1.5V161l11.77 9.82a1.51 1.51 0 0 1-1 2.66h-4v20.1a1.5 1.5 0 0 1-3 0V172a1.5 1.5 0 0 1 1.5-1.5H201l-9.13-7.62a1.52 1.52 0 0 1-.54-1.15v-10.39h-3.53v5.1a1.5 1.5 0 0 1-2.47 1.14l-9.87-8.44L150 170.45h1.39a1.46 1.46 0 0 1 1.06.44 1.5 1.5 0 0 1 .44 1.06v24.11a1.5 1.5 0 0 1-1.55 1.5z"></path>
      <path fill="#e1e5e9" d="M180.55 194.68v-16.24H170.4v16.24"></path>
      <path
        fill="#576474"
        d="M180.55 196.18a1.5 1.5 0 0 1-1.5-1.5v-14.74h-7.15v14.74a1.5 1.5 0 0 1-3 0v-16.24a1.5 1.5 0 0 1 1.5-1.5h10.15a1.5 1.5 0 0 1 1.5 1.5v16.24a1.5 1.5 0 0 1-1.5 1.5z"></path>
      <path
        fill="#c4c8d0"
        d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"></path>
      <path
        fill="#576474"
        d="M93.42 65.74l5.46 1.2a12.52 12.52 0 0 1-3.47 7.49 10.1 10.1 0 0 1-7.34 2.73 11 11 0 0 1-8.56-3.64q-3.29-3.66-3.29-11 0-6.48 3.49-10.14A11.1 11.1 0 0 1 88 48.69a10.38 10.38 0 0 1 7.16 2.63 10.62 10.62 0 0 1 3.44 7.08l-5.22.82a8.12 8.12 0 0 0-1.64-4.07 4.44 4.44 0 0 0-3.67-1.58 5.66 5.66 0 0 0-4.56 2.34 10.3 10.3 0 0 0-1.87 6.59q0 5 1.81 7.39a5.56 5.56 0 0 0 4.62 2.4c3.07 0 4.85-2.19 5.35-6.55zM102.58 76.58v-27.3H108L115.92 67h.08l7.91-17.7h5.46v27.3h-5.14V59.81h-.08l-7.76 16.77h-.86l-7.76-16.77h-.08v16.77zM151.45 76.58l-1.56-4.49h-10.42l-1.56 4.49h-5.22l9.87-27.3H147l9.83 27.3zm-6.79-19.5L141 67.72h7.45l-3.67-10.64zM28.53 214.45a1.5 1.5 0 0 1-.1-3l174.67-10.83a1.5 1.5 0 0 1 .19 3L28.62 214.45z"></path>
      <path
        fill="#e1e5e9"
        d="M128.47 235.84h-25.22l12.61-25.96 12.61 25.96z"></path>
      <path
        fill="#576474"
        d="M128.47 237.34h-25.22a1.51 1.51 0 0 1-1.35-2.16l12.61-26a1.5 1.5 0 0 1 2.7 0l12.61 26a1.51 1.51 0 0 1-1.35 2.16zm-22.82-3h20.42l-10.21-21z"></path>
    </svg>
  )
}
