export function InfoIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm0-11a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm.004 1a.994.994 0 0 0-.994.992L9 14a.999.999 0 1 0 1.998.002V8.994A.994.994 0 0 0 10.004 8Z"
        fill="currentColor"
      />
    </svg>
  )
}
