export function MoneyIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 20"
      {...props}>
      <path
        fillRule="evenodd"
        d="M10 16.5a6.5 6.5 0 100-13 6.5 6.5 0 000 13zm0 1.5a8 8 0 100-16 8 8 0 000 16z"
        clipRule="evenodd"
      />
      <path d="M9.726 14.763h.618v-.86c1.525-.102 2.616-.918 2.616-2.266 0-1.177-.769-1.767-2.235-2.1l-.381-.081V7.56c.601.086 1.004.419 1.08 1h1.433c-.053-1.269-1.02-2.106-2.513-2.214v-.875h-.618v.87c-1.477.091-2.535.924-2.535 2.207 0 1.16.773 1.789 2.175 2.106l.36.086v1.96c-.73-.08-1.117-.462-1.203-1.01h-1.44c.038 1.343 1.134 2.133 2.643 2.219v.854zM8.695 8.409c0-.43.354-.78 1.031-.86v1.762c-.725-.193-1.031-.473-1.031-.902zm2.766 3.394c0 .489-.37.822-1.117.897v-1.82c.832.209 1.117.45 1.117.923z" />
    </svg>
  )
}
