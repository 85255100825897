import { api } from '@cma/app'
import {
  UpdateCustomPageMutation,
  UpdateCustomPageMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useUpdateCustomPage() {
  const queryClient = useQueryClient()
  return useMutation<
    UpdateCustomPageMutation,
    Error,
    UpdateCustomPageMutationVariables
  >(
    ({ id, input }) => {
      const query = gql`
        mutation UpdateCustomPage($id: ID!, $input: CustomPageInput!) {
          updateCustomPage(id: $id, input: $input) {
            customPage {
              key
            }
            errors
          }
        }
      `
      const variables = { id, input }
      return api<UpdateCustomPageMutation>({ query, variables })
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries(['customPages'])
      }
    }
  )
}
