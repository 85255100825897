import { api } from '@cma/app'
import {
  AddIntegrationMutation,
  AddIntegrationMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useAddIntegration() {
  const queryClient = useQueryClient()
  return useMutation<
    AddIntegrationMutation,
    Error,
    AddIntegrationMutationVariables
  >(
    ({ key, name, password }) => {
      const query = gql`
        mutation AddIntegration(
          $key: IntegrationTypeEnum!
          $name: String!
          $password: String
        ) {
          updateIntegrationCredential(
            key: $key
            name: $name
            password: $password
          ) {
            apiCredential {
              key
              name
            }
            errors
          }
        }
      `
      const variables = { key, name, password }
      return api<AddIntegrationMutation>({ query, variables })
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries(['integrations'])
      }
    }
  )
}
