import { api } from '@cma/app'
import { BoostQuery } from '@cma/generated/graphql'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useBoost() {
  return useQuery<BoostQuery, Error>(['boost'], () => {
    const query = gql`
      query Boost {
        boostRedirectLink {
          url
        }
      }
    `
    return api<BoostQuery>({ query })
  })
}
