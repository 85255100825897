// ! THIS FILE WILL BE REMOVED ONCE WE HAVE
// ! THE ENTIRE APP MOVED INTO AN SPA

import { NavLinkProps } from 'react-router-dom'
import { useHandoff } from './HandoffProvider'

export function HandoffLink({
  children,
  to: _to,
  className: _className,
  style: _style,
  ...props
}: // Making this NavLinkProps as it's a derivative of LinkProps
NavLinkProps) {
  const { buildHandoffUrl } = useHandoff()

  const path = _to.toString()
  const className =
    typeof _className === 'function'
      ? _className({ isActive: false, isPending: false })
      : _className
  const style =
    typeof _style === 'function'
      ? _style({ isActive: false, isPending: false })
      : _style

  // Removing end as it's not a valid prop on link
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { end, ...validProps } = props

  return (
    <a
      {...validProps}
      className={className}
      style={style}
      href={buildHandoffUrl(path)}
      rel="noreferrer">
      <>{children}</>
    </a>
  )
}
