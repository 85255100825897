import { api } from '@cma/app'
import { MlsesQuery, MlsesQueryVariables } from '@cma/generated/graphql'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useMlses(
  variables?: MlsesQueryVariables,
  options?: UseQueryOptions<MlsesQuery, Error>
) {
  return useQuery<MlsesQuery, Error>(
    ['mlses', variables],
    () => {
      const query = gql`
        fragment MlsParts on Mls {
          title
          key
          states
          instructions
        }

        query Mlses($restrict: Boolean) {
          mlses(restrict: $restrict) {
            ...MlsParts
          }
        }
      `
      return api<MlsesQuery>({ query, variables })
    },
    options
  )
}
