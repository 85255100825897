import { useQuery } from '@tanstack/react-query'

interface News {
  id: string
  title: string
  url: string
}

// TODO: Finish when backend is in place.
export function useNews() {
  return useQuery<News[]>(['news'], () => [])
}
