export function AgentIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 14A4.75 4.75 0 0 1 8.5 9.25h4A4.75 4.75 0 0 1 17.25 14v3a.75.75 0 0 1-.75.75h-12a.75.75 0 0 1-.75-.75v-3Zm4.75-3.25A3.25 3.25 0 0 0 5.25 14v2.25h10.5V14a3.25 3.25 0 0 0-3.25-3.25h-4Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 5a3.75 3.75 0 1 1 7.5 0v1a3.75 3.75 0 1 1-7.5 0V5Zm3.75-2.25A2.25 2.25 0 0 0 8.25 5v1a2.25 2.25 0 0 0 4.5 0V5a2.25 2.25 0 0 0-2.25-2.25Z"
        fill="currentColor"
      />
      <path
        d="M11.5 13.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
