export function ChevronUpIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.53 12.53a.75.75 0 0 1-1.06 0L10 9.06l-3.47 3.47a.75.75 0 0 1-1.06-1.06l4-4a.75.75 0 0 1 1.06 0l4 4a.75.75 0 0 1 0 1.06Z"
        fill="currentColor"
      />
    </svg>
  )
}
