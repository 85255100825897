import { classNames } from '@cma/common'
import { ListingPartsFragment } from '@cma/generated/graphql'
import { HouseIcon } from '@cma/icons'

interface ReportListingsSummaryProps {
  listings: ListingPartsFragment[]
}

export function ReportListingsSummary({
  listings
}: ReportListingsSummaryProps) {
  const mappedListings = listings.reduce<{
    active: ListingPartsFragment[]
    backup: ListingPartsFragment[]
    cancelled: ListingPartsFragment[]
    expired: ListingPartsFragment[]
    pending: ListingPartsFragment[]
    sold: ListingPartsFragment[]
  }>(
    (byStatus, listing) => {
      if (!listing.hide && listing?.statusAsLetter === 'A') {
        return { ...byStatus, active: [...byStatus.active, listing] }
      }

      if (!listing.hide && listing?.statusAsLetter === 'B') {
        return { ...byStatus, backup: [...byStatus.backup, listing] }
      }

      if (
        !listing.hide &&
        (listing?.statusAsLetter === 'C' ||
          listing?.statusAsLetter === 'H' ||
          listing?.statusAsLetter === 'W')
      ) {
        return { ...byStatus, cancelled: [...byStatus.cancelled, listing] }
      }

      if (!listing.hide && listing?.statusAsLetter === 'X') {
        return { ...byStatus, expired: [...byStatus.expired, listing] }
      }

      if (!listing.hide && listing?.statusAsLetter === 'P') {
        return { ...byStatus, pending: [...byStatus.pending, listing] }
      }

      if (!listing.hide && listing?.statusAsLetter === 'S') {
        return { ...byStatus, sold: [...byStatus.sold, listing] }
      }

      return byStatus
    },
    {
      active: [],
      sold: [],
      expired: [],
      backup: [],
      pending: [],
      cancelled: []
    }
  )

  return (
    <>
      <h3 className="mb-4 text-lg text-gray-700">Summary of Listings</h3>
      <ul className="mx-auto mb-6 inline-flex flex-col space-y-4 text-gray-500 sm:block">
        <ListingsStatsCount label="Active" listings={mappedListings.active} />
        <ListingsStatsCount label="Backup" listings={mappedListings.backup} />
        <ListingsStatsCount
          label="Cancelled"
          listings={mappedListings.cancelled}
        />
        <ListingsStatsCount label="Expired" listings={mappedListings.expired} />
        <ListingsStatsCount label="Pending" listings={mappedListings.pending} />
        <ListingsStatsCount label="Sold" listings={mappedListings.sold} />
        {!mappedListings.active.length &&
          !mappedListings.backup.length &&
          !mappedListings.cancelled.length &&
          !mappedListings.expired.length &&
          !mappedListings.pending.length &&
          !mappedListings.sold.length && (
            <li className="text-sm">No Listings Selected</li>
          )}
      </ul>
    </>
  )
}

interface ListingsStatsCountProps {
  label: string
  listings: ListingPartsFragment[]
}

function ListingsStatsCount({ label, listings }: ListingsStatsCountProps) {
  if (!listings.length) return null

  return (
    <li className="flex items-start">
      <span
        style={{
          backgroundColor: `#${listings[0].statusAsColor}`,
          color: `#${listings[0].statusAsColor}`
        }}
        className={classNames(
          'relative mr-2.5 flex items-center rounded px-2.5 py-1.5',
          'after:absolute after:left-1/2 after:bottom-0 after:block after:h-2 after:w-2 after:-translate-x-1/2 after:translate-y-1/2 after:rotate-45 after:rounded-br-sm after:bg-current'
        )}>
        <HouseIcon className="h-[0.6875rem] w-[0.75rem] text-white" />
      </span>
      <span className="text-sm">
        {label}: {listings.length}
      </span>
    </li>
  )
}
