import {
  Alert,
  copyTextToClipboard,
  formatNumber,
  Link,
  Menu,
  Spinner
} from '@cma/common'
import { EmailReport, ReportStats, useReportStatus } from '@cma/features/report'
import { FlyerThumbnailIcon } from '@cma/icons'
import { EyeIcon } from '@heroicons/react/outline'
import { DotsHorizontalIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { useDestroyFlyer } from './useDestroyFlyer'

// TODO: Can this be a reusable report component?

interface FlyerCardProps {
  report: {
    __typename: string
    id: string
    status?: string | null
    thumbnail?: string | null
    addressParts?: string[] | null
    title?: string | null
    notes?: string | null
    pdfPermalink?: string | null
    viewCount?: number | null
    lastViewedAt?: string | null
    updatedAt?: string | null
  }
}

export function FlyerCard({ report }: FlyerCardProps) {
  const [showEmail, setShowEmail] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showStats, setShowStats] = useState(false)
  const isPublishing = report.status === 'active-report'
  const isPendingPublish = report.status === 'pending-report'
  const isPublished = report.status === 'published-report'
  const [street, cityStateZip] = report.addressParts ?? []
  const { mutate: destroyReport, error } = useDestroyFlyer()
  const [isDestroying, setIsDestroying] = useState(false)

  useReportStatus({ id: report.id, status: report.status || 'pending-report' })

  const handleDestroy = () => {
    setIsDestroying(true)
    setShowDelete(false)
    destroyReport({ id: report.id })
  }

  return (
    <div
      className="relative flex h-[9.875rem] flex-col rounded bg-white shadow"
      data-testid="flyer-card">
      {isDestroying && (
        <div
          role="alert"
          aria-live="polite"
          aria-label="Deleting Report"
          className="absolute inset-0 z-10 flex items-center justify-center rounded bg-white bg-opacity-80">
          {!!error && (
            <div className="space-y-1 text-center text-red-500">
              <div>{error.message}</div>
              <button className="text-sm text-gray-500" onClick={handleDestroy}>
                Retry
              </button>
            </div>
          )}
          {!error && (
            <div className="flex items-center justify-center space-x-2 text-gray-500">
              <div className="h-5 w-5">
                <Spinner />
              </div>
              <div>Deleting Report</div>
            </div>
          )}
        </div>
      )}
      <div className="relative flex flex-grow p-3">
        {report.thumbnail ? (
          <div
            style={{ backgroundImage: `url('${report.thumbnail}')` }}
            className="h-[5.5rem] w-[4.25rem] flex-shrink-0 rounded-sm bg-gray-200"
          />
        ) : (
          <FlyerThumbnailIcon className="h-[5.5rem] w-[4.25rem] flex-shrink-0" />
        )}
        <div className="min-w-0 flex-grow px-4">
          <strong
            className="my-1 block truncate text-sm font-medium"
            title={report.title || ''}>
            {report.title}
          </strong>
          <div className="truncate text-xs text-gray-700" title={street}>
            {street}
          </div>
          {cityStateZip && (
            <div
              className="mt-0.5 truncate text-xs font-normal text-gray-700"
              title={cityStateZip}>
              {cityStateZip}
            </div>
          )}
          {report.notes && (
            <i className="mt-2 block truncate text-xs text-gray-400">
              {report.notes}
            </i>
          )}
        </div>
        <div className="ml-auto flex-shrink-0">
          <Menu
            button={
              <DotsHorizontalIcon
                className="h-5 w-5 text-gray-600"
                aria-hidden="true"
              />
            }>
            <Menu.Item
              disabled={!isPublished}
              title={
                !isPublished
                  ? 'Your flyer must be published before you can copy the PDF link'
                  : undefined
              }
              onClick={() => {
                report.pdfPermalink && copyTextToClipboard(report.pdfPermalink)
              }}>
              Copy PDF Link
            </Menu.Item>
            <Menu.Item
              disabled={!isPublished}
              title={
                !isPublished
                  ? 'Your report must be published before you can email it'
                  : undefined
              }
              onClick={() => setShowEmail(true)}>
              Email Report
            </Menu.Item>
            <Menu.Item variant="danger" onClick={() => setShowDelete(true)}>
              Delete Report
            </Menu.Item>
          </Menu>
          <EmailReport
            report={report}
            isOpen={showEmail}
            onClose={() => setShowEmail(false)}
          />
          <Alert variant="danger" isOpen={showDelete}>
            <Alert.Title>Delete Report</Alert.Title>
            <Alert.Content>
              Are you sure you want to delete this report (
              <strong className="font-medium text-gray-700">
                {report.title}
              </strong>
              )? This action cannot be undone.
            </Alert.Content>
            <Alert.Cancel onClick={() => setShowDelete(false)}>
              Cancel
            </Alert.Cancel>
            <Alert.Confirm loading={isDestroying} onClick={handleDestroy}>
              Delete Forever
            </Alert.Confirm>
          </Alert>
        </div>
        {showStats && (
          <ReportStats
            viewCount={report.viewCount ?? 0}
            lastViewedAt={report.lastViewedAt ?? ''}
            updatedAt={report.updatedAt ?? ''}
          />
        )}
      </div>
      <div className="flex flex-shrink-0 justify-between border-t border-solid border-gray-200 px-4 py-3 text-xs font-medium text-green-500">
        <div className="flex items-center space-x-3">
          {isPublishing && (
            <button
              disabled
              className="flex cursor-not-allowed items-center space-x-1 font-medium text-gray-400">
              <span className="h-4 w-4">
                <Spinner />
              </span>
              <span>Publishing...</span>
            </button>
          )}
          {isPublished && report.pdfPermalink && (
            <a href={report.pdfPermalink} target="_blank" rel="noreferrer">
              View PDF
            </a>
          )}
          {isPendingPublish && (
            <button
              disabled
              className="cursor-not-allowed font-medium opacity-50">
              View PDF
            </button>
          )}
        </div>
        <div className="flex items-center space-x-3">
          <div className="flex items-center space-x-1 text-gray-500">
            <EyeIcon
              className="h-5 w-5"
              onMouseEnter={() => setShowStats(true)}
              onMouseLeave={() => setShowStats(false)}
            />
            <div>{formatNumber(report.viewCount ?? 0)}</div>
          </div>
          <Link
            to={`/flyers/${report.id}/edit`}
            className="text-green-500 hover:no-underline">
            Edit
          </Link>
        </div>
      </div>
    </div>
  )
}

FlyerCard.Skeleton = FlyerCardSkeleton

function FlyerCardSkeleton() {
  return (
    <div className="flex h-[9.875rem] animate-pulse flex-col rounded bg-white shadow">
      <div className="relative flex flex-grow p-3">
        <div className="h-[5.5rem] w-[4.25rem] flex-shrink-0 rounded-sm bg-gray-100" />
        <div className="min-w-0 flex-grow px-4">
          <div className="space-y-1">
            <div className="h-5 w-8/12 rounded bg-gray-100" />
            <div className="h-3 w-5/12 rounded bg-gray-100" />
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-between border-t border-solid border-gray-200 px-4 py-3 text-xs font-medium text-green-500">
        <div className="h-3 w-3/12 rounded bg-gray-100" />
      </div>
    </div>
  )
}
