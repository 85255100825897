import { Pagination, usePaginate, Wrapper } from '@cma/common'
import { PropertyCard } from '@cma/features/property'
import {
  CreateReport,
  ReportGettingStarted,
  ReportsHeader,
  useReports
} from '@cma/features/report'
import { ReportTypeEnum } from '@cma/generated/graphql'
import { Fragment, Suspense } from 'react'
import { Helmet } from 'react-helmet-async'

export default function Properties() {
  const paginate = usePaginate({ limit: 27 })

  return (
    <Wrapper>
      <Helmet>
        <title>Properties - Cloud CMA</title>
      </Helmet>
      <ReportsHeader
        title="Property Reports"
        placeholder="Search Property Reports"
        onSubmit={(data) => {
          paginate.setFirst(paginate.limit)
          paginate.setLast(undefined)
          paginate.setAfter(undefined)
          paginate.setBefore(undefined)
          paginate.setSearch(data.search)
        }}
      />
      <Suspense
        fallback={
          <>
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              <CreateReport to="/properties/new">
                Create Property Report
              </CreateReport>
              {new Array(5).fill('').map((_, index) => (
                <PropertyCard.Skeleton key={index} />
              ))}
            </div>
            <Pagination disablePrev={true} disableNext={true} />
          </>
        }>
        <PropertyCards paginate={paginate} />
      </Suspense>
    </Wrapper>
  )
}

interface PropertyCardsProps {
  paginate: ReturnType<typeof usePaginate>
}

function PropertyCards({ paginate }: PropertyCardsProps) {
  const { data: { reports } = {} } = useReports({
    types: [ReportTypeEnum.Property],
    first: paginate.first,
    last: paginate.last,
    after: paginate.after,
    before: paginate.before,
    search: paginate.search
  })

  return (
    <>
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        <CreateReport to="/properties/new">Create Property Report</CreateReport>
        {reports?.nodes?.map((report) => (
          <Fragment key={report?.id}>
            {report && <PropertyCard report={report} />}
          </Fragment>
        ))}
        {(reports?.total ?? 0) <= 4 && (
          <ReportGettingStarted videoId="wkqzrf1qc4">
            Getting Started with Property Reports
          </ReportGettingStarted>
        )}
      </div>
      <Pagination
        disablePrev={!reports?.pageInfo.hasPreviousPage}
        disableNext={!reports?.pageInfo.hasNextPage}
        onPrevClick={() => {
          paginate.setBefore(reports?.pageInfo.startCursor ?? undefined)
          paginate.setAfter(undefined)
          paginate.setFirst(undefined)
          paginate.setLast(paginate.limit)
        }}
        onNextClick={() => {
          paginate.setBefore(undefined)
          paginate.setAfter(reports?.pageInfo?.endCursor ?? undefined)
          paginate.setFirst(paginate.limit)
          paginate.setLast(undefined)
        }}
      />
    </>
  )
}
