import { api } from '@cma/app'
import {
  UpdatePlanMutation,
  UpdatePlanMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useUpdatePlan() {
  const queryClient = useQueryClient()
  return useMutation<UpdatePlanMutation, Error, UpdatePlanMutationVariables>(
    ({ planId, discountCode, nonce }) => {
      const query = gql`
        mutation UpdatePlan(
          $planId: ID
          $discountCode: String
          $nonce: String
        ) {
          updateSubscriptionPlan(
            planId: $planId
            discountCode: $discountCode
            paymentNonce: $nonce
          ) {
            errors
            result
          }
        }
      `
      const variables = { planId, discountCode, nonce }
      return api<UpdatePlanMutation>({ query, variables })
    },
    {
      async onSuccess() {
        await Promise.all([
          queryClient.invalidateQueries(['currentUser']),
          queryClient.invalidateQueries(['billing'])
        ])
      }
    }
  )
}
