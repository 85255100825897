import { api } from '@cma/app'
import {
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables
} from '@cma/generated/graphql'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient
} from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useUpdatePassword(
  options?: Omit<
    UseMutationOptions<
      UpdatePasswordMutation,
      Error,
      UpdatePasswordMutationVariables
    >,
    'mutationFn'
  >
) {
  const queryClient = useQueryClient()
  return useMutation<
    UpdatePasswordMutation,
    Error,
    UpdatePasswordMutationVariables
  >(
    ({ password, passwordConfirmation }) => {
      const query = gql`
        mutation UpdatePassword(
          $password: String!
          $passwordConfirmation: String!
        ) {
          updateUserPassword(
            password: $password
            passwordConfirmation: $passwordConfirmation
          ) {
            user {
              id
            }
            errors
          }
        }
      `
      const variables = { password, passwordConfirmation }
      return api<UpdatePasswordMutation>({ query, variables })
    },
    {
      ...options,
      async onSuccess(...args) {
        await queryClient.invalidateQueries(['currentUser'])
        await options?.onSuccess?.(...args)
      }
    }
  )
}
