import { getAccessTokens } from '@cma/features/auth'
import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'

export function useBuildHandoffUrl() {
  const location = useLocation()

  return useCallback(
    (path: string) => {
      const accessTokens = getAccessTokens()
      const queryString = new URLSearchParams()
      // Backend requires that we have the params nested within path_params
      const pathParams = new URLSearchParams(location.search).toString()
      if (pathParams) {
        queryString.append('path_params', pathParams)
      }
      queryString.append('path', path)
      queryString.append(
        'jwt',
        accessTokens.impersonateToken ||
          accessTokens?.accessToken ||
          'NO_ACCESS_TOKEN'
      )

      return `${process.env.VITE_APP_CMA_URL}/handoff?${queryString.toString()}`
    },
    [location]
  )
}
