import { api } from '@cma/app'
import {
  PartnershipUserQuery,
  PartnershipUserQueryVariables
} from '@cma/generated/graphql'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useSsoUser(
  variables: PartnershipUserQueryVariables,
  options?: Omit<
    UseQueryOptions<PartnershipUserQuery, Error>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: () => undefined
  }
) {
  return useQuery<PartnershipUserQuery, Error>(
    ['ssoUser', variables],
    () => {
      const query = gql`
        query PartnershipUser($token: String!) {
          partnershipUser(token: $token) {
            companyName
            email
            firstName
            id
            lastName
            mls {
              title
            }
            mlsOptions {
              mlsId
              mlsKey
              mlsName
              ouid
            }
            mlsId
            ouid
            partnershipId
            phoneNumber
          }
        }
      `
      return api<PartnershipUserQuery>({ query, variables })
    },
    options
  )
}
