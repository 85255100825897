import { classNames, formatDate, toast } from '@cma/common'
import SsoLogin from '@cma/features/sso/SsoLogin'
import { PotentialUsersPartsFragment } from '@cma/generated/graphql'
import { useOnboardingForm } from '@cma/pages/sso/SsoOnboarding'
import { HelixIcon } from '@helix/helix-icon'
import { spinner as SpinnerIcon } from '@helix/helix-icon/outlined'
import { diamond_exclamation as ExclamationIcon } from '@helix/helix-icon/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import pluralize from 'pluralize'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import * as yup from 'yup'
import { useMagicLink } from './useMagicLink'
import { usePotentialUser } from './usePotentialUser'

const magicLinkSchema = yup.object({
  email: yup.string().email('Invalid email').required('Your email is required')
})

export function AccountLogIn() {
  const [searchParams, setSearchParams] = useSearchParams()
  const token = searchParams.get('token') || ''
  const brokerageName = searchParams.get('brokerage_name') || ''

  const { data: { potentialUsers } = {} } = usePotentialUser({
    token
  })
  const { mutate: sendMagicLink, isLoading: sendingMagicLink } = useMagicLink()

  const { setValue } = useOnboardingForm()

  const {
    formState: { errors },
    ...magicLinkForm
  } = useForm<yup.InferType<typeof magicLinkSchema>>({
    resolver: yupResolver(magicLinkSchema)
  })

  const [showLoginForm, setShowLoginForm] = useState(false)

  const [selectedAccount, setSelectedAccount] =
    useState<PotentialUsersPartsFragment | null>(null)

  const selectedAccountBelongsToBroker = selectedAccount?.belongsToBroker
    ? selectedAccount.account?.name || brokerageName
    : ''

  const switchToLogin = (potentialUsers?.length || 0) <= 1 || showLoginForm

  return (
    <div>
      {switchToLogin ? (
        <SsoLogin />
      ) : (
        <div className="helix-card max-w-[46.25rem] space-y-10 p-5 sm:p-8">
          <div className="space-y-5">
            <div className="space-y-1">
              <div className="helix-heading text-base">
                We found a few Cloud CMA accounts belonging to you
              </div>
              <div className="helix-body helix-text-gray-500 text-sm">
                Please select the account you'd like to connect
              </div>
            </div>
            <div className="helix-border w-full overflow-auto rounded-md">
              <table className="helix-table whitespace-nowrap">
                <thead>
                  <tr>
                    <th>Mls Connection</th>
                    <th>Email</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  {potentialUsers?.map((account) => {
                    const totalReports = Object.entries(
                      account?.stats || {}
                    ).reduce((total, [, value]) => {
                      return total + (value || 0)
                    }, 0)
                    return (
                      <tr
                        key={account.id}
                        className={classNames('cursor-pointer', {
                          'helix-bg-red-50': account.belongsToBroker
                        })}
                        onClick={() => {
                          setSelectedAccount(account)
                        }}>
                        <td>
                          <div className="flex space-x-5">
                            <input
                              type="radio"
                              readOnly
                              value={account.id}
                              checked={selectedAccount?.id === account.id}
                            />
                            <div className="flex flex-col">
                              <div className="helix-body--strong text-sm capitalize">
                                {account.mlsCredential?.mlsName ||
                                  'Unknown MLS'}
                              </div>
                              <div className="helix-body helix-text-gray-600">
                                {account.mlsCredential?.code}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="helix-body w-56 truncate">
                            {account.email}
                          </div>
                        </td>
                        <td>
                          <div className="flex flex-col">
                            <div className="helix-body">
                              {totalReports} {pluralize('Report', totalReports)}
                            </div>
                            {account?.lastSignInAt && (
                              <div className="helix-small helix-text-gray-500">
                                Last seen{' '}
                                {formatDate(account?.lastSignInAt, 'M/d/yy')}
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            {selectedAccountBelongsToBroker && (
              <div className="helix-bg-red-50 helix-text-red-900 helix-body my-1 flex flex-col p-3.5">
                <div className="flex">
                  <HelixIcon
                    icon={ExclamationIcon}
                    className="helix-svg-fill--red-600 mr-2.5 h-5 w-5 shrink-0"
                  />
                  <div className="helix-subheading text-base">
                    This account is owned by {selectedAccountBelongsToBroker}
                  </div>
                </div>
                <div className="helix-body mt-2 text-sm sm:ml-9">
                  Your account under {selectedAccount?.email} cannot be
                  connected to Realtor.com®. You can create a new account with a
                  different email for free, courtesy of Realtor.com®.
                </div>
              </div>
            )}
            <button
              disabled={!selectedAccount}
              className="helix-btn helix-btn--primary"
              onClick={() => {
                if (selectedAccountBelongsToBroker) {
                  setValue('onBoardingType', 'signup')
                  const [firstName, lastName] =
                    selectedAccount?.name.split(' ') || []
                  setSearchParams(
                    {
                      token: token,
                      first_name: firstName || '',
                      last_name: lastName || '',
                      email: selectedAccount?.email || '',
                      code: selectedAccount?.mlsCredential?.code || '',
                      company_name: selectedAccount?.companyName || ''
                    },
                    { replace: true }
                  )
                } else {
                  setShowLoginForm(true)
                  setSearchParams(
                    { token: token, email: selectedAccount?.email || '' },
                    { replace: true }
                  )
                }
              }}>
              {selectedAccountBelongsToBroker
                ? 'Create New Account'
                : 'Connect Account'}
            </button>
          </div>
          <div>
            <div className="helix-heading">Have another account?</div>
            <div className="helix-body helix-text-gray-500 mb-3 mt-1">
              If you don't see that account you were looking for, enter the
              email associated with the account below and we'll send you a magic
              link to complete the connection.
            </div>
            <form
              onSubmit={magicLinkForm.handleSubmit(({ email }) => {
                sendMagicLink(
                  { email, token },
                  {
                    onSuccess() {
                      magicLinkForm.reset()
                      toast(
                        <div>Magic Link sent! Please check your email.</div>,
                        {
                          variant: 'success'
                        }
                      )
                    },
                    onError(error) {
                      toast(<div>{error.message}</div>, {
                        variant: 'error'
                      })
                    }
                  }
                )
              })}>
              <div className="flex flex-col space-x-0 space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
                <input
                  type="email"
                  placeholder="Your Email"
                  className="helix-text-input"
                  {...magicLinkForm.register('email')}
                />
                <button
                  disabled={!magicLinkForm.watch('email') || sendingMagicLink}
                  className="helix-btn helix-btn--secondary">
                  {!sendingMagicLink ? (
                    'Send Magic Link'
                  ) : (
                    <span className="mx-auto block h-5 w-5 animate-spin">
                      <HelixIcon icon={SpinnerIcon} />
                    </span>
                  )}
                </button>
              </div>
              {errors?.email?.message && (
                <div
                  role="alert"
                  aria-live="polite"
                  className="helix-small helix-text--error mt-1">
                  {errors?.email?.message}
                </div>
              )}
            </form>
          </div>
          <div>
            <div className="helix-heading">Need some help?</div>
            <div className="helix-body helix-text-gray-500 mt-1">
              If you are need of assistance, please{' '}
              <a href="mailto:cas-support@lwolf.com">Contact Support</a>.
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
