import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

export interface WistiaProps {
  videoId: string
  autoplay?: boolean
  muted?: boolean
  popover?: boolean
  popoverAnimateThumbnail?: boolean
  playerColor?: string
  buttonBackgroundColor?: string
  controlsVisibleOnLoad?: boolean
}

export function Wistia({
  videoId,
  autoplay = true,
  muted = false,
  popover = false,
  popoverAnimateThumbnail = false,
  playerColor = '#20b575',
  buttonBackgroundColor = '#20b575',
  controlsVisibleOnLoad = true
}: WistiaProps) {
  useEffect(() => {
    // @ts-ignore Ignoring window variable
    window._wq = window._wq ?? []
    // @ts-ignore Ignoring window variable
    window._wq = [
      // @ts-ignore Ignoring window variable
      ...window._wq,
      {
        id: videoId,
        onReady: () => {
          // Empty for now
        }
      }
    ]
    return () => {
      // @ts-ignore Ignoring window variable
      window._wq = window._wq.filter(
        (video: { id: string }) => video.id !== videoId
      )
    }
  }, [videoId])

  const classes = [
    `wistia_embed wistia_async_${videoId}`,
    autoplay ? `autoPlay=${autoplay}` : '',
    `muted=${muted}`,
    `popover=${popover}`,
    `popoverAnimateThumbnail=${popoverAnimateThumbnail}`,
    `playerColor=${playerColor}`,
    `buttonBackground=${buttonBackgroundColor}`,
    `controlsVisibleOnLoad=${controlsVisibleOnLoad}`
  ].filter(Boolean)

  return (
    <div className="aspect-video">
      <Helmet>
        <script src="//fast.wistia.com/assets/external/E-v1.js"></script>
      </Helmet>
      <div className={`${classes.join(' ')} h-full w-full`} />
    </div>
  )
}
