import { api } from '@cma/app'
import {
  PublishReportMutation,
  PublishReportMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function usePublishReport() {
  const queryClient = useQueryClient()
  return useMutation<
    PublishReportMutation,
    Error,
    PublishReportMutationVariables
  >(
    ({ id, template }) => {
      const query = gql`
        mutation PublishReport($id: ID!, $template: String) {
          publishReport(id: $id, template: $template) {
            report {
              id
            }
            errors
          }
        }
      `
      const variables = { id, template }
      return api({ query, variables })
    },
    {
      mutationKey: ['publishReport'],
      async onSuccess(data) {
        await Promise.all([
          queryClient.invalidateQueries([
            'report',
            data.publishReport?.report?.id
          ]),
          queryClient.invalidateQueries(['reports']),
          queryClient.invalidateQueries([
            'tour',
            data.publishReport?.report?.id
          ])
        ])
      }
    }
  )
}
