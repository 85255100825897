import { api } from '@cma/app'
import {
  ImportAgentsMutation,
  ImportAgentsMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useImportAgents() {
  const queryClient = useQueryClient()
  return useMutation<
    ImportAgentsMutation,
    Error,
    ImportAgentsMutationVariables
  >(
    async ({ brokerId, agents, sendInvitation }) => {
      const query = gql`
        mutation ImportAgents(
          $brokerId: ID!
          $agents: [AgentUploadInput!]!
          $sendInvitation: Boolean
        ) {
          importAgents(
            accountId: $brokerId
            agents: $agents
            sendInvitation: $sendInvitation
          ) {
            errors
            importingErrors
            users {
              id
            }
          }
        }
      `
      const variables = { brokerId, agents, sendInvitation }
      const res = await api<ImportAgentsMutation>({ query, variables })

      const errors = res.importAgents?.importingErrors || []
      if (errors.length) throw new Error(errors[0])

      return res
    },
    {
      async onSuccess() {
        await Promise.all([
          queryClient.invalidateQueries(['broker']),
          queryClient.invalidateQueries(['brokerAgents']),
          queryClient.invalidateQueries(['brokerStats'])
        ])
      }
    }
  )
}
