import 'cropperjs/dist/cropper.css'
import { useEffect, useRef, useState } from 'react'
import { Cropper, ReactCropperElement } from 'react-cropper'
import { Button } from './Button'
import styles from './ImageCropperModal.module.css'
import { Modal } from './Modal'

interface OnConfirmArgs {
  data: Cropper.Data
  preview?: string
}

interface ImageCropperModalProps {
  image?: File
  rounded?: boolean
  isOpen: boolean
  onClose: () => void
  onConfirm: (args: OnConfirmArgs) => void
}

export function ImageCropperModal({
  image,
  rounded,
  isOpen,
  onClose,
  onConfirm
}: ImageCropperModalProps) {
  const cropperRef = useRef<ReactCropperElement>(null)
  const [previewUrl, setPreviewUrl] = useState('')

  useEffect(() => {
    let newPreviewUrl = ''

    if (image) {
      newPreviewUrl = URL.createObjectURL(image)
      setPreviewUrl(newPreviewUrl)
    }

    return () => {
      URL.revokeObjectURL(newPreviewUrl)
    }
  }, [image])

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(previewUrl)
    }
  }, [previewUrl])

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
      <div className="space-y-4">
        <Modal.Title>Adjust your Photo</Modal.Title>
        <Cropper
          ref={cropperRef}
          src={previewUrl}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          zoomTo={0}
          rotatable={false}
          scalable={false}
          aspectRatio={rounded ? 1 : undefined}
          checkOrientation={false}
          guides
          className={rounded ? styles.cropper : undefined}
        />
        <div className="flex justify-end space-x-2">
          <Button variant="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              const cropper = cropperRef?.current?.cropper

              if (!cropper) return

              onConfirm({
                data: cropper.getData(rounded),
                preview: cropper.getCroppedCanvas()?.toDataURL('image/jpeg')
              })
            }}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  )
}
