import { Link, Modal, Wrapper } from '@cma/common'
import { CmaCard } from '@cma/features/cma'
import { DocumentCard } from '@cma/features/document'
import { FlyerCard } from '@cma/features/flyer'
import { useNews } from '@cma/features/news'
import { PropertyCard } from '@cma/features/property'
import { CreateReport, ReportsHeader, useReports } from '@cma/features/report'
import { TourBanner, TourCard } from '@cma/features/tour'
import { ReportTypeEnum } from '@cma/generated/graphql'
import {
  CmaThumbnailIcon,
  DocumentThumbnailIcon,
  FlyerThumbnailIcon,
  PropertyThumbnailIcon,
  TourThumbnailIcon
} from '@cma/icons'
import { Dispatch, Fragment, SetStateAction, Suspense, useState } from 'react'
import { Helmet } from 'react-helmet-async'

export default function Home() {
  const [showCreateModal, setShowCreateModal] = useState(false)

  return (
    <>
      <TourBanner />
      <Wrapper>
        <Helmet>
          <title>Cloud CMA</title>
        </Helmet>
        <div className="space-y-16">
          <div className="space-y-8">
            <ReportsHeader title="Recent Reports" />
            <Suspense
              fallback={
                <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                  <CreateReport to="" onClick={() => setShowCreateModal(true)}>
                    Create New Report
                  </CreateReport>
                  {new Array(5).fill('').map((_, index) => (
                    <CmaCard.Skeleton key={index} />
                  ))}
                </div>
              }>
              <RecentReports setShowCreateModal={setShowCreateModal} />
            </Suspense>
          </div>
          <div className="space-y-4">
            <h2 className="text-2xl text-gray-800">What's New</h2>
            <Suspense fallback={<div>TODO: Loading News Skeletons</div>}>
              <News />
            </Suspense>
          </div>
        </div>
        <Modal
          size="max"
          isOpen={showCreateModal}
          onClose={() => setShowCreateModal(false)}>
          <Modal.Title>Create New Report</Modal.Title>
          <div className="mt-4">
            <div className="-m-2 flex flex-wrap items-center">
              <div className="p-2">
                <Link
                  to="/cmas/new"
                  className="block transition-all hover:-translate-y-1 hover:shadow-lg">
                  <CmaThumbnailIcon className="h-36 w-28" />
                </Link>
              </div>
              <div className="p-2">
                <Link
                  to="/tours/new"
                  className="block transition-all hover:-translate-y-1 hover:shadow-lg">
                  <TourThumbnailIcon className="h-36 w-28" />
                </Link>
              </div>
              <div className="p-2">
                <Link
                  to="/properties/new"
                  className="block transition-all hover:-translate-y-1 hover:shadow-lg">
                  <PropertyThumbnailIcon className="h-36 w-28" />
                </Link>
              </div>
              <div className="p-2">
                <Link
                  to="/flyers/new"
                  className="block transition-all hover:-translate-y-1 hover:shadow-lg">
                  <FlyerThumbnailIcon className="h-36 w-28" />
                </Link>
              </div>
              <div className="p-2">
                <Link
                  to="/documents/new"
                  className="block transition-all hover:-translate-y-1 hover:shadow-lg">
                  <DocumentThumbnailIcon className="h-36 w-28" />
                </Link>
              </div>
            </div>
          </div>
        </Modal>
      </Wrapper>
    </>
  )
}

interface RecentReportsProps {
  setShowCreateModal: Dispatch<SetStateAction<boolean>>
}

function RecentReports({ setShowCreateModal }: RecentReportsProps) {
  const { data: { reports } = {} } = useReports({
    types: [
      ReportTypeEnum.Cma,
      ReportTypeEnum.Tour,
      ReportTypeEnum.Property,
      ReportTypeEnum.Flyer,
      ReportTypeEnum.Document
    ],
    first: 5
  })

  return (
    <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
      <CreateReport to="" onClick={() => setShowCreateModal(true)}>
        Create New Report
      </CreateReport>
      {reports?.nodes?.map((report) => (
        <Fragment key={report?.id}>
          {report?.__typename === 'CmaReport' && (
            <CmaCard key={report?.id} report={report} />
          )}
          {report?.__typename === 'TourReport' && (
            <TourCard key={report?.id} report={report} />
          )}
          {report?.__typename === 'PropertyReport' && (
            <PropertyCard key={report?.id} report={report} />
          )}
          {report?.__typename === 'FlyerReport' && (
            <FlyerCard key={report?.id} report={report} />
          )}
          {report?.__typename === 'DocumentReport' && (
            <DocumentCard key={report?.id} report={report} />
          )}
        </Fragment>
      ))}
    </div>
  )
}

function News() {
  const { data: news } = useNews()

  return (
    <>
      {!news?.length && <p>No news</p>}
      {news?.map((news) => (
        <div key={news.id}>
          <a href={news.url} target="_blank" rel="noreferrer">
            {news.title}
          </a>
        </div>
      ))}
    </>
  )
}
