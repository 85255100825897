import { api } from '@cma/app'
import {
  ReportsQuery,
  ReportsQueryVariables,
  SortDirectionEnum,
  SortFieldEnum
} from '@cma/generated/graphql'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useReports({
  types,
  search,
  first,
  last,
  after,
  before,
  sort = {
    field: SortFieldEnum.UpdatedAt,
    dir: SortDirectionEnum.Desc
  }
}: ReportsQueryVariables) {
  return useQuery<ReportsQuery, Error>(
    [
      'reports',
      types,
      search,
      first,
      last,
      after,
      before,
      sort?.field,
      sort?.dir
    ],
    () => {
      const query = gql`
        query Reports(
          $search: String
          $types: [ReportTypeEnum!]
          $first: Int
          $last: Int
          $after: String
          $before: String
          $sort: SortInput
        ) {
          reports(
            query: $search
            reportTypes: $types
            first: $first
            last: $last
            after: $after
            before: $before
            sort: $sort
          ) {
            total
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            nodes {
              __typename
              id
              address
              title
              addressParts: reportAddressParts
              pdfPermalink
              status
              guid
              notes
              thumbnail: thumbnailUrlWithTimestamp
              subjectProperty {
                beds
                baths
                sqft
                garages
                lotSize
                propType
                propSubType
              }
              viewCount
              lastViewedAt
              updatedAt
              ... on TourReport {
                listingCount
                cities
              }
            }
          }
        }
      `
      const variables = { types, search, first, last, after, before, sort }
      return api<ReportsQuery>({ query, variables })
    },
    {
      keepPreviousData: true,
      cacheTime: 0
    }
  )
}
