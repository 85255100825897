import { api } from '@cma/app'
import {
  TourQuery,
  UpdateTourListingMutation,
  UpdateTourListingMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

interface UpdateTourListingContext {
  previousTour?: TourQuery
}

export function useUpdateTourListing() {
  const queryClient = useQueryClient()
  return useMutation<
    UpdateTourListingMutation,
    Error,
    UpdateTourListingMutationVariables & { tourId: string },
    UpdateTourListingContext
  >(
    async ({ id, input }) => {
      const query = gql`
        mutation UpdateTourListing($id: ID!, $input: PublicListingInput!) {
          updatePublicListing(id: $id, input: $input) {
            errors
          }
        }
      `
      const variables = { id, input }
      const res = await api<UpdateTourListingMutation>({ query, variables })
      return res
    },
    {
      onMutate: async ({ tourId, id, input }) => {
        await queryClient.cancelQueries(['tour', tourId])
        const previousTour = queryClient.getQueryData<TourQuery>([
          'tour',
          tourId
        ])

        if (previousTour?.tour) {
          queryClient.setQueryData<TourQuery>(['tour', tourId], {
            ...previousTour,
            tour: {
              ...previousTour.tour,
              appointments: previousTour.tour.appointments.map(
                (appointment) => {
                  if (appointment.listing.id === id) {
                    return {
                      ...appointment,
                      listing: {
                        ...appointment.listing,
                        ...input
                      }
                    }
                  }
                  return appointment
                }
              )
            }
          })
        }

        return { previousTour }
      },
      onError(_, { tourId }, context) {
        if (context?.previousTour) {
          queryClient.setQueryData<TourQuery>(
            ['tour', tourId],
            context.previousTour
          )
        }
      }
    }
  )
}
