import { classNames } from '@cma/common'
import { getAccessTokens } from '@cma/features/auth'
import styles from './AppHeader.module.css'
import { LegacyNavigation } from './LegacyNavigation'
import { Navigation } from './Navigation'
import { useCurrentUser } from './useCurrentUser'

export function AppHeader() {
  const { data: { currentUser } = {} } = useCurrentUser()
  const accessTokens = getAccessTokens()
  const canUseNewNavigation =
    !!currentUser?.lwaJwt &&
    !accessTokens?.impersonateToken &&
    !currentUser.isAuthSso

  return (
    <div className={classNames('sticky top-0 z-10', styles.container)}>
      {canUseNewNavigation ? <Navigation /> : <LegacyNavigation />}
    </div>
  )
}
