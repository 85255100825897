import { ExclamationIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import './Pdf.module.css'
import { Spinner } from './Spinner'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PIXELS_TO_INCHES = 816 // (11in at 96ppi)
const errors: Record<number, string> = {
  404: 'Page not found.',
  422: 'You must publish at least one report for preview to work.',
  500: 'We could not process your request at the moment. Please try again later.'
}

interface PdfProps {
  url: string
  loadingText?: string
  onLoad?: () => void
  onError?: (error: Error) => void
}

export function Pdf({
  url,
  loadingText = 'Loading PDF',
  onLoad,
  onError
}: PdfProps) {
  const [error, setError] = useState<Error>()
  const [totalPages, setTotalPages] = useState(0)
  const [isLandscape, setIsLandScape] = useState(false)

  const statusFromErrorMessage: string | undefined = (error?.message.match(
    /\((\d+)\)/
  ) || [])[1]

  return (
    <Document
      file={url}
      loading={
        <div className="flex items-center space-x-2 text-gray-500">
          <div className="h-5 w-5">
            <Spinner />
          </div>
          <div>{loadingText}</div>
        </div>
      }
      error={
        <div className="flex items-center space-x-2 text-red-500">
          <ExclamationIcon className="h-5 w-5" />
          <div>
            {errors[Number(statusFromErrorMessage)] ||
              'There was a problem loading the pdf.'}
          </div>
        </div>
      }
      onLoadSuccess={({ numPages }) => {
        setTotalPages(numPages)
        onLoad?.()
      }}
      onLoadError={(error) => {
        setError(error)
        onError?.(error)
      }}>
      {new Array(totalPages).fill('').map((_, index) => (
        <Page
          renderAnnotationLayer={false}
          key={index}
          width={isLandscape ? undefined : PIXELS_TO_INCHES}
          height={isLandscape ? PIXELS_TO_INCHES : undefined}
          pageNumber={index + 1}
          onLoadSuccess={(page) => {
            let { width, height } = page

            if (page.rotate === 90 || page.rotate === 270) {
              width = page.height
              height = page.width
            }

            if (width > height) setIsLandScape(true)
          }}
        />
      ))}
    </Document>
  )
}
