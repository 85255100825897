import { useQuery } from '@tanstack/react-query'

// TODO: Talk to backend later on to see if we need the config object found here:
// TODO: https://github.com/wrstudios/cloudcma/blob/master/app/helpers/react_helper.rb#L109
export function useConfig() {
  return useQuery(['config'], () => {
    return {
      supportEmail: 'support@cloudcma.com'
    }
  })
}
