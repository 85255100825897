import { api } from '@cma/app'
import {
  CreateTourMutation,
  CreateTourMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { getReportTemplates } from '../report'
import { getTour } from './useTour'

export function useCreateTour() {
  const queryClient = useQueryClient()
  return useMutation<CreateTourMutation, Error, CreateTourMutationVariables>(
    async ({ input }) => {
      const query = gql`
        mutation CreateTour($input: LiveTourInput!) {
          createTour(input: $input) {
            tour {
              id
            }
          }
        }
      `
      const variables = { input }
      const res = await api<CreateTourMutation>({ query, variables })
      return res
    },
    {
      async onSuccess({ createTour }) {
        const id = createTour?.tour?.id
        await Promise.all([
          queryClient.prefetchQuery(['tour', id], () => getTour(id)),
          queryClient.prefetchQuery(['reportTemplates', id], () =>
            getReportTemplates(id)
          )
        ])
      }
    }
  )
}
