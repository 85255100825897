import { Modal } from '@cma/common'
import { CustomizePageWithPages } from './useCustomizePages'

interface CustomizePageContentProps {
  page: CustomizePageWithPages
  isOpen: boolean
  onClose: () => void
}

export function CustomizePageContent({
  page,
  isOpen,
  onClose
}: CustomizePageContentProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Title>{page.title} - Customize Page Content</Modal.Title>
      <div className="mt-2">WYSIWYG editor here</div>
    </Modal>
  )
}
