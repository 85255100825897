export function MapIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.552 2.398a.75.75 0 0 1 .664-.116l4.802 1.44 5.8-1.45a.75.75 0 0 1 .398.01l5 1.5a.75.75 0 0 1 .534.718V17a.75.75 0 0 1-.965.718l-4.803-1.44-5.8 1.45a.75.75 0 0 1-.398-.01l-5-1.5a.75.75 0 0 1-.534-.718V3a.75.75 0 0 1 .302-.602ZM13.75 14.942l3.5 1.05V5.058l-3.5-1.05v10.934Zm-1.5-10.981v10.953l-4.5 1.125V5.086l4.5-1.125Zm-6 1.097-3.5-1.05v10.934l3.5 1.05V5.058Z"
        fill="currentColor"
      />
    </svg>
  )
}
