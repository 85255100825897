export function ArrowPrevIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 34" {...props}>
      <path
        d="M14 1L2 17l12 16"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
        opacity=".8"
        strokeLinecap="round"
      />
    </svg>
  )
}
