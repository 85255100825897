import { formatCurrency } from '@cma/common'
import { ListingPartsFragment } from '@cma/generated/graphql'

function median(arr: number[]) {
  if (!arr.length) return 0
  const sorted = [...arr].sort((a, b) => a - b)
  const half = Math.floor(sorted.length / 2)
  return sorted.length % 2
    ? sorted[half]
    : (sorted[half - 1] + sorted[half]) / 2
}

interface ReportPricesSummaryProps {
  listings: ListingPartsFragment[]
}

export function ReportPricesSummary({ listings }: ReportPricesSummaryProps) {
  const prices = listings
    .filter(
      (listing): listing is ListingPartsFragment & { price: number } =>
        !!listing.price && !listing.hide
    )
    .map((listing) => listing.price)

  return (
    <>
      <h3 className="mb-4 text-lg text-gray-700">Summary of Prices</h3>
      <ul className="mb-6 flex flex-col space-y-4 text-gray-500">
        <ListingsStatsPrices
          label="Low"
          value={!prices.length ? 0 : Math.min(...prices)}
        />
        <ListingsStatsPrices
          label="Median"
          value={!prices.length ? 0 : median(prices)}
        />
        <ListingsStatsPrices
          label="Average"
          value={
            !prices.length
              ? 0
              : prices.reduce((prev, curr) => (curr += prev), 0) / prices.length
          }
        />
        <ListingsStatsPrices
          label="High"
          value={!prices.length ? 0 : Math.max(...prices)}
        />
      </ul>
    </>
  )
}

interface ListingsStatsPricesProps {
  label: string
  value: number
}

function ListingsStatsPrices({ label, value }: ListingsStatsPricesProps) {
  return (
    <li className="flex justify-between text-sm">
      <span>{label}:</span>
      <span
        aria-hidden
        className="w-full overflow-hidden whitespace-nowrap px-1 before:content-['_._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._']"
      />
      <span>{formatCurrency(value)}</span>
    </li>
  )
}
