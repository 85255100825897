import { Link, LinkProps } from '@cma/common'
import { PlusIcon } from '@heroicons/react/outline'

export function CreateReport({
  children,
  ...props
}: Omit<LinkProps, 'className'>) {
  return (
    <Link
      {...props}
      className="flex h-[9.875rem] flex-col items-center justify-center rounded border-2 border-dashed border-gray-300 py-10 transition-colors hover:border-gray-500 hover:no-underline">
      <PlusIcon className="h-10 w-10 text-green-500" />
      <div className="mt-1 text-lg font-medium text-gray-800">{children}</div>
    </Link>
  )
}
