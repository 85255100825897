import { api } from '@cma/app'
import { useLogout } from '@cma/features/auth'
import { DestroyAccountMutation } from '@cma/generated/graphql'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useDestroyAccount() {
  const logout = useLogout()
  return useMutation<DestroyAccountMutation, Error>(
    async () => {
      const query = gql`
        mutation DestroyAccount {
          cancelAccount
        }
      `
      const res = await api<DestroyAccountMutation>({ query })

      if (!res.cancelAccount) {
        throw new Error(
          'There was a problem while cancelling your account. Please reach out to support for further assistance.'
        )
      }

      return res
    },
    {
      async onSuccess() {
        await logout({ shouldUseCmaToLogout: true })
      }
    }
  )
}
