export function IconLayoutFlyer6(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 233 303" {...props}>
      <path
        fill="currentColor"
        d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
      />
      <path
        fill="currentColor"
        d="M25.56 23.75h36.83v4H25.56zm56.98 0h124.9v4H82.54zm99.51 221.05h23.59v3h-23.59zm-92.89 0h79.98v3H89.16zm0 18.41h116.48v3H89.16zm0-36.75h116.48v3H89.16zm-58.36-1.04h36.83v4H30.8zm-3.44 19.79h43.71v4H27.36zm8.48 17.53h26.75v4H35.84z"
      />
      <rect
        fill="#e1e5e9"
        x="25.78"
        y="159.2"
        width="51.53"
        height="44.22"
        rx="5"
        ry="5"
      />
      <path
        fill="currentColor"
        d="M72.31 204.91H30.78a6.5 6.5 0 0 1-6.5-6.5V164.2a6.5 6.5 0 0 1 6.5-6.5h41.53a6.51 6.51 0 0 1 6.5 6.5v34.21a6.51 6.51 0 0 1-6.5 6.5zM30.78 160.7a3.5 3.5 0 0 0-3.5 3.5v34.21a3.5 3.5 0 0 0 3.5 3.5h41.53a3.5 3.5 0 0 0 3.5-3.5V164.2a3.5 3.5 0 0 0-3.5-3.5z"
      />
      <rect
        fill="#e1e5e9"
        x="90.73"
        y="159.2"
        width="51.53"
        height="44.22"
        rx="5"
        ry="5"
      />
      <path
        fill="currentColor"
        d="M137.27 204.91H95.73a6.51 6.51 0 0 1-6.5-6.5V164.2a6.51 6.51 0 0 1 6.5-6.5h41.54a6.51 6.51 0 0 1 6.5 6.5v34.21a6.51 6.51 0 0 1-6.5 6.5zM95.73 160.7a3.5 3.5 0 0 0-3.5 3.5v34.21a3.5 3.5 0 0 0 3.5 3.5h41.54a3.5 3.5 0 0 0 3.5-3.5V164.2a3.5 3.5 0 0 0-3.5-3.5z"
      />
      <rect
        fill="#e1e5e9"
        x="155.69"
        y="159.2"
        width="51.53"
        height="44.22"
        rx="5"
        ry="5"
      />
      <path
        fill="currentColor"
        d="M202.22 204.91h-41.53a6.51 6.51 0 0 1-6.5-6.5V164.2a6.51 6.51 0 0 1 6.5-6.5h41.53a6.5 6.5 0 0 1 6.5 6.5v34.21a6.5 6.5 0 0 1-6.5 6.5zm-41.53-44.21a3.5 3.5 0 0 0-3.5 3.5v34.21a3.5 3.5 0 0 0 3.5 3.5h41.53a3.5 3.5 0 0 0 3.5-3.5V164.2a3.5 3.5 0 0 0-3.5-3.5z"
      />
      <rect
        fill="#e1e5e9"
        x="25.78"
        y="42.62"
        width="181.45"
        height="103.83"
        rx="6.3"
        ry="6.3"
      />
      <path
        fill="currentColor"
        d="M200.92 148H32.08a7.81 7.81 0 0 1-7.8-7.8V48.91a7.8 7.8 0 0 1 7.8-7.79h168.84a7.8 7.8 0 0 1 7.8 7.79v91.24a7.81 7.81 0 0 1-7.8 7.85zM32.08 44.12a4.8 4.8 0 0 0-4.8 4.79v91.24a4.8 4.8 0 0 0 4.8 4.8h168.84a4.8 4.8 0 0 0 4.8-4.8V48.91a4.8 4.8 0 0 0-4.8-4.79z"
      />
      <path
        fill="#fff"
        d="m31.234 51.234 1.409-2.649 3.531 1.878-1.408 2.649zM188.44 134.81l-7-3.71 1.41-2.65 7 3.71zm-14-7.42-7-3.72 1.46-2.67 7 3.72zm-14-7.43-7-3.71 1.41-2.65 7 3.71zm-14-7.43-7-3.71 1.41-2.65 7 3.71zm-14-7.42-7-3.72 1.56-2.65 7 3.72zm-14-7.43-7-3.72 1.56-2.64 7 3.68zm-14-7.43-7-3.71L99 83.89l7 3.71zm-14-7.42-7-3.72 1.4-2.65 7 3.72zm-14-7.43-7-3.72L71.1 69l7 3.71zM62.71 68l-7-3.71 1.41-2.65 7 3.71zm-14-7.42-7-3.72 1.41-2.65 7 3.72zM195.44 138.546l1.407-2.65 3.532 1.878-1.408 2.649zM31.63 138.04l3.523-1.894 1.42 2.642-3.524 1.894z"
      />
      <path
        fill="#fff"
        d="m43.52 135.05-1.42-2.64 7-3.74 1.42 2.64zm13.91-7.47L56 124.93l7-3.73 1.42 2.64zm13.9-7.48-1.42-2.64 7-3.74 1.42 2.65zm13.9-7.47L83.81 110l7-3.74 1.42 2.65zm13.91-7.47-1.42-2.64 7-3.74 1.42 2.64zM113 97.69l-1.42-2.64 6.95-3.74L120 94zm13.9-7.48-1.42-2.64 7-3.73 1.42 2.64zm13.9-7.47-1.42-2.64 7-3.74 1.41 2.64zm13.91-7.47-1.42-2.64 6.95-3.74 1.42 2.65zm13.9-7.47-1.42-2.64 7-3.74 1.42 2.64zm13.9-7.47-1.42-2.65 7-3.73 1.42 2.64zM195.038 50.214l3.524-1.893 1.42 2.643-3.524 1.893z"
      />
    </svg>
  )
}
