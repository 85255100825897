export function SearchIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3.75a5.25 5.25 0 1 0 0 10.5 5.25 5.25 0 0 0 0-10.5ZM2.25 9a6.75 6.75 0 1 1 12.024 4.213l2.756 2.757a.75.75 0 1 1-1.06 1.06l-2.757-2.756A6.75 6.75 0 0 1 2.25 9Z"
        fill="currentColor"
      />
    </svg>
  )
}
