import { Modal } from '@cma/common'
import { TemplateIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { CustomizeButton } from './CustomizeButton'

export function CustomizeTemplate() {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <CustomizeButton
        icon={<TemplateIcon />}
        title="Template"
        subtitle="Default Template"
        onClick={() => setShowModal(true)}
      />
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <Modal.Title>Template</Modal.Title>
        <div className="mt-2">This is the modal content</div>
      </Modal>
    </>
  )
}
