import { api } from '@cma/app'
import {
  UpdateReportListingMutation,
  UpdateReportListingMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useUpdateReportListing() {
  const queryClient = useQueryClient()
  return useMutation<
    UpdateReportListingMutation,
    Error,
    UpdateReportListingMutationVariables & { reportId?: string }
  >(
    ({ listingId, listing }) => {
      if (!listing.address) {
        throw new Error('You must include either an mls number or an address')
      }

      const query = gql`
        mutation UpdateReportListing($listingId: ID!, $listing: ListingInput!) {
          updateListing(id: $listingId, input: $listing) {
            listing {
              id
            }
          }
        }
      `
      const variables = { listingId: listingId, listing }
      return api<UpdateReportListingMutation>({ query, variables })
    },
    {
      async onSuccess(_, { reportId }) {
        await queryClient.invalidateQueries(['report', reportId])
      }
    }
  )
}
