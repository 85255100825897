import { api } from '@cma/app'
import {
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useUpdateProfile() {
  const queryClient = useQueryClient()
  return useMutation<
    UpdateUserProfileMutation,
    Error,
    UpdateUserProfileMutationVariables
  >(
    ({ input }) => {
      const query = gql`
        mutation UpdateUserProfile($input: UserProfileInput!) {
          updateUserProfile(input: $input) {
            user {
              avatar
              companyLogoUrl
              status
            }
            errors
          }
        }
      `
      const variables = { input }
      return api<UpdateUserProfileMutation>({ query, variables })
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries(['currentUser'])
      }
    }
  )
}
