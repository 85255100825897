import { PropsWithChildren } from 'react'
import { classNames } from './utils'

interface BadgeProps {
  variant?: 'default' | 'success' | 'info' | 'danger' | 'warning'
}

export function Badge({
  variant = 'default',
  children
}: PropsWithChildren<BadgeProps>) {
  return (
    <span
      className={classNames(
        'leading-1 inline-flex h-[1.0625rem] max-w-full items-center truncate whitespace-nowrap rounded-full px-2 text-[0.6875rem] font-medium',
        {
          'bg-gray-100 text-gray-900': variant === 'default',
          'bg-green-100 text-green-900': variant === 'success',
          'bg-light-blue-100 text-light-blue-900': variant === 'info',
          'bg-red-100 text-red-900': variant === 'danger',
          'bg-yellow-100 text-yellow-900': variant === 'warning'
        }
      )}>
      <span className="truncate">{children}</span>
    </span>
  )
}
