import { api } from '@cma/app'
import {
  BrokerExistenceQuery,
  BrokerExistenceQueryVariables
} from '@cma/generated/graphql'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useBrokerExistence(
  { filters }: BrokerExistenceQueryVariables,
  options?: Omit<
    UseQueryOptions<BrokerExistenceQuery, Error>,
    'queryKey' | 'queryFn' | 'initialData' | 'suspense' | 'staleTime'
  >
) {
  return useQuery<BrokerExistenceQuery, Error>(
    ['brokerExistence', filters],
    () => {
      const query = gql`
        query BrokerExistence($filters: [BrokerFilterInput!]) {
          brokers(filters: $filters) {
            id
          }
        }
      `
      const variables = { filters }
      return api<{ brokers: { id: string }[] }>({ query, variables })
    },
    {
      ...options,
      suspense: false,
      staleTime: 0
    }
  )
}
