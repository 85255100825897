import { api } from '@cma/app'
import { AlternateMlsesQuery } from '@cma/generated/graphql'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useAlternateMlses() {
  return useQuery<AlternateMlsesQuery, Error>(['alternateMlses'], () => {
    const query = gql`
      fragment AlternateMlsParts on AlternateMlsCredential {
        id
        code
        name
        password
      }

      query AlternateMlses {
        alternateMlses: currentUser {
          mlses: alternateMlsCredentials {
            ...AlternateMlsParts
          }
        }
      }
    `
    return api<AlternateMlsesQuery>({ query })
  })
}
