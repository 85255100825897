import { api } from '@cma/app'
import { ContactMethodsQuery } from '@cma/generated/graphql'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useContactMethods() {
  return useQuery<ContactMethodsQuery, Error>(['contactMethods'], () => {
    const query = gql`
      fragment ContactMethodParts on ContactItem {
        key
        prettyKey
      }

      query ContactMethods {
        currentUser {
          contactMethods {
            ...ContactMethodParts
          }
        }
      }
    `
    return api<ContactMethodsQuery>({ query })
  })
}
