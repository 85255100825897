export function HeartOutlineIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.625 3.75c-1.887 0-3.375 1.495-3.375 3.446 0 1.198.524 2.343 1.592 3.673 1.078 1.341 2.633 2.78 4.579 4.576l.579.535.579-.534c1.946-1.797 3.5-3.236 4.579-4.577 1.068-1.33 1.592-2.475 1.592-3.673 0-1.951-1.488-3.446-3.375-3.446-1.073 0-2.12.512-2.8 1.323a.75.75 0 0 1-1.15 0c-.68-.81-1.727-1.323-2.8-1.323ZM1.75 7.196c0-2.756 2.135-4.946 4.875-4.946 1.254 0 2.457.482 3.375 1.276a5.198 5.198 0 0 1 3.375-1.276c2.74 0 4.875 2.19 4.875 4.946 0 1.683-.751 3.154-1.923 4.613-1.155 1.437-2.79 2.947-4.689 4.7l-.042.039-1.087 1.003a.75.75 0 0 1-1.018 0l-1.13-1.042c-1.898-1.753-3.533-3.264-4.688-4.7C2.501 10.35 1.75 8.879 1.75 7.196Z"
        fill="currentColor"
      />
    </svg>
  )
}
