import { api } from '@cma/app'
import {
  BrokerStatsQuery,
  BrokerStatsQueryVariables
} from '@cma/generated/graphql'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useBrokerStats({ id }: BrokerStatsQueryVariables) {
  return useQuery<BrokerStatsQuery, Error>(['brokerStats', id], () => {
    const query = gql`
      query BrokerStats($id: ID!) {
        broker(id: $id) {
          stats {
            monthlyActiveUsers {
              singleMonthStats {
                mls {
                  key
                  title
                }
                month
                totalUsers
                totalActiveUsers
                cmaActiveUsers
                flyerActiveUsers
                buyerActiveUsers
                propertyActiveUsers
                homebeatActiveUsers
              }
            }
          }
        }
      }
    `
    const variables = { id }
    return api<BrokerStatsQuery>({ query, variables })
  })
}
