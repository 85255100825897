import { api } from '@cma/app'
import {
  DestroyCustomPageMutation,
  DestroyCustomPageMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useDestroyCustomPage() {
  const queryClient = useQueryClient()
  return useMutation<
    DestroyCustomPageMutation,
    Error,
    DestroyCustomPageMutationVariables
  >(
    ({ id }) => {
      const query = gql`
        mutation DestroyCustomPage($id: ID!) {
          destroyCustomPage(id: $id) {
            customPage {
              id
            }
            errors
          }
        }
      `
      const variables = { id }
      return api<DestroyCustomPageMutation>({ query, variables })
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries(['customPages'])
      }
    }
  )
}
