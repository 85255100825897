import { Button, ButtonProps } from '@cma/common'
import { useIsMutating } from '@tanstack/react-query'
import { MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePublishReport } from './usePublishReport'

interface ReportPublishButtonProps extends ButtonProps {
  reportId: string
  to: string
}

export function ReportPublishButton({
  reportId,
  to,
  onClick,
  ...props
}: ReportPublishButtonProps) {
  const { mutate: publishReport } = usePublishReport()
  const isLoading = !!useIsMutating(['publishReport'])
  const navigate = useNavigate()

  return (
    <Button
      {...props}
      loading={isLoading}
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        publishReport(
          { id: reportId },
          {
            onSuccess() {
              navigate(to)
            }
          }
        )
        onClick?.(e)
      }}
    />
  )
}
