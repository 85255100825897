import { Button, Link, NotFound, Wrapper } from '@cma/common'
import {
  ReportHeader,
  ReportListings,
  ReportListingsSummary,
  ReportMap,
  ReportPricesSummary,
  useReport
} from '@cma/features/report'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

export default function DocumentListings() {
  const { id } = useParams()
  const { data: { report } = {} } = useReport({ id: id as string })

  if (!report) {
    return (
      <Wrapper>
        <NotFound />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Helmet>
        <title>Organize Listings - Document- Cloud CMA</title>
      </Helmet>
      <ReportHeader
        reportId={report.id}
        title="Organize Listings"
        breadcrumbs={[
          { title: 'Criteria', url: `/documents/${report.id}/edit` },
          { title: 'Listings', url: `/documents/${report.id}` },
          { title: 'Customize', url: `/documents/${report.id}/customize` },
          {
            title: 'Publish',
            url: `/documents/${report.id}/publish`,
            publish: true
          }
        ]}>
        <Button as={Link} to={`/documents/${report.id}/customize`}>
          Customize Report
        </Button>
      </ReportHeader>
      <main className="space-y-8">
        <ReportMap reportId={report.id} listings={report?.listings || []} />
        <div className="grid grid-cols-12 gap-8">
          <div className="col-span-12 lg:col-span-9">
            <ReportListings
              reportId={report.id}
              reportType={report.__typename}
              listings={report?.listings || []}
            />
          </div>
          <div className="col-span-12 lg:col-span-3">
            <div className="sticky top-20 flex flex-wrap">
              <div className="w-full sm:w-1/2 lg:w-full">
                <ReportListingsSummary listings={report?.listings || []} />
              </div>
              <div className="w-full sm:w-1/2 lg:w-full">
                <ReportPricesSummary listings={report?.listings || []} />
              </div>
            </div>
          </div>
        </div>
        <div className="text-center">
          <Button size="lg" as={Link} to={`/documents/${report.id}/customize`}>
            Customize Report
          </Button>
        </div>
      </main>
    </Wrapper>
  )
}
