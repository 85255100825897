import { useProductAvailability } from '@cma/app'
import { Button } from '@cma/common'
import { ReportPartsFragment } from '@cma/generated/graphql'
import { AlertIcon } from '@cma/icons'
import emailAlert from './images/email_alert.png'
import justListedEmailAlert from './images/just_listed_email_alert.png'
import textAlert from './images/text_alert.png'

interface ReportPublishAlertsProps {
  report: ReportPartsFragment
}

export function ReportPublishAlerts({ report }: ReportPublishAlertsProps) {
  const { data: availability } = useProductAvailability()
  const variants = {
    0: {
      heading:
        'Got leads? Set them up with listing alerts that keep you top-of-mind.',
      message:
        "It's easy to keep them engaged with your own modern listing alerts that keep your name in front of them.",
      tip: "Search for listings that correspond closely to what they're interested in, then choose <i>Daily</i> or <i>Weekly</i> email alerts for them to stay engaged.",
      image: emailAlert
    },
    1: {
      heading:
        'Active buyers should be getting real-time text message listing alerts.',
      message:
        "Don't let their dream home slip away sitting in their email inbox. Make sure they are the first to see the home with the industry's fastest alert notifications.",
      tip: 'Simply, search for the listings that match their criteria and choose <i>Real-time</i> alerts.',
      image: textAlert
    },
    2: {
      heading: "Never miss a comp that affects this home's selling potential.",
      message:
        'Setting up listing alerts around this home will keep you (and your seller) in the loop of how the market is evolving.',
      tip: 'Set your search criteria to match the criteria you chose for this CMA. Then choose <i>Real-time</i> or <i>Daily</i> alerts so you stay on top of the market.',
      image: justListedEmailAlert
    }
  }
  const variantNumber = ['Property', 'Tour'].includes(report?.__typename || '')
    ? 1
    : 2
  const variant = variants[variantNumber]
  const createUrl =
    availability?.products.find((product) => product.key === 'cloud_streams')
      ?.createUrl || ''

  return (
    <div className="flex flex-col items-start space-y-4 xl:flex-row xl:space-x-4 xl:space-y-0">
      <div className="space-y-4">
        <div className="text-xl font-medium text-gray-500">
          While we're preparing that:
        </div>
        <div className="mb-4 text-2xl font-semibold text-gray-900">
          {variant.heading}
        </div>
        <div className="text-sm text-gray-600">{variant.message}</div>
        <div className="rounded-md bg-gray-50 p-4 text-sm text-gray-600">
          <strong className="text-gray-900">Pro Tip:</strong>
          {' "'}
          <blockquote
            className="inline"
            dangerouslySetInnerHTML={{ __html: variant.tip }}
          />
          {'"'}
        </div>
        <Button
          size="xl"
          disabled={!createUrl}
          leftIcon={<AlertIcon />}
          onClick={() => {
            // @ts-ignore ignoring google analytics
            if (typeof window.ga === 'function') {
              // @ts-ignore ignoring google analytics
              window.ga(
                'cloudAgentSuiteTracker.send',
                'event',
                'Cross Product',
                'Login'
              )
            }
            window.open(createUrl, '_blank')
          }}>
          Set Up Alerts
        </Button>
      </div>
      <div className="shrink-0">
        <img
          src={variant.image}
          alt="Text Alerts"
          className="h-80 w-[18.5rem] rounded-xl object-cover"
        />
      </div>
    </div>
  )
}
