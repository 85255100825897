import { Button } from '@cma/common'
import { Select } from '@cma/generated/graphql'
import { StarIcon } from '@cma/icons'
import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'

interface CustomizeThemeTryModernProps {
  isOpen: boolean
  onClose: () => void
  handleTryModernTheme: () => void
  theme?: Select
}

export function CustomizeThemeTryModern({
  isOpen,
  onClose,
  handleTryModernTheme,
  theme
}: CustomizeThemeTryModernProps) {
  return (
    <Dialog
      open={isOpen}
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      onClose={() => onClose()}>
      <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      <div className="flex min-h-[100dvh] items-center justify-center p-4">
        <div
          className="w-full max-w-xl transform overflow-hidden rounded-lg px-4 pb-4 pt-4 shadow-xl transition-all sm:my-8 sm:p-6"
          style={{
            background: 'linear-gradient(rgb(45, 51, 93), rgb(19, 25, 67))'
          }}>
          <div className="absolute top-0 right-0 block pt-4 pr-4">
            <button
              type="button"
              className="text-white"
              onClick={() => onClose()}>
              <span className="sr-only">Close Modal</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div>
            <div className="flex items-center justify-center gap-2 text-lg text-white">
              <span className="h-10 w-10 rounded-lg border-2 border-white p-1 text-white ">
                <StarIcon />
              </span>
              Try the new modern theme
            </div>
            <div className="relative my-6 flex justify-center">
              {[0, 1, 2].map((previewId) => {
                return (
                  <div
                    key={previewId}
                    className="relative shadow-md first-of-type:absolute first-of-type:left-2 last-of-type:absolute last-of-type:right-2">
                    <img
                      className="w-48"
                      src={`${process.env.VITE_APP_CMA_URL}/images/reports/flyer/modern_${theme?.key}_${previewId}.jpg`}
                      alt={`Modern ${theme?.value} Preview ${previewId}`}
                      style={{ zIndex: previewId }}
                    />
                  </div>
                )
              })}
            </div>

            <div className="flex justify-center pt-4 text-center">
              <Button variant="primary" onClick={handleTryModernTheme}>
                Try Modern
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
