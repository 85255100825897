import { api } from '@cma/app'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export interface Address {
  address: string | null
  city: string | null
  lat: number | null
  lon: number | null
  mlsnum: string | null
  state: string | null
  zipcode: string | null
  formattedAddress: string
}

const MIN_CHAR_LIMIT = 5

interface AddressSearchArgs {
  address: string
  guid?: string
}

export function useAddressSearch(
  { address, guid }: AddressSearchArgs,
  options?: Omit<
    UseQueryOptions<Address[], Error>,
    | 'queryKey'
    | 'queryFn'
    | 'suspense'
    | 'refetchOnWindowFocus'
    | 'keepPreviousData'
  >
) {
  return useQuery<Address[], Error>(
    ['addressSearch', address, guid],
    async () => {
      const query = gql`
        query AddressSearch($address: String!) {
          addressSuggestions(address: $address) {
            addresses {
              address
              city
              lat
              lon
              mlsnum
              state
              zipcode
            }
          }
        }
      `
      const variables = { address }
      const res = await api<{
        addressSuggestions: { addresses: Omit<Address, 'formattedAddress'>[] }
      }>({
        query,
        variables,
        guid
      })
      return res.addressSuggestions.addresses.map((address) => ({
        ...address,
        formattedAddress: address.address?.includes(',')
          ? address.address
          : [
              address.address,
              address.city,
              [address.state, address.zipcode].filter(Boolean).join(' ')
            ]
              .filter(Boolean)
              .join(', ')
      }))
    },
    {
      ...options,
      suspense: false,
      refetchOnWindowFocus: false,
      enabled: options?.enabled ?? address.length > MIN_CHAR_LIMIT,
      keepPreviousData: address.length > MIN_CHAR_LIMIT
    }
  )
}
