export function CompareIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.091 3.371a.75.75 0 0 1 .818 0l3.5 2.275a.75.75 0 0 1 .341.629V11a.75.75 0 0 1-.75.75H1A.75.75 0 0 1 .25 11V6.275a.75.75 0 0 1 .341-.629l3.5-2.275Zm11 0a.75.75 0 0 1 .818 0l3.5 2.275a.75.75 0 0 1 .341.629V11a.75.75 0 0 1-.75.75h-7a.75.75 0 0 1-.75-.75V6.275a.75.75 0 0 1 .341-.629l3.5-2.275ZM1.75 6.682v3.568h2v-1.5a.75.75 0 0 1 1.5 0v1.5h2V6.682L4.5 4.895 1.75 6.682Zm11 0v3.568h2v-1.5a.75.75 0 0 1 1.5 0v1.5h2V6.682L15.5 4.895l-2.75 1.787ZM2 15.25a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1-.75-.75Zm11 0a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1-.75-.75Z"
        fill="currentColor"
      />
    </svg>
  )
}
