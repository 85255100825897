import { Link } from '@cma/common'
import { CmaRdcLogo } from '@cma/features/sso'
import { useOnboardingForm } from '@cma/pages/sso/SsoOnboarding'
import { HelixIcon } from '@helix/helix-icon'
import { spinner as SpinnerIcon } from '@helix/helix-icon/outlined'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'
import { useLogin } from '../auth'

const schema = yup.object({
  email: yup.string().email('Invalid email').required('Your email is required'),
  password: yup.string().required('Your password is required')
})

export default function SsoLogin() {
  const { service } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const token = searchParams.get('token') || ''
  const firstName = searchParams.get('first_name') || ''
  const email = searchParams.get('email') || ''
  const magicLinkError = searchParams.get('error')
    ? 'Magic link is expired or invalid'
    : ''

  const {
    mutate: login,
    isLoading: isLoggingIn,
    error: errorLogginIn
  } = useLogin({
    async onSuccess(data) {
      await new Promise(() => {
        if (
          service === 'rdc' &&
          data.authenticate?.user?.features?.offerRdcToolkit
        ) {
          navigate(`/sso/connect/${service}?token=${token}`)
        } else {
          window.location.href = process.env.VITE_APP_CMA_URL
        }
      })
    }
  })

  const { getValues } = useOnboardingForm()

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: email || getValues('email')
    }
  })

  return (
    <div className="helix-card helix-w-100-percent max-w-[26.25rem]">
      <form
        className="helix-py-7"
        onSubmit={handleSubmit(({ email, password }) =>
          login({ email, password, partnershipLogin: true })
        )}>
        <div className="helix-px-2 helix-mb-5">
          <div className="helix-mb-5 helix-text--center mx-auto h-[1.875rem] w-[7.75rem]">
            <CmaRdcLogo />
          </div>
          <h1 className="helix-display-small helix-text-black helix-mb-2 helix-text--center">
            Hello{firstName ? ` ${firstName}` : ''}, Sign In to continue
          </h1>
          <p className="helix-heading helix-text-gray-600 helix-text--center helix-px-7">
            Enter your Cloud CMA password
          </p>
        </div>
        <div className="helix-px-sm-5 md:px-[2.625rem]">
          <div className="helix-mb-4">
            <label
              className="helix-body--strong helix-mb-1 helix-d-inline-block helix-text-gray-700"
              htmlFor="email">
              Email
            </label>
            <input
              type="text"
              className="helix-text-input"
              id="email"
              placeholder="emmamckenna@acme.com"
              {...register('email')}
            />
            {errors.email?.message && (
              <div
                role="alert"
                aria-live="polite"
                className="helix-small helix-text--error helix-mt-1">
                {errors.email.message}
              </div>
            )}
          </div>
          <div className="helix-mb-5">
            <label
              className="helix-body--strong helix-mb-1 helix-d-inline-block helix-text-gray-700"
              htmlFor="password">
              Password
            </label>
            <input
              type="password"
              className="helix-text-input"
              id="password"
              {...register('password')}
            />
            {errors.password?.message && (
              <div
                role="alert"
                aria-live="polite"
                className="helix-small helix-text--error helix-mt-1">
                {errors.password.message}
              </div>
            )}
          </div>
          <button
            className="helix-btn helix-btn--large helix-btn--primary helix-w-100-percent"
            disabled={!isValid || isLoggingIn}>
            {!isLoggingIn ? (
              'Sign In'
            ) : (
              <span className="mx-auto block h-5 w-5 animate-spin">
                <HelixIcon icon={SpinnerIcon} />
              </span>
            )}
          </button>
          {(errorLogginIn || (!!magicLinkError && !watch('email'))) && (
            <div className="mt-3 text-center text-sm text-red-500">
              {magicLinkError || errorLogginIn?.message}
            </div>
          )}
          <div className="helix-d-flex helix-flex--column helix-small helix-text--center helix-text-gray-600 mt-6">
            <strong className="helix-text-gray-600">Forgot Password?</strong>
            <p>
              We can email you a magic link so you can Sign In without typing a
              password.{' '}
              <Link
                to={`/sso/email_login/${service}?${new URLSearchParams({
                  email: watch('email'),
                  token
                }).toString()}`}>
                Get Magic Link
              </Link>
            </p>
          </div>
        </div>
      </form>
    </div>
  )
}
