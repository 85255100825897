import { Checkbox, Map } from '@cma/common'
import { ListingPartsFragment } from '@cma/generated/graphql'
import { Fragment, useState } from 'react'
import { useToggleReportListing } from './useToggleReportListing'

interface ReportMapProps {
  reportId: string
  listings: ListingPartsFragment[]
}

export function ReportMap({ reportId, listings }: ReportMapProps) {
  const { mutate: toggleListing } = useToggleReportListing()
  const [activeListingId, setActiveListingId] = useState<string>()
  const firstListing: ListingPartsFragment | undefined = listings[0]
  const lngLats = listings.map<[lng: number, lat: number]>((listing) => [
    listing.lon || -1,
    listing.lat || -1
  ])
  const bounds = Map.fitBounds(lngLats)

  return (
    <div className="h-96 w-full overflow-hidden rounded-md bg-gray-100">
      <Map
        center={
          lngLats.length <= 1
            ? { lat: firstListing.lat || -1, lng: firstListing.lon || -1 }
            : undefined
        }
        onClick={() => setActiveListingId(undefined)}
        bounds={lngLats.length > 1 ? bounds : undefined}>
        {listings.map((listing) => (
          <Fragment key={listing.id}>
            <Map.Listing
              lat={listing.lat || -1}
              lon={listing.lon || -1}
              price={listing.price || 0}
              hide={listing.hide || false}
              statusColor={`#${listing.statusAsColor || '000'}`}
              onClick={() => setActiveListingId(listing.id)}
            />
            {activeListingId === listing.id && (
              <Map.Popup
                lat={listing.lat || -1}
                lon={listing.lon || -1}
                address={listing.address || 'No Address'}
                city={listing.city || 'No City'}
                state={listing.state || ''}
                zip={listing.zip || ''}
                photo={listing.photos?.[0] || ''}
                price={listing.price || 0}
                status={listing.status || ''}
                statusColor={`#${listing.statusAsColor || '000'}`}>
                <div className="absolute top-0 right-0 pt-2 pr-2">
                  <Checkbox
                    checked={listing.hide !== null ? !listing.hide : false}
                    onChange={() => toggleListing({ reportId, id: listing.id })}
                  />
                </div>
              </Map.Popup>
            )}
          </Fragment>
        ))}
        <Map.Zoom />
      </Map>
    </div>
  )
}
