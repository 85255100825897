import { Breadcrumbs, Spinner } from '@cma/common'
import { useIsMutating } from '@tanstack/react-query'
import { PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePublishReport } from './usePublishReport'

interface ReportHeaderProps {
  reportId: string
  title: string
  breadcrumbs: {
    title: string
    url: string
    disabled?: boolean
    publish?: boolean
  }[]
}

export function ReportHeader({
  reportId,
  title,
  breadcrumbs,
  children
}: PropsWithChildren<ReportHeaderProps>) {
  const navigate = useNavigate()
  const { mutate: publishReport } = usePublishReport()
  const isLoading = !!useIsMutating(['publishReport'])

  return (
    <header className="space-y-4">
      <h1 className="text-4xl font-medium">{title}</h1>
      <div className="flex items-end justify-between space-x-4">
        <Breadcrumbs>
          {breadcrumbs.map((breadcrumb, index) => (
            <Breadcrumbs.Crumb
              key={index}
              to={breadcrumb.url}
              disabled={
                breadcrumb.disabled || (breadcrumb.publish && isLoading)
              }
              onClick={(e) => {
                if (breadcrumb.publish) {
                  e.preventDefault()
                  publishReport(
                    { id: reportId },
                    {
                      onSuccess() {
                        navigate(breadcrumb.url)
                      }
                    }
                  )
                }
              }}>
              {breadcrumb.publish && isLoading && (
                <span className="flex items-center space-x-2">
                  <span>{breadcrumb.title}</span>
                  <span className="h-4 w-4">
                    <Spinner />
                  </span>
                </span>
              )}
              {((breadcrumb.publish && !isLoading) || !breadcrumb.publish) &&
                breadcrumb.title}
            </Breadcrumbs.Crumb>
          ))}
        </Breadcrumbs>
        {children}
      </div>
    </header>
  )
}
