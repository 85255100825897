export function IconLayoutFlyer2(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 233 303" {...props}>
      <path
        fill="currentColor"
        d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
      />
      <path
        fill="currentColor"
        d="M184.74 148.73h23.59v3h-23.59zm-92.89 0h79.98v3H91.85zm0 18.41h116.48v3H91.85zm0-36.76h112.47v3H91.85zm0 72.34h23.59v3H91.85zm36.5 0h73.98v3h-73.98zm-36.5 18.41h116.48v3H91.85zm0-36.76h116.48v3H91.85zm92.89 70.84h23.59v3h-23.59zm-92.89 0h79.98v3H91.85zm0 18.41h109.47v3H91.85zm0-36.75h107.47v3H91.85zM26.44 23.75h36.83v4H26.44zm56.99 0h124.9v4H83.43zm-49.94 105.6h36.83v4H33.49zm-3.44 19.79h43.71v4H30.05zm8.48 17.52h26.75v4H38.53z"
      />
      <rect
        fill="#e1e5e9"
        x="28.3"
        y="43.39"
        width="80.27"
        height="69.6"
        rx="4.92"
        ry="4.92"
      />
      <path
        fill="currentColor"
        d="M103.64 114.49H33.22a6.43 6.43 0 0 1-6.42-6.43V48.31a6.43 6.43 0 0 1 6.42-6.42h70.42a6.44 6.44 0 0 1 6.43 6.42v59.75a6.44 6.44 0 0 1-6.43 6.43zm-70.42-69.6a3.42 3.42 0 0 0-3.42 3.42v59.75a3.43 3.43 0 0 0 3.42 3.43h70.42a3.44 3.44 0 0 0 3.43-3.43V48.31a3.43 3.43 0 0 0-3.43-3.42z"
      />
      <rect
        fill="#e1e5e9"
        x="122.47"
        y="43.39"
        width="80.27"
        height="69.6"
        rx="4.92"
        ry="4.92"
      />
      <path
        fill="currentColor"
        d="M197.81 114.49h-70.42a6.43 6.43 0 0 1-6.42-6.43V48.31a6.43 6.43 0 0 1 6.42-6.42h70.42a6.44 6.44 0 0 1 6.43 6.42v59.75a6.44 6.44 0 0 1-6.43 6.43zm-70.42-69.6a3.42 3.42 0 0 0-3.39 3.42v59.75a3.43 3.43 0 0 0 3.42 3.43h70.42a3.44 3.44 0 0 0 3.43-3.43V48.31a3.43 3.43 0 0 0-3.43-3.42z"
      />
    </svg>
  )
}
