import { Button, Modal } from '@cma/common'
import { PropsWithChildren, ReactNode, useEffect, useRef } from 'react'

type Variant = 'primary' | 'secondary' | 'danger'

interface ConfirmProps {
  isOpen: boolean
  variant?: Variant
  title?: ReactNode
  cancelText?: ReactNode
  confirmText?: ReactNode
  onCancel: () => void
  onConfirm: () => void
  onClose: () => void
}

export function Confirm({
  isOpen,
  variant = 'primary',
  title,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  children,
  onCancel,
  onConfirm,
  onClose
}: PropsWithChildren<ConfirmProps>) {
  const cancelButtonRef = useRef<HTMLButtonElement>(null)

  const onConfirmRef = useRef(onConfirm)
  useEffect(() => {
    onConfirmRef.current = onConfirm
  })

  useEffect(() => {
    if (!isOpen) return

    const handler = (e: KeyboardEvent) => {
      // Native behavior works like this:
      // 1. If you're focused on the cancel button and hit enter, confirm is true
      // 2. If you're focused on the confirm button and press enter or space bar, confirm is true
      // 3. If you manually click cancel or press space bar while cancel is focused, confirm is false
      // 4. If you press Esc, confirm is false
      if (
        e.key === 'Enter' &&
        cancelButtonRef.current === document.activeElement
      ) {
        e.preventDefault()
        onConfirmRef.current()
      }
    }

    document.addEventListener('keypress', handler)
    return () => {
      document.removeEventListener('keypress', handler)
    }
  }, [isOpen])

  return (
    <Modal hideClose size="md" isOpen={isOpen} onClose={onClose}>
      <Modal.Title>{title}</Modal.Title>
      <div className="mt-2 text-sm text-gray-500">{children}</div>
      <div className="mt-5 flex justify-end space-x-2 sm:mt-4">
        <Button variant="secondary" ref={cancelButtonRef} onClick={onCancel}>
          {cancelText}
        </Button>
        <Button variant={variant} onClick={onConfirm}>
          {confirmText}
        </Button>
      </div>
    </Modal>
  )
}
