export function ExcludeIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-2-6h24v24H-2z" />
        <path
          d="M12 4H0v2h12V4Zm0-4H0v2h12V0Zm5.484 8.413-1.415-1.415-2.828 2.829-2.828-2.829-1.415 1.415 2.829 2.828-2.829 2.828 1.415 1.415 2.828-2.829 2.828 2.829 1.415-1.415-2.829-2.828 2.829-2.828ZM0 10h8V8H0v2Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
