import { useCurrentUser } from '@cma/app'
import {
  Button,
  Checkbox,
  classNames,
  CsvData,
  CsvDropzone,
  Dropdown,
  formatNumber,
  FormField,
  Input,
  Modal,
  Radio,
  toast
} from '@cma/common'
import {
  ArrowRightLongIcon,
  ConfirmationIcon,
  ErrorIcon,
  LeadingIcon
} from '@cma/icons'
import Fuse from 'fuse.js'
import pluralize from 'pluralize'
import { useEffect, useMemo, useRef, useState } from 'react'
import * as yup from 'yup'
import { useImportAgents } from './useImportAgents'

const IMPORT_LIMIT = 250

// * We aren't using this with useForm.
// * This is still useful to typecheck our dynamic fields
const schema = yup.object({
  firstName: yup
    .string()
    .typeError('The first name field should be a string')
    .required('The first name field is required'),
  lastName: yup
    .string()
    .typeError('This last name field should be a string')
    .required('The last name field is required'),
  email: yup
    .string()
    .email('This field should be a valid email')
    .required('The email field is required'),
  photoUrl: yup.string().typeError('The photo field should be a string')
})

interface ImportAgentsProps {
  brokerId: string
  brokerName: string
  canSendInvite: boolean
  isOpen: boolean
  onClose: () => void
  onAfterSuccess?: () => void
}

export function ImportAgents({
  brokerId,
  brokerName,
  canSendInvite,
  isOpen,
  onClose,
  onAfterSuccess
}: ImportAgentsProps) {
  const [isImportingCsv, setIsImportingCsv] = useState(true)
  const [isMappingCsv, setIsMappingCsv] = useState(false)
  const [csvData, setCsvData] = useState<CsvData>()
  const { data: { currentUser } = {} } = useCurrentUser()

  /**
   * We reuse the same modal instead of using 2 different modals
   * because headless ui starts yelling at us and we can't
   * auto focus buttons going from one modal to another.
   *
   * See https://github.com/tailwindlabs/headlessui/issues/493#issuecomment-841165213
   */
  return (
    <Modal
      size="full-screen"
      isOpen={isOpen}
      onClose={onClose}
      onAfterClose={() => {
        setIsImportingCsv(true)
        setIsMappingCsv(false)
        setCsvData(undefined)
      }}>
      <Modal.Title
        agentName={currentUser?.name}
        agentAvatar={currentUser?.avatar || undefined}>
        <span className="hidden text-center leading-none sm:block">
          <span
            className="truncate text-xl font-semibold text-gray-900"
            title={
              isImportingCsv
                ? 'Import Agents'
                : isMappingCsv
                ? 'Map Fields'
                : ''
            }>
            {isImportingCsv
              ? 'Import Agents'
              : isMappingCsv
              ? 'Map Fields'
              : ''}
          </span>
          <span
            className="block truncate text-xs font-semibold uppercase text-gray-700"
            title={brokerName}>
            {brokerName}
          </span>
        </span>
      </Modal.Title>
      {isImportingCsv && (
        <div className="h-full space-y-4">
          <div className="h-full">
            <CsvDropzone
              limit={IMPORT_LIMIT}
              limitLabel="agent limit"
              information={[
                'First Name',
                'Last Name',
                'Email',
                'Profile Photo URL (optional)'
              ]}
              onDrop={(data) => {
                setCsvData(data)
                setIsImportingCsv(false)
                setIsMappingCsv(true)
              }}
            />
          </div>
        </div>
      )}
      {isMappingCsv && csvData && (
        <FieldMapper
          brokerId={brokerId}
          csvData={csvData}
          onClose={onClose}
          onAfterSuccess={onAfterSuccess}
          canSendInvite={canSendInvite}
        />
      )}
    </Modal>
  )
}

interface FieldMapperProps {
  brokerId: string
  canSendInvite: boolean
  csvData: CsvData
  onClose: () => void
  onAfterSuccess?: () => void
}

function FieldMapper({
  brokerId,
  csvData,
  onClose,
  onAfterSuccess,
  canSendInvite
}: FieldMapperProps) {
  const {
    mutate: importAgents,
    isLoading,
    isSuccess,
    error
  } = useImportAgents()
  const [sendInvitation, setSendInvitation] = useState(false)
  const [validationError, setValidationError] = useState<yup.ValidationError>()
  const [fieldMap, setFieldMap] = useState({
    firstName: '',
    lastName: '',
    email: '',
    photoUrl: ''
  })
  const agents = useMemo(() => {
    return csvData.data.map((data) => ({
      user: {
        firstname: String(data[fieldMap.firstName] ?? '').trim(),
        lastname: String(data[fieldMap.lastName] ?? '').trim(),
        email: String(data[fieldMap.email] ?? '').trim()
      },
      photoUrl: String(data[fieldMap.photoUrl] ?? '').trim()
    }))
  }, [
    fieldMap.firstName,
    fieldMap.lastName,
    fieldMap.email,
    fieldMap.photoUrl,
    csvData.data
  ])

  const hasValidationError = useMemo(() => {
    setValidationError(undefined)

    const agents = csvData.data.map((data) => ({
      firstName: String(data[fieldMap.firstName] ?? '').trim(),
      lastName: String(data[fieldMap.lastName] ?? '').trim(),
      email: String(data[fieldMap.email] ?? '').trim(),
      photoUrl: String(data[fieldMap.photoUrl] ?? '').trim()
    }))

    try {
      // Throws if an error occurs
      agents.forEach((agent) => schema.validateSync(agent))

      return false
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        setValidationError(error)
      }

      return true
    }
  }, [
    fieldMap.firstName,
    fieldMap.lastName,
    fieldMap.email,
    fieldMap.photoUrl,
    csvData.data
  ])
  const isDisabled =
    hasValidationError ||
    isSuccess ||
    [fieldMap.firstName, fieldMap.lastName, fieldMap.email].some(
      (item) => !item
    )

  return (
    <div className="mx-auto my-8 w-full max-w-xl rounded-lg bg-white p-8 shadow">
      <header className="mb-8">
        <h1 className="mb-3 text-2xl font-semibold text-black">Review</h1>
        <div className="flex items-center space-x-2 rounded-lg bg-gray-100 p-2 text-xs font-medium text-gray-700">
          <ConfirmationIcon className="h-5 w-5 text-green-500" />
          <div>{csvData.fileName}</div>
        </div>
      </header>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          importAgents(
            { brokerId, agents, sendInvitation },
            {
              onSuccess() {
                toast(
                  <div className="space-y-0.5">
                    <strong>Agent import is complete</strong>
                    <ul className="list-outside list-disc pl-6">
                      <li>{`${formatNumber(csvData.data.length)} ${pluralize(
                        'agents',
                        csvData.data.length
                      )} were imported`}</li>
                    </ul>
                  </div>,
                  {
                    variant: 'success'
                  }
                )
                onClose()
                onAfterSuccess?.()
              }
            }
          )
        }}>
        <div className="flex items-center space-x-3">
          <h4 className="w-1/2 text-xs font-semibold uppercase tracking-wider text-gray-700">
            Imported Data
          </h4>
          <div className="h-5 w-5 flex-shrink-0" aria-hidden />
          <h4 className="w-1/2 text-xs font-semibold uppercase tracking-wider text-gray-700">
            Match Fields
          </h4>
        </div>
        <div className="mt-1.5 space-y-6">
          <Field
            label="First Name"
            column="firstName"
            columnsToMatch={['first name']}
            csvData={csvData}
            onChange={(field) =>
              setFieldMap((fields) => ({ ...fields, firstName: field }))
            }
          />
          <Field
            label="Last Name"
            column="lastName"
            columnsToMatch={['last name']}
            csvData={csvData}
            onChange={(field) =>
              setFieldMap((fields) => ({ ...fields, lastName: field }))
            }
          />
          <Field
            label="Email"
            column="email"
            columnsToMatch={['email']}
            csvData={csvData}
            onChange={(field) =>
              setFieldMap((fields) => ({ ...fields, email: field }))
            }
          />
          <Field
            label="Photo URL"
            column="photoUrl"
            columnsToMatch={['photo', 'avatar']}
            optional
            csvData={csvData}
            onChange={(field) =>
              setFieldMap((fields) => ({ ...fields, photoUrl: field }))
            }
          />
        </div>
        <div className="mt-8 flex flex-col space-y-2">
          {canSendInvite && (
            <div className="pl-2">
              <Checkbox
                checked={sendInvitation}
                onChange={(e) => setSendInvitation(e.target.checked)}>
                Send invitations to agents (you can do this later)
              </Checkbox>
            </div>
          )}
          <Button
            fullWidth
            size="lg"
            variant={!error ? 'primary' : 'danger'}
            loading={isLoading}
            disabled={isDisabled}
            leftIcon={
              error ? <ErrorIcon /> : isSuccess ? <LeadingIcon /> : undefined
            }>
            {!!error && <>System error, try again</>}
            {!error && (
              <>
                {isLoading
                  ? 'Importing'
                  : isSuccess
                  ? 'Success'
                  : `Import ${formatNumber(csvData.data.length)} ${pluralize(
                      'Agents',
                      csvData.data.length
                    )}`}
              </>
            )}
          </Button>
          {error && (
            <p role="alert" aria-live="polite" className="text-sm text-red-600">
              {error.message}
            </p>
          )}
          {validationError && (
            <div
              role="alert"
              aria-live="polite"
              className="space-y-2 text-sm text-red-600">
              <div>{validationError.message}</div>
              {typeof validationError.params?.value === 'string' && (
                <code className="inline-block rounded bg-gray-100 py-0.5 px-2 text-gray-800">
                  <pre>{validationError.params.value}</pre>
                </code>
              )}
            </div>
          )}
        </div>
      </form>
    </div>
  )
}

interface FieldProps {
  label: string
  column: 'firstName' | 'lastName' | 'email' | 'photoUrl'
  columnsToMatch: string[]
  optional?: boolean
  csvData: CsvData
  onChange: (field: string) => void
}

function Field({
  label,
  column,
  columnsToMatch,
  optional,
  csvData,
  onChange
}: FieldProps) {
  const [selectedColumn, setSelectedColumn] = useState(() =>
    searchForColumn(columnsToMatch, csvData)
  )
  const [error, setError] = useState<string>()
  const previousSelectedColumnRef = useRef<typeof selectedColumn>()
  const [firstRecord] = csvData?.data || []
  const selectedColumnValue = String(firstRecord?.[selectedColumn] ?? '')

  useEffect(() => {
    if (previousSelectedColumnRef.current !== selectedColumn) {
      previousSelectedColumnRef.current = selectedColumn
      onChange(selectedColumn)
    }
  }, [selectedColumn, onChange])

  useEffect(() => {
    if (optional) return

    schema
      .validateAt(column, { [column]: selectedColumnValue })
      .then(() => setError(undefined))
      .catch((error) => setError(error.message))
  }, [column, selectedColumn, selectedColumnValue, columnsToMatch, optional])

  return (
    <div>
      <div className="flex items-start space-x-3">
        <div
          className={classNames('w-1/2', {
            'mt-6': !optional,
            'mt-1': optional
          })}>
          {optional && (
            <div aria-hidden className="mb-1 text-right text-xs text-gray-700">
              Optional
            </div>
          )}
          <FormField
            label=""
            secondaryLabel="Optional"
            aria-label={label}
            error={error}
            showErrorIcon>
            {(props) => (
              <Dropdown
                {...props}
                value={selectedColumn}
                onChange={(value) =>
                  setSelectedColumn(searchForColumn([value], csvData))
                }>
                <Dropdown.Button>{selectedColumn || 'Skip'}</Dropdown.Button>
                {optional && (
                  <Dropdown.Option value="">
                    <div className="flex items-start space-x-1">
                      <div className="mt-0.5">
                        <Radio defaultChecked={selectedColumn === ''} />
                      </div>
                      <div className="space-y-0.5">
                        <div className="text-sm text-gray-900">Skip</div>
                        <div className="text-xs text-gray-600">No Value</div>
                      </div>
                    </div>
                  </Dropdown.Option>
                )}
                {csvData?.meta.fields?.map((field) => (
                  <Dropdown.Option key={field} value={field}>
                    <div className="flex items-start space-x-1">
                      <div className="mt-0.5">
                        <Radio defaultChecked={selectedColumn === field} />
                      </div>
                      <div className="space-y-0.5">
                        <div className="text-sm text-gray-900">{field}</div>
                        <div className="text-xs text-gray-600">
                          {firstRecord?.[field] || 'No Value'}
                        </div>
                      </div>
                    </div>
                  </Dropdown.Option>
                ))}
              </Dropdown>
            )}
          </FormField>
        </div>
        <ArrowRightLongIcon
          className={classNames('mt-8 h-5 w-5 flex-shrink-0', {
            'text-gray-400': (!selectedColumnValue && !error) || !!error,
            'text-green-500':
              (optional && !error) || (selectedColumnValue && !error)
          })}
        />
        <div className="w-1/2">
          <label
            aria-hidden
            className="mb-1 text-sm font-semibold text-gray-500">
            {label}
          </label>
          <FormField label="" aria-label={label}>
            <Input value={selectedColumnValue} readOnly />
          </FormField>
        </div>
      </div>
    </div>
  )
}

function searchForColumn(columnsToMatch: string[], csvData: CsvData) {
  const fuse = new Fuse(csvData.meta?.fields || [], {
    minMatchCharLength: 2,
    threshold: 0.2
  })

  // Loops through each column and find the
  // appropriate columns to use
  let foundColumns = [
    ...new Set(
      columnsToMatch
        .map((column) => {
          const [result] = fuse.search(column)
          return result?.item
        })
        .filter(Boolean)
    )
  ]

  // If no columns are found we use a blank value to
  // use the default value of the dropdown
  if (!foundColumns.length) {
    foundColumns = ['']
  }

  return foundColumns[0]
}
