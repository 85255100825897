import { useCurrentUser } from '@cma/app'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

export function Pendo() {
  const { data: { currentUser } = {} } = useCurrentUser()

  useEffect(() => {
    // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
    // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
    // Call this function in your authentication promise handler or callback when your visitor and account id values are available
    // Please use Strings, Numbers, or Bools for value types.
    //
    // @ts-ignore window.pendo comes from the below script
    window.pendo?.initialize({
      visitor: {
        id: currentUser?.guid, // Required if user is logged in, default creates anonymous ID
        email: currentUser?.email, // Recommended if using Pendo Feedback, or NPS Email
        full_name: currentUser?.name // Recommended if using Pendo Feedback
      },
      account: {
        id: currentUser?.account?.id // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
      }
    })
  }, [currentUser])

  return (
    <Helmet
      script={[
        {
          innerHTML: `
            (function(apiKey) {
              (function(p,e,n,d,o) {
                var v,w,x,y,z; o=p[d]=p[d]||{}; o._q=o._q||[];
                v=['initialize','identify','updateOptions','pageLoad','track']; for(w=0,x=v.length;w<x;++w)(function(m) {
                  o[m]=o[m]||function() {o._q[m===v[0]? 'unshift':'push']([m].concat([].slice.call(arguments,0)));};
                })(v[w]);
                y=e.createElement(n); y.async=!0; y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
                z=e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y,z);
              })(window,document,'script','pendo');
            })('${process.env.VITE_APP_PENDO_API_KEY}');
          `
        }
      ]}
    />
  )
}
