import { Wrapper } from '@cma/common'
import { Helmet } from 'react-helmet-async'

export default function Homebeats() {
  return (
    <Wrapper>
      <Helmet>
        <title>Homebeats - Cloud CMA</title>
      </Helmet>
      <h1>Homebeats</h1>
    </Wrapper>
  )
}
