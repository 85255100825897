import { Button, NotFound, Wrapper } from '@cma/common'
import { ReportHeader, useReport } from '@cma/features/report'
import { Helmet } from 'react-helmet-async'
import { useMatch, useParams } from 'react-router-dom'

export default function PropertyDetails() {
  const { id } = useParams()
  const { data: { report } = {} } = useReport({ id: id as string })
  const isEditingMatch = useMatch('/properties/:id/edit')
  const isEditing = !!isEditingMatch

  if (isEditing && !report) {
    return (
      <Wrapper>
        <NotFound />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Helmet>
        <title>
          {isEditing ? 'Edit Details' : 'Create Details'} - Property - Cloud CMA
        </title>
      </Helmet>
      <ReportHeader
        reportId={report?.id || ''}
        title={isEditing ? 'Edit Property Report' : 'Create Property Report'}
        breadcrumbs={[
          {
            title: 'Criteria',
            url: isEditing ? `/properties/${id}/edit` : '/properties/new'
          },
          { title: 'Listings', url: `/properties/${id}`, disabled: !isEditing },
          {
            title: 'Customize',
            url: `/properties/${id}/customize`,
            disabled: !isEditing
          },
          {
            title: 'Publish',
            url: `/properties/${id}/publish`,
            disabled: !isEditing,
            publish: true
          }
        ]}>
        <Button
          onClick={() => {
            console.log(
              isEditing
                ? 'Updating listing and navigating to listings'
                : 'Fetching listings and navigating to listing'
            )
          }}>
          {isEditing ? 'Update Listings' : 'Fetch Listings'}
        </Button>
      </ReportHeader>
      <main className="space-y-6">
        <div>TODO</div>
        <div className="text-center">
          <Button size="lg">
            {isEditing ? 'Update Listings' : 'Fetch Listings'}
          </Button>
        </div>
      </main>
    </Wrapper>
  )
}
