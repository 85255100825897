import { classNames, Input, InputProps } from '@cma/common'
import { Combobox } from '@headlessui/react'
import { ForwardedRef, forwardRef, useRef, useState } from 'react'
import { useDebounce } from 'usehooks-ts'
import { Address, useAddressSearch } from './useAddressSearch'

interface AddressSearchProps extends Omit<InputProps, 'loading'> {
  guid?: string
  showMlsNum?: boolean
  defaultValue?: string
  onSelect?: (address: Address) => void
  onInputChange?: (value: string) => void
}

export const AddressSearch = forwardRef(function AddressSearch(
  {
    guid,
    showMlsNum = true,
    defaultValue,
    onSelect,
    onInputChange,
    ...props
  }: AddressSearchProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const [selected, setSelected] = useState<Address>()
  const [query, setQuery] = useState('')
  const [hasChangedDefaultValue, setHasChangedDefaultValue] = useState(false)
  const debouncedQuery = useDebounce(query, 300)

  const hasChangedRef = useRef(false)
  const { data: addresses, isFetching } = useAddressSearch(
    { address: debouncedQuery, guid },
    { enabled: hasChangedRef.current ? false : undefined }
  )

  return (
    <div className="relative">
      <Combobox
        value={selected}
        onChange={(address: Address) => {
          hasChangedRef.current = true
          setQuery(
            `${address.formattedAddress}${
              address.mlsnum && showMlsNum ? ` (${address.mlsnum})` : ''
            }`
          )
          setSelected(address)
          onSelect?.(address)
        }}>
        <Combobox.Input
          {...props}
          ref={ref}
          as={Input}
          displayValue={() =>
            query
              ? query
              : hasChangedDefaultValue
              ? query
              : defaultValue || query
          }
          onChange={(event) => {
            hasChangedRef.current = false
            setQuery(event.target.value)
            setHasChangedDefaultValue(true)
            onInputChange?.(event.target.value)
          }}
          loading={isFetching}
          autoComplete="off"
        />
        {!!addresses?.length && (
          <Combobox.Options className="absolute z-10 mt-2 max-h-52 w-full overflow-auto rounded-md bg-white py-1 shadow-lg">
            {addresses.map((address, index) => (
              <Combobox.Option
                key={(address.mlsnum || '') + index}
                value={address}
                className={({ active, selected }) =>
                  classNames('px-4 py-2 text-sm text-gray-900', {
                    'cursor-pointer bg-gray-100': active || selected
                  })
                }>
                {address.formattedAddress}
                {showMlsNum && (
                  <>
                    {address.mlsnum && ' '}
                    {address.mlsnum && (
                      <strong className="font-semibold">
                        ({address.mlsnum})
                      </strong>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </Combobox>
    </div>
  )
})
