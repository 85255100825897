import { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'

declare global {
  interface Window {
    embedded_svc: any
  }
}

export function Salesforce() {
  const hasMountedRef = useRef(false)

  useEffect(() => {
    if (hasMountedRef.current) return

    const initESW = function (gslbBaseURL: string | null) {
      window.embedded_svc.settings.displayHelpButton = true //Or false
      window.embedded_svc.settings.language = '' //For example, enter 'en' or 'en-US'

      //window.embedded_svc.settings.defaultMinimizedText = '...'; //(Defaults to Chat with an Expert)
      //window.embedded_svc.settings.disabledMinimizedText = '...'; //(Defaults to Agent Offline)

      //window.embedded_svc.settings.loadingText = ''; //(Defaults to Loading)
      //window.embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)

      // Settings for Chat
      //window.embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
      // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
      // Returns a valid button ID.
      //};
      //window.embedded_svc.settings.prepopulatedPrechatFields = {}; //Sets the auto-population of pre-chat form fields
      //window.embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
      //window.embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)

      window.embedded_svc.settings.enabledFeatures = ['LiveAgent']
      window.embedded_svc.settings.entryFeature = 'LiveAgent'

      window.embedded_svc.init(
        'https://lonewolf.my.salesforce.com',
        'https://community.lwolf.com/',
        gslbBaseURL,
        '00D1a000000aEOE',
        'Cloud_Agent_Suite_Web_Chat_Legacy',
        {
          baseLiveAgentContentURL:
            'https://c.la1-core1.sfdc-58ktaz.salesforceliveagent.com/content',
          deploymentId: '5721a000000L3N7',
          buttonId: '573N30000002D61',
          baseLiveAgentURL:
            'https://d.la1-core1.sfdc-58ktaz.salesforceliveagent.com/chat',
          eswLiveAgentDevName:
            'EmbeddedServiceLiveAgent_Parent04IN30000005VzFMAU_18dcd8fa78d',
          isOfflineSupportEnabled: true
        }
      )
    }

    if (!window.embedded_svc) {
      const s = document.createElement('script')
      s.setAttribute(
        'src',
        'https://lonewolf.my.salesforce.com/embeddedservice/5.0/esw.min.js'
      )
      s.onload = () => initESW(null)
      document.body.appendChild(s)
    } else {
      initESW('https://service.force.com')
    }
  }, [])

  useEffect(() => {
    hasMountedRef.current = true

    return () => {
      hasMountedRef.current = false
    }
  }, [])

  return (
    <Helmet>
      <style type="text/css">{`
      .embeddedServiceHelpButton .helpButton .uiButton {
        background-color: #005290 !important;
        font-family: 'Arial', sans-serif !important;
      }
      .embeddedServiceHelpButton .helpButton .uiButton:focus {
        outline: 1px solid #005290 !important;
      }
    `}</style>
    </Helmet>
  )
}
