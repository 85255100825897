import { api } from '@cma/app'
import { setAccessTokens } from '@cma/features/auth'
import {
  PartnershipUserSignupMutation,
  PartnershipUserSignupMutationVariables
} from '@cma/generated/graphql'
import {
  UseMutationOptions,
  useMutation,
  useQueryClient
} from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useSsoActivate(
  options?: Omit<
    UseMutationOptions<
      PartnershipUserSignupMutation,
      Error,
      PartnershipUserSignupMutationVariables
    >,
    'mutationFn'
  >
) {
  const queryClient = useQueryClient()
  return useMutation<
    PartnershipUserSignupMutation,
    Error,
    PartnershipUserSignupMutationVariables
  >(
    (variables) => {
      const query = gql`
        mutation PartnershipUserSignup(
          $token: String!
          $input: UserInput!
          $ouid: String!
        ) {
          partnershipUserSignup(token: $token, input: $input, ouid: $ouid) {
            redirectUrl
            user {
              casJwt
            }
            errors
          }
        }
      `
      return api<PartnershipUserSignupMutation>({ query, variables })
    },
    {
      ...options,
      async onSuccess(data, error, context) {
        const jwt = data.partnershipUserSignup?.user?.casJwt

        if (!jwt) {
          throw new Error('Error trying to log you in')
        }

        setAccessTokens(jwt)

        queryClient.setQueryData<string>(['accessToken'], jwt)
        await queryClient.invalidateQueries(['currentUser'])

        await options?.onSuccess?.(data, error, context)
      }
    }
  )
}
