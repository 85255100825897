import { classNames } from '@cma/common'
import { PropsWithChildren } from 'react'

export function SettingsCard({ children }: PropsWithChildren<unknown>) {
  return (
    <div className="space-y-12 rounded-lg bg-white p-6 shadow">{children}</div>
  )
}

SettingsCard.Title = SettingsCardTitle
SettingsCard.Group = SettingsCardGroup

interface SettingsCardTitleProps
  extends Omit<JSX.IntrinsicElements['h2'], 'className'> {
  align?: 'left' | 'center' | 'right'
  weight?: 'bold'
}

function SettingsCardTitle({
  align = 'center',
  weight,
  children,
  ...props
}: SettingsCardTitleProps) {
  return (
    <h2
      {...props}
      className={classNames('text-2xl text-gray-700', {
        'text-left': align === 'left',
        'text-center': align === 'center',
        'text-right': align === 'right',
        'font-bold': weight === 'bold'
      })}>
      {children}
    </h2>
  )
}

function SettingsCardGroup({
  children,
  ...props
}: Omit<JSX.IntrinsicElements['div'], 'className'>) {
  return (
    <div {...props} className="space-y-6">
      {children}
    </div>
  )
}
