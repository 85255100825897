// ! tinymce needs to be the first import
import tinymce, { RawEditorSettings } from 'tinymce/tinymce'
import { api } from '@cma/app'
import { useMutation } from '@tanstack/react-query'
import { Editor } from '@tinymce/tinymce-react'
import { gql } from 'graphql-request'
import 'tinymce/icons/default'
import 'tinymce/plugins/code'
import 'tinymce/plugins/image'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/media'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/themes/silver'
import './RichTextEditor.module.css'
import { formatNumber } from './utils'

// Our db limit
const MAX_CHAR_COUNT = 65536

interface RichTextEditorProps {
  settings?: RawEditorSettings & {
    selector?: undefined
    target?: undefined
  }
  value: string
  onChange: (content: string) => void
}

export function RichTextEditor({
  settings,
  value,
  onChange
}: RichTextEditorProps) {
  const { mutateAsync: uploadCustomImage } = useUploadCustomImage()

  return (
    <Editor
      value={value}
      onEditorChange={onChange}
      init={{
        plugins: 'code link image media lists charactercount',
        menubar: false,
        toolbar: [
          'fontsizeselect forecolor',
          'bold italic underline',
          'alignleft aligncenter alignright indent outdent',
          'bullist numlist',
          'link unlink image media',
          'undo redo code removeformat'
        ].join(' | '),
        branding: false,
        paste_data_images: true,
        image_title: true,
        media_poster: false,
        target_list: false,
        skin: false,
        content_css: false,
        file_picker_types: 'image',
        file_picker_callback: (cb) => {
          const input = document.createElement('input')
          input.setAttribute('type', 'file')
          input.setAttribute('accept', 'image/*')
          input.addEventListener('change', async (e) => {
            const [file] = ((e?.target as any)?.files || []) as FileList
            if (file) {
              const image = await uploadCustomImage({ image: file })
              cb(image.url, {
                title: file.name,
                width: image.width,
                height: image.height
              })
            }
          })
          input.click()
        },
        ...settings
      }}
    />
  )
}

RichTextEditor.MAX_CHAR_COUNT = MAX_CHAR_COUNT

tinymce?.PluginManager?.add('charactercount', function (editor) {
  if (MAX_CHAR_COUNT) {
    editor.on('init', () => {
      const container = document.querySelector('.tox-statusbar__text-container')

      if (container) {
        const countContainer = document.createElement('div')
        container.appendChild(countContainer)

        editor.on('SetContent BeforeAddUndo Undo Redo keyup', () => {
          const contentLength = editor.getContent().length

          countContainer.textContent = `${formatNumber(
            contentLength
          )} / ${formatNumber(MAX_CHAR_COUNT)}`

          contentLength > MAX_CHAR_COUNT
            ? countContainer.classList.add('text-red-500')
            : countContainer.classList.remove('text-red-500')
        })
      }
    })
  }

  return {
    getMetadata: function () {
      return {
        name: 'Character Count Plugin',
        url: 'https://lwolf.com'
      }
    }
  }
})

interface CustomImage {
  url: string
  width: number
  height: number
}

interface UploadCustomImageInput {
  image: File
}

function useUploadCustomImage() {
  return useMutation<CustomImage, Error, UploadCustomImageInput>(
    async ({ image }) => {
      const query = gql`
        mutation UploadCustomImage($input: UploadCustomImageInput!) {
          uploadCustomImage(input: $input) {
            image {
              url
              width
              height
            }
            errors
          }
        }
      `
      const variables = { input: { image } }
      const res = await api<{ uploadCustomImage: { image: CustomImage } }>({
        query,
        variables
      })
      return res.uploadCustomImage.image
    }
  )
}
