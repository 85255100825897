import {
  Badge,
  classNames,
  formatCurrencyShort,
  Input,
  InputProps
} from '@cma/common'
import { ListingSearchPartsFragment } from '@cma/generated/graphql'
import { SearchIcon } from '@cma/icons'
import { Combobox } from '@headlessui/react'
import { ForwardedRef, forwardRef, KeyboardEvent, useState } from 'react'
import { useDebounce } from 'usehooks-ts'
import { useListingSearch } from './useListingSearch'

interface ListingSearchProps extends Omit<InputProps, 'loading'> {
  defaultValue?: string
  onSelect?: (address: ListingSearchPartsFragment) => void
  onInputChange?: (value: string) => void
}

export const ListingSearch = forwardRef(function ListingSearch(
  { defaultValue, onSelect, onInputChange, ...props }: ListingSearchProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const [selected, setSelected] = useState<ListingSearchPartsFragment>()
  const [query, setQuery] = useState('')
  const [hasChangedDefaultValue, setHasChangedDefaultValue] = useState(false)
  const debouncedQuery = useDebounce(query, 300)

  const {
    data: listings,
    isFetching,
    isSuccess
  } = useListingSearch({
    query: debouncedQuery
  })

  return (
    <div className="relative">
      <Combobox
        value={selected}
        onChange={(listing: ListingSearchPartsFragment) => {
          setQuery('')
          setSelected(listing)
          onSelect?.(listing)
        }}>
        <Combobox.Input
          {...props}
          ref={ref}
          as={Input}
          leftIcon={<SearchIcon />}
          displayValue={() =>
            query
              ? query
              : hasChangedDefaultValue
              ? query
              : defaultValue || query
          }
          onChange={(e) => {
            setQuery(e.target.value)
            setHasChangedDefaultValue(true)
            onInputChange?.(e.target.value)
          }}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter' && !isSuccess) {
              e.preventDefault()
              e.stopPropagation()
            }
          }}
          loading={isFetching}
          autoComplete="off"
        />
        {!!listings?.length && (
          <Combobox.Options className="absolute z-10 mt-2 max-h-52 w-full overflow-auto rounded-md bg-white py-1 shadow-lg">
            {listings.map((listing) => {
              const cityStateZip = [
                listing.city,
                [listing.state, listing.zip].filter(Boolean).join(' ')
              ]
                .filter(Boolean)
                .join(', ')

              return (
                <Combobox.Option
                  key={listing.id}
                  value={listing}
                  className={({ active, selected }) =>
                    classNames('space-y-1 px-4 py-2 text-sm text-gray-900', {
                      'cursor-pointer bg-gray-100': active || selected
                    })
                  }>
                  <div className="flex items-center justify-between space-x-2">
                    <div className="flex items-baseline space-x-1.5">
                      <div
                        className="truncate text-gray-900"
                        title={listing.address || ''}>
                        {listing.address}
                      </div>

                      <Badge
                        variant={
                          listing.mappedStatus === 'active'
                            ? 'success'
                            : listing.mappedStatus === 'pending'
                            ? 'warning'
                            : listing.mappedStatus === 'closed' ||
                              listing.mappedStatus === 'deleted'
                            ? 'danger'
                            : listing.mappedStatus === 'backup'
                            ? 'info'
                            : 'default'
                        }>
                        {listing.status || 'N/A'}
                      </Badge>
                    </div>
                    <div className="text-sm text-gray-900">
                      {formatCurrencyShort(listing.price || 0)}
                    </div>
                  </div>
                  <div className="flex items-center justify-between space-x-2">
                    <div
                      className="truncate text-xs text-gray-600"
                      title={cityStateZip}>
                      {cityStateZip}
                    </div>
                    <div className="text-xs text-gray-600">
                      MLS#{listing.mlsnum}
                    </div>
                  </div>
                </Combobox.Option>
              )
            })}
          </Combobox.Options>
        )}
      </Combobox>
    </div>
  )
})
