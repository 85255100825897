// ! THIS FILE WILL BE REMOVED ONCE WE HAVE
// ! THE ENTIRE APP MOVED INTO AN SPA

import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps
} from 'react-router-dom'
import { HandoffLink } from './HandoffLink'
import { useHandoff } from './HandoffProvider'

export function Link({ children, to, ...props }: ReactRouterLinkProps) {
  const { isLegacy } = useHandoff()

  if (
    process.env.VITE_APP_ENABLE_HANDOFF === 'true' &&
    isLegacy(to.toString())
  ) {
    return (
      <HandoffLink {...props} to={to}>
        {children}
      </HandoffLink>
    )
  }

  return (
    <ReactRouterLink {...props} to={to}>
      {children}
    </ReactRouterLink>
  )
}
