import { NotFound, Pdf, Wrapper } from '@cma/common'
import {
  CustomizeDescription,
  CustomizeFont,
  CustomizeHeadline,
  CustomizeLayout,
  CustomizeTheme
} from '@cma/features/customize'
import { useUpdateFlyer } from '@cma/features/flyer'
import {
  ReportHeader,
  ReportPublishButton,
  useReport
} from '@cma/features/report'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

function useFlyer() {
  const { id } = useParams()
  return useReport({ id: id as string })
}

export default function FlyerCustomize() {
  const { data: { report } = {}, dataUpdatedAt } = useFlyer()

  if (!report) {
    return (
      <Wrapper>
        <NotFound />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Helmet>
        <title>Customize Flyer - Flyer - Cloud CMA</title>
      </Helmet>
      <ReportHeader
        reportId={report.id}
        title="Customize Flyer"
        breadcrumbs={[
          { title: 'Criteria', url: `/flyers/${report.id}/edit` },
          { title: 'Listings', url: `/flyers/${report.id}` },
          { title: 'Customize', url: `/flyers/${report.id}/customize` },
          {
            title: 'Publish',
            url: `/flyers/${report.id}/publish`,
            publish: true
          }
        ]}>
        <ReportPublishButton
          reportId={report.id}
          to={`/flyers/${report.id}/publish`}>
          Publish Report
        </ReportPublishButton>
      </ReportHeader>
      <main className="space-y-8">
        <div className="w-full overflow-hidden rounded-lg rounded-bl-lg rounded-br-lg border bg-white">
          <div className="space-y-8 p-10">
            <h2 className="text-2xl font-normal text-gray-700">Your Flyer</h2>
            <div className="grid grid-cols-2 gap-5 lg:grid-cols-3">
              <FlyerCustomizeTheme />
              <FlyerCustomizeLayout />
              <FlyerCustomizeFont />
              <FlyerCustomizeHeadline />
              <FlyerCustomizeDescription />
            </div>
          </div>
          <div className="relative flex  h-full bg-gray-100 p-1 sm:p-8">
            <div className="mx-auto flex aspect-[8.5/7.8] flex-grow items-center justify-center">
              <Pdf
                key={dataUpdatedAt}
                url={`${process.env.VITE_APP_CMA_URL}/flyers/${report.id}/preview.pdf`}
                loadingText="Loading Preview"
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <ReportPublishButton
            size="lg"
            reportId={report.id}
            to={`/flyers/${report.id}/publish`}>
            Publish Report
          </ReportPublishButton>
        </div>
      </main>
    </Wrapper>
  )
}

function FlyerCustomizeFont() {
  const { data: { report } = {} } = useFlyer()
  const { mutate: updateFlyer, isLoading, error } = useUpdateFlyer()

  if (!report) return null

  return (
    <CustomizeFont
      font={report.font || undefined}
      fonts={report.fonts || undefined}
      loading={isLoading}
      error={error?.message}
      onUpdate={(option) =>
        updateFlyer({
          id: report.id,
          input: {
            font: option.key === 'default' ? null : option.key
          }
        })
      }
    />
  )
}

function FlyerCustomizeTheme() {
  const { data: { report } = {} } = useFlyer()
  const { mutate: updateFlyer, isLoading, error, reset } = useUpdateFlyer()

  if (!report) return null

  return (
    <CustomizeTheme
      theme={report.theme || undefined}
      themes={report.themes || undefined}
      loading={isLoading}
      reset={reset}
      error={error?.message}
      onUpdate={(themeKey) =>
        updateFlyer({
          id: report.id,
          input: {
            theme: themeKey
          }
        })
      }
    />
  )
}

function FlyerCustomizeLayout() {
  const { data: { report } = {} } = useFlyer()
  const { mutate: updateFlyer, isLoading, error } = useUpdateFlyer()
  if (!report) return null

  return (
    <CustomizeLayout
      isModern={report.isModern ?? false}
      layout={report.layout || undefined}
      layouts={report.layouts || undefined}
      loading={isLoading}
      error={error?.message}
      onUpdate={(option) =>
        updateFlyer({
          id: report.id,
          input: {
            layout: option.key
          }
        })
      }
    />
  )
}

function FlyerCustomizeHeadline() {
  const { data: { report } = {} } = useFlyer()
  const { mutate: updateFlyer, isLoading, error } = useUpdateFlyer()

  if (!report || report.__typename !== 'FlyerReport') return null

  return (
    <CustomizeHeadline
      headline={report.headline || undefined}
      headlines={report.headlines || undefined}
      modern={report.isModern}
      loading={isLoading}
      error={error?.message}
      onUpdate={(option) =>
        updateFlyer({
          id: report.id,
          input: {
            headline: option.key
          }
        })
      }
    />
  )
}

function FlyerCustomizeDescription() {
  const { data: { report } = {} } = useFlyer()
  const { mutateAsync: updateFlyer, isLoading, error } = useUpdateFlyer()

  if (!report) return null

  return (
    <CustomizeDescription
      description={report.description}
      loading={isLoading}
      error={error?.message}
      onUpdate={async (description) => {
        // We use async here because we want the modal to stay open until the operation is complete
        await updateFlyer({ id: report.id, input: { description } })
      }}
    />
  )
}
