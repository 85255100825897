export function ShareIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 21"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m10 .94 3.53 3.53a.75.75 0 0 1-1.06 1.06l-1.72-1.72v8.64a.75.75 0 0 1-1.5 0V3.81L7.53 5.53a.75.75 0 0 1-1.06-1.06L10 .94ZM3.25 8A.75.75 0 0 1 4 7.25h3a.75.75 0 0 1 0 1.5H4.75v9.5h10.5v-9.5H13a.75.75 0 0 1 0-1.5h3a.75.75 0 0 1 .75.75v11a.75.75 0 0 1-.75.75H4a.75.75 0 0 1-.75-.75V8Z"
        fill="currentColor"
      />
    </svg>
  )
}
