import { MinusIcon, PlusIcon } from '@heroicons/react/outline'
import { LocationMarkerIcon } from '@heroicons/react/solid'
import { LngLatBounds, LngLatBoundsLike } from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { PropsWithChildren, useEffect, useState } from 'react'
import ReactMapGL, { MapProvider, Marker, Popup, useMap } from 'react-map-gl'
import styles from './Map.module.css'
import { classNames, formatCurrency, formatCurrencyShort } from './utils'
import useDeepCompareEffect from 'use-deep-compare-effect'

interface MapProps {
  center?: { lat: number; lng: number }
  bounds?: LngLatBoundsLike
  boundPadding?: number
  onClick?: () => void
}

export function Map(props: PropsWithChildren<MapProps>) {
  return (
    <MapProvider>
      <MapInner {...props} />
    </MapProvider>
  )
}

// Used so we can access the map provider
function MapInner({
  center = { lat: 37.0902, lng: -95.7129 },
  bounds,
  boundPadding = 20,
  children,
  onClick
}: PropsWithChildren<MapProps>) {
  const { map } = useMap()
  const [error, setError] = useState<string>()

  useDeepCompareEffect(() => {
    if (bounds) {
      map?.fitBounds(bounds, { padding: boundPadding })
    }
    // {} is a small hack for useDeepCompareEffect
    // because it sees bounds and map as primitive and won't run
  }, [bounds || {}, map || {}, boundPadding || {}])

  return (
    <div className={`${styles.container} space-y-1`}>
      <ReactMapGL
        id="map"
        doubleClickZoom={false}
        scrollZoom={false}
        style={{ width: 400, height: 400 }}
        initialViewState={{
          latitude: center.lat,
          longitude: center.lng,
          pitch: 50,
          zoom: 18,
          bounds,
          fitBoundsOptions: {
            padding: boundPadding
          }
        }}
        attributionControl={false}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxAccessToken={process.env.VITE_APP_MAPBOX_TOKEN}
        onClick={onClick}
        onError={(error) => setError(error.error.message)}>
        {children}
      </ReactMapGL>
      {error && (
        <p role="alert" aria-live="polite" className="text-sm text-red-600">
          {error}
        </p>
      )}
    </div>
  )
}

Map.Pin = MapPin
Map.Listing = MapListing
Map.Popup = MapPopup
Map.Zoom = MapZoom
Map.fitBounds = fitBounds

interface MapPinProps {
  lat: number
  lon: number
  size?: 'sm' | 'md' | 'lg' | 'xl'
}

function MapPin({ lat, lon, size = 'md' }: MapPinProps) {
  return (
    <Marker latitude={lat || -1} longitude={lon || -1} offset={[0, -17]}>
      <LocationMarkerIcon
        style={{ filter: 'drop-shadow(0 1px 1px rgba(0, 0, 0, 0.5))' }}
        className={classNames('text-green-500', {
          'h-4 w-4': size === 'sm',
          'h-5 w-5': size === 'md',
          'h-8 w-8': size === 'lg',
          'h-10 w-10': size === 'xl'
        })}
      />
    </Marker>
  )
}

interface MapListingProps {
  lat: number
  lon: number
  price: number
  hide?: boolean
  statusColor?: string
  mappedStatus?: string
  onClick?: () => void
}

function MapListing({
  lat,
  lon,
  price,
  hide,
  statusColor,
  mappedStatus,
  onClick
}: MapListingProps) {
  const { map } = useMap()

  return (
    <Marker
      latitude={lat || -1}
      longitude={lon || -1}
      offset={[0, -11]}
      style={{ zIndex: hide ? 0 : 1 }}>
      <button
        style={
          !hide
            ? { backgroundColor: statusColor, color: statusColor || 'darkgray' }
            : {}
        }
        className={classNames(
          'relative rounded border-2 border-white px-1 text-sm font-medium text-white [text-shadow:0.08rem_0.08rem_0_rgba(0,0,0,0.2)]',
          'before:absolute before:left-1/2 before:-bottom-0.5 before:block before:h-2 before:w-2 before:-translate-x-1/2 before:translate-y-1/2 before:rotate-45 before:rounded-br-sm before:bg-white',
          'after:absolute after:left-1/2 after:bottom-0 after:block after:h-2 after:w-2 after:-translate-x-1/2 after:translate-y-1/2 after:rotate-45 after:rounded-br-sm after:bg-current',
          {
            'bg-slate-400 text-slate-400 opacity-60': !!hide,

            '!bg-green-600 !text-green-600':
              mappedStatus === 'active' && !statusColor,
            '!bg-light-blue-600 !text-light-blue-600':
              mappedStatus === 'backup' && !statusColor,
            '!bg-red-600 !text-red-600':
              (mappedStatus === 'closed' || mappedStatus === 'deleted') &&
              !statusColor,
            '!bg-yellow-600 !text-yellow-600':
              mappedStatus === 'pending' && !statusColor
          }
        )}
        onClick={(e) => {
          e.stopPropagation()
          map?.easeTo({ center: [lon, lat] })
          onClick?.()
        }}>
        <span
          style={!hide ? { backgroundColor: statusColor || 'darkgray' } : {}}
          className={classNames('relative z-10 text-white', {
            'bg-slate-400': !!hide,

            '!bg-green-600': mappedStatus === 'active' && !statusColor,
            '!bg-light-blue-600': mappedStatus === 'backup' && !statusColor,
            '!bg-red-600':
              (mappedStatus === 'closed' || mappedStatus === 'deleted') &&
              !statusColor,
            '!bg-yellow-600': mappedStatus === 'pending' && !statusColor
          })}>
          {formatCurrencyShort(price)}
        </span>
      </button>
    </Marker>
  )
}

interface MapPopup {
  lat: number
  lon: number
  address: string
  city: string
  state: string
  zip: string
  photo: string
  price: number
  status: string
  statusColor: string
}

function MapPopup({
  lat,
  lon,
  address,
  city,
  state,
  zip,
  photo,
  price,
  status,
  statusColor,
  children
}: PropsWithChildren<MapPopup>) {
  const cityStateZip = [[city, state].filter(Boolean).join(' '), zip]
    .filter(Boolean)
    .join(', ')

  return (
    <Popup
      latitude={lat || -1}
      longitude={lon || -1}
      anchor="bottom"
      closeOnClick={false}
      closeButton={undefined}
      className="z-20">
      <div className="relative w-48 rounded bg-white shadow after:absolute after:-bottom-1 after:left-1/2 after:z-0 after:h-2.5 after:w-2.5 after:-translate-x-1/2 after:rotate-45 after:rounded-br-sm after:bg-white after:shadow">
        <div
          className="flex h-24 w-full items-end justify-end rounded-t bg-gray-200 bg-cover bg-center"
          style={{ backgroundImage: `url('${photo}')` }}>
          <div className="bg-black/50 px-1 text-white">
            {formatCurrency(price)}
          </div>
          {children}
        </div>
        <div className="relative z-10 rounded-b bg-white p-1.5 text-xs">
          <div
            className="truncate font-medium text-gray-900"
            title={address || ''}>
            {address}
          </div>
          <div className="flex space-x-2">
            <div className="truncate text-gray-500" title={cityStateZip}>
              {cityStateZip}
            </div>
            <div
              title={status}
              className="truncate"
              style={{ color: statusColor }}>
              {status}
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

function MapZoom() {
  const { map } = useMap()
  const minZoom = map?.getMinZoom() || 0
  const maxZoom = map?.getMaxZoom() || 0
  const currentZoom = map?.getZoom() || 0
  const [, forceUpdate] = useState({})

  useEffect(() => {
    map?.on('zoom', forceUpdate)
    return () => {
      map?.off('zoom', forceUpdate)
    }
  }, [map])

  return (
    <div className="absolute bottom-0 right-0 m-4 divide-y overflow-hidden rounded bg-white shadow">
      <button
        aria-label="Zoom In"
        className={classNames('block p-2', {
          'text-gray-500 hover:bg-gray-100': currentZoom < maxZoom,
          'cursor-not-allowed text-gray-300': currentZoom >= maxZoom
        })}
        disabled={currentZoom >= maxZoom}
        onClick={() => map?.zoomIn()}>
        <PlusIcon className="h-6 w-6" />
      </button>
      <button
        aria-label="Zoom Out"
        className={classNames('block p-2', {
          'text-gray-500 hover:bg-gray-100': currentZoom > minZoom,
          'cursor-not-allowed text-gray-300': currentZoom <= minZoom
        })}
        disabled={currentZoom <= minZoom}
        onClick={() => map?.zoomOut()}>
        <MinusIcon className="h-6 w-6" />
      </button>
    </div>
  )
}

function fitBounds(latLngs: [lng: number, lat: number][]) {
  const bounds = new LngLatBounds()
  latLngs.forEach((latLng) => {
    bounds.extend(latLng)
  })
  return bounds
}
