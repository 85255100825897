import { Avatar } from '@cma/common'

interface ListingRemarksProps {
  remarks: string
  isPrivate?: boolean
  agentName?: string | null
  agentCompany?: string | null
  agentEmail?: string | null
  agentPhone?: string | null
  agentAvatar?: string | null
}

export function ListingRemarks({
  remarks,
  isPrivate,
  agentName,
  agentCompany,
  agentEmail,
  agentPhone,
  agentAvatar
}: ListingRemarksProps) {
  if (isPrivate) {
    return (
      <div className="space-y-4 rounded-2xl bg-gray-200 p-4 [&_a]:text-blue-500">
        <p
          className="break-words text-sm text-gray-900"
          dangerouslySetInnerHTML={{
            __html: registerPhoneNumbers(registerEmails(remarks))
          }}
        />
        <div className="flex flex-col justify-between gap-3 sm:flex-row sm:items-end">
          <div className="flex items-center gap-3">
            <Avatar size="lg" name={agentName} src={agentAvatar} />
            <div className="text-sm">
              <div>{agentName}</div>
              <div>{agentCompany}</div>
              <div className="flex gap-2">
                <a href={`mailto:${agentEmail}`}>{agentEmail}</a>
                {!!agentPhone && !!agentEmail && (
                  <div className="text-xl leading-none text-gray-400">
                    &bull;
                  </div>
                )}
                <a href={`tel:${agentPhone}`}>{agentPhone}</a>
              </div>
            </div>
          </div>
          <em className="ml-auto text-xs text-gray-700">Agents Only</em>
        </div>
      </div>
    )
  }

  return (
    <div className="flex gap-3 bg-yellow-100 p-6 shadow-raised">
      <Avatar size="lg" name={agentName} src={agentAvatar} />
      <div className="space-y-2">
        <div className="font-semibold text-gray-900">
          Remarks from {agentName?.split(' ')?.[0]}
        </div>
        <div className="break-words text-sm text-gray-900">{remarks}</div>
      </div>
    </div>
  )
}

function registerPhoneNumbers(string = '') {
  if (!string) return string
  return string.replace(/(\d\d\d-\d\d\d-\d\d\d\d)/g, '<a href="tel:$1">$1</a>')
}

function registerEmails(string = '') {
  if (!string) return string
  return string.replace(
    /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi,
    '<a href="mailto:$1">$1</a>'
  )
}
