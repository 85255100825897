import {
  Alert,
  Button,
  classNames,
  Flash,
  Input,
  Label,
  Modal,
  Pdf,
  Spinner,
  Tooltip
} from '@cma/common'
import { getAccessTokens } from '@cma/features/auth'
import {
  CustomPageEditor,
  CustomPdfs,
  isTemplateKey,
  SettingsCard,
  useCreateCustomPage,
  useCustomPages,
  useDestroyCustomPage
} from '@cma/features/settings'
import { CustomPagePartsFragment } from '@cma/generated/graphql'
import { EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { Dispatch, SetStateAction, Suspense, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

const schema = yup.object({
  pageTitle: yup.string().required('Page Title is required')
})

function CustomPages() {
  const { register, handleSubmit, formState, reset } = useForm<
    yup.InferType<typeof schema>
  >({
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const { isValid } = formState
  const [showEditor, setShowEditor] = useState(false)
  const [editKey, setEditKey] = useState('')

  const { data: customPagesQuery } = useCustomPages()
  const customPages = customPagesQuery?.currentUser?.customPages

  const {
    mutate: createCustomPage,
    isLoading: isCreatingCustomPage,
    error: createCustomPageError
  } = useCreateCustomPage()

  return (
    <>
      <SettingsCard>
        {createCustomPageError?.message && (
          <Flash variant="error">{createCustomPageError.message}</Flash>
        )}
        <SettingsCard.Group>
          <SettingsCard.Title>Custom Pages</SettingsCard.Title>
          <p className="text-center text-sm text-gray-500">
            Edit, update, and delete your custom pages and custom pdf pages.
          </p>
        </SettingsCard.Group>
        <SettingsCard.Group>
          <SettingsCard.Title>Add New Custom Page</SettingsCard.Title>
          <form
            onSubmit={handleSubmit((data) =>
              createCustomPage(
                {
                  input: {
                    key: data.pageTitle
                  }
                },
                {
                  onSuccess: (data) => {
                    const key = data.createCustomPage?.customPage?.key ?? null
                    reset()
                    if (key !== null) {
                      setEditKey(key)
                    }
                    setShowEditor(true)
                  }
                }
              )
            )}>
            <Label htmlFor="page-title-input">Page Title</Label>
            <div className="flex w-full flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-2">
              <Input
                id="page-title-input"
                fullWidth
                {...register('pageTitle')}
              />
              <Button disabled={!isValid} loading={isCreatingCustomPage}>
                {isCreatingCustomPage ? 'Adding' : 'Add'}
              </Button>
            </div>
          </form>
        </SettingsCard.Group>
        <SettingsCard.Group>
          <SettingsCard.Title>Custom Pages List</SettingsCard.Title>
          <div>
            {customPages?.map((page) => (
              <CustomPage
                key={page.key}
                page={page}
                setEditKey={setEditKey}
                setShowEditor={setShowEditor}
              />
            ))}
          </div>
        </SettingsCard.Group>
        <SettingsCard.Group>
          <SettingsCard.Title>Custom PDF Pages</SettingsCard.Title>
          <CustomPdfs />
        </SettingsCard.Group>
      </SettingsCard>

      <CustomPageEditor
        isOpen={showEditor}
        initialPageKey={editKey}
        onClose={() => {
          setShowEditor(false)
          setEditKey('')
        }}
      />
    </>
  )
}

interface CustomPageProps {
  page: CustomPagePartsFragment
  setEditKey: Dispatch<SetStateAction<string>>
  setShowEditor: Dispatch<SetStateAction<boolean>>
}

function CustomPage({ page, setEditKey, setShowEditor }: CustomPageProps) {
  const accessTokens = getAccessTokens()
  const [showPreview, setShowPreview] = useState(false)
  const [showDestroy, setShowDestroy] = useState(false)
  const [pdfLoaded, setPdfLoaded] = useState(false)
  const [pdfError, setPdfError] = useState<Error>()
  const { mutate: destroy, isLoading, error } = useDestroyCustomPage()

  const pageId = page.id ?? null
  const pageKey = page.key ?? null

  function handleDelete() {
    if (pageId !== null) {
      destroy({ id: pageId })
    }
  }

  return (
    <>
      <div
        className="flex justify-between border-t px-2 py-1 text-gray-500"
        data-testid="custom-page">
        <div>
          {page.title}
          {pageKey !== null && isTemplateKey(pageKey) && (
            <span className="ml-1 inline-block rounded bg-purple-100 py-0.5 px-1 text-xs italic text-purple-500">
              Template
            </span>
          )}
        </div>
        <div className="flex items-center space-x-2">
          <button
            aria-label="Edit"
            onClick={() => {
              if (pageKey !== null) {
                setEditKey(pageKey)
              }
              setShowEditor(true)
            }}>
            <PencilIcon className="h-full w-5 cursor-pointer" />
          </button>
          <Tooltip
            disabled={pageId !== null}
            content="You must edit a custom page to preview">
            <div className="flex">
              <button
                aria-label="Preview"
                disabled={pageId === null}
                onClick={() => setShowPreview(true)}>
                <EyeIcon
                  className={classNames('h-full w-5', {
                    'cursor-not-allowed opacity-50': pageId === null,
                    'cursor-pointer': pageId !== null
                  })}
                />
              </button>
            </div>
          </Tooltip>
          <Tooltip
            disabled={!page.isDefault}
            content="You cannot delete default pages">
            <div className="flex">
              <button
                aria-label="Delete"
                disabled={page?.isDefault || false}
                onClick={() => setShowDestroy(true)}>
                <TrashIcon
                  className={classNames('h-full w-5', {
                    'cursor-not-allowed opacity-50': page.isDefault,
                    'cursor-pointer': !page.isDefault
                  })}
                />
              </button>
            </div>
          </Tooltip>
        </div>
      </div>

      {pageId !== null && (
        <Alert variant="danger" isOpen={showDestroy}>
          <Alert.Title>Delete Report</Alert.Title>
          <Alert.Content>
            <div className="space-y-2">
              <div>
                Are you sure you want to delete this report (
                <strong className="font-medium text-gray-700">
                  {page.title}
                </strong>
                )? This action cannot be undone.
              </div>
              {error && (
                <div className="text-red-500" role="alert" aria-live="polite">
                  {error.message}
                </div>
              )}
            </div>
          </Alert.Content>
          <Alert.Cancel onClick={() => setShowDestroy(false)}>
            Cancel
          </Alert.Cancel>
          <Alert.Confirm loading={isLoading} onClick={handleDelete}>
            {isLoading ? 'Deleting' : 'Delete Forever'}
          </Alert.Confirm>
        </Alert>
      )}
      <Modal
        size={!pdfLoaded || !!pdfError ? '4xl' : 'max'}
        fullHeight
        isOpen={showPreview}
        onClose={() => {
          setShowPreview(false)
          setPdfLoaded(false)
          setPdfError(undefined)
        }}>
        <Modal.Title>{page.title} PDF Preview</Modal.Title>
        <div
          className={classNames(
            '-mx-6 -mb-6 mt-4 flex h-full items-center justify-center p-6',
            {
              'bg-gray-600': pdfLoaded
            }
          )}>
          <Pdf
            url={`${process.env.VITE_APP_CMA_URL}/api/cma_app/preview/${pageId}.pdf?jwt=${accessTokens?.currentToken}`}
            onLoad={() => setPdfLoaded(true)}
            onError={setPdfError}
          />
        </div>
      </Modal>
    </>
  )
}

export default function CustomPagesProvider() {
  return (
    <>
      <Helmet>
        <title>Custom Pages - Account Settings - Cloud CMA</title>
      </Helmet>
      <Suspense
        fallback={
          <SettingsCard>
            <SettingsCard.Group>
              <SettingsCard.Title>Custom Pages</SettingsCard.Title>
              <div className="flex items-center justify-center space-x-2 text-sm text-gray-500">
                <div className="h-4 w-4">
                  <Spinner />
                </div>
                <div>Loading Custom Pages</div>
              </div>
            </SettingsCard.Group>
          </SettingsCard>
        }>
        <CustomPages />
      </Suspense>
    </>
  )
}
