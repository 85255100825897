import { api } from '@cma/app'
import {
  BrokerAgentExistenceQuery,
  BrokerAgentExistenceQueryVariables
} from '@cma/generated/graphql'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useBrokerAgentExistence({
  email
}: BrokerAgentExistenceQueryVariables) {
  return useQuery<BrokerAgentExistenceQuery, Error>(
    ['brokerAgentExistence', email],
    () => {
      const query = gql`
        query BrokerAgentExistence($email: String) {
          user(email: $email) {
            id
            isAdmin
          }
        }
      `
      const variables = { email }
      return api<BrokerAgentExistenceQuery>({ query, variables })
    },
    {
      suspense: false,
      enabled: !!email,
      staleTime: 0
    }
  )
}
