import { NotFound, Wrapper } from '@cma/common'
import { ReportHeader, useReport } from '@cma/features/report'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

export default function DocumentPublish() {
  const { id } = useParams()
  const { data: { report } = {} } = useReport({ id: id as string })

  if (!report) {
    return (
      <Wrapper>
        <NotFound />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Helmet>
        <title>Publish Report - Document - Cloud CMA</title>
      </Helmet>
      <ReportHeader
        reportId={report.id}
        title="Publish Report"
        breadcrumbs={[
          { title: 'Criteria', url: `/documents/${report.id}/edit` },
          { title: 'Listings', url: `/documents/${report.id}` },
          { title: 'Customize', url: `/documents/${report.id}/customize` },
          {
            title: 'Publish',
            url: `/documents/${report.id}/publish`,
            publish: true
          }
        ]}
      />
      <main>TODO</main>
    </Wrapper>
  )
}
