export function CompanyLogoIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.1662 7.6982C50.605 8.09892 50.855 8.66573 50.855 9.25997V48.74C50.855 49.908 49.9081 50.855 48.74 50.855H9.26002C8.09194 50.855 7.14502 49.908 7.14502 48.74V12.8491C7.14502 11.7552 7.97914 10.8418 9.06854 10.7427L48.5485 7.15365C49.1403 7.09985 49.7275 7.29749 50.1662 7.6982ZM11.375 14.7805V46.625H22.655V34.64C22.655 33.4719 23.6019 32.525 24.77 32.525H33.23C34.3981 32.525 35.345 33.4719 35.345 34.64V46.625H46.625V11.576L11.375 14.7805ZM31.115 46.625V36.755H26.885V46.625H31.115ZM22.655 23.36C22.655 22.1919 23.6019 21.245 24.77 21.245H33.23C34.3981 21.245 35.345 22.1919 35.345 23.36C35.345 24.528 34.3981 25.475 33.23 25.475H24.77C23.6019 25.475 22.655 24.528 22.655 23.36Z"
        fill="currentColor"
      />
    </svg>
  )
}
