export function ConfirmationIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.592-10.709a.994.994 0 0 0-1.405-.001l-3.675 3.663-.814-.814a.995.995 0 0 0-1.409 1.404l1.358 1.367a1.004 1.004 0 0 0 .795.469.996.996 0 0 0 .943-.475l4.207-4.207a.994.994 0 0 0 0-1.406Z"
        fill="currentColor"
      />
    </svg>
  )
}
