import { Modal } from '@cma/common'
import { DocumentDuplicateIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { CustomizeButton } from './CustomizeButton'

export function CustomizeCover() {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <CustomizeButton
        icon={<DocumentDuplicateIcon />}
        title="Cover"
        subtitle="No Cover"
        onClick={() => setShowModal(true)}
      />
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <Modal.Title>Cover</Modal.Title>
        <div className="mt-2">This is the modal content</div>
      </Modal>
    </>
  )
}
