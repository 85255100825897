import { useCurrentUser } from '@cma/app'
import {
  Button,
  Flash,
  FormField,
  Input,
  Select,
  Spinner,
  statesByName,
  statesByValue
} from '@cma/common'
import {
  SettingsCard,
  useMlses,
  useMlsesByState,
  useUpdateMlsCredential
} from '@cma/features/settings'
import { useTranslations } from '@cma/features/translations'
import { yupResolver } from '@hookform/resolvers/yup'
import { Suspense, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import MlsAlternateCredentials from './MlsAlternateCredentials'

const schema = yup.object({
  code: yup.string().required('Your MLS Provider is required'),
  name: yup.string().required('Your MLSID is required'),
  password: yup.string()
})

function MlsCredentials() {
  const { data: { currentUser } = {} } = useCurrentUser()
  const { data: { translations } = {} } = useTranslations()
  const { data: { mlses } = {} } = useMlses()
  const {
    mutate: updateMlsCredential,
    isLoading,
    isSuccess,
    error
  } = useUpdateMlsCredential()

  const currentMls = mlses?.find(
    (mls) => mls.key === currentUser?.mlsCredential?.code
  )

  const [state, setState] = useState(
    statesByName[currentMls?.states?.[0] || 'AL']?.name ||
      statesByValue[currentUser?.state || 'AL']?.name ||
      ''
  )

  const { mlsesByStates, mlsesByState } = useMlsesByState(mlses ?? [], state)

  // If the user changes their state under profile and then goes to mls credentials
  // we want to make sure the mls provider is reset so we check if that state
  // includes the mls. If it doesn't, reset the mls provider. (see line 59)
  const doesStateIncludeMls = mlsesByState.some(
    (mls) => mls.key === currentUser?.mlsCredential?.code
  )

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      code: doesStateIncludeMls ? currentUser?.mlsCredential?.code ?? '' : '',
      name: currentUser?.mlsCredential?.name ?? '',
      password: currentUser?.mlsCredential?.password ?? ''
    }
  })
  const selectedMls = mlsesByStates[state]?.find(
    (mls) => mls.key === watch('code')
  )

  // Translations
  const mlsLabel = translations?.mls?.label || 'MLS'

  return (
    <>
      <Helmet>
        <title>MLS Credentials - Account Settings - Cloud CMA</title>
      </Helmet>
      <SettingsCard>
        <SettingsCard.Group>
          <SettingsCard.Title weight="bold">Primary MLS</SettingsCard.Title>
          {!currentUser?.features?.canEditMls && (
            <Flash variant="note" closeable={false}>
              Because you are accessing Cloud CMA using Single Sign-On, these
              settings cannot be changed.
            </Flash>
          )}
          {currentUser?.features?.canEditMls && (
            <>
              {selectedMls?.instructions && (
                <Flash variant="note">
                  <strong>Special instructions:</strong>{' '}
                  {selectedMls.instructions}
                </Flash>
              )}
              <form
                className="space-y-4"
                onSubmit={handleSubmit((data) =>
                  updateMlsCredential({ input: data })
                )}>
                <FormField label="State / Province">
                  <Select
                    value={state}
                    onChange={(e) => {
                      setState(e.target.value)
                      setValue('code', '')
                    }}>
                    <option value="" disabled>
                      Select an Option
                    </option>
                    {Object.keys(mlsesByStates)
                      .sort()
                      .map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                  </Select>
                </FormField>
                <FormField
                  label={`${mlsLabel} Provider`}
                  error={errors.code?.message}
                  required>
                  <Select {...register('code')}>
                    <option value="" disabled>
                      Select an {mlsLabel}
                    </option>
                    {mlsesByState
                      .sort((a, b) =>
                        (a.title ?? '').localeCompare(b.title ?? '')
                      )
                      .map((mls) => (
                        <option key={mls.key} value={mls.key ?? ''}>
                          {mls.title}
                        </option>
                      ))}
                  </Select>
                </FormField>
                <FormField
                  label={`${mlsLabel} User ID`}
                  error={errors.name?.message}
                  required>
                  <Input {...register('name')} />
                </FormField>
                <FormField
                  label={`${mlsLabel} Password or Pin`}
                  error={errors.password?.message}>
                  <Input type="password" {...register('password')} />
                </FormField>
                <Button fullWidth loading={isLoading}>
                  Update {mlsLabel} Credentials
                </Button>
                {error && <Flash variant="error">{error?.message}</Flash>}
                {isSuccess && (
                  <Flash variant="success">
                    {mlsLabel} Credentials Updated!
                  </Flash>
                )}
                <div>
                  <p className="text-xs italic text-gray-400">
                    Your {mlsLabel} credentials are both stored and transmitted
                    using RSA public key encryption.
                  </p>
                </div>
              </form>
            </>
          )}
        </SettingsCard.Group>

        <SettingsCard.Group>
          <SettingsCard.Title weight="bold">Alternate MLS</SettingsCard.Title>
          <p className="text-sm font-medium text-gray-500">
            When searching by MLS Numbers, we will use your Alternate MLS to
            search for listings not found in your Primary MLS. You can add more
            than one and we will search across them all.
          </p>
          <MlsAlternateCredentials />
        </SettingsCard.Group>
      </SettingsCard>
    </>
  )
}

export default function MlsCredentialsProvider() {
  return (
    <>
      <Helmet>
        <title>MLS Credentials - Account Settings - Cloud CMA</title>
      </Helmet>
      <Suspense
        fallback={
          <SettingsCard>
            <SettingsCard.Group>
              <SettingsCard.Title>Primary MLS</SettingsCard.Title>
              <div className="flex items-center justify-center space-x-2 text-sm text-gray-500">
                <div className="h-4 w-4">
                  <Spinner />
                </div>
                <div>Loading MLS Credentials</div>
              </div>
            </SettingsCard.Group>
          </SettingsCard>
        }>
        <MlsCredentials />
      </Suspense>
    </>
  )
}
