import { Alert, Button, Flash } from '@cma/common'
import { CustomPdfPartsFragment } from '@cma/generated/graphql'
import { TrashIcon } from '@heroicons/react/solid'
import { ChangeEvent, useState } from 'react'
import { useCreateCustomPdf } from './useCreateCustomPdf'
import { useCustomPdfs } from './useCustomPdfs'
import { useDestroyCustomPdf } from './useDestroyCustomPdf'

export function CustomPdfs() {
  const { data: customPdfsQuery } = useCustomPdfs()
  const customPdfs = customPdfsQuery?.currentUser?.customPdfs
  const { mutate: createCustomPdf, isLoading, error } = useCreateCustomPdf()

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      const [file] = e.target.files
      if (file) {
        createCustomPdf({ input: { page: file } })
      }
    }
  }

  return (
    <>
      {error && <Flash variant="error">{error.message}</Flash>}
      <div className="flex flex-wrap justify-center">
        {customPdfs?.map((pdf) => (
          <CustomPdf pdf={pdf} key={pdf.id} />
        ))}
      </div>
      <div className="mb-4 text-center">
        <i className="text-xs text-gray-500">
          Use the button below to upload PDF files.
        </i>
      </div>
      <div className="text-center">
        <input
          id="custom-pdf-input"
          type="file"
          accept="application/pdf"
          className="hidden"
          onChange={handleChange}
        />
        <Button as="label" htmlFor="custom-pdf-input" loading={isLoading}>
          {isLoading ? 'Uploading' : 'Upload PDF'}
        </Button>
      </div>
    </>
  )
}

interface CustomPdfProps {
  pdf: CustomPdfPartsFragment
}

function CustomPdf({ pdf }: CustomPdfProps) {
  const [isDeleting, setIsDeleting] = useState(false)
  const { mutate: destroyCustomPdf, isLoading, error } = useDestroyCustomPdf()
  const pdfId = pdf.id ?? null

  function handleDelete() {
    if (pdfId !== null) {
      destroyCustomPdf(
        { id: pdfId },
        {
          onSuccess: () => {
            setIsDeleting(false)
          }
        }
      )
    }
  }

  return (
    <div className="mb-3 w-1/2 p-2" data-testid="custom-pdf">
      <a
        href={pdf.url || ''}
        aria-label="View PDF"
        target="_blank"
        rel="noreferrer noopener">
        <img
          src={pdf.image || ''}
          alt={`${pdf.title} pdf preview`}
          className="w-full rounded shadow"
        />
      </a>
      <div className="mt-2 flex justify-between text-gray-500">
        <div className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-5">
          {pdf.title}
        </div>
        <button
          aria-label="Delete"
          onClick={(e) => {
            e.preventDefault()
            setIsDeleting(true)
          }}>
          <TrashIcon className="h-5 w-5 flex-shrink-0 cursor-pointer" />
        </button>
      </div>
      <Alert variant="danger" isOpen={isDeleting}>
        <Alert.Title>Delete Report</Alert.Title>
        <Alert.Content>
          Are you sure you want to delete the custom pdf page:{' '}
          <strong className="font-medium text-gray-700">{pdf.title}</strong>?
          {error && (
            <div className="text-red-500" role="alert" aria-live="polite">
              {error.message}
            </div>
          )}
        </Alert.Content>
        <Alert.Cancel onClick={() => setIsDeleting(false)}>Cancel</Alert.Cancel>
        <Alert.Confirm loading={isLoading} onClick={handleDelete}>
          Delete Forever
        </Alert.Confirm>
      </Alert>
    </div>
  )
}
