import { Modal } from '@cma/common'
import { CustomizePageWithPages } from './useCustomizePages'

interface CustomizePageTitleProps {
  page: CustomizePageWithPages
  isOpen: boolean
  onClose: () => void
}

export function CustomizePageTitle({
  page,
  isOpen,
  onClose
}: CustomizePageTitleProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Title>{page.title} - Customize Page Title</Modal.Title>
      <div className="mt-2">
        This shouldn't be a modal but leaving as a modal for now
      </div>
    </Modal>
  )
}
