import { useCurrentUser } from '@cma/app'
import { Button, useBuildHandoffUrl } from '@cma/common'
import { IntegrationTypeEnum } from '@cma/generated/graphql'
import { useRemoveIntegration } from './useRemoveIntegration'

function useTransactionDeskUrl() {
  const { data: { currentUser } = {} } = useCurrentUser()
  const buildHandoffUrl = useBuildHandoffUrl()
  const searchParams = new URLSearchParams()

  searchParams.append('user_id', currentUser?.id || '')
  searchParams.append('report_id', '')
  searchParams.append('return_path', 'settings')

  return buildHandoffUrl(`/transactions/connect_td?${searchParams}`)
}

export function IntegrationTransactionDesk() {
  const { data: { currentUser } = {} } = useCurrentUser()
  const transactionDeskUrl = useTransactionDeskUrl()
  const {
    mutate: removeIntegration,
    isLoading: isRemoving,
    error: removeError
  } = useRemoveIntegration()

  return (
    <div className="space-y-2" data-testid="transaction_desk-container">
      <h4 className="text-base font-bold text-gray-600">
        Transactions (TransactionDesk Edition)
      </h4>
      {!currentUser?.transactionDeskToken && (
        <Button variant="tertiary" as="a" href={transactionDeskUrl} fullWidth>
          Sign into Transactions (TransactionDesk Edition)
        </Button>
      )}
      {!!currentUser?.transactionDeskToken && (
        <Button
          variant="tertiary-danger"
          fullWidth
          loading={isRemoving}
          onClick={() =>
            removeIntegration({ key: IntegrationTypeEnum.TransactionDesk })
          }>
          Disconnect from Transactions (TransactionDesk Edition)
        </Button>
      )}
      {!!removeError && (
        <div className="mt-2 text-sm text-red-600">{removeError.message}</div>
      )}
    </div>
  )
}
