import { copyTextToClipboard, formatDate, useTimedState } from '@cma/common'

interface ListingDisclaimerProps {
  mlsNumber?: string | null
  brokerageName?: string | null
  lastUpdated?: string | null
}

export function ListingDisclaimer({
  mlsNumber,
  brokerageName,
  lastUpdated
}: ListingDisclaimerProps) {
  const [copied, startCopyTimer] = useTimedState()

  return (
    <div className="text-xs text-gray-900">
      <div className="mb-0.5 flex items-center gap-2">
        <button
          className="flex items-center gap-2"
          disabled={!mlsNumber}
          onClick={() => {
            if (!mlsNumber) return
            copyTextToClipboard(mlsNumber)
            startCopyTimer()
          }}>
          <span>
            MLS # <span className="text-blue-800">{mlsNumber}</span>
          </span>
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 12"
            className="h-[0.75rem] w-[1rem] text-gray-500">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.405.5H3.23c-.658 0-1.196.45-1.196 1v7H3.23v-7h7.176v-1Zm1.794 2H5.621c-.658 0-1.196.45-1.196 1v7c0 .55.538 1 1.196 1H12.2c.658 0 1.196-.45 1.196-1v-7c0-.55-.538-1-1.196-1Zm0 8H5.621v-7H12.2v7Z"
              fill="currentColor"
            />
          </svg>
        </button>
        {copied && <span className="text-gray-500">Copied!</span>}
      </div>
      <div>
        Last Updated:{' '}
        {lastUpdated ? formatDate(lastUpdated, 'MM/dd/yyyy h:mmaaa') : '-'}
      </div>
      {!!brokerageName && <div>Courtesy of {brokerageName}</div>}
    </div>
  )
}
