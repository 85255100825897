import {
  AddressSearch,
  Alert,
  Button,
  classNames,
  DatePicker,
  FormField,
  generateKey,
  Input,
  isCorrectFileSize,
  isSupportedFileType,
  Select,
  SUPPORTED_IMAGE_FORMATS,
  useTaxData
} from '@cma/common'
import { ListingPartsFragment } from '@cma/generated/graphql'
import { ChevronDownIcon } from '@cma/icons'
import { Disclosure } from '@headlessui/react'
import { TrashIcon } from '@heroicons/react/outline'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChangeEvent, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useCreateReportListing } from './useCreateReportListing'
import { useDestroyReportListing } from './useDestroyReportListing'
import { useUpdateReportListing } from './useUpdateReportListing'

const lazyNumber = (error: string) => (value: string | number | boolean) => {
  return value === '' ? yup.string() : yup.number().typeError(error)
}

const addEditReportListingSchema = yup.object({
  mlsnum: yup.string(),
  lat: yup.number(),
  lon: yup.number(),
  city: yup.string(),
  address: yup.string(),
  beds: yup.lazy(lazyNumber('Beds must be a valid number')),
  fullBaths: yup.lazy(lazyNumber('Full baths must be a valid number')),
  halfBaths: yup.lazy(lazyNumber('Half baths must be a valid number')),
  sqft: yup.lazy(lazyNumber('Sq Ft must be a valid number')),
  status: yup.string(),
  price: yup.lazy(lazyNumber('Price must be a valid number')),
  originalPrice: yup.lazy(lazyNumber('Original Price must be a valid number')),
  soldPrice: yup.lazy(lazyNumber('Sold Price must be a valid number')),
  dateListed: yup.string().nullable(),
  dateSold: yup.string().nullable(),
  description: yup.string(),
  lotSize: yup.lazy(lazyNumber('Lot size must be a valid number')),
  lotdim: yup.string(),
  yearBuilt: yup.lazy(lazyNumber('Year built must be a valid number')),
  taxes: yup.lazy(lazyNumber('Taxes must be a valid number')),
  garages: yup.lazy(lazyNumber('Garages must be a valid number')),
  area: yup.string(),
  subdivision: yup.string(),
  style: yup.string()
})

interface AddEditReportListingProps {
  reportId: string
  listing?: ListingPartsFragment
  onAdd: () => void
  onUpdate: () => void
  onCancel: () => void
}

export function AddEditReportListing({
  reportId,
  listing,
  onAdd,
  onUpdate,
  onCancel
}: AddEditReportListingProps) {
  const isEditing = !!listing
  const [isDeleting, setIsDeleting] = useState(false)
  const [selectedPhotos, setSelectedPhotos] = useState<FileList | null>(null)
  const [photoKey, setPhotoKey] = useState(() => generateKey())
  const [photosError, setPhotosError] = useState<string>()
  const address = [
    listing?.address,
    [listing?.city, listing?.state].filter(Boolean).join(' '),
    listing?.zip
  ]
    .filter(Boolean)
    .join(', ')
  const {
    control,
    register,
    handleSubmit,
    setValue,
    setFocus,
    getValues,
    reset,
    formState: { errors, dirtyFields }
  } = useForm<yup.InferType<typeof addEditReportListingSchema>>({
    resolver: yupResolver(addEditReportListingSchema),
    defaultValues: {
      address,
      beds: listing?.beds ? Number(listing.beds) : undefined,
      fullBaths:
        listing?.bathsFull && listing.bathsFull !== '0'
          ? Number(listing.bathsFull)
          : undefined,
      halfBaths:
        listing?.bathsHalf && listing.bathsHalf !== '0'
          ? Number(listing.bathsHalf)
          : undefined,
      sqft: listing?.sqft ? Number(listing.sqft) : undefined,
      status: listing?.status?.toLowerCase() ?? 'active',
      price: listing?.price ? Number(listing.price) : undefined,
      originalPrice: listing?.priceListOrig
        ? Number(listing.priceListOrig)
        : undefined,
      soldPrice: listing?.priceSold ? Number(listing.priceSold) : undefined,
      dateListed: listing?.dateList,
      dateSold: listing?.dateSold,
      description: listing?.remarks?.replace(/<[^>\s+]+>/gi, '') ?? undefined,
      lotSize:
        listing?.lotsize && listing?.lotsize !== '0'
          ? Number(listing.lotsize)
          : undefined,
      lotdim: listing?.lotdim ?? undefined,
      yearBuilt: listing?.yearBuilt ? Number(listing.yearBuilt) : undefined,
      taxes:
        listing?.taxes && listing?.taxes !== '0'
          ? Number(listing.taxes)
          : undefined,
      garages: listing?.garages ? Number(listing.garages) : undefined,
      area: listing?.area ?? undefined,
      subdivision: listing?.subdivision ?? undefined,
      style: listing?.style ?? undefined
    }
  })
  const {
    mutate: createListing,
    error: createError,
    isLoading: isCreating
  } = useCreateReportListing()
  const {
    mutate: updateListing,
    error: updateError,
    isLoading: isUpdating
  } = useUpdateReportListing()
  const { mutate: destroyListing, isLoading: isDestroying } =
    useDestroyReportListing()
  const hasAdvanceInfoError = Boolean(
    errors.lotSize ||
      errors.yearBuilt ||
      errors.taxes ||
      errors.garages ||
      errors.area ||
      errors.subdivision ||
      errors.style
  )

  const {
    data: { parcel: taxData } = {},
    isFetching: isFetchingTaxData,
    error: fetchingTaxDataError
  } = useTaxData(
    { address: getValues('address') ?? '' },
    {
      enabled: !!dirtyFields['address'],
      onSuccess({ parcel }) {
        setValue('beds', parcel?.beds ?? undefined)
        setValue('fullBaths', parcel?.baths ?? undefined)
        setValue('sqft', parcel?.sqft ?? undefined)
      },
      onError() {
        setFocus('beds')
      }
    }
  )

  const handlePhotosChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files

    if (files?.length) {
      if (!isSupportedFileType(files)) {
        setPhotosError('Unsupported file type')
        return
      }

      if (!isCorrectFileSize(files)) {
        setPhotosError('A photo must be smaller than 5MB')
        return
      }

      setSelectedPhotos(files)
    } else {
      setPhotoKey(generateKey())
    }
  }

  return (
    <form
      data-testid="add-edit-form"
      className="space-y-6 p-4"
      onSubmit={handleSubmit((data) => {
        const newListing = {
          source: 'manual',
          mlsnum: data.mlsnum,
          lat: data.lat,
          lon: data.lon,
          city: data.city,
          address: data.address,
          geoSource: 'mapbox',
          beds: data.beds ? Number(data.beds) : undefined,
          bathsFull: data.fullBaths ? Number(data.fullBaths) : undefined,
          bathsHalf: data.halfBaths ? Number(data.halfBaths) : undefined,
          sqft: data.sqft ? Number(data.sqft) : undefined,
          status: data.status,
          price: data.price ? String(data.price) : undefined,
          priceList: data.price ? Number(data.price) : undefined,
          priceListOrig: data.originalPrice
            ? Number(data.originalPrice)
            : undefined,
          priceSold: data.soldPrice ? Number(data.soldPrice) : undefined,
          dateList: data.dateListed ?? undefined,
          dateSold: data.dateSold ?? undefined,
          remarks: data.description,
          photos: selectedPhotos ? Array.from(selectedPhotos) : undefined,
          yearBuilt: data.yearBuilt ? Number(data.yearBuilt) : undefined,
          lotsize: data.lotSize ? Number(data.lotSize) : undefined,
          lotdim: data.lotdim ?? undefined,
          taxes: data.taxes ? Number(data.taxes) : undefined,
          garages: data.garages ? Number(data.garages) : undefined,
          area: data.area ?? undefined,
          subdivision: data.subdivision,
          style: data.style,
          hide: false
        }

        if (isEditing) {
          updateListing(
            { reportId, listingId: listing.id, listing: newListing },
            { onSuccess: onUpdate }
          )
        } else {
          createListing({ reportId, listing: newListing }, { onSuccess: onAdd })
        }
      })}>
      <h3 className="text-lg text-gray-700">
        {isEditing ? 'Edit' : 'Add a'} listing
      </h3>
      {!isEditing && (
        <>
          <div className="space-y-4">
            <h4 className="text-gray-700">On the MLS</h4>
            <div className="grid grid-cols-5 gap-4">
              <FormField label="MLS Number">
                <Input {...register('mlsnum')} />
              </FormField>
            </div>
          </div>
          <div
            className="relative flex items-center justify-center"
            data-testid="divider">
            <div className="absolute top-1/2 w-full border-t"></div>
            <div className="relative flex h-8 w-8 items-center justify-center rounded-full border bg-white text-xs uppercase text-gray-500">
              Or
            </div>
          </div>
        </>
      )}
      <div className="space-y-4">
        {!isEditing && <h4 className="text-gray-700">Off MLS Listing</h4>}
        <div className="grid grid-cols-2 gap-6 sm:grid-cols-4 md:grid-cols-5">
          <div className="col-span-full">
            <div className="grid grid-cols-5 gap-6">
              <div className="col-span-3">
                <FormField
                  label="Address"
                  error={errors.address?.message}
                  secondaryLabel={
                    <>
                      {isFetchingTaxData && (
                        <span className="text-xs not-italic">
                          Fetching Tax Data...
                        </span>
                      )}
                      {!isFetchingTaxData && taxData && (
                        <span className="text-xs not-italic text-purple-500">
                          Tax data found
                        </span>
                      )}
                      {!isFetchingTaxData && fetchingTaxDataError && (
                        <span className="text-xs not-italic">
                          No tax data. Input manually.
                        </span>
                      )}
                    </>
                  }>
                  <AddressSearch
                    onSelect={(address) => {
                      reset()
                      setValue('address', address.formattedAddress, {
                        shouldDirty: true
                      })
                      setValue('mlsnum', address.mlsnum || '')
                      setValue('lat', address.lat || undefined)
                      setValue('lon', address.lon || undefined)
                    }}
                  />
                </FormField>
              </div>
            </div>
          </div>
          <FormField label="Beds" error={errors.beds?.message}>
            <span
              className={classNames({
                'text-purple-500': !dirtyFields['beds']
              })}>
              <Input {...register('beds')} />
            </span>
          </FormField>
          <FormField label="Full Baths" error={errors.fullBaths?.message}>
            <span
              className={classNames({
                'text-purple-500': !dirtyFields['fullBaths']
              })}>
              <Input {...register('fullBaths')} />
            </span>
          </FormField>
          <FormField label="Half Baths" error={errors.halfBaths?.message}>
            <Input {...register('halfBaths')} />
          </FormField>
          <FormField label="Sq Ft" error={errors.sqft?.message}>
            <span
              className={classNames({
                'text-purple-500': !dirtyFields['sqft']
              })}>
              <Input {...register('sqft')} />
            </span>
          </FormField>
          <FormField label="Status" error={errors.status?.message}>
            <Select {...register('status')}>
              <option value="active">Active</option>
              <option value="hold">Hold</option>
              <option value="backup">Backup</option>
              <option value="pending">Pending</option>
              <option value="closed">Sold</option>
              <option value="expired">Expired</option>
              <option value="withdrawn">Withdrawn</option>
              <option value="canceled">Canceled</option>
            </Select>
          </FormField>
          <FormField label="Price" error={errors.price?.message}>
            <Input {...register('price')} />
          </FormField>
          <FormField
            label="Original Price"
            error={errors.originalPrice?.message}>
            <Input {...register('originalPrice')} />
          </FormField>
          <FormField label="Sold Price" error={errors.soldPrice?.message}>
            <Input {...register('soldPrice')} />
          </FormField>
          <FormField label="Date Listed" error={errors.dateListed?.message}>
            {(props) => (
              <Controller
                control={control}
                name="dateListed"
                render={({ field }) => {
                  let selected: Date | undefined

                  if (field.value) {
                    const parsedDate = new Date(field.value)
                    selected = new Date(
                      `${
                        parsedDate.getUTCMonth() + 1
                      }/${parsedDate.getUTCDate()}/${parsedDate.getUTCFullYear()}`
                    )
                  }

                  return (
                    <DatePicker
                      {...props}
                      selected={selected}
                      onChange={(date) => field.onChange(date?.toISOString())}
                    />
                  )
                }}
              />
            )}
          </FormField>
          <FormField label="Date Sold" error={errors.dateSold?.message}>
            {(props) => (
              <Controller
                control={control}
                name="dateSold"
                render={({ field }) => {
                  let selected: Date | undefined

                  if (field.value) {
                    const parsedDate = new Date(field.value)
                    selected = new Date(
                      `${
                        parsedDate.getUTCMonth() + 1
                      }/${parsedDate.getUTCDate()}/${parsedDate.getUTCFullYear()}`
                    )
                  }

                  return (
                    <DatePicker
                      {...props}
                      selected={selected}
                      onChange={(date) => field.onChange(date?.toISOString())}
                    />
                  )
                }}
              />
            )}
          </FormField>
          <div className="col-span-full">
            <FormField label="Description" error={errors.description?.message}>
              <Input as="textarea" rows={5} {...register('description')} />
            </FormField>
          </div>
        </div>
      </div>
      <div className="space-y-2">
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0">
            <FormField label="Photos">
              {(props) => (
                <div className="flex items-center">
                  <input
                    key={photoKey}
                    id={props.id}
                    type="file"
                    multiple
                    accept={SUPPORTED_IMAGE_FORMATS.join(',')}
                    className="peer h-0 w-0 overflow-hidden"
                    onChange={handlePhotosChange}
                  />
                  <div className="rounded-md outline-2 [outline-color:-webkit-focus-ring-color] peer-focus-visible:outline">
                    <Button as="label" {...props} htmlFor={props.id}>
                      Choose Files
                    </Button>
                  </div>
                </div>
              )}
            </FormField>
          </div>
          <span className="mt-6 text-sm text-gray-500">
            {[...(selectedPhotos || [])]
              .map((file: File) => file.name)
              .join(', ') || 'No files selected'}
          </span>
        </div>
        {photosError && (
          <p className="text-xs text-red-500" role="alert" aria-live="polite">
            {photosError}
          </p>
        )}
      </div>
      <Disclosure>
        <Disclosure.Button className="flex w-full items-center space-x-1 rounded-md py-2 text-sm focus:outline-none focus:ring-2 focus:ring-green-500">
          {({ open }) => (
            <>
              {open || hasAdvanceInfoError ? (
                <ChevronDownIcon className="h-5 w-5 flex-shrink-0 text-gray-500" />
              ) : (
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-500" />
              )}
              <span className="flex min-w-0 items-baseline space-x-2">
                <span className="flex-shrink-0 text-green-500">
                  Advanced Info
                </span>
                <span className="truncate text-xs text-gray-400">
                  Additional subject info used in four column layouts
                </span>
              </span>
            </>
          )}
        </Disclosure.Button>
        <Disclosure.Panel static={hasAdvanceInfoError}>
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-4">
            <FormField label="Year Blt." error={errors.yearBuilt?.message}>
              <Input {...register('yearBuilt')} />
            </FormField>
            <FormField label="Lot Size (sqft)" error={errors.lotSize?.message}>
              <Input {...register('lotSize')} />
            </FormField>
            <FormField label="Lot Dim." error={errors.lotdim?.message}>
              <Input {...register('lotdim')} />
            </FormField>
            <FormField label="Taxes" error={errors.taxes?.message}>
              <Input {...register('taxes')} />
            </FormField>
            <FormField label="Garages" error={errors.garages?.message}>
              <Input {...register('garages')} />
            </FormField>
            <FormField label="Area" error={errors.area?.message}>
              <Input {...register('area')} />
            </FormField>
            <FormField label="Subdivision" error={errors.subdivision?.message}>
              <Input {...register('subdivision')} />
            </FormField>
            <FormField label="Style" error={errors.style?.message}>
              <Input {...register('style')} />
            </FormField>
          </div>
        </Disclosure.Panel>
      </Disclosure>
      {(createError || updateError) && (
        <p className="text-xs text-red-500" role="alert" aria-live="polite">
          {(createError || updateError)?.message}
        </p>
      )}
      <div className="flex flex-col space-y-2 sm:flex-row sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
        <div>
          {isEditing && (
            <>
              <Button
                type="button"
                variant="danger"
                fullWidth
                leftIcon={<TrashIcon />}
                onClick={() => setIsDeleting(true)}>
                Remove Listing
              </Button>
              <Alert variant="danger" isOpen={isDeleting}>
                <Alert.Title>Delete Listing</Alert.Title>
                <Alert.Content>
                  Are you sure you want to delete this listing (
                  <strong className="font-medium text-gray-700">
                    {listing.address}
                  </strong>
                  )? This action cannot be undone.
                </Alert.Content>
                <Alert.Cancel onClick={() => setIsDeleting(false)}>
                  Cancel
                </Alert.Cancel>
                <Alert.Confirm
                  loading={isDestroying}
                  onClick={() =>
                    destroyListing({ reportId, listingId: listing.id })
                  }>
                  Delete Forever
                </Alert.Confirm>
              </Alert>
            </>
          )}
        </div>
        <div className="flex flex-shrink-0 flex-col-reverse items-center sm:flex-row sm:space-x-4">
          <div className="mt-2 w-full sm:mt-0 sm:w-auto">
            <Button
              type="button"
              aria-label={`Cancel ${isEditing ? 'editing' : 'adding'} listing`}
              outline
              fullWidth
              onClick={onCancel}>
              Cancel
            </Button>
          </div>
          <div className="w-full sm:w-auto">
            <Button fullWidth loading={isCreating || isUpdating}>
              {isEditing ? 'Update' : 'Add'} Listing
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}
