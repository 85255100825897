import { Modal, toast } from '@cma/common'
import { useCancelBroker } from '@cma/features/admin/broker'
import { HelixIcon } from '@helix/helix-icon'
import { spinner as SpinnerIcon } from '@helix/helix-icon/outlined'
import { useState } from 'react'

interface DeleteAccountProps {
  id: string
  name: string
  isOpen: boolean
  onClose: () => void
  onSuccess?: () => void
}

export function DeleteAccount({
  id,
  name,
  isOpen,
  onClose,
  onSuccess
}: DeleteAccountProps) {
  const { mutate: cancelBroker, isLoading } = useCancelBroker()
  const [_name, setName] = useState('')

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Title weight={600}>Delete Account?</Modal.Title>
      <form
        className="mt-4 space-y-6"
        onSubmit={(e) => {
          e.preventDefault()
          cancelBroker(
            { id },
            {
              onSuccess(data) {
                if (data.cancelBrokerAccount?.errors?.length) {
                  toast(data.cancelBrokerAccount.errors.join(', '), {
                    variant: 'error'
                  })
                  return
                }

                toast('Broker account deleted successfully!', {
                  variant: 'success'
                })
                onClose()
                onSuccess?.()
              },
              onError() {
                toast('Could not delete broker account. Please try again.', {
                  variant: 'error'
                })
              }
            }
          )
        }}>
        <p className="helix-body helix-text-gray-800">
          Are you sure you want to delete the <strong>{name}</strong> Broker
          Account? They will lose everything: Users, themes, and everything
          associated with this broker account.
        </p>
        <p className="helix-body helix-text-gray-800">
          If you're sure, confirm below.
        </p>
        <div>
          <label
            className="helix-body--strong helix-mb-1 helix-d-inline-block helix-text-gray-700"
            htmlFor="name">
            Enter Broker name exactly as written above to confirm deletion
          </label>
          <input
            id="name"
            className="helix-text-input"
            placeholder={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="-mx-6 flex justify-end gap-3 border-t border-solid border-gray-200 px-6 pt-6">
          <button
            className="helix-btn helix-btn--tertiary"
            type="button"
            onClick={onClose}>
            Cancel
          </button>
          <button
            disabled={_name !== name || isLoading}
            className="helix-btn helix-btn--danger helix-d-flex helix-align--center helix-justify--center gap-1 disabled:cursor-not-allowed disabled:opacity-50">
            {isLoading && (
              <span className="mx-auto block h-5 w-5 animate-spin">
                <HelixIcon icon={SpinnerIcon} />
              </span>
            )}
            <span>Delete Permanently</span>
          </button>
        </div>
      </form>
    </Modal>
  )
}
