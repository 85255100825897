import { FormField, Modal, Select } from '@cma/common'
import { Select as ISelect } from '@cma/generated/graphql'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { CustomizeButton } from './CustomizeButton'

const schema = yup.object({
  headline: yup.string()
})

interface CustomizeHeadlineProps {
  headline?: ISelect
  headlines?: ISelect[]
  modern?: boolean | null
  loading?: boolean
  error?: string
  onUpdate: (option: ISelect) => void
}

export function CustomizeHeadline({
  headline,
  headlines,
  modern,
  loading,
  error,
  onUpdate
}: CustomizeHeadlineProps) {
  const [showModal, setShowModal] = useState(false)

  const {
    register,
    formState: { errors },
    setFocus
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      headline: headline?.key
    }
  })

  useEffect(() => {
    if (showModal) {
      setTimeout(() => setFocus('headline'))
    }
  }, [setFocus, showModal])

  if (!modern) return null

  return (
    <>
      <CustomizeButton
        error={error}
        loading={loading}
        icon={
          <svg viewBox="-5 -5 24 24" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
              <path d="M-5-4h24v24H-5z" />
              <path fill="#89929D" d="M0 0v3h5.5v12h3V3H14V0z" />
            </g>
          </svg>
        }
        title="Title"
        subtitle={headline?.value || 'Default Title'}
        onClick={() => setShowModal(true)}
      />
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <Modal.Title>Title</Modal.Title>
        <div className="mt-3">
          <FormField label="Choose Title" error={errors.headline?.message}>
            <Select
              {...register('headline')}
              onChange={(e) => {
                const headline = headlines?.find(
                  (headline) => headline.key === e.target.value
                )

                if (headline) {
                  onUpdate(headline)
                  setShowModal(false)
                }
              }}>
              <option hidden>Select a Title</option>
              {headlines?.map((headline) => (
                <option key={headline.key} value={headline.key}>
                  {headline.value}
                </option>
              ))}
            </Select>
          </FormField>
        </div>
      </Modal>
    </>
  )
}
