import { CustomizePage } from './useCustomizePages'

const TEMP_CUSTOMIZE_PAGES = [
  {
    key: 'introduction',
    title: 'Introduction',
    active: false,
    parent: null,
    isParent: true,
    isCustom: false,
    group: 'inactive_pages',
    position: 0
  },
  {
    key: 'my_recent_sales',
    title: 'My Recent Sales',
    active: true,
    parent: 'introduction',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 1
  },
  {
    key: 'titlefy_report',
    title: 'Titlefy report',
    active: true,
    parent: 'introduction',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 2
  },
  {
    key: 'setup',
    title: 'Setup',
    active: false,
    parent: null,
    isParent: true,
    isCustom: false,
    group: 'inactive_pages',
    position: 3
  },
  {
    key: 'map',
    title: 'Map of All Listings',
    active: false,
    parent: 'setup',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 4
  },
  {
    key: 'summary_of_comps',
    title: 'Summary of comparable properties',
    active: false,
    parent: 'setup',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 5
  },
  {
    key: 'listings',
    title: 'Listings',
    active: false,
    parent: null,
    isParent: true,
    isCustom: false,
    group: 'inactive_pages',
    position: 6
  },
  {
    key: 'listings_chapter',
    title: 'Listings Chapter',
    active: false,
    parent: 'listings',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 7
  },
  {
    key: 'listing',
    title: 'Details',
    active: true,
    parent: 'listings',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 8
  },
  {
    key: 'listing_adjustments',
    title: 'Adjustments',
    active: false,
    parent: 'listings',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 9
  },
  {
    key: 'listing_photos',
    title: 'Photos (first dozen on one page)',
    active: false,
    parent: 'listings',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 10
  },
  {
    key: 'more_listing_photos',
    title: 'More photos (all the rest)',
    active: true,
    parent: 'listings',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 11
  },
  {
    key: 'analysis',
    title: 'Analysis',
    active: false,
    parent: null,
    isParent: true,
    isCustom: false,
    group: 'inactive_pages',
    position: 12
  },
  {
    key: 'online_valuation_analysis_zillow',
    title: 'Online valuation analysis',
    active: false,
    parent: 'analysis',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 13
  },
  {
    key: 'closing',
    title: 'Closing',
    active: false,
    parent: null,
    isParent: true,
    isCustom: false,
    group: 'inactive_pages',
    position: 14
  },
  {
    key: 'features_that_sell',
    title: 'Features that sell',
    active: false,
    parent: 'closing',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 15
  },
  {
    key: 'guidance',
    title: 'Guidance',
    active: false,
    parent: null,
    isParent: true,
    isCustom: false,
    group: 'inactive_pages',
    position: 16
  },
  {
    key: 'selling_in_a_tough_market',
    title: 'Selling your home in a tough market',
    active: false,
    parent: 'guidance',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 17
  },
  {
    key: 'negotiating_with_buyers',
    title: 'Negotiating with Buyers',
    active: false,
    parent: 'guidance',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 18
  },
  {
    key: 'what_is_a_short_sale',
    title: 'What is a short sale?',
    active: false,
    parent: 'guidance',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 19
  },
  {
    key: 'online_home_valuation_tools',
    title: 'Online Home Valuation Tools',
    active: false,
    parent: 'guidance',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 20
  },
  {
    key: 'cmas_vs_appraisals',
    title: 'CMAs vs Appraisals',
    active: false,
    parent: 'guidance',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 21
  },
  {
    key: 'foreclosures',
    title: 'Short Sales and Foreclosures Explanation',
    active: false,
    parent: 'guidance',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 22
  },
  {
    key: 'explaining_listing_syndication',
    title: 'Explaining listing syndication',
    active: false,
    parent: 'guidance',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 23
  },
  {
    key: 'instant_offers_and_ibuyers',
    title: 'Instant offers and iBuyers',
    active: false,
    parent: 'guidance',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 24
  },
  {
    key: 'testimonials',
    title: 'Testimonials',
    active: false,
    parent: null,
    isParent: true,
    isCustom: false,
    group: 'inactive_pages',
    position: 25
  },
  {
    key: 'testimonials_chapter',
    title: 'Testimonials Chapter',
    active: false,
    parent: 'testimonials',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 26
  },
  {
    key: 'zillow_agent_reviews',
    title: 'My ratings and reviews on Zillow',
    active: false,
    parent: 'testimonials',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 27
  },
  {
    key: 'realsatisfied_agent_reviews',
    title: 'RealSatisfied agent ratings',
    active: false,
    parent: 'testimonials',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 28
  },
  {
    key: 'ratedagent_reviews',
    title: 'RatedAgent rating',
    active: false,
    parent: 'testimonials',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 29
  },
  {
    key: 'reach150_reviews',
    title: 'Reach150 reviews',
    active: false,
    parent: 'testimonials',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 30
  },
  {
    key: 'testimonialtree_reviews',
    title: 'Testimonial Tree reviews',
    active: false,
    parent: 'testimonials',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 31
  },
  {
    key: 'cover',
    title: 'Title page',
    active: false,
    parent: 'introduction',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 32
  },
  {
    key: 'cover_letter',
    title: 'Cover letter',
    active: false,
    parent: 'introduction',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 33
  },
  {
    key: 'agent_resume',
    title: 'Agent resume',
    active: false,
    parent: 'introduction',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 34
  },
  {
    key: 'our_company',
    title: 'Our company',
    active: false,
    parent: 'introduction',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 35
  },
  {
    key: 'what_is_a_cma',
    title: 'What is a CMA?',
    active: false,
    parent: 'introduction',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 36
  },
  {
    key: 'agent_flyer',
    title: 'Contact me',
    active: false,
    parent: 'introduction',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 37
  },
  {
    key: 'analysis_chapter',
    title: 'Analysis Chapter',
    active: false,
    parent: 'analysis',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 38
  },
  {
    key: 'summary_statistics',
    title: 'Comparable property statistics',
    active: false,
    parent: 'analysis',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 39
  },
  {
    key: 'sold_property_analysis',
    title: 'Sold property analysis',
    active: false,
    parent: 'analysis',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 40
  },
  {
    key: 'suggested_list_price',
    title: 'Suggested list price',
    active: false,
    parent: 'analysis',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 41
  },
  {
    key: 'net_sheet',
    title: 'Sellers net sheet',
    active: false,
    parent: 'analysis',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 42
  },
  {
    key: 'closing_chapter',
    title: 'Closing Chapter',
    active: false,
    parent: 'closing',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 43
  },
  {
    key: 'marketing_action_plan',
    title: 'Marketing action plan',
    active: false,
    parent: 'closing',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 44
  },
  {
    key: 'money_distribution',
    title: 'Commission distribution',
    active: false,
    parent: 'closing',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 45
  },
  {
    key: 'value_of_professional',
    title: 'Why you need a real estate professional',
    active: false,
    parent: 'closing',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 46
  },
  {
    key: 'importance_of_intelligent_pricing',
    title: 'Intelligent pricing and timing',
    active: false,
    parent: 'closing',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 47
  },
  {
    key: 'first_impressions',
    title: 'First impressions are lasting',
    active: false,
    parent: 'closing',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 48
  },
  {
    key: 'staging_your_home',
    title: 'Staging your home',
    active: false,
    parent: 'closing',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 49
  },
  {
    key: 'open_house_checklist',
    title: 'Showings/Open house checklist',
    active: false,
    parent: 'closing',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 50
  },
  {
    key: 'moving_checklist',
    title: 'Moving checklist',
    active: false,
    parent: 'closing',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 51
  },
  {
    key: 'listing_paperwork_overview',
    title: 'Listing paperwork overview',
    active: false,
    parent: 'closing',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 52
  },
  {
    key: 'notes',
    title: 'Notes',
    active: false,
    parent: 'closing',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 53
  },
  {
    key: 'guidance_chapter',
    title: 'Guidance Chapter',
    active: false,
    parent: 'guidance',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 54
  },
  {
    key: 'client_testimonials',
    title: 'Client testimonials',
    active: false,
    parent: 'guidance',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 55
  },
  {
    key: 'home_value_factors',
    title: 'The Value of Your Home',
    active: false,
    parent: 'guidance',
    isParent: false,
    isCustom: false,
    group: 'inactive_pages',
    position: 56
  },
  {
    key: 'packs-marketing_2013-ten_jobs_for_your_real_estate_professional',
    title: 'Ten jobs for your real estate professional',
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_2013',
    position: 57
  },
  {
    key: 'packs-marketing_2013-cmas_for_buyers_too',
    title: "CMA's are for buyers too",
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_2013',
    position: 58
  },
  {
    key: 'packs-marketing_2013-emotional_triggers_for_home_buyers',
    title: 'Emotional triggers for home buyers',
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_2013',
    position: 59
  },
  {
    key: 'packs-marketing_2013-the_importance_of_communication',
    title: 'The importance of communication',
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_2013',
    position: 60
  },
  {
    key: 'packs-marketing_2013-understanding_the_listing_contract',
    title: 'Understanding the listing contract',
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_2013',
    position: 61
  },
  {
    key: 'packs-marketing_2013-choosing_your_real_estate_professional',
    title: 'Choosing your real estate professional',
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_2013',
    position: 62
  },
  {
    key: 'packs-marketing_2013-vetting_buyers_and_offers',
    title: 'Vetting buyers and offers',
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_2013',
    position: 63
  },
  {
    key: 'packs-marketing_2013-think_like_a_buyer',
    title: 'Think like a buyer',
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_2013',
    position: 64
  },
  {
    key: 'packs-luxe-what_you_dont_know_about_disclosures',
    title: "What you don't know about disclosures",
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_luxe',
    position: 65
  },
  {
    key: 'packs-tom_ferry_lpkt-intro_chapter',
    title: "Chapter Page 'Introduction'",
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_tom_ferry',
    position: 66
  },
  {
    key: 'packs-tom_ferry_lpkt-thank_you',
    title: 'Thank You',
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_tom_ferry',
    position: 67
  },
  {
    key: 'packs-tom_ferry_lpkt-pricing_chapter',
    title: "Chapter Page 'Pricing'",
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_tom_ferry',
    position: 68
  },
  {
    key: 'packs-tom_ferry_lpkt-home_features_that_increase_value',
    title: 'Home Features That Increase Value',
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_tom_ferry',
    position: 69
  },
  {
    key: 'packs-tom_ferry_lpkt-home_features_that_decrease_value',
    title: 'Home Features That Decrease Value',
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_tom_ferry',
    position: 70
  },
  {
    key: 'packs-tom_ferry_lpkt-three_point_pricing_overview',
    title: '3 Point Pricing Overview',
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_tom_ferry',
    position: 71
  },
  {
    key: 'packs-tom_ferry_lpkt-price_above_market_value',
    title: 'Price Above Market Value',
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_tom_ferry',
    position: 72
  },
  {
    key: 'packs-tom_ferry_lpkt-price_at_market_value',
    title: 'Price At Market Value',
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_tom_ferry',
    position: 73
  },
  {
    key: 'packs-tom_ferry_lpkt-price_below_market_value',
    title: 'Price Below Market Value',
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_tom_ferry',
    position: 74
  },
  {
    key: 'packs-tom_ferry_lpkt-choose_an_option',
    title: 'Choose An Option',
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_tom_ferry',
    position: 75
  },
  {
    key: 'packs-tom_ferry_lpkt-presenting_fees',
    title: 'Presenting Fees',
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_tom_ferry',
    position: 76
  },
  {
    key: 'packs-tom_ferry_lpkt-marketing_chapter',
    title: "Chapter Page 'Marketing'",
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_tom_ferry',
    position: 77
  },
  {
    key: 'packs-tom_ferry_lpkt-marketing_proposal',
    title: 'Marketing Proposal',
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_tom_ferry',
    position: 78
  },
  {
    key: 'packs-tom_ferry_lpkt-mega_open_houses',
    title: 'Mega Open Houses',
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_tom_ferry',
    position: 79
  },
  {
    key: 'packs-tom_ferry_lpkt-sell_it_ourselves',
    title: 'We Want To Try Selling It Ourselves',
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_tom_ferry',
    position: 80
  },
  {
    key: 'packs-tom_ferry_lpkt-list_price_alone',
    title: "Don't Pick Your Agent on List Price Alone",
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_tom_ferry',
    position: 81
  },
  {
    key: 'packs-tom_ferry_lpkt-wait_to_list',
    title: "Don't Wait to List",
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_tom_ferry',
    position: 82
  },
  {
    key: 'packs-tom_ferry_lpkt-listings_chapter',
    title: "Chapter Page 'Listings'",
    active: false,
    parent: null,
    isParent: false,
    isCustom: false,
    group: 'inactive_marketing_tom_ferry',
    position: 83
  }
].map((page, index) => ({ ...page, position: index })) as CustomizePage[]

export { TEMP_CUSTOMIZE_PAGES }
