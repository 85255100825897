import { getAccessTokens } from '@cma/features/auth'
import { Component, ErrorInfo, ReactNode } from 'react'
import { airbrake } from './utils'

interface Props {
  children: ReactNode
  fallback: ReactNode | ((error: Error) => ReactNode)
}

interface State {
  error?: Error
}

export class ErrorBoundary extends Component<Props, State> {
  state = { error: undefined }

  static getDerivedStateFromError(error: Error): State {
    return { error }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    const accessTokens = getAccessTokens()
    airbrake?.notify({ error, params: { info, accessTokens } })
    console.error('Uncaught error:', error, info)
  }

  render() {
    if (this.state.error) {
      return typeof this.props.fallback === 'function'
        ? this.props.fallback(this.state.error)
        : this.props.fallback
    }
    return this.props.children
  }
}
