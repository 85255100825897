import { api } from '@cma/app'
import {
  ContactItemPartsFragment,
  SortContactItemsMutation,
  SortContactItemsMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

interface Context {
  previousContactItems?: ContactItemPartsFragment[]
}

export function useSortContactItems() {
  const queryClient = useQueryClient()
  return useMutation<
    SortContactItemsMutation,
    Error,
    SortContactItemsMutationVariables,
    Context
  >(
    ({ ids }) => {
      const query = gql`
        mutation SortContactItems($ids: [ID!]!) {
          sortContactItems(ids: $ids) {
            contactItems {
              id
            }
            errors
          }
        }
      `
      const variables = { ids }
      return api<SortContactItemsMutation>({ query, variables })
    },
    {
      onError: (_, __, context) => {
        queryClient.setQueryData<ContactItemPartsFragment[] | undefined>(
          ['contactItems'],
          context?.previousContactItems
        )
      },
      onSettled() {
        queryClient.invalidateQueries(['contactItems'])
      }
    }
  )
}
