import { Map } from '@cma/common'
import { TourAppointment } from '@cma/generated/graphql'

interface TourMapProps {
  appointments: TourAppointment[]
  onPinClick: (appointment: TourAppointment) => void
}

export function TourMap({ appointments, onPinClick }: TourMapProps) {
  const firstAppointment: TourAppointment | undefined = appointments[0]
  const lngLats = appointments.map<[lng: number, lat: number]>(
    (appointment) => [
      appointment.listing.lon || -1,
      appointment.listing.lat || -1
    ]
  )
  const bounds = Map.fitBounds(lngLats)

  return (
    <div className="flex h-full w-full items-center justify-center">
      {appointments.length ? (
        <Map
          center={
            lngLats.length <= 1
              ? {
                  lat: firstAppointment.listing.lat || -1,
                  lng: firstAppointment.listing.lon || -1
                }
              : undefined
          }
          bounds={lngLats.length > 1 ? bounds : undefined}
          boundPadding={200}>
          {appointments.map((appointment) => (
            <Map.Listing
              key={appointment.id}
              lat={appointment.listing.lat || -1}
              lon={appointment.listing.lon || -1}
              price={appointment.listing.price || 0}
              mappedStatus={appointment.listing.mappedStatus || undefined}
              onClick={() => onPinClick(appointment)}
            />
          ))}
        </Map>
      ) : (
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 138 93"
          className="h-[5.8125rem] w-[8.625rem]">
          <g clipPath="url(#tour-create-map-illustration)">
            <path
              d="M126.339 13.158 135.628.822m-10.835 1.54 12.38 9.255M2.372 92.177l9.29-12.335M.825 81.383l12.38 9.255"
              stroke="#576474"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.942 73.964s.661-38.633 36.124-37.447c20.967.7 31.562 26.01 59.504 23.046 38.843-4.117 32.232-38.682 32.232-38.682"
              stroke="#C4C8D0"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="7 7"
            />
            <path d="M49.678 87.27v-9.066h-5.554v9.065" fill="#E1E5E9" />
            <path
              d="M32.694 87.27a1 1 0 1 0 2 0h-2Zm1-12.598h1a1 1 0 0 0-1-1v1Zm-3.025 0-.64-.769a1 1 0 0 0 .64 1.769v-1Zm16.232-13.504.64-.768a1 1 0 0 0-1.28 0l.64.768Zm16.231 13.504v1a1 1 0 0 0 .64-1.769l-.64.769Zm-3.024 0v-1a1 1 0 0 0-1 1h1Zm-1 12.597a1 1 0 1 0 2 0h-2Zm-10.43 0a1 1 0 1 0 2 0h-2Zm1-9.065h1a1 1 0 0 0-1-1v1Zm-5.554 0v-1a1 1 0 0 0-1 1h1Zm-1 9.065a1 1 0 1 0 2 0h-2Zm-8.43 0V74.672h-2v12.597h2Zm-1-13.597H30.67v2h3.025v-2ZM31.31 75.44 47.54 61.937 46.261 60.4 30.03 73.903l1.279 1.537Zm14.952-13.504 16.232 13.504 1.279-1.538L47.54 60.4l-1.279 1.537Zm16.871 11.735h-3.024v2h3.024v-2ZM50.678 87.269v-9.065h-2v9.065h2Zm-1-10.065h-5.554v2h5.554v-2Zm-6.554 1v9.065h2v-9.065h-2Zm15.983-3.532v12.597h2V74.672h-2Z"
              fill="#576474"
            />
            <path d="M95.959 31.28v-9.057h-5.554v9.057" fill="#E1E5E9" />
            <path
              d="M78.975 31.28a1 1 0 1 0 2 0h-2Zm1-12.598h1a1 1 0 0 0-1-1v1Zm-3.025 0-.64-.768a1 1 0 0 0 .64 1.768v-1ZM93.182 5.18l.64-.769a1 1 0 0 0-1.28 0l.64.769Zm16.231 13.503v1a1.002 1.002 0 0 0 .64-1.768l-.64.768Zm-3.025 0v-1a1 1 0 0 0-1 1h1Zm-1 12.598a1 1 0 0 0 2 0h-2Zm-10.43 0a1 1 0 1 0 2 0h-2Zm1-9.057h1a1 1 0 0 0-1-1v1Zm-5.553 0v-1a1 1 0 0 0-1 1h1Zm-1 9.057a1 1 0 1 0 2 0h-2Zm-8.43 0V18.682h-2V31.28h2Zm-1-13.598H76.95v2h3.025v-2Zm-2.385 1.77L93.821 5.947 92.542 4.41 76.311 17.914l1.279 1.537ZM92.542 5.947l16.232 13.503 1.279-1.537L93.821 4.41l-1.279 1.538Zm16.871 11.734h-3.025v2h3.025v-2ZM96.959 31.28v-9.057h-2v9.057h2Zm-1-10.057h-5.554v2h5.554v-2Zm-6.554 1v9.057h2v-9.057h-2Zm15.983-3.54V31.28h2V18.682h-2Z"
              fill="#576474"
            />
          </g>
          <defs>
            <clipPath id="tour-create-map-illustration">
              <path fill="#fff" d="M0 0h138v93H0z" />
            </clipPath>
          </defs>
        </svg>
      )}
    </div>
  )
}
