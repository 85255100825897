export function CmaRdcLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 25" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.832 0.5L25.3413 13.15H21.5266V24.5H4.12084V13.15H0.297852L12.832 0.5ZM16.1103 9.33135C16.7461 9.33135 17.2745 9.5101 17.6048 9.83509C17.9186 10.1763 18.0672 10.5825 18.0589 11.0863C18.0589 11.8256 17.5883 12.5649 16.6965 12.5649C15.7965 12.5649 15.4827 12.0125 15.2598 11.4925C15.0699 11.0375 14.8965 10.7531 14.6322 10.7531C13.9387 10.7531 12.8735 12.305 12.6011 14.2386C12.5763 14.5473 12.5433 15.2541 12.5433 15.9854V18.4065C12.5433 19.7877 12.6918 20.3564 12.9313 20.8601C13.0965 21.1607 13.2533 21.3232 13.435 21.3882C13.6827 21.4938 13.8643 21.5263 14.1121 21.5507L14.2194 21.5588V21.9H8.14228V21.5588L8.25784 21.5507C8.53859 21.5182 8.78631 21.4532 8.98447 21.3639C9.33951 21.1851 9.51294 21.0226 9.67807 20.4945C9.7689 19.9502 9.78539 19.1377 9.78539 18.5446V12.7355C9.78539 12.2968 9.78539 11.2731 9.70285 10.9238C9.65329 10.6231 9.4964 10.3876 9.24043 10.2169C8.95144 10.0138 8.63767 9.98128 8.30741 9.95692L8.20004 9.94883V9.60756H12.2707C12.4854 9.60756 12.5845 9.7538 12.5845 9.89196C12.5845 9.95692 12.5763 10.5013 12.5597 11.135C12.558 11.2207 12.5563 11.3078 12.5545 11.3949C12.548 11.7209 12.5415 12.0468 12.535 12.2968C12.9643 11.1594 13.4845 10.5744 13.9387 10.1438L13.9469 10.1357C14.4011 9.76194 15.0616 9.33135 16.1103 9.33135Z"
        fill="#D92228"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.9414 6.69998C57.9414 6.3686 57.6728 6.09998 57.3414 6.09998C57.01 6.09998 56.7414 6.3686 56.7414 6.69998V11.9H51.5414C51.21 11.9 50.9414 12.1686 50.9414 12.5C50.9414 12.8313 51.21 13.1 51.5414 13.1H56.7414V18.3C56.7414 18.6313 57.01 18.9 57.3414 18.9C57.6728 18.9 57.9414 18.6313 57.9414 18.3V13.1H63.1414C63.4728 13.1 63.7414 12.8313 63.7414 12.5C63.7414 12.1686 63.4728 11.9 63.1414 11.9H57.9414V6.69998Z"
        fill="#6B7280"
      />
      <path
        d="M109.881 24.4983H107.501C107.24 24.4983 107.026 24.2848 107.026 24.0231V21.902C107.026 21.6817 107.202 21.506 107.422 21.506H109.912C113.651 21.506 116.774 18.4484 116.709 14.7089C116.654 11.486 114.33 8.77605 111.144 8.24234L111.055 8.22856C110.635 8.1597 110.273 7.88767 110.087 7.50547L110.046 7.42283C108.865 4.99872 106.455 3.49399 103.762 3.49399C100.167 3.49399 97.1129 6.29686 96.803 9.87103L96.7927 9.98122C96.7479 10.4908 96.4139 10.9281 95.9353 11.1072C93.9037 11.8613 92.4506 13.731 92.3473 15.8969C92.1993 18.9649 94.6509 21.506 97.6845 21.506C99.5508 21.506 101.252 20.5557 102.233 18.9649L104.254 15.6283C104.368 15.4423 104.606 15.3838 104.792 15.4974L106.682 16.6441C106.868 16.7577 106.926 16.9953 106.813 17.1812L104.785 20.5281C103.245 23.0211 100.591 24.4983 97.6811 24.4983C93.0808 24.4983 89.3413 20.7588 89.3413 16.1586C89.3413 12.9976 91.1525 10.1086 93.9347 8.70719C94.3169 6.57577 95.3844 4.6234 96.9958 3.13932C98.8415 1.43488 101.245 0.498291 103.759 0.498291C107.353 0.498291 110.59 2.37491 112.377 5.44291C116.633 6.49657 119.656 10.2808 119.701 14.7296C119.756 20.1046 115.252 24.5017 109.877 24.5017L109.881 24.4983Z"
        fill="#1CB576"
      />
    </svg>
  )
}
