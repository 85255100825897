import { api } from '@cma/app'
import {
  ListingPartsFragment,
  ReportQuery,
  SortDirectionEnum,
  SortReportListingsMutation,
  SortReportListingsMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { mapPosition, sortByPosition, sortByProperty } from './utils'

interface SortReportListingsContext {
  previousReport?: ReportQuery
}

export function useSortReportListings() {
  const queryClient = useQueryClient()
  return useMutation<
    SortReportListingsMutation,
    Error,
    SortReportListingsMutationVariables,
    SortReportListingsContext
  >(
    ({ reportId, sortBy, sortDir }) => {
      const query = gql`
        mutation SortReportListings(
          $reportId: ID!
          $sortBy: String!
          $sortDir: SortDirectionEnum!
        ) {
          sortByListing(
            reportId: $reportId
            field: $sortBy
            direction: $sortDir
          ) {
            errors
          }
        }
      `
      const variables = { reportId, sortBy, sortDir }
      return api({ query, variables })
    },
    {
      onMutate: async ({ reportId, sortBy, sortDir }) => {
        await queryClient.cancelQueries(['report', reportId])
        const previousReport = queryClient.getQueryData<ReportQuery>([
          'report',
          reportId
        ])

        if (previousReport && previousReport.report) {
          queryClient.setQueryData<ReportQuery>(['report', reportId], {
            ...previousReport,
            report: {
              ...previousReport.report,
              listings: sort(
                [...(previousReport.report?.listings || [])],
                sortBy,
                sortDir
              )
            }
          })
        }

        return { previousReport }
      },
      onError(_, { reportId }, context) {
        if (context?.previousReport) {
          queryClient.setQueryData<ReportQuery>(
            ['report', reportId],
            context.previousReport
          )
        }
      }
    }
  )
}

function sort(
  listings: ListingPartsFragment[],
  sortBy: string,
  sortDir: SortDirectionEnum
) {
  const newListings = sortByProperty(listings, sortBy, sortDir)
  const newListingsWithNewPosition = mapPosition(newListings)
  const newListingsByPosition = sortByPosition(newListingsWithNewPosition)
  return newListingsByPosition
}
