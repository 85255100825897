import { classNames, Spinner } from '@cma/common'
import { ErrorIcon } from '@cma/icons'

interface CustomizeButtonProps
  extends Omit<JSX.IntrinsicElements['button'], 'className' | 'title'> {
  loading?: boolean
  error?: string
  icon: JSX.Element
  title: string
  subtitle: string
}

export function CustomizeButton({
  loading,
  error,
  icon,
  title,
  subtitle,
  ...props
}: CustomizeButtonProps) {
  return (
    <button
      {...props}
      title={error || subtitle}
      type="button"
      aria-label={`Customize ${title}`}
      className="flex items-center space-x-3 text-left">
      <span
        className={classNames(
          'flex h-11 w-11 flex-shrink-0 items-center justify-center rounded border-2 border-solid border-gray-200',
          {
            'border-red-500': !!error
          }
        )}>
        <span
          className={classNames('h-6 w-6 fill-current', {
            'text-gray-400': !error,
            'text-red-500': !!error
          })}>
          {error ? <ErrorIcon /> : loading ? <Spinner /> : icon}
        </span>
      </span>
      <span className="min-w-0 text-sm">
        <span className="block truncate text-gray-500">{title}</span>
        <span
          className={classNames('block truncate', {
            'text-green-500': !error,
            'text-red-500': !!error
          })}>
          {error || subtitle}
        </span>
      </span>
    </button>
  )
}
