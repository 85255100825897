import { Button, FormField, Link, Wrapper, classNames } from '@cma/common'
import {
  useBroker,
  useBrokerAgentExistence,
  useBrokerExistence,
  useCreateBroker,
  useUpdateBrokerAccount
} from '@cma/features/admin/broker'
import { useMlses, useMlsesByState } from '@cma/features/settings'
import { BrokerFilterFieldEnum } from '@cma/generated/graphql'
import { ErrorIcon } from '@cma/icons'
import { Select } from '@helix/select'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'
import * as yup from 'yup'

export default function BrokerDetails() {
  const params = useParams()
  const isEditing = !!params.brokerId
  const { data: { broker } = {} } = useBroker({
    id: String(params.brokerId || '')
  })

  const { data: { mlses } = {}, isLoading } = useMlses(
    { restrict: false },
    { suspense: false }
  )
  const { mlsesByStates } = useMlsesByState(mlses ?? [])

  const [accountKey, setAccountKey] = useState('')
  const [email, setEmail] = useState('')
  const navigate = useNavigate()
  const {
    mutate: createBroker,
    isLoading: isCreating,
    error: createBrokerError
  } = useCreateBroker()
  const {
    mutate: updateBroker,
    isLoading: isUpdating,
    error: updateBrokerError
  } = useUpdateBrokerAccount()
  const { data: { brokers } = {} } = useBrokerExistence(
    { filters: [{ field: BrokerFilterFieldEnum.Key, value: accountKey }] },
    { enabled: !!accountKey }
  )
  const doesBrokerExist = !!brokers?.length

  const { data: { user } = {} } = useBrokerAgentExistence({ email })

  const schema = yup.object({
    brokerName: yup.string().required('Brokerage name is required'),
    brokerKey: yup.string().required('Brokerage account key is required'),
    mlsFeeds: yup.array().of(
      yup.object({
        label: yup.string().required('MLS name is required'),
        value: yup.string().required('MLS key is required')
      })
    ),
    admins: !isEditing
      ? yup.array(
          yup.object({
            label: yup.string().required('Admin name is required'),
            value: yup.string().required('Admin id is required')
          })
        )
      : yup
          .array()
          .of(
            yup.object({
              label: yup.string().required('Admin name is required'),
              value: yup.string().required('Admin id is required')
            })
          )
          .min(1, 'At least one admin is required on the account'),
    adminFirstName: isEditing
      ? yup.string()
      : yup.string().required('Admin first name is required'),
    adminLastName: isEditing
      ? yup.string()
      : yup.string().required('Admin last name is required'),
    adminEmail: isEditing
      ? yup.string()
      : yup.string().required('Admin email is required').email('Invalid email')
  })
  const splitName = broker?.admin?.name.split(' ')
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    control
  } = useForm<yup.InferType<typeof schema>>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      brokerKey: broker?.key,
      brokerName: broker?.name,
      mlsFeeds: [],
      admins: broker?.admin
        ? broker.agents.edges
            ?.filter((edge) => edge?.node?.isBrokerAdmin)
            .map((edge) => ({
              value: edge?.node?.id || '_NO_VALUE_',
              label:
                [edge?.node?.firstName, edge?.node?.lastName]
                  .filter(Boolean)
                  .join(', ') || '_NO_LABEL_'
            }))
        : [],
      adminFirstName: splitName?.[0],
      adminLastName: splitName?.[1],
      adminEmail: broker?.admin?.email
    }
  })

  useDeepCompareEffectNoCheck(() => {
    const mappedMlses = mlses
      ?.filter((mls) => (mls.key ? broker?.mlsFeeds?.includes(mls.key) : false))
      .map((mls) => ({
        value: mls.key || '_NO_VALUE_',
        label: mls.title || '_NO_TITLE_'
      }))

    setValue('mlsFeeds', mappedMlses || [])
  }, [mlses, broker?.mlsFeeds])

  return (
    <Wrapper>
      <Helmet>
        <title>Create New Broker - Cloud CMA</title>
      </Helmet>
      <div className="m-auto flex w-full max-w-md flex-col rounded-3xl border bg-white p-6 shadow">
        <form
          onSubmit={handleSubmit((formData) => {
            if (isEditing) {
              if (!broker?.id) return

              updateBroker(
                {
                  input: {
                    id: broker?.id,
                    brokerName: formData.brokerName,
                    mlsFeeds: formData.mlsFeeds?.map((mls) => mls.value),
                    brokerAdminIds: formData.admins?.map((admin) => admin.value)
                  }
                },
                {
                  onSuccess: (data) => {
                    navigate(
                      `/admin/brokers/${data.updateBrokerAccount?.account?.id}`
                    )
                  }
                }
              )
            } else {
              createBroker(
                {
                  input: {
                    brokerName: formData.brokerName,
                    brokerKey: formData.brokerKey,
                    mlsFeeds: formData.mlsFeeds?.map((mls) => mls.value),
                    adminFirstName: formData.adminFirstName || '',
                    adminLastName: formData.adminLastName || '',
                    adminEmail: formData.adminEmail || ''
                  }
                },
                {
                  onSuccess: (data) => {
                    navigate(
                      `/admin/brokers/${data.generateBrokerAccount?.account?.id}`
                    )
                  }
                }
              )
            }
          })}
          className="space-y-6">
          <div className="space-y-9">
            <div className="space-y-4">
              <h2 className="text-[16px] font-semibold leading-[22px]">
                Brokerage
              </h2>
              <FormField
                aria-label="Broker Name"
                id="broker-name"
                error={errors.brokerName?.message}
                required>
                {(props) => (
                  <div className="space-y-1">
                    <label htmlFor="broker-name" className="helix-body--strong">
                      Name
                    </label>
                    <input
                      {...register('brokerName')}
                      className="helix-text-input"
                      placeholder="Enter full name"
                      {...props}
                    />
                  </div>
                )}
              </FormField>
              <div>
                <FormField
                  aria-label="Account Key"
                  id="account-key"
                  error={[
                    errors.brokerKey?.message,
                    doesBrokerExist ? ' ' : ''
                  ]}
                  required>
                  {(props) => (
                    <div className="space-y-1">
                      <label
                        htmlFor="account-key"
                        className="helix-body--strong">
                        Account Key
                      </label>
                      <input
                        className="helix-text-input disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-400"
                        {...register('brokerKey')}
                        disabled={isEditing}
                        placeholder="Enter account key"
                        onBlur={(e) => {
                          const newValue = e.target.value
                            .toUpperCase()
                            .replaceAll(' ', '_')
                            .replace(/[^A-Z_]/g, '')
                          setValue('brokerKey', newValue)
                          setAccountKey(newValue)
                        }}
                        {...props}
                      />
                    </div>
                  )}
                </FormField>
                {!isEditing && (
                  <ul className="ml-1.5 mt-2 space-y-1 text-xs text-gray-800">
                    <li className="flex items-center gap-2 text-gray-800 before:block before:h-1.5 before:w-1.5 before:rounded-full before:bg-gray-300 before:content-['']">
                      <span>No Spaces allowed</span>
                    </li>
                    <li
                      className={classNames(
                        "flex items-center gap-2 text-gray-800 before:block before:h-1.5 before:w-1.5 before:rounded-full before:content-['']",
                        {
                          'before:bg-gray-300': !accountKey || !doesBrokerExist,
                          'text-red-700 before:bg-red-700':
                            accountKey && !!doesBrokerExist
                        }
                      )}>
                      <span>
                        Must be unique
                        {accountKey && !!doesBrokerExist ? '.' : ''}{' '}
                        {accountKey && !!doesBrokerExist && (
                          <>
                            Account with key{' '}
                            <Link
                              className="text-red-700 underline"
                              to={`/admin/brokers/${accountKey}`}>
                              {accountKey}
                            </Link>{' '}
                            already exists
                          </>
                        )}
                      </span>
                    </li>
                    <li className="flex items-center gap-2 text-gray-800 before:block before:h-1.5 before:w-1.5 before:rounded-full before:bg-gray-300 before:content-['']">
                      <span>Uppercases with underscores</span>
                    </li>
                  </ul>
                )}
              </div>
              <FormField
                aria-label="MLS Feeds"
                id="mls-feeds"
                error={errors.mlsFeeds?.map?.((error) => error?.message)}
                required>
                {(props) => (
                  <div className="space-y-1">
                    <label htmlFor="mls-feeds" className="helix-body--strong">
                      MLS Feeds
                    </label>
                    <Controller
                      control={control}
                      name="mlsFeeds"
                      render={({ field: { onChange, onBlur, value } }) => {
                        const options = Object.entries(mlsesByStates)
                          .sort(([stateA], [stateB]) =>
                            stateA.localeCompare(stateB)
                          )
                          .map(([state, mlses]) => ({
                            label: state || '_NO_STATE_',
                            options: mlses?.map((mls) => ({
                              value: mls?.key || '_NO_VALUE_',
                              label: mls?.title || '_NO_LABEL_'
                            }))
                          }))

                        return (
                          <Select
                            isClearable
                            isMulti
                            selected
                            isLoading={isLoading}
                            options={options}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            {...props}
                          />
                        )
                      }}
                    />
                    <small className="block text-gray-500">
                      Agents on this account will be restricted to the feeds
                      selected here. Leave blank to allow agents to use any
                      feed.
                    </small>
                  </div>
                )}
              </FormField>
              {isEditing && (
                <FormField
                  aria-label="Admin User(s)"
                  id="admins"
                  error={
                    errors.admins?.message ||
                    errors.admins?.map?.((error) => error?.message)
                  }
                  required>
                  {(props) => (
                    <div className="space-y-1">
                      <label htmlFor="admins" className="helix-body--strong">
                        Admin User(s)
                      </label>
                      <Controller
                        control={control}
                        name="admins"
                        render={({ field: { onChange, onBlur, value } }) => {
                          const options = broker?.agents.edges?.map((edge) => ({
                            value: edge?.node?.id || '_NO_VALUE_',
                            label:
                              [edge?.node?.firstName, edge?.node?.lastName]
                                .filter(Boolean)
                                .join(' ') || '_NO_LABEL_'
                          }))

                          return (
                            <Select
                              isClearable
                              isMulti
                              selected
                              options={options}
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              {...props}
                            />
                          )
                        }}
                      />
                      <small className="block text-gray-500">
                        Accounts must have at least one admin.
                      </small>
                    </div>
                  )}
                </FormField>
              )}
            </div>
            {!isEditing && (
              <div className="space-y-4">
                <h2 className="font-semibold leading-5">Admin User</h2>
                <FormField
                  aria-label="First name"
                  id="first-name"
                  error={errors.adminFirstName?.message}
                  required>
                  {(props) => (
                    <div className="space-y-1">
                      <label
                        htmlFor="first-name"
                        className="helix-body--strong">
                        First name
                      </label>
                      <input
                        {...register('adminFirstName')}
                        className="helix-text-input"
                        placeholder="Enter first name"
                        {...props}
                      />
                    </div>
                  )}
                </FormField>
                <FormField
                  aria-label="Last name"
                  id="last-name"
                  error={errors.adminLastName?.message}
                  required>
                  {(props) => (
                    <div className="space-y-1">
                      <label htmlFor="last-name" className="helix-body--strong">
                        Last name
                      </label>
                      <input
                        {...register('adminLastName')}
                        className="helix-text-input"
                        placeholder="Enter last name"
                        {...props}
                      />
                    </div>
                  )}
                </FormField>
                <div>
                  <FormField
                    aria-label="Email"
                    id="email"
                    error={[errors.adminEmail?.message, user ? ' ' : undefined]}
                    required>
                    {(props) => (
                      <div className="space-y-1">
                        <label htmlFor="email" className="helix-body--strong">
                          Email
                        </label>
                        <input
                          {...register('adminEmail')}
                          placeholder="Enter Email"
                          className="helix-text-input"
                          onBlur={(e) => {
                            setEmail(e.target.value)
                          }}
                          {...props}
                        />
                      </div>
                    )}
                  </FormField>
                  {email && !!user && (
                    <span className="mt-2 flex flex-row space-x-2 text-xs text-red-700">
                      <ErrorIcon className="h-5 w-5" />
                      <span>
                        {user.isAdmin
                          ? `This user is an admin on another account and cannot be added here.`
                          : `A user with this email already exists. By proceeding, you will migrate that user to this account.`}
                      </span>
                    </span>
                  )}
                </div>
              </div>
            )}
            <Button
              type="submit"
              fullWidth
              loading={isCreating || isUpdating}
              disabled={!isValid || !!doesBrokerExist || !!user?.isAdmin}>
              {isEditing ? 'Update Account' : 'Create Account'}
            </Button>
          </div>
          <p className="text-xs text-gray-500">All fields are required.</p>
          {!user?.isAdmin && (createBrokerError || updateBrokerError) && (
            <div
              role="alert"
              aria-live="polite"
              className="mt-2 space-x-2 text-xs text-red-700">
              {createBrokerError?.message || updateBrokerError?.message}
            </div>
          )}
        </form>
      </div>
    </Wrapper>
  )
}
