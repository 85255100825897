import { api } from '@cma/app'
import {
  SendMagicLinkMutation,
  SendMagicLinkMutationVariables
} from '@cma/generated/graphql'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useMagicLink() {
  return useMutation<
    SendMagicLinkMutation,
    Error,
    SendMagicLinkMutationVariables
  >((variables) => {
    const query = gql`
      mutation SendMagicLink($email: String!, $token: String!) {
        createMagicLink(email: $email, token: $token) {
          errors
          result
        }
      }
    `

    return api<SendMagicLinkMutation>({ query, variables })
  })
}
