import { Input } from '@cma/common'
import { SearchIcon } from '@heroicons/react/outline'
import { useForm } from 'react-hook-form'

interface FormData {
  search: string
}

interface ReportsHeaderProps {
  title: string
  placeholder?: string
  onSubmit?: (data: FormData) => void
}

export function ReportsHeader({
  title,
  placeholder = 'Search',
  onSubmit
}: ReportsHeaderProps) {
  const { register, handleSubmit } = useForm<FormData>()

  return (
    <header className="flex flex-col items-center justify-between sm:flex-row">
      <h1 className="text-4xl sm:mb-0">{title}</h1>
      {!!onSubmit && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <Input
            id="search"
            type="search"
            leftIcon={<SearchIcon />}
            placeholder={placeholder}
            {...register('search')}
          />
          <button className="sr-only">Submit Search</button>
        </form>
      )}
    </header>
  )
}
