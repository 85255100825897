export function HomebeatHeartIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 20"
      {...props}>
      <path
        fillRule="evenodd"
        d="M9.51 1.433a.75.75 0 01.98 0l7 6.058a.75.75 0 01.26.567V17a.75.75 0 01-.75.75H3a.75.75 0 01-.75-.75V8.058a.75.75 0 01.26-.567l7-6.058zM3.75 8.4v7.85h12.5V8.4L10 2.992 3.75 8.4z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M10 14l-.435-.43C8.02 12.041 7 11.033 7 9.798 7 8.79 7.724 8 8.65 8c.522 0 1.023.265 1.35.682.327-.417.828-.682 1.35-.682.925 0 1.65.79 1.65 1.798 0 1.235-1.02 2.243-2.565 3.772L10 14z"
        clipRule="evenodd"
      />
    </svg>
  )
}
