export function ArrowNextIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 34" {...props}>
      <path
        d="M1 1l12 16L1 33"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
        opacity=".8"
        strokeLinecap="round"
      />
    </svg>
  )
}
