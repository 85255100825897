import { Pagination, usePaginate, Wrapper } from '@cma/common'
import { CmaCard } from '@cma/features/cma/CmaCard'
import {
  CreateReport,
  ReportGettingStarted,
  ReportsHeader,
  useReports
} from '@cma/features/report'
import { ReportTypeEnum } from '@cma/generated/graphql'
import { Fragment, Suspense } from 'react'
import { Helmet } from 'react-helmet-async'

export default function Cmas() {
  const paginate = usePaginate({ limit: 27 })

  return (
    <Wrapper>
      <Helmet>
        <title>CMA Reports - Cloud CMA</title>
      </Helmet>
      <ReportsHeader
        title="CMA Reports"
        placeholder="Search CMAs"
        onSubmit={(data) => {
          paginate.setFirst(paginate.limit)
          paginate.setLast(undefined)
          paginate.setAfter(undefined)
          paginate.setBefore(undefined)
          paginate.setSearch(data.search)
        }}
      />
      <Suspense
        fallback={
          <>
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              <CreateReport to="/cmas/new">Create CMA Report</CreateReport>
              {new Array(5).fill('').map((_, index) => (
                <CmaCard.Skeleton key={index} />
              ))}
            </div>
            <Pagination disablePrev={true} disableNext={true} />
          </>
        }>
        <CmaCards paginate={paginate} />
      </Suspense>
    </Wrapper>
  )
}

interface CmaCardsProps {
  paginate: ReturnType<typeof usePaginate>
}

function CmaCards({ paginate }: CmaCardsProps) {
  const { data: { reports } = {} } = useReports({
    types: [ReportTypeEnum.Cma],
    first: paginate.first,
    last: paginate.last,
    after: paginate.after,
    before: paginate.before,
    search: paginate.search
  })

  return (
    <>
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        <CreateReport to="/cmas/new">Create CMA Report</CreateReport>
        {reports?.nodes?.map((report) => (
          <Fragment key={report?.id}>
            {report && <CmaCard report={report} />}
          </Fragment>
        ))}
        {(reports?.total ?? 0) <= 4 && (
          <ReportGettingStarted videoId="mlufh62wue">
            Getting Started with CMAs
          </ReportGettingStarted>
        )}
        {(reports?.total ?? 0) <= 3 && (
          <ReportGettingStarted videoId="sa0m3cux0z">
            Generating Leads with CMA
          </ReportGettingStarted>
        )}
      </div>
      <Pagination
        disablePrev={!reports?.pageInfo.hasPreviousPage}
        disableNext={!reports?.pageInfo.hasNextPage}
        onPrevClick={() => {
          paginate.setBefore(reports?.pageInfo.startCursor ?? undefined)
          paginate.setAfter(undefined)
          paginate.setFirst(undefined)
          paginate.setLast(paginate.limit)
        }}
        onNextClick={() => {
          paginate.setBefore(undefined)
          paginate.setAfter(reports?.pageInfo?.endCursor ?? undefined)
          paginate.setFirst(paginate.limit)
          paginate.setLast(undefined)
        }}
      />
    </>
  )
}
