export function IconLayoutFlyer7(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 233 303" {...props}>
      <path
        fill="currentColor"
        d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
      />
      <path
        fill="currentColor"
        d="M25.56 23.75h36.83v4H25.56zm56.98 0h124.9v4H82.54zm99.51 221.05h23.59v3h-23.59zm-92.89 0h79.98v3H89.16zm0 18.41h116.48v3H89.16zm0-36.75h116.48v3H89.16zm-58.36-1.04h36.83v4H30.8zm-3.44 19.79h43.71v4H27.36zm8.48 17.53h26.75v4H35.84z"
      />
      <rect
        fill="#e1e5e9"
        x="25.78"
        y="40.1"
        width="51.53"
        height="44.22"
        rx="5"
        ry="5"
      />
      <path
        fill="currentColor"
        d="M72.31 85.82H30.78a6.51 6.51 0 0 1-6.5-6.5V45.1a6.51 6.51 0 0 1 6.5-6.5h41.53a6.51 6.51 0 0 1 6.5 6.5v34.22a6.51 6.51 0 0 1-6.5 6.5zM30.78 41.6a3.5 3.5 0 0 0-3.5 3.5v34.22a3.5 3.5 0 0 0 3.5 3.5h41.53a3.5 3.5 0 0 0 3.5-3.5V45.1a3.5 3.5 0 0 0-3.5-3.5z"
      />
      <rect
        fill="#e1e5e9"
        x="90.73"
        y="40.1"
        width="51.53"
        height="44.22"
        rx="5"
        ry="5"
      />
      <path
        fill="currentColor"
        d="M137.27 85.82H95.73a6.51 6.51 0 0 1-6.5-6.5V45.1a6.51 6.51 0 0 1 6.5-6.5h41.54a6.51 6.51 0 0 1 6.5 6.5v34.22a6.51 6.51 0 0 1-6.5 6.5zM95.73 41.6a3.5 3.5 0 0 0-3.5 3.5v34.22a3.5 3.5 0 0 0 3.5 3.5h41.54a3.5 3.5 0 0 0 3.5-3.5V45.1a3.5 3.5 0 0 0-3.5-3.5z"
      />
      <rect
        fill="#e1e5e9"
        x="155.69"
        y="40.1"
        width="51.53"
        height="44.22"
        rx="5"
        ry="5"
      />
      <path
        fill="currentColor"
        d="M202.22 85.82h-41.53a6.51 6.51 0 0 1-6.5-6.5V45.1a6.51 6.51 0 0 1 6.5-6.5h41.53a6.51 6.51 0 0 1 6.5 6.5v34.22a6.51 6.51 0 0 1-6.5 6.5zM160.69 41.6a3.5 3.5 0 0 0-3.5 3.5v34.22a3.5 3.5 0 0 0 3.5 3.5h41.53a3.5 3.5 0 0 0 3.5-3.5V45.1a3.5 3.5 0 0 0-3.5-3.5z"
      />
      <rect
        fill="#e1e5e9"
        x="25.78"
        y="99.61"
        width="181.45"
        height="103.83"
        rx="6.3"
        ry="6.3"
      />
      <path
        fill="currentColor"
        d="M200.92 205H32.08a7.81 7.81 0 0 1-7.8-7.8v-91.29a7.81 7.81 0 0 1 7.8-7.8h168.84a7.81 7.81 0 0 1 7.8 7.8v91.24a7.81 7.81 0 0 1-7.8 7.85zM32.08 101.11a4.81 4.81 0 0 0-4.8 4.8v91.24a4.81 4.81 0 0 0 4.8 4.8h168.84a4.81 4.81 0 0 0 4.8-4.8v-91.24a4.81 4.81 0 0 0-4.8-4.8z"
      />
      <path
        fill="#fff"
        d="m31.232 108.234 1.408-2.649 3.532 1.878-1.408 2.65zM188.44 191.81l-7-3.71 1.41-2.65 7 3.71zm-14-7.43-7-3.71 1.46-2.67 7 3.71zm-14-7.42-7-3.72 1.41-2.65 7 3.72zm-14-7.43-7-3.71 1.41-2.65 7 3.71zm-14-7.43-7-3.71 1.41-2.65 7 3.71zm-14-7.42-7-3.72 1.41-2.65 7 3.72zm-14-7.43-7-3.71 1.4-2.65 7 3.71zm-14-7.43-7-3.71 1.4-2.65 7 3.71zm-14-7.42-7-3.72L71.1 126l7 3.72zm-14-7.43-7-3.71 1.41-2.65 7 3.71zm-14-7.43-7-3.71 1.41-2.65 7 3.71zM195.443 195.547l1.408-2.649 3.532 1.877-1.408 2.65zM31.628 195.04l3.524-1.893 1.42 2.643-3.524 1.893z"
      />
      <path
        fill="#fff"
        d="m43.52 192.05-1.42-2.65 7-3.73 1.42 2.64zm13.91-7.48L56 181.93l7-3.74 1.42 2.65zm13.9-7.47-1.42-2.64 7-3.74 1.42 2.64zm13.9-7.47L83.81 167l7-3.74 1.42 2.64zm13.91-7.47-1.42-2.65 7-3.73 1.42 2.64zm13.9-7.48-1.42-2.68 6.95-3.73L120 151zm13.9-7.47-1.42-2.64 7-3.74 1.42 2.65zm13.9-7.47-1.42-2.64 7-3.74 1.42 2.64zm13.91-7.47-1.42-2.64 6.95-3.74 1.42 2.64zm13.9-7.47-1.42-2.65 7-3.73 1.42 2.64zm13.9-7.48-1.42-2.64 7-3.73 1.42 2.64zM195.033 107.206l3.523-1.893 1.42 2.643-3.523 1.893z"
      />
    </svg>
  )
}
