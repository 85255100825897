import { useConfig } from '@cma/app'
import { Alert } from '@cma/common'
import { useState } from 'react'
import { useBilling } from './useBilling'
import { useDestroyAccount } from './useDestroyAccount'

export function BillingDestroyAccount() {
  const { data: billing } = useBilling()
  const { data: config } = useConfig()
  const { mutate: destroyAccount, isLoading, error } = useDestroyAccount()
  const [showCancel, setShowCancel] = useState(false)

  if (billing?.user?.isCasUser) {
    return (
      <div className="space-y-4 text-center text-sm">
        <p className="text-gray-500">
          To change your billing details or cancel your account, please visit
          Cloud Agent Suite.
        </p>
        <p>
          <a
            href={`${process.env.VITE_APP_CAS_URL}/app/billing`}
            target="_blank"
            rel="noreferrer"
            className="text-green-500">
            Go to Cloud Agent Suite
          </a>
        </p>
      </div>
    )
  }

  if (billing?.account?.isShared && billing.user?.isAccountAdmin) {
    return (
      <div className="space-y-4">
        <p className="text-sm text-gray-500">
          Since you are the account admin and this is a shared account that
          affects other users, you must contact{' '}
          <a
            href={`mailto: ${config?.supportEmail}?subject=Shared Account Cancellation`}
            target="_blank"
            rel="noreferrer"
            className="text-green-500">
            {config?.supportEmail}
          </a>{' '}
          if you wish to cancel it.
        </p>
      </div>
    )
  }

  return (
    <div className="space-y-4">
      <p className="text-sm text-gray-500">
        We don't want to see you go! If there is anything we can help you with,
        please contact us at{' '}
        <a
          href={`mailto:${config?.supportEmail}?subject=Account Cancellation`}
          target="_blank"
          rel="noreferrer"
          className="text-green-500">
          {config?.supportEmail}
        </a>
        .
      </p>
      <p className="text-sm text-gray-500">
        Please keep in mind that once your account is cancelled, you will lose
        access to all your reports that we host in the cloud -{' '}
        <strong>
          please be sure to download them <i>first</i> if you wish to keep a
          copy.
        </strong>
      </p>
      {billing?.account?.subscription?.isPurchasedFromOutsideStore &&
        !!billing.account.subscription.affiliate?.name && (
          <>
            {billing.account.subscription.affiliate.token === 'mris' && (
              <p className="text-sm text-gray-500">
                If you are an MRIS customer who purchased your subscription
                through the MRIS.com website, you must contact the MRIS Support
                Center to cancel at 1-888-838-8200. Please note that MRIS
                requires the cancellation to be in writing.
              </p>
            )}
            {billing.account.subscription.affiliate.token === 'brightmls' && (
              <p className="text-sm text-gray-500">
                If you are an BrightMLS customer who purchased your subscription
                through the BrightMLS.com website, you must contact the
                BrightMLS Support Center to cancel at 1-888-838-8200. Please
                note that BrightMLS requires the cancellation to be in writing.
              </p>
            )}
            {!['mris', 'brightmls'].includes(
              billing.account.subscription.affiliate.token || ''
            ) && (
              <p className="text-sm text-gray-500">
                {billing.account.subscription.affiliate.name} Store users must
                cancel their accounts from the store where they made their
                purchase.
              </p>
            )}
          </>
        )}
      {!billing?.account?.subscription?.isPurchasedFromOutsideStore && (
        <>
          {(billing?.account?.subscription?.amount ?? 0) > 0 && (
            <p className="text-sm text-gray-500">
              If you are currently being billed, you won't be charged again, but
              you are responsible for any charges already incurred.
            </p>
          )}
          <p className="text-sm text-gray-500">
            I understand this is irreversible -{' '}
            <button
              className="text-red-500"
              onClick={() => setShowCancel(true)}>
              Please cancel my account
            </button>
          </p>
          <Alert variant="danger" isOpen={showCancel}>
            <Alert.Title>Cancel Account</Alert.Title>
            <Alert.Content>
              <div className="space-y-2">
                <div>
                  Are you sure you want to cancel your account? Remember - this
                  is irreversible!
                </div>
                <div>
                  You will receive an e-mail confirming your account has been
                  successfully cancelled. Please contact{' '}
                  <a
                    href={`mailto:${config?.supportEmail}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-green-500">
                    {config?.supportEmail}
                  </a>{' '}
                  if you do not receive confirmation or if you have any
                  questions.
                </div>
                {error && (
                  <div className="text-red-500" role="alert" aria-live="polite">
                    {error.message}
                  </div>
                )}
              </div>
            </Alert.Content>
            <Alert.Cancel onClick={() => setShowCancel(false)}>
              Cancel
            </Alert.Cancel>
            <Alert.Confirm loading={isLoading} onClick={() => destroyAccount()}>
              Cancel Forever
            </Alert.Confirm>
          </Alert>
        </>
      )}
    </div>
  )
}
