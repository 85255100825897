import { useMemo, useState } from 'react'

interface PaginateArgs {
  limit: number
}

export function usePaginate({ limit }: PaginateArgs) {
  const [first, setFirst] = useState<number | undefined>(limit)
  const [last, setLast] = useState<number | undefined>(undefined)
  const [after, setAfter] = useState<string | undefined>(() => {
    const params = new URLSearchParams(window.location.search)
    return params.get('after') ?? undefined
  })
  const [before, setBefore] = useState<string | undefined>(() => {
    const params = new URLSearchParams(window.location.search)
    return params.get('before') ?? undefined
  })
  const [search, setSearch] = useState<string | undefined>(() => {
    const params = new URLSearchParams(window.location.search)
    return params.get('search') ?? undefined
  })

  return useMemo(
    () => ({
      limit,
      first,
      setFirst,
      last,
      setLast,
      after,
      setAfter,
      before,
      setBefore,
      search,
      setSearch
    }),
    [
      limit,
      first,
      setFirst,
      last,
      setLast,
      after,
      setAfter,
      before,
      setBefore,
      search,
      setSearch
    ]
  )
}
