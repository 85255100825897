export function AlertIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 20"
      {...props}>
      <path
        fillRule="evenodd"
        d="M4.25 8.75a5.75 5.75 0 1111.5 0v1.287c0 .969.287 1.916.824 2.722l1.05 1.575A.75.75 0 0117 15.5h-4.261a2.75 2.75 0 01-5.478 0H3a.75.75 0 01-.624-1.166l1.05-1.575a4.908 4.908 0 00.824-2.722V8.75zm4.525 6.75a1.25 1.25 0 002.45 0h-2.45zM10 4.5a4.25 4.25 0 00-4.25 4.25v1.287a6.408 6.408 0 01-1.076 3.554L4.4 14H15.6l-.273-.409a6.408 6.408 0 01-1.076-3.554V8.75A4.25 4.25 0 0010 4.5z"
        clipRule="evenodd"
      />
    </svg>
  )
}
