export function ChevronRightIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.22 3.22a.75.75 0 0 0 0 1.06l5.47 5.47-5.47 5.47a.75.75 0 1 0 1.06 1.06l6.53-6.53-6.53-6.53a.75.75 0 0 0-1.06 0Z"
        fill="currentColor"
      />
    </svg>
  )
}
