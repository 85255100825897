export function ArrowLeftIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.53 3.47a.75.75 0 0 1 0 1.06L8.06 10l5.47 5.47a.75.75 0 1 1-1.06 1.06L5.94 10l6.53-6.53a.75.75 0 0 1 1.06 0Z"
        fill="currentColor"
      />
    </svg>
  )
}
