import { api } from '@cma/app'
import {
  UpdateMlsCredentialMutation,
  UpdateMlsCredentialMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useUpdateMlsCredential() {
  const queryClient = useQueryClient()
  return useMutation<
    UpdateMlsCredentialMutation,
    Error,
    UpdateMlsCredentialMutationVariables
  >(
    ({ input }) => {
      const query = gql`
        mutation UpdateMlsCredential($input: MlsCredentialInput!) {
          updateMlsCredential(input: $input) {
            mlsCredential {
              code
            }
            errors
          }
        }
      `
      const variables = { input }
      return api<UpdateMlsCredentialMutation>({ query, variables })
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries(['currentUser'])
        await queryClient.invalidateQueries(['translations'])
      }
    }
  )
}
