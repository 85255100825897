import { classNames, Modal } from '@cma/common'
import { Select, SelectLayout } from '@cma/generated/graphql'
import {
  IconLayoutFlyer1,
  IconLayoutFlyer10,
  IconLayoutFlyer11,
  IconLayoutFlyer12,
  IconLayoutFlyer2,
  IconLayoutFlyer3,
  IconLayoutFlyer4,
  IconLayoutFlyer5,
  IconLayoutFlyer6,
  IconLayoutFlyer7,
  IconLayoutFlyer8,
  IconLayoutFlyer9
} from '@cma/icons/layouts'
import { ViewBoardsIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { CustomizeButton } from './CustomizeButton'

function getIconsFromLayoutKey(layoutKey: string, currentLayoutKey?: string) {
  switch (layoutKey) {
    case 'flyer1':
      return (
        <IconLayoutFlyer1
          className={classNames('hover:text-green-500', {
            'text-green-500': currentLayoutKey === layoutKey
          })}
        />
      )

    case 'flyer2':
      return (
        <IconLayoutFlyer2
          className={classNames('hover:text-green-500', {
            'text-green-500': currentLayoutKey === layoutKey
          })}
        />
      )

    case 'flyer3':
      return (
        <IconLayoutFlyer3
          className={classNames('hover:text-green-500', {
            'text-green-500': currentLayoutKey === layoutKey
          })}
        />
      )

    case 'flyer4':
      return (
        <IconLayoutFlyer4
          className={classNames('hover:text-green-500', {
            'text-green-500': currentLayoutKey === layoutKey
          })}
        />
      )

    case 'flyer5':
      return (
        <IconLayoutFlyer5
          className={classNames('hover:text-green-500', {
            'text-green-500': currentLayoutKey === layoutKey
          })}
        />
      )

    case 'flyer6':
      return (
        <IconLayoutFlyer6
          className={classNames('hover:text-green-500', {
            'text-green-500': currentLayoutKey === layoutKey
          })}
        />
      )

    case 'flyer7':
      return (
        <IconLayoutFlyer7
          className={classNames('hover:text-green-500', {
            'text-green-500': currentLayoutKey === layoutKey
          })}
        />
      )

    case 'flyer8':
      return (
        <IconLayoutFlyer8
          className={classNames('hover:text-green-500', {
            'text-green-500': currentLayoutKey === layoutKey
          })}
        />
      )

    case 'flyer9':
      return (
        <IconLayoutFlyer9
          className={classNames('hover:text-green-500', {
            'text-green-500': currentLayoutKey === layoutKey
          })}
        />
      )

    case 'flyer10':
      return (
        <IconLayoutFlyer10
          className={classNames('hover:text-green-500', {
            'text-green-500': currentLayoutKey === layoutKey
          })}
        />
      )

    case 'flyer11':
      return (
        <IconLayoutFlyer11
          className={classNames('hover:text-green-500', {
            'text-green-500': currentLayoutKey === layoutKey
          })}
        />
      )

    case 'flyer12':
      return (
        <IconLayoutFlyer12
          className={classNames('hover:text-green-500', {
            'text-green-500': currentLayoutKey === layoutKey
          })}
        />
      )
  }
}

interface CustomizeLayoutProps {
  isModern?: boolean
  layout?: Select
  layouts?: SelectLayout[]
  loading?: boolean
  error?: string
  onUpdate: (option: Select) => void
}

export function CustomizeLayout({
  isModern,
  layout,
  layouts,
  loading,
  error,
  onUpdate
}: CustomizeLayoutProps) {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <CustomizeButton
        error={error}
        loading={loading}
        icon={<ViewBoardsIcon />}
        title="Layout"
        subtitle={layout?.value || 'Flyer 1'}
        onClick={() => setShowModal(true)}
      />
      <Modal size="2xl" isOpen={showModal} onClose={() => setShowModal(false)}>
        <Modal.Title>Layout</Modal.Title>
        <div className="mt-2 grid gap-y-4 gap-x-4 text-gray-400 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 ">
          {layouts?.map((_layout) => {
            return (
              <button
                key={_layout.key}
                className="relative"
                onClick={() => {
                  setShowModal(false)
                  onUpdate({ key: _layout.key, value: _layout.value })
                }}>
                <div>
                  {_layout.key === layout?.key && (
                    <span className="absolute top-0 left-4 right-4 bg-green-500 text-center text-[0.7rem] text-white">
                      Currently Selected
                    </span>
                  )}

                  {isModern ? (
                    getIconsFromLayoutKey(_layout.key, layout?.key || 'flyer1')
                  ) : (
                    <img
                      className={classNames(
                        'overflow-hidden rounded border-2 hover:border-green-500',
                        {
                          'border-green-500': layout?.key === _layout.key,
                          'border-gray-400': layout?.key !== _layout.key
                        }
                      )}
                      src={`${process.env.VITE_APP_CMA_URL}${
                        _layout?.preview ?? ''
                      }`}
                      alt={`${_layout.key} preview`}
                    />
                  )}
                </div>
                <div className="m-1 text-center text-[0.8rem] text-gray-500">
                  {_layout.value}
                </div>
              </button>
            )
          })}
        </div>
      </Modal>
    </>
  )
}
