import { Button, classNames, FormField, Input } from '@cma/common'
import { IntegrationTypeEnum } from '@cma/generated/graphql'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useAddIntegration } from './useAddIntegration'
import { useIntegrations } from './useIntegrations'
import { useRemoveIntegration } from './useRemoveIntegration'

const schema = yup.object({
  name: yup.string().required('Your name is required'),
  password: yup.string()
})

interface IntegrationProps {
  // We can't use the prop `key` as react reserves that
  // for internal use so we'll use `service` instead.
  service: IntegrationTypeEnum
  title: string
  label: string
  addText?: string
  removeText?: string
  hasPassword?: boolean
}

export function Integration({
  service,
  title,
  label,
  addText = 'Add',
  removeText = 'Remove',
  hasPassword
}: IntegrationProps) {
  const { data: integrations } = useIntegrations()
  const {
    mutate: addIntegration,
    isLoading: isAdding,
    error: addError
  } = useAddIntegration()
  const {
    mutate: removeIntegration,
    isLoading: isRemoving,
    error: removeError
  } = useRemoveIntegration()

  const initialName = integrations?.[service]?.name
  const initialPassword = integrations?.[service]?.password
  const hasInitialName = !!initialName

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: initialName || '',
      password: initialPassword || undefined
    }
  })

  const disable =
    !hasInitialName &&
    (!watch('name') || (hasPassword ? !watch('password') : false))

  return (
    <div data-testid={`${service}-container`}>
      <h4 className="font-bold text-gray-600">{title}</h4>
      <form
        className="grid grid-cols-1 gap-3 lg:grid-cols-5"
        onSubmit={handleSubmit(({ name, password }) => {
          addIntegration({ key: service, name, password })
        })}>
        <div
          className={classNames({
            'lg:col-span-4': !hasPassword,
            'lg:col-span-2': hasPassword
          })}>
          <FormField label={label} error={errors.name?.message}>
            <Input
              {...register('name')}
              readOnly={isRemoving || hasInitialName}
            />
          </FormField>
        </div>
        {hasPassword && (
          <div className="lg:col-span-2">
            <FormField label="Password" error={errors.password?.message}>
              <Input type="password" {...register('password')} />
            </FormField>
          </div>
        )}
        <div className="lg:col-span-1 lg:mt-5">
          <Button
            fullWidth
            variant={
              !isRemoving && !hasInitialName ? 'tertiary' : 'tertiary-danger'
            }
            loading={isAdding || isRemoving}
            disabled={isRemoving || disable}
            type={isRemoving || hasInitialName ? 'button' : 'submit'}
            onClick={() => {
              if (!hasInitialName) return
              removeIntegration(
                { key: service },
                {
                  onSuccess() {
                    setValue('name', '')
                    setValue('password', '')
                  }
                }
              )
            }}>
            {!isRemoving && !hasInitialName ? addText : removeText}
          </Button>
        </div>
      </form>
      {(!!addError || !!removeError) && (
        <div className="mt-2 text-sm text-red-600">
          {addError?.message || removeError?.message}
        </div>
      )}
    </div>
  )
}
