import { api } from '@cma/app'
import {
  ConnectProductMutation,
  ConnectProductMutationVariables
} from '@cma/generated/authenticated'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useConnectProduct() {
  return useMutation<
    ConnectProductMutation,
    Error,
    ConnectProductMutationVariables
  >(
    (service) => {
      const query = gql`
        mutation connectProduct($service: ProductKey) {
          connectProduct(service: $service) {
            acknowledged
          }
        }
      `
      const variables = service as ConnectProductMutationVariables
      const url = `${process.env.VITE_APP_CAS_URL}/graphql/authenticated`
      return api<ConnectProductMutation>({ query, variables, url })
    },
    {
      onSuccess() {
        window.location.href = `${process.env.VITE_APP_LW_AGENT_URL}/provisioning`
      }
    }
  )
}
