import { api } from '@cma/app'
import {
  GenerateBrokerAccountMutation,
  GenerateBrokerAccountMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { getBroker } from './useBroker'

export function useCreateBroker() {
  const queryClient = useQueryClient()
  return useMutation<
    GenerateBrokerAccountMutation,
    Error,
    GenerateBrokerAccountMutationVariables
  >(
    ({ input }) => {
      const query = gql`
        mutation GenerateBrokerAccount($input: GenerateBrokerAccountInput!) {
          generateBrokerAccount(brokerAccount: $input) {
            account {
              id
            }
            errors
          }
        }
      `
      const variables = { input }
      return api<GenerateBrokerAccountMutation>({ query, variables })
    },
    {
      async onSuccess(data) {
        const id = data.generateBrokerAccount?.account?.id || ''
        await queryClient.prefetchQuery(['broker'], () => getBroker({ id }))
      }
    }
  )
}
