import { useCallback, useEffect, useState } from 'react'

export function useTimedState(): [boolean, () => void] {
  const [isRunning, setIsRunning] = useState(false)

  useEffect(() => {
    if (!isRunning) return

    const timeout = setTimeout(() => {
      setIsRunning(false)
    }, 2000)

    return () => {
      clearTimeout(timeout)
    }
  }, [isRunning])

  const startTimer = useCallback(() => {
    setIsRunning(true)
  }, [])

  return [isRunning, startTimer]
}
