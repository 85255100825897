import { api } from '@cma/app'
import { ReceiptsQuery } from '@cma/generated/graphql'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useReceipts() {
  return useQuery<ReceiptsQuery, Error>(['receipts'], () => {
    const query = gql`
      query Receipts {
        currentUser {
          receipts {
            amount
            createdAt
            product
            type
          }
        }
      }
    `
    return api<ReceiptsQuery>({ query })
  })
}
