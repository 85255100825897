import { useCurrentUser } from '@cma/app'
import { Button, Flash, FormField, Input } from '@cma/common'
import { SettingsCard, useUpdatePassword } from '@cma/features/settings'
import { yupResolver } from '@hookform/resolvers/yup'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

const schema = yup.object({
  password: yup.string().required('The password is required'),
  passwordConfirmation: yup
    .string()
    .required('The password confirmation is required')
    .oneOf([yup.ref('password'), null], "The passwords don't match")
})

export default function Password() {
  const { data } = useCurrentUser()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema)
  })
  const {
    mutate: updatePassword,
    isLoading,
    isSuccess,
    error
  } = useUpdatePassword({
    onSuccess() {
      reset()
    }
  })

  const isLWManaged = data?.currentUser?.lwaId

  return (
    <>
      <Helmet>
        <title>Password - Account Settings - Cloud CMA</title>
      </Helmet>
      <SettingsCard>
        <div className="helix-d-flex helix-flex-direction--column helix-gap-6">
          <div className="helix-d-flex helix-flex-direction--column helix-gap-4">
            <SettingsCard.Title align="left">
              Lone Wolf Account
            </SettingsCard.Title>
            {isLWManaged ? (
              <>
                <p className="helix-body">
                  You are using your Lone Wolf login to sign into this account.
                  To manage your password and app connections go to your Lone
                  Wolf account.
                </p>
                <a
                  href={`${process.env.VITE_APP_LW_PLATFORM_URL}/account/manage`}
                  className="helix-btn helix-btn--primary helix-100-percent helix-text--center hover:no-underline">
                  Manage Lone Wolf Account
                </a>
              </>
            ) : (
              <>
                <p className="helix-body">
                  A Lone Wolf Account allows you to sign into all of your Lone
                  Wolf products with a single password, and switch between them
                  seamlessly.
                </p>
                <a
                  href={`${process.env.VITE_APP_CMA_URL}/connect/lone_wolf`}
                  className="helix-btn helix-btn--primary helix-100-percent helix-text--center hover:no-underline">
                  Create or Connect a Lone Wolf Account
                </a>
              </>
            )}
          </div>
          {!isLWManaged && (
            <>
              <div className="helix-w-100-percent helix-bg-gray-200 h-[1px]" />
              <div className="space-y-6">
                <SettingsCard.Title align="left">Password</SettingsCard.Title>
                <form
                  className="space-y-4"
                  onSubmit={handleSubmit((data) => updatePassword(data))}>
                  <FormField
                    required
                    label="Password"
                    error={errors.password?.message}>
                    <Input type="password" {...register('password')} />
                  </FormField>
                  <FormField
                    required
                    label="Confirm Password"
                    error={errors.passwordConfirmation?.message}>
                    <Input
                      type="password"
                      {...register('passwordConfirmation')}
                    />
                  </FormField>
                  <Button fullWidth loading={isLoading}>
                    Update Password
                  </Button>
                  {error && <Flash variant="error">{error?.message}</Flash>}
                  {isSuccess && (
                    <Flash variant="success">Password Updated!</Flash>
                  )}
                </form>
              </div>
            </>
          )}
        </div>
      </SettingsCard>
    </>
  )
}
