import { ConfirmationIcon, ErrorIcon, InfoIcon, WarningIcon } from '@cma/icons'
import { XIcon } from '@heroicons/react/outline'
import { toast, ToastContainer, ToastContent } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { classNames } from './utils'

type Variant = 'success' | 'error' | 'info' | 'warning' | 'default'

export function ToastProvider() {
  return (
    <ToastContainer
      draggable={false}
      className="space-y-3 sm:w-full sm:max-w-[26.4375rem]"
      toastClassName={(props) => {
        const variant = (props?.type || 'default') as Variant
        return classNames(
          'flex items-start p-4 text-sm rounded shadow-lg w-full',
          {
            'bg-green-700 text-white': variant === 'success',
            'bg-red-700 text-white': variant === 'error',
            'bg-blue-700 text-white': variant === 'info',
            'bg-yellow-700 text-white': variant === 'warning',
            'bg-gray-50 text-gray-900': variant === 'default'
          }
        )
      }}
      bodyClassName="p-0"
      position="bottom-right"
      hideProgressBar
      autoClose={3000}
      icon={false}
      closeButton={({ closeToast }) => (
        <button onClick={closeToast}>
          <XIcon className="h-6 w-6 flex-shrink-0" />
        </button>
      )}
    />
  )
}

interface ToastProps {
  variant?: Variant
  showIcon?: boolean
}

const _toast = (content: ToastContent, props?: ToastProps) => {
  const { variant = 'default', showIcon = true } = props || {}

  return toast(
    <div className="flex items-center space-x-3 pr-2">
      {showIcon && (
        <div className="h-6 w-6 flex-shrink-0" data-testid="toast-icon">
          {variant === 'success' && <ConfirmationIcon />}
          {variant === 'info' && <InfoIcon />}
          {variant === 'warning' && <WarningIcon />}
          {variant === 'error' && <ErrorIcon />}
          {variant === 'default' && <WarningIcon className="text-gray-500" />}
        </div>
      )}
      <div>
        <>{content}</>
      </div>
    </div>,
    {
      type: variant
    }
  )
}

export { _toast as toast }
