export type Status = 'complete' | 'invited' | 'notSetup' | 'onboarding'
export type SortDir = 'asc' | 'desc'
export enum StatusFilter {
  'all',
  'complete',
  'invited',
  'notSetup',
  'onboarding'
}

export function prettyStatus(status: StatusFilter) {
  switch (status) {
    case StatusFilter.all:
      return 'All'
    case StatusFilter.notSetup:
      return 'Not Setup'
    case StatusFilter.invited:
      return 'Invited'
    case StatusFilter.onboarding:
      return 'Onboarding'
    case StatusFilter.complete:
      return 'Complete'
  }
}
