export function TrashIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 4c0-.966.784-1.75 1.75-1.75h4c.966 0 1.75.784 1.75 1.75v.75h2.75a.75.75 0 0 1 .234 1.463l-.834 9.515v.002a2.75 2.75 0 0 1-2.74 2.52H6.84a2.75 2.75 0 0 1-2.74-2.52v-.002l-.834-9.515A.75.75 0 0 1 3.5 4.75h2.75V4Zm1.5.75h4.5V4a.25.25 0 0 0-.25-.25H8a.25.25 0 0 0-.25.25v.75Zm-2.975 1.5.82 9.354A1.25 1.25 0 0 0 6.84 16.75h6.32a1.25 1.25 0 0 0 1.245-1.146V15.6l.82-9.351H4.775ZM8.25 8a.75.75 0 0 1 .75.75v5.5a.75.75 0 0 1-1.5 0v-5.5A.75.75 0 0 1 8.25 8Zm3.5 0a.75.75 0 0 1 .75.75v5.5a.75.75 0 0 1-1.5 0v-5.5a.75.75 0 0 1 .75-.75Z"
        fill="currentColor"
      />
    </svg>
  )
}
