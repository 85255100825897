import { api } from '@cma/app'
import {
  UpdateFlyerMutation,
  UpdateFlyerMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useUpdateFlyer() {
  const queryClient = useQueryClient()
  return useMutation<
    UpdateFlyerMutation,
    Error,
    UpdateFlyerMutationVariables & { updateListings?: boolean }
  >(
    ({ id, input, updateListings }) => {
      const query = gql`
        mutation UpdateFlyer($id: ID!, $input: FlyerInput!) {
          updateFlyer(id: $id, input: $input) {
            flyer {
              id
            }
            errors
          }
        }
      `
      const variables = { id, input, updateListings: !!updateListings }
      return api<UpdateFlyerMutation>({ query, variables })
    },
    {
      async onSuccess(data) {
        await queryClient.invalidateQueries([
          'report',
          data.updateFlyer?.flyer?.id
        ])
      }
    }
  )
}
