export function CardsIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 20"
      {...props}>
      <path
        fillRule="evenodd"
        d="M10.5 4A1.5 1.5 0 0112 2.5h4A1.5 1.5 0 0117.5 4v4A1.5 1.5 0 0116 9.5h-4A1.5 1.5 0 0110.5 8V4zm-8 0A1.5 1.5 0 014 2.5h4A1.5 1.5 0 019.5 4v4A1.5 1.5 0 018 9.5H4A1.5 1.5 0 012.5 8V4zm8 8a1.5 1.5 0 011.5-1.5h4a1.5 1.5 0 011.5 1.5v4a1.5 1.5 0 01-1.5 1.5h-4a1.5 1.5 0 01-1.5-1.5v-4zm-8 0A1.5 1.5 0 014 10.5h4A1.5 1.5 0 019.5 12v4A1.5 1.5 0 018 17.5H4A1.5 1.5 0 012.5 16v-4zM4 4v4h4V4H4zm8 0v4h4V4h-4zm4 8v4h-4v-4h4zM4 12v4h4v-4H4z"
        clipRule="evenodd"
      />
    </svg>
  )
}
