import { Disclosure } from '@headlessui/react'
import { FolderIcon, PlusCircleIcon } from '@heroicons/react/outline'
import {
  ChevronDownIcon,
  ChevronRightIcon,
  FolderIcon as FolderIconClosed
} from '@heroicons/react/solid'
import { PropsWithChildren, useState } from 'react'
import { CustomizeClassicPowerPacks } from './CustomizeClassicPowerPacks'
import { CustomizePageEdit } from './CustomizePageEdit'
import { CustomizePagePreview } from './CustomizePagePreview'
import { CustomizePowerPacks } from './CustomizePowerPacks'
import { CustomizeTomFerryPacksPacks } from './CustomizeTomFerryPacks'
import { CustomizePageWithPages } from './useCustomizePages'

export function CustomizeInactivePages({
  children
}: PropsWithChildren<unknown>) {
  return <div>{children}</div>
}

CustomizeInactivePages.Folder = CustomizeInactiveFolder
CustomizeInactivePages.Group = CustomizeInactiveGroup
CustomizeInactivePages.Page = CustomizeInactivePageWrapper

interface CustomizeFolderProps {
  title: string
  empty?: boolean
}

function CustomizeInactiveFolder({
  title,
  empty,
  children
}: PropsWithChildren<CustomizeFolderProps>) {
  return (
    <div
      className="flex flex-col"
      data-testid={`customize-inactive-folder-${title}`}>
      <Disclosure defaultOpen>
        <Disclosure.Button
          className="flex items-center space-x-3 truncate text-lg text-gray-600"
          title={title}>
          {({ open }) => (
            <>
              {open ? (
                <FolderIcon className="h-6 w-6 flex-shrink-0" />
              ) : (
                <FolderIconClosed className="h-6 w-6 flex-shrink-0" />
              )}
              <span className="truncate">{title}</span>
            </>
          )}
        </Disclosure.Button>
        <Disclosure.Panel>
          <div className="-ml-2 space-y-4 pt-4 pl-11">
            {empty && (
              <div className="text-sm text-gray-600">No pages to select</div>
            )}
            {children}
          </div>
        </Disclosure.Panel>
      </Disclosure>
    </div>
  )
}

interface CustomizeInactiveGroupProps {
  title: string
}

function CustomizeInactiveGroup({
  title,
  children
}: PropsWithChildren<CustomizeInactiveGroupProps>) {
  return (
    <div data-testid={`customize-inactive-group-${title}`}>
      <Disclosure>
        <Disclosure.Button className="flex items-center space-x-2 text-gray-600">
          {({ open }) => (
            <>
              {open ? (
                <ChevronDownIcon className="h-6 w-6 text-green-500" />
              ) : (
                <ChevronRightIcon className="h-6 w-6 text-green-500" />
              )}
              <span>{title}</span>
            </>
          )}
        </Disclosure.Button>
        <Disclosure.Panel className="-ml-2.5 space-y-4 pl-10 pt-4">
          {children}
        </Disclosure.Panel>
      </Disclosure>
    </div>
  )
}

interface CustomizeInactivePageProps {
  page: CustomizePageWithPages
  onAdd: (page: CustomizePageWithPages) => void
}

function CustomizeInactivePageWrapper({
  page,
  onAdd
}: CustomizeInactivePageProps) {
  const isActive = page.isParent
    ? page.pages?.every((page) => page.active)
    : page.active

  if (isActive) return null

  if (page.isParent) {
    return (
      <div key={page.key} className="-ml-8">
        <CustomizeInactiveGroup title={page.title}>
          {page.pages?.map((page) => {
            if (page.active) return null
            return (
              <CustomizeInactivePage key={page.key} page={page} onAdd={onAdd} />
            )
          })}
        </CustomizeInactiveGroup>
      </div>
    )
  }

  return <CustomizeInactivePage key={page.key} page={page} onAdd={onAdd} />
}

function CustomizeInactivePage({ page, onAdd }: CustomizeInactivePageProps) {
  const [showClassicPowerPacks, setShowClassicPowerPacks] = useState(false)
  const [showPowerPacks, setShowPowerPacks] = useState(false)
  const [showTomFerryPacks, setShowTomFerryPacks] = useState(false)

  // TODO: Come from user config
  const hasClassicPowerPacks = true
  const hasPowerPacks = false
  const hasTomFerryPacks = false

  const canAddPage =
    page.group === 'inactive_pages' ||
    (page.group === 'inactive_marketing_2013' && hasClassicPowerPacks) ||
    (page.group === 'inactive_marketing_luxe' && hasPowerPacks) ||
    (page.group === 'inactive_marketing_tom_ferry' && hasTomFerryPacks)

  const showPack = () => {
    setShowClassicPowerPacks(page.group === 'inactive_marketing_2013')
    setShowPowerPacks(page.group === 'inactive_marketing_luxe')
    setShowTomFerryPacks(page.group === 'inactive_marketing_tom_ferry')
  }

  const hidePack = () => {
    setShowClassicPowerPacks(false)
    setShowPowerPacks(false)
    setShowTomFerryPacks(false)
  }

  return (
    <div className="flex items-center space-x-2">
      <button
        type="button"
        className="text-green-500"
        aria-label={`Add ${page.title}`}
        onClick={() => (canAddPage ? onAdd(page) : showPack())}>
        <PlusCircleIcon className="h-6 w-6" />
      </button>
      <div className="flex w-full min-w-0 items-baseline justify-between space-x-2">
        <CustomizePagePreview page={page} />
        <CustomizePageEdit page={page} />
      </div>
      <CustomizeClassicPowerPacks
        isOpen={showClassicPowerPacks}
        onClose={hidePack}
      />
      <CustomizePowerPacks isOpen={showPowerPacks} onClose={hidePack} />
      <CustomizeTomFerryPacksPacks
        isOpen={showTomFerryPacks}
        onClose={hidePack}
      />
    </div>
  )
}
