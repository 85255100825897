export function RotateIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 20"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.359 10c0-.985.727-2.034 2.25-2.88 1.498-.832 3.618-1.37 6-1.37s4.502.538 6 1.37c1.523.846 2.25 1.895 2.25 2.88 0 .685-.344 1.39-1.075 2.054-.734.665-1.818 1.245-3.162 1.642a.75.75 0 0 0 .426 1.439c1.497-.443 2.8-1.115 3.743-1.97.945-.856 1.568-1.937 1.568-3.165 0-1.777-1.288-3.228-3.022-4.191-1.76-.978-4.14-1.559-6.728-1.559S5.64 4.831 3.88 5.809C2.146 6.772.859 8.223.859 10c0 1.777 1.287 3.228 3.022 4.191 1.535.853 3.543 1.404 5.75 1.531l-1.79 1.705a.75.75 0 0 0 1.035 1.086l3.158-3.01.57-.543-.57-.543-3.158-3.01a.75.75 0 1 0-1.035 1.086l1.813 1.728c-1.991-.123-3.75-.622-5.045-1.341-1.523-.846-2.25-1.895-2.25-2.88Z"
        fill="currentColor"
      />
    </svg>
  )
}
