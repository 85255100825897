import { api } from '@cma/app'
import {
  AddOrChangeAlternateMlsMutation,
  AddOrChangeAlternateMlsMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useAddOrChangeAlternateMlsCredential() {
  const queryClient = useQueryClient()

  return useMutation<
    AddOrChangeAlternateMlsMutation,
    Error,
    AddOrChangeAlternateMlsMutationVariables
  >(
    (variables) => {
      const query = gql`
        mutation AddOrChangeAlternateMls(
          $id: ID
          $code: String!
          $name: String
          $password: String
        ) {
          AddOrChangeAlternateMlsCredential(
            input: { code: $code, name: $name, password: $password }
            alternateMlsCredentialId: $id
          ) {
            alternateMlsCredential {
              id
              code
              name
            }
            errors
          }
        }
      `
      return api<AddOrChangeAlternateMlsMutation>({
        query,
        variables: variables
      })
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries(['alternateMlses'])
      }
    }
  )
}
