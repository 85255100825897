import { api } from '@cma/app'
import {
  DestroyReportListingMutation,
  DestroyReportListingMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useDestroyReportListing() {
  const queryClient = useQueryClient()
  return useMutation<
    DestroyReportListingMutation,
    Error,
    DestroyReportListingMutationVariables & { reportId: string }
  >(
    ({ listingId }) => {
      const query = gql`
        mutation DestroyReportListing($listingId: ID!) {
          destroyListing(id: $listingId) {
            listing {
              id
            }
            errors
          }
        }
      `
      const variables = { listingId }
      return api({ query, variables })
    },
    {
      async onSuccess(_, { reportId }) {
        await queryClient.invalidateQueries(['report', reportId])
      }
    }
  )
}
