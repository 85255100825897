import { Wrapper } from '@cma/common'
import { Helmet } from 'react-helmet-async'

export default function Live() {
  return (
    <Wrapper>
      <Helmet>
        <title>Live CMA - Cloud CMA</title>
      </Helmet>
      <h1>Live</h1>
    </Wrapper>
  )
}
