import { XIcon } from '@heroicons/react/outline'
import { PropsWithChildren, useState } from 'react'
import { classNames } from './utils'

interface FlashProps {
  variant?: 'error' | 'info' | 'success' | 'note'
  sharp?: boolean
  closeable?: boolean
}

export function Flash({
  variant = 'error',
  sharp = false,
  closeable = true,
  children
}: PropsWithChildren<FlashProps>) {
  const [isOpen, setIsOpen] = useState(true)

  if (!isOpen) {
    return null
  }
  return (
    <div
      role="alert"
      aria-live="polite"
      className={classNames('flex items-center justify-between p-2 shadow', {
        'bg-red-200 text-red-500': variant === 'error',
        'bg-green-200 text-green-500': variant === 'success',
        'bg-blue-200 text-blue-500': variant === 'info',
        'bg-yellow-200 text-gray-600': variant === 'note',

        rounded: !sharp
      })}>
      <span className="text-sm">{children}</span>
      {closeable && (
        <button className="ml-5">
          <XIcon className="h-6 w-6" onClick={() => setIsOpen(false)} />
        </button>
      )}
    </div>
  )
}
