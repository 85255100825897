import { useBuildHandoffUrl } from '@cma/common'
import { getAccessTokens, useLogout } from '@cma/features/auth'
import {
  ApplicationSwitcher,
  Navbar,
  NavbarAdditionalItems,
  NavbarNavigationBar,
  NavbarUserMenu
} from '@helix/navbar'
import { Link } from 'react-router-dom'
import { UserRole, useCurrentUser } from './useCurrentUser'
import { useProductAvailability } from './useProductAvailability'

export function LegacyNavigation() {
  const { data: { currentUser } = {} } = useCurrentUser()
  const { data: appSwitcher } = useProductAvailability()
  const buildHandoffUrl = useBuildHandoffUrl()
  const logout = useLogout()
  const accessTokens = getAccessTokens()

  return (
    <Navbar
      logoUrl={buildHandoffUrl('/')}
      dataLwtId="default"
      applicationName={!appSwitcher?.enabled ? 'Cloud CMA' : undefined}>
      {appSwitcher?.enabled && (
        <ApplicationSwitcher
          applicationName="Cloud CMA"
          appSections={[
            {
              sectionName: 'Promote',
              sectionItems: appSwitcher.products.map((product) => ({
                selected: product.key === 'cloud_cma',
                icon:
                  product.key === 'cloud_cma'
                    ? 'compare'
                    : product.key === 'cloud_streams'
                    ? 'alert'
                    : product.key === 'cloud_mlx'
                    ? 'search'
                    : product.key === 'cloud_attract'
                    ? 'agent'
                    : product.key === 'launchpad'
                    ? 'cards'
                    : undefined,
                label: product.name || 'N/A',
                description: product.tagline || '',
                onClick:
                  product.key !== 'cloud_cma'
                    ? () => product.url && window.open(product.url, '_self')
                    : undefined
              }))
            }
          ]}
        />
      )}
      <NavbarNavigationBar
        navItems={[
          { label: 'CMA', url: '/cmas' },
          { label: 'Buyer Tour', url: '/tours' },
          { label: 'Property', url: '/properties' },
          currentUser?.features?.hasFlyers
            ? { label: 'Flyer', url: '/flyers' }
            : undefined,
          currentUser?.features?.hasDocuments
            ? { label: 'Documents', url: '/documents' }
            : undefined,
          currentUser?.features?.hasHomebeat ||
          currentUser?.features?.offerHomebeat
            ? { label: 'Homebeat', url: '/homebeats' }
            : undefined
        ].filter((item): item is { label: string; url: string } => !!item)}
        linkWrapper={({ item }) => {
          if (
            item.url ===
            `${process.env.VITE_APP_CMA_URL}/admin/account/${currentUser?.account?.id}`
          ) {
            return <a href={item.url}>{item.label}</a>
          }

          const isExternal = ['Learning Resources', 'Support Center'].includes(
            `${item.label}`
          )

          if (isExternal) {
            return (
              <a href={item.url} target="_blank" rel="noreferrer">
                {item.label}
              </a>
            )
          }

          return <Link to={item.url || ''}>{item.label}</Link>
        }}
      />
      <NavbarAdditionalItems>
        <NavbarUserMenu
          user={{
            name: currentUser?.name,
            email: currentUser?.email,
            avatarURL: currentUser?.avatar || undefined
          }}
          menuItems={[
            { label: 'Account Settings', url: '/settings' },
            (currentUser?.role || 0) >= UserRole.ADMIN_PLUS
              ? { label: 'Brokers', url: '/admin/brokers' }
              : currentUser?.features?.hasBrokerAccess
              ? { label: 'Dashboard', url: '/dashboard' }
              : // Old dashboard for backward compatibility
              currentUser?.isAdmin && currentUser.account?.isSmallBroker
              ? {
                  label: 'Dashboard',
                  url: `${process.env.VITE_APP_CMA_URL}/admin/account/${currentUser.account?.id}`
                }
              : {},
            { divider: true },
            { label: 'Custom Pages', url: '/settings/custom-pages' },
            currentUser?.features?.hasApiButton
              ? { label: 'Lead Generation', url: '/settings/lead-generation' }
              : {},
            { divider: true },
            {
              label: 'Learning Resources',
              url: 'https://learn.cloudagentsuite.com/videos-cma'
            },
            {
              label: 'Support Center',
              url: 'https://lonewolf.my.site.com/s/cloud-cma-resources'
            },
            { divider: true },
            {
              label: accessTokens?.impersonateToken
                ? 'Back to your account'
                : 'Sign Out',
              onClick: () => logout({ shouldUseCmaToLogout: true })
            }
          ].filter((item) => !!Object.keys(item).length)}
          linkWrapper={({ item }) => {
            if (
              item.url ===
              `${process.env.VITE_APP_CMA_URL}/admin/account/${currentUser?.account?.id}`
            ) {
              return <a href={item.url}>{item.label}</a>
            }

            const isExternal = [
              'Learning Resources',
              'Support Center'
            ].includes(`${item.label}`)

            if (isExternal) {
              return (
                <a href={item.url} target="_blank" rel="noreferrer">
                  {item.label}
                </a>
              )
            }

            return <Link to={item.url || ''}>{item.label}</Link>
          }}
        />
      </NavbarAdditionalItems>
    </Navbar>
  )
}
