import { useCallback, useMemo, useState } from 'react'
import { DropResult } from 'react-beautiful-dnd'

export type CustomizePageGroup =
  | 'inactive_pages'
  | 'inactive_marketing_2013'
  | 'inactive_marketing_luxe'
  | 'inactive_marketing_tom_ferry'

export interface CustomizePage {
  key: string
  title: string
  active: boolean
  parent: string | null
  isParent: boolean
  isCustom: boolean
  position: number
  group: CustomizePageGroup
}

export interface CustomizePageWithPages extends CustomizePage {
  pages?: CustomizePage[]
}

// We only allow listing sub pages at the moment
// If we want to add more in the future, add
// the parent key to this array.
const validSubPageParentKeys = [/*'introduction', */ 'listings']

// Builds pages under a parent if one is available.
// If a parent isn't available, it'll build that
// page as a top level page.
function buildPageTree(pages: CustomizePage[]): CustomizePageWithPages[] {
  const subPagesMap = pages.reduce<Record<string, CustomizePageWithPages[]>>(
    (pages, page) => {
      if (!page.parent) return pages
      return {
        ...pages,
        [page.parent]: [...(pages[page.parent] ?? []), page]
      }
    },
    {}
  )
  const nestedPages = pages
    .filter((page) => {
      const isParent = page.isParent
      // A page can have no parent but also not
      // be a parent such as a power pack page.
      const noParent = !page.parent
      return isParent || noParent
    })
    .map((page) => ({ ...page, pages: subPagesMap[page.key] }))

  return nestedPages
}

type InactivePages = Record<
  CustomizePageGroup,
  {
    title: string
    pages: CustomizePageWithPages[]
  }
>

function sortByPosition(a: CustomizePageWithPages, b: CustomizePageWithPages) {
  if (a.position > b.position) return 1
  if (a.position < b.position) return -1
  return 0
}

function buildInactivePages(pages: CustomizePage[]): InactivePages {
  const nestedPages = buildPageTree(pages)
  const pagesAsGroups = nestedPages.reduce<InactivePages>(
    (byKey, data) => {
      const currentGroup = byKey[data.group]
      return {
        ...byKey,
        [data.group]: {
          ...currentGroup,
          pages: [...currentGroup.pages, data]
        }
      }
    },
    {
      inactive_pages: { title: 'Standard', pages: [] },
      inactive_marketing_2013: { title: 'Classic Power Pack Pages', pages: [] },
      inactive_marketing_luxe: { title: 'Power Pack Pages', pages: [] },
      inactive_marketing_tom_ferry: { title: 'Tom Ferry Pages', pages: [] }
    }
  )

  return pagesAsGroups
}

function buildActivePages(pages: CustomizePage[]): CustomizePageWithPages[] {
  const nestedPages = buildPageTree(pages)
  const activePages = nestedPages
    // Filters out all the inactive children
    .reduce<CustomizePageWithPages[]>((pages, page) => {
      return [
        ...pages,
        {
          ...page,
          pages: page.pages?.filter((page) => page.active).sort(sortByPosition)
        }
      ]
    }, [])
    // Filter out pages based on if the children are
    // active or if the parent itself is active
    .filter((page) => {
      return page.pages?.some((page) => page.active) ?? page.active
    })

  // Flatten all the groups except for keys in `validSubPageParentKeys`
  const flattenedPages = activePages.reduce<CustomizePageWithPages[]>(
    (pages, page) => {
      if (
        !Array.isArray(page.pages) ||
        validSubPageParentKeys.includes(page.key)
      ) {
        return [...pages, page]
      }
      return [...pages, ...(page?.pages ?? [])]
    },
    []
  )

  // Sort the active pages
  const sortedPages = [...flattenedPages].sort(sortByPosition)

  return sortedPages
}

function reorderPages(
  pages: CustomizePage[],
  startIndex: number,
  endIndex: number
) {
  const newPages = [...pages]
  const [pageToMove] = newPages.splice(startIndex, 1)
  newPages.splice(endIndex, 0, pageToMove)
  return newPages
}

function buildSortedPages(
  pages: CustomizePage[],
  startIndex: number,
  endIndex: number
): CustomizePage[] {
  const activePages = buildActivePages(pages)
  const reorderedActivePages = reorderPages(activePages, startIndex, endIndex)
  const reorderedActivePositions = reorderedActivePages
    // Only modify up to (including) the last item changed
    .slice(0, Math.max(startIndex, endIndex) + 1)
    .map((page, index) => ({ ...page, position: index }))
    .reduce<Record<string, number>>(
      (pages, page) => ({ ...pages, [page.key]: page.position }),
      {}
    )
  const sortedPages: CustomizePage[] = pages.map((page) => {
    return {
      ...page,
      position: reorderedActivePositions[page.key] ?? page.position
    }
  })

  return sortedPages
}

function buildSortedSubPages(
  pages: CustomizePage[],
  parentKey: string,
  startIndex: number,
  endIndex: number
) {
  const activePages = buildActivePages(pages)
  const subPages =
    activePages.find((page) => page.key === parentKey)?.pages ?? []
  const reorderedSubPages = reorderPages(subPages, startIndex, endIndex)
  const reorderedSubPagesPositions = reorderedSubPages
    // Only modify up to (including) the last item changed
    .slice(0, Math.max(startIndex, endIndex) + 1)
    .map((page, index) => ({ ...page, position: index }))
    .reduce<Record<string, number>>(
      (pages, page) => ({ ...pages, [page.key]: page.position }),
      {}
    )
  const sortedPages = pages.map((page) => {
    return {
      ...page,
      position: reorderedSubPagesPositions[page.key] ?? page.position
    }
  })

  return sortedPages
}

function activatePage(pages: CustomizePage[], pageKey: string) {
  return pages.map((page) => {
    if (page.key === pageKey) {
      return { ...page, active: true }
    }
    return page
  })
}

function deactivatePage(pages: CustomizePage[], pageKey: string) {
  return pages.map((page) => {
    if (page.key === pageKey) {
      return { ...page, active: false }
    }
    return page
  })
}

export function useCustomizePages(initialPages: CustomizePage[]) {
  const [pages, setPages] = useState<CustomizePage[]>(initialPages)
  const inactivePages = useMemo(() => buildInactivePages(pages), [pages])
  const activePages = useMemo(() => buildActivePages(pages), [pages])

  const addPage = useCallback(
    (pageKey: string) => {
      const newPages = activatePage(pages, pageKey)
      setPages(newPages)
    },
    [pages]
  )

  const removePage = useCallback(
    (pageKey: string) => {
      const newPages = deactivatePage(pages, pageKey)
      setPages(newPages)
    },
    [pages]
  )

  const handleSortPages = useCallback(
    (result: DropResult) => {
      if (!result.destination) return

      const startIndex = result.source.index
      const endIndex = result.destination.index

      if (result.type.includes('sub-pages')) {
        // This comes from `types` on `<CustomizeActivePages />`
        const parentKey = result.type.replace('-sub-pages', '')
        const sortedSubPages = buildSortedSubPages(
          pages,
          parentKey,
          startIndex,
          endIndex
        )
        setPages(sortedSubPages)
      } else {
        const sortedPages = buildSortedPages(pages, startIndex, endIndex)
        setPages(sortedPages)
      }
    },
    [pages]
  )

  const getActivePageKeys = useCallback(() => {
    return activePages.reduce<string[]>(
      (pageKeys, page) => [
        ...pageKeys,
        page.key,
        ...(page.pages?.map((page) => page.key) ?? [])
      ],
      []
    )
  }, [activePages])

  return useMemo(
    () => ({
      inactivePages,
      activePages,
      addPage,
      removePage,
      sortPages: handleSortPages,
      getActivePageKeys
    }),
    [
      inactivePages,
      activePages,
      addPage,
      removePage,
      handleSortPages,
      getActivePageKeys
    ]
  )
}
