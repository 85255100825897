import { api } from '@cma/app'
import {
  UpdateTourMutation,
  UpdateTourMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { getReportTemplates } from '../report'

export function useUpdateTour() {
  const queryClient = useQueryClient()
  return useMutation<UpdateTourMutation, Error, UpdateTourMutationVariables>(
    async ({ id, input }) => {
      const query = gql`
        mutation UpdateTour($id: ID!, $input: LiveTourInput!) {
          updateTour(id: $id, input: $input) {
            errors
            tour {
              id
            }
          }
        }
      `
      const variables = { id, input }
      const res = await api<UpdateTourMutation>({ query, variables })
      return res
    },
    {
      async onSuccess({ updateTour }) {
        const id = updateTour?.tour?.id
        await Promise.all([
          queryClient.invalidateQueries(['tour', id]),
          queryClient.prefetchQuery(['reportTemplates', id], () =>
            getReportTemplates(id)
          )
        ])
      }
    }
  )
}
