import { useCurrentUser } from '@cma/app'
import { Link } from '@cma/common'

export function TourBanner() {
  const { data: { currentUser } = {} } = useCurrentUser()

  if (!currentUser?.features?.hasLiveTours || currentUser.liveToursEnabled) {
    return null
  }

  return (
    <div className="flex items-center justify-center gap-2 bg-suite-700 p-2.5 text-sm text-white">
      <div className="rounded-full bg-purple-400 px-2 py-0.5 text-xs font-semibold">
        New Feature
      </div>
      <div>You can now create new interactive live tours.</div>
      <Link to="/tours/new" className="font-bold text-white hover:no-underline">
        Try Live Tours →
      </Link>
    </div>
  )
}
