import { api } from '@cma/app'
import { ContactItemsQuery } from '@cma/generated/graphql'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useContactItems() {
  return useQuery<ContactItemsQuery, Error>(['contactItems'], () => {
    const query = gql`
      fragment ContactItemParts on ContactItem {
        id
        position
        prettyKey
        value
      }

      query ContactItems {
        currentUser {
          contactItems {
            ...ContactItemParts
          }
        }
      }
    `
    return api<ContactItemsQuery>({ query })
  })
}
