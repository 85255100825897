import { useCurrentUser, UserRole } from '@cma/app'
import {
  Badge,
  Button,
  formatNumber,
  Input,
  Spinner,
  Table,
  Tabs,
  Wrapper
} from '@cma/common'
import {
  DeleteAccount,
  prettyStatus,
  StatusFilter,
  useBrokers
} from '@cma/features/admin/broker'
import {
  BrokerFilterFieldEnum,
  BrokerFilterInput
} from '@cma/generated/graphql'
import { ShortcutIcon } from '@cma/icons'
import { HelixIcon } from '@helix/helix-icon'
import { trash_can as TrashCanIcon } from '@helix/helix-icon/outlined'
import { PlusIcon, SearchIcon } from '@heroicons/react/outline'
import {
  ChangeEvent,
  createContext,
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDebounce } from 'usehooks-ts'

const BrokersContext = createContext<{
  status: StatusFilter
  search: string
  setStatusFilter: (newFilter: StatusFilter) => void
}>({
  status: StatusFilter.all,
  search: '',
  setStatusFilter() {}
})

export default function Brokers() {
  const inputRef = useRef<HTMLInputElement>(null)
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)
  const [statusFilter, setStatusFilter] = useState<StatusFilter>(
    StatusFilter.all
  )

  const isFocusedRef = useRef(false)
  useEffect(() => {
    function onKeyPress(event: KeyboardEvent) {
      if (event.key === '/' && !isFocusedRef.current) {
        event.preventDefault()
        inputRef.current?.focus()
      }
    }
    document.body.addEventListener('keydown', onKeyPress)
    return () => {
      document.body.removeEventListener('keydown', onKeyPress)
    }
  }, [])

  const context = {
    search: debouncedSearch,
    setStatusFilter
  }

  return (
    <Wrapper>
      <header className="flex items-baseline justify-between space-x-2">
        <h1 className="text-4xl font-medium">Broker Accounts</h1>
        <Button as={Link} to="/admin/brokers/new" leftIcon={<PlusIcon />}>
          New Broker
        </Button>
      </header>
      <div className="space-y-4">
        <Tabs
          selectedIndex={statusFilter}
          onChange={(status) => setStatusFilter(status)}>
          <div className="flex items-center justify-between space-x-2">
            <Tabs.List>
              <Tabs.Tab>All</Tabs.Tab>
              <Tabs.Tab>Not Setup</Tabs.Tab>
              <Tabs.Tab>Invited</Tabs.Tab>
              <Tabs.Tab>Onboarding</Tabs.Tab>
              <Tabs.Tab>Complete</Tabs.Tab>
            </Tabs.List>
            <div className="w-full max-w-[29%]">
              <Input
                ref={inputRef}
                aria-label="search"
                leftIcon={<SearchIcon />}
                rightIcon={<ShortcutIcon className="text-gray-700" />}
                placeholder="Search broker name or key"
                value={search}
                onFocus={() => (isFocusedRef.current = true)}
                onBlur={() => (isFocusedRef.current = false)}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSearch(e.target.value)
                }
              />
            </div>
          </div>
          <Tabs.Panels>
            <BrokersContext.Provider
              value={{ ...context, status: StatusFilter.all }}>
              <BrokerPanel />
            </BrokersContext.Provider>
            <BrokersContext.Provider
              value={{ ...context, status: StatusFilter.notSetup }}>
              <BrokerPanel />
            </BrokersContext.Provider>
            <BrokersContext.Provider
              value={{ ...context, status: StatusFilter.invited }}>
              <BrokerPanel />
            </BrokersContext.Provider>
            <BrokersContext.Provider
              value={{ ...context, status: StatusFilter.onboarding }}>
              <BrokerPanel />
            </BrokersContext.Provider>
            <BrokersContext.Provider
              value={{ ...context, status: StatusFilter.complete }}>
              <BrokerPanel />
            </BrokersContext.Provider>
          </Tabs.Panels>
        </Tabs>
      </div>
    </Wrapper>
  )
}

function BrokerPanel() {
  const { data: { currentUser } = {} } = useCurrentUser()
  const canDelete = (currentUser?.role || 0) >= UserRole.ADMIN_PLUS

  return (
    <Tabs.Panel>
      <div className="space-y-2 overflow-auto rounded-lg bg-white shadow print:border print:shadow-none">
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Header>Brokerage</Table.Header>
              <Table.Header>Admin</Table.Header>
              <Table.Header align="right">Seats Used</Table.Header>
              <Table.Header align="right">Active Agents</Table.Header>
              <Table.Header align="right">Status</Table.Header>
              {canDelete && (
                <Table.Header align="right">
                  <span className="sr-only">Delete</span>
                </Table.Header>
              )}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <Suspense
              fallback={
                <Table.Row>
                  <Table.Data colSpan={5}>
                    <div className="flex items-center space-x-2 text-gray-500">
                      <div className="h-5 w-5">
                        <Spinner />
                      </div>
                      <div>Loading Brokers</div>
                    </div>
                  </Table.Data>
                </Table.Row>
              }>
              <BrokerData />
            </Suspense>
          </Table.Body>
        </Table>
      </div>
    </Tabs.Panel>
  )
}

function BrokerData() {
  const { status, search } = useContext(BrokersContext)
  const { data: { brokers } = {} } = useBrokers({
    filters: [
      { field: BrokerFilterFieldEnum.Status, value: StatusFilter[status] },
      search ? { field: BrokerFilterFieldEnum.Name, value: search } : undefined
    ].filter((filter): filter is BrokerFilterInput => !!filter)
  })
  const navigate = useNavigate()
  const { data: { currentUser } = {} } = useCurrentUser()
  const canDelete = (currentUser?.role || 0) >= UserRole.ADMIN_PLUS

  return (
    <>
      {!!search && !brokers?.length && (
        <Table.Row>
          <Table.Data colSpan={5}>
            <NoResults />
            {status !== StatusFilter.all && <SearchAll />}
          </Table.Data>
        </Table.Row>
      )}
      {!search && !brokers?.length && (
        <Table.Row>
          <Table.Data>
            <div>0 Brokerages Found</div>
          </Table.Data>
        </Table.Row>
      )}
      {brokers?.map((broker) => (
        <Table.Row key={broker.id} highlightOnHover>
          <Table.Data onClick={() => navigate(`/admin/brokers/${broker.id}`)}>
            <div>{broker.name}</div>
            <code className="mt-1 block text-xs text-gray-500">
              {broker.key}
            </code>
          </Table.Data>
          <Table.Data onClick={() => navigate(`/admin/brokers/${broker.id}`)}>
            <div>{broker.admin?.name}</div>
            <code className="mt-1 block text-xs text-gray-500">
              {broker.admin?.email}
            </code>
          </Table.Data>
          <Table.Data
            align="right"
            onClick={() => navigate(`/admin/brokers/${broker.id}`)}>
            {formatNumber(broker.seatsUsed || 0)}
          </Table.Data>
          <Table.Data
            align="right"
            onClick={() => navigate(`/admin/brokers/${broker.id}`)}>
            {formatNumber(broker.activeAgentCount || 0)}
          </Table.Data>
          <Table.Data
            align="right"
            onClick={() => navigate(`/admin/brokers/${broker.id}`)}>
            {broker.status === 'complete' && (
              <Badge variant="success">Complete</Badge>
            )}
            {broker.status === 'invited' && (
              <Badge variant="warning">Invited</Badge>
            )}
            {broker.status === 'notSetup' && (
              <Badge variant="danger">Not Setup</Badge>
            )}
            {broker.status === 'onboarding' && (
              <Badge variant="info">Onboarding</Badge>
            )}
          </Table.Data>
          {canDelete && (
            <Table.Data align="right">
              <DeleteBrokerButton id={broker.id} name={broker.name} />
            </Table.Data>
          )}
        </Table.Row>
      ))}
    </>
  )
}

interface DeleteBrokerButtonProps {
  id: string
  name: string
}

function DeleteBrokerButton({ id, name }: DeleteBrokerButtonProps) {
  const [showDelete, setShowDelete] = useState(false)

  return (
    <>
      <button
        className="flex items-center justify-end [&_path]:hover:fill-red-500"
        onClick={(e) => {
          e.stopPropagation()
          setShowDelete(true)
        }}>
        <HelixIcon icon={TrashCanIcon} />
      </button>
      <DeleteAccount
        id={id}
        name={name}
        isOpen={showDelete}
        onClose={() => setShowDelete(false)}
      />
    </>
  )
}

function NoResults() {
  const { status, search } = useContext(BrokersContext)

  return (
    <div className="flex justify-center">
      <p className="p-4 text-lg">
        There are no results for <span className="font-bold">"{search}"</span>{' '}
        in <span className="font-bold">{prettyStatus(status)}</span> broker
        accounts.
      </p>
    </div>
  )
}

function SearchAll() {
  const { setStatusFilter } = useContext(BrokersContext)

  return (
    <div className="flex justify-center">
      <Button size="lg" onClick={() => setStatusFilter(0)}>
        Search All instead?
      </Button>
    </div>
  )
}
