import { api } from '@cma/app'
import {
  BrokerAgentsQuery,
  BrokerAgentsQueryVariables
} from '@cma/generated/graphql'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useBrokerAgents({
  id,
  first,
  last,
  after,
  before,
  sortKey,
  filters
}: BrokerAgentsQueryVariables) {
  return useQuery<BrokerAgentsQuery, Error>(
    ['brokerAgents', id, first, last, after, before, sortKey, filters],
    () => {
      const query = gql`
        fragment BrokerAgentParts on Agent {
          id
          firstname
          lastname
          email
          isBrokerAdmin
          status
          avatar
          reportCount
          lastLoggedIn
          recentReport {
            __typename
            title
            updatedAt
            pdfPermalink
            guid
          }
        }

        query BrokerAgents(
          $id: ID!
          $after: String
          $before: String
          $first: Int
          $last: Int
          $filters: [AgentFilterInput!]
          $sortKey: AgentSortEnum
        ) {
          broker(id: $id) {
            agents(
              after: $after
              before: $before
              first: $first
              last: $last
              filters: $filters
              sorting: $sortKey
            ) {
              total
              pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
              }
              edges {
                node {
                  ...BrokerAgentParts
                }
              }
            }
          }
        }
      `
      const variables = {
        id,
        first,
        last,
        after,
        before,
        sortKey,
        filters //: filters?.filter(({ value }) => !!value)
      }
      return api<BrokerAgentsQuery>({ query, variables })
    },
    {
      enabled: !!id
    }
  )
}
