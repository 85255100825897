import { api } from '@cma/app'
import { HomeWorthQuery, HomeWorthQueryVariables } from '@cma/generated/graphql'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useHomeWorthUser({ guid }: HomeWorthQueryVariables) {
  return useQuery<HomeWorthQuery, Error>(
    ['homeWorth', guid],
    () => {
      const query = gql`
        query HomeWorth($guid: String) {
          user(guid: $guid) {
            id
            name
            email
            avatar
            companyName
            officePhone
            mobilePhone
            licenseNumber
            licenseLabel
            website
          }
        }
      `
      const variables = { guid }
      return api<HomeWorthQuery>({ query, variables })
    },
    {
      enabled: !!guid
    }
  )
}
