export function IconLayoutFlyer12(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 233 303" {...props}>
      <path
        fill="currentColor"
        d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
      />
      <path
        fill="currentColor"
        d="M184.74 244.8h23.59v3h-23.59zm-92.89 0h79.98v3H91.85zm0 18.41h116.48v3H91.85zm0-36.75h116.48v3H91.85zM26.44 23.75h36.83v4H26.44zm56.99 0h124.9v4H83.43zM33.49 225.42h36.83v4H33.49zm-3.44 19.79h43.71v4H30.05zm8.48 17.53h26.75v4H38.53z"
      />
      <rect
        fill="#e1e5e9"
        x="25.78"
        y="43.53"
        width="51.53"
        height="44.22"
        rx="5"
        ry="5"
      />
      <path
        fill="currentColor"
        d="M72.31 89.25H30.78a6.51 6.51 0 0 1-6.5-6.5V48.53a6.51 6.51 0 0 1 6.5-6.5h41.53a6.51 6.51 0 0 1 6.5 6.5v34.22a6.51 6.51 0 0 1-6.5 6.5zM30.78 45a3.5 3.5 0 0 0-3.5 3.5v34.25a3.5 3.5 0 0 0 3.5 3.5h41.53a3.5 3.5 0 0 0 3.5-3.5V48.53a3.5 3.5 0 0 0-3.5-3.5z"
      />
      <rect
        fill="#e1e5e9"
        x="90.73"
        y="43.53"
        width="51.53"
        height="44.22"
        rx="5"
        ry="5"
      />
      <path
        fill="currentColor"
        d="M137.27 89.25H95.73a6.51 6.51 0 0 1-6.5-6.5V48.53a6.51 6.51 0 0 1 6.5-6.5h41.54a6.51 6.51 0 0 1 6.5 6.5v34.22a6.51 6.51 0 0 1-6.5 6.5zM95.73 45a3.5 3.5 0 0 0-3.5 3.5v34.25a3.5 3.5 0 0 0 3.5 3.5h41.54a3.5 3.5 0 0 0 3.5-3.5V48.53a3.5 3.5 0 0 0-3.5-3.5z"
      />
      <rect
        fill="#e1e5e9"
        x="155.69"
        y="43.53"
        width="51.53"
        height="44.22"
        rx="5"
        ry="5"
      />
      <path
        fill="currentColor"
        d="M202.22 89.25h-41.53a6.51 6.51 0 0 1-6.5-6.5V48.53a6.51 6.51 0 0 1 6.5-6.5h41.53a6.51 6.51 0 0 1 6.5 6.5v34.22a6.51 6.51 0 0 1-6.5 6.5zM160.69 45a3.5 3.5 0 0 0-3.5 3.5v34.25a3.5 3.5 0 0 0 3.5 3.5h41.53a3.5 3.5 0 0 0 3.5-3.5V48.53a3.5 3.5 0 0 0-3.5-3.5z"
      />
      <rect
        fill="#e1e5e9"
        x="25.78"
        y="100.86"
        width="51.53"
        height="44.22"
        rx="5"
        ry="5"
      />
      <path
        fill="currentColor"
        d="M72.31 146.58H30.78a6.5 6.5 0 0 1-6.5-6.5v-34.21a6.5 6.5 0 0 1 6.5-6.5h41.53a6.51 6.51 0 0 1 6.5 6.5v34.21a6.51 6.51 0 0 1-6.5 6.5zm-41.53-44.21a3.5 3.5 0 0 0-3.5 3.5v34.21a3.5 3.5 0 0 0 3.5 3.5h41.53a3.5 3.5 0 0 0 3.5-3.5v-34.21a3.5 3.5 0 0 0-3.5-3.5z"
      />
      <rect
        fill="#e1e5e9"
        x="90.73"
        y="100.86"
        width="51.53"
        height="44.22"
        rx="5"
        ry="5"
      />
      <path
        fill="currentColor"
        d="M137.27 146.58H95.73a6.51 6.51 0 0 1-6.5-6.5v-34.21a6.51 6.51 0 0 1 6.5-6.5h41.54a6.51 6.51 0 0 1 6.5 6.5v34.21a6.51 6.51 0 0 1-6.5 6.5zm-41.54-44.21a3.5 3.5 0 0 0-3.5 3.5v34.21a3.5 3.5 0 0 0 3.5 3.5h41.54a3.5 3.5 0 0 0 3.5-3.5v-34.21a3.5 3.5 0 0 0-3.5-3.5z"
      />
      <rect
        fill="#e1e5e9"
        x="155.69"
        y="100.86"
        width="51.53"
        height="44.22"
        rx="5"
        ry="5"
      />
      <path
        fill="currentColor"
        d="M202.22 146.58h-41.53a6.51 6.51 0 0 1-6.5-6.5v-34.21a6.51 6.51 0 0 1 6.5-6.5h41.53a6.5 6.5 0 0 1 6.5 6.5v34.21a6.5 6.5 0 0 1-6.5 6.5zm-41.53-44.21a3.5 3.5 0 0 0-3.5 3.5v34.21a3.5 3.5 0 0 0 3.5 3.5h41.53a3.5 3.5 0 0 0 3.5-3.5v-34.21a3.5 3.5 0 0 0-3.5-3.5z"
      />
      <rect
        fill="#e1e5e9"
        x="25.78"
        y="159.2"
        width="51.53"
        height="44.22"
        rx="5"
        ry="5"
      />
      <path
        fill="currentColor"
        d="M72.31 204.91H30.78a6.5 6.5 0 0 1-6.5-6.5V164.2a6.5 6.5 0 0 1 6.5-6.5h41.53a6.51 6.51 0 0 1 6.5 6.5v34.21a6.51 6.51 0 0 1-6.5 6.5zM30.78 160.7a3.5 3.5 0 0 0-3.5 3.5v34.21a3.5 3.5 0 0 0 3.5 3.5h41.53a3.5 3.5 0 0 0 3.5-3.5V164.2a3.5 3.5 0 0 0-3.5-3.5z"
      />
      <rect
        fill="#e1e5e9"
        x="90.73"
        y="159.2"
        width="51.53"
        height="44.22"
        rx="5"
        ry="5"
      />
      <path
        fill="currentColor"
        d="M137.27 204.91H95.73a6.51 6.51 0 0 1-6.5-6.5V164.2a6.51 6.51 0 0 1 6.5-6.5h41.54a6.51 6.51 0 0 1 6.5 6.5v34.21a6.51 6.51 0 0 1-6.5 6.5zM95.73 160.7a3.5 3.5 0 0 0-3.5 3.5v34.21a3.5 3.5 0 0 0 3.5 3.5h41.54a3.5 3.5 0 0 0 3.5-3.5V164.2a3.5 3.5 0 0 0-3.5-3.5z"
      />
      <rect
        fill="#e1e5e9"
        x="155.69"
        y="159.2"
        width="51.53"
        height="44.22"
        rx="5"
        ry="5"
      />
      <path
        fill="currentColor"
        d="M202.22 204.91h-41.53a6.51 6.51 0 0 1-6.5-6.5V164.2a6.51 6.51 0 0 1 6.5-6.5h41.53a6.5 6.5 0 0 1 6.5 6.5v34.21a6.5 6.5 0 0 1-6.5 6.5zm-41.53-44.21a3.5 3.5 0 0 0-3.5 3.5v34.21a3.5 3.5 0 0 0 3.5 3.5h41.53a3.5 3.5 0 0 0 3.5-3.5V164.2a3.5 3.5 0 0 0-3.5-3.5z"
      />
    </svg>
  )
}
