import { formatCurrency, formatNumber, Modal } from '@cma/common'
import { ListingCard } from '@cma/features/listing'
import { TourAppointment } from '@cma/generated/graphql'
import { ChevronRightIcon } from '@cma/icons'
import { useLayoutEffect, useRef, useState } from 'react'
import { TourAppointmentDetails } from './TourAppointmentDetails'
import { TourHeader } from './TourHeader'
import { useTour } from './useTour'

interface TourCompareProps {
  tourId?: string
  isOpen: boolean
  onClose: () => void
}

export function TourCompare({ tourId, isOpen, onClose }: TourCompareProps) {
  const [selectedAppointment, setSelectedApppointment] =
    useState<TourAppointment>()
  const { data: { tour } = {} } = useTour({ id: tourId })
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number>()
  const gridContainerRef = useRef<HTMLDivElement>(null)
  const gridRef = useRef<HTMLDivElement>(null)
  const subjectPropertyRef = useRef<HTMLDivElement>(null)
  const secondComparableRef = useRef<HTMLDivElement>(null)
  const scrollbarRef = useRef<HTMLDivElement>(null)

  const handleScroll = (direction: 'next' | 'prev') => {
    if (!secondComparableRef.current) return

    const currentGridScrollLeft = gridContainerRef.current?.scrollLeft || 0
    const comparableWidth = Math.round(
      secondComparableRef.current.getBoundingClientRect().width
    )
    const comparableMarginLeft = parseInt(
      window.getComputedStyle(secondComparableRef.current).marginLeft,
      10
    )

    let newGridScrollLeft =
      currentGridScrollLeft +
      comparableWidth * (direction === 'next' ? 1 : -1) +
      comparableMarginLeft * (direction === 'next' ? 1 : -1)

    if (newGridScrollLeft <= 0) newGridScrollLeft = 0
    gridContainerRef.current?.scroll({
      left: newGridScrollLeft,
      behavior: 'smooth'
    })
  }

  useLayoutEffect(() => {
    const subjectPropertyRect =
      subjectPropertyRef.current?.getBoundingClientRect()
    const width =
      (subjectPropertyRect?.width || 0) * (tour?.appointments.length || 0)

    if (gridRef.current) {
      gridRef.current.style.width = `${width}px`
    }
  }, [tour?.appointments.length])

  // We manually update display so there is no lag on scroll.
  useLayoutEffect(() => {
    const gridContainer = gridContainerRef.current

    const handler = () => {
      ;(gridContainer?.scrollLeft || 0) > 0
        ? subjectPropertyRef.current?.classList.add('scrolling')
        : subjectPropertyRef.current?.classList.remove('scrolling')

      const bodyWidth = document.body.getBoundingClientRect().width
      const gridWidth = gridRef.current?.getBoundingClientRect().width || 0
      gridWidth > bodyWidth
        ? scrollbarRef.current?.classList.add('show')
        : scrollbarRef.current?.classList.remove('show')
      gridWidth > bodyWidth
        ? gridContainer?.classList.add('pad')
        : gridContainer?.classList.remove('pad')
    }
    handler()
    gridContainer?.addEventListener('scroll', handler)
    window.addEventListener('resize', handler)
    return () => {
      gridContainer?.removeEventListener('scroll', handler)
      window.removeEventListener('resize', handler)
    }
  })

  return (
    <Modal size="full-screen" isOpen={isOpen} onClose={onClose}>
      <div className="-mx-4 -mb-4 -mt-4 sm:-m-6">
        <TourHeader
          title={tour?.title}
          agentName={tour?.agent?.name}
          agentPhone={tour?.agent?.mobilePhone || tour?.agent?.officePhone}
          agentAvatar={tour?.agent?.avatar}
          companyLogo={tour?.agent?.companyLogoUrl}
          onBackClick={onClose}
        />
        <div
          ref={gridContainerRef}
          className="flex snap-x snap-mandatory gap-[0.0625rem] overflow-x-auto border-b bg-gray-200 scrollbar-hide"
          tabIndex={-1}>
          {tour?.appointments.map((appointment, index) => (
            <div
              key={appointment.id}
              ref={
                index === 0
                  ? subjectPropertyRef
                  : index === 1
                  ? secondComparableRef
                  : null
              }
              role="button"
              tabIndex={index + 1}
              className="w-[20.5625rem] shrink-0 snap-start"
              onMouseEnter={() => setHoveredRowIndex(index)}
              onMouseLeave={() => setHoveredRowIndex(undefined)}
              onClick={() => setSelectedApppointment(appointment)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setSelectedApppointment(appointment)
                }
              }}>
              <ListingCard>
                <div className="h-[13.125rem] w-full bg-gray-100">
                  {appointment.listing.photos?.[0] && (
                    <img
                      src={appointment.listing.photos[0]}
                      alt={appointment.listing.address || 'Thumbnail'}
                      className="h-full w-full object-cover"
                    />
                  )}
                </div>
                <ListingCard.Address
                  street={appointment.listing.address}
                  city={appointment.listing.city}
                  state={appointment.listing.state}
                  zip={appointment.listing.zip}
                  price={appointment.listing.price}
                  status={appointment.listing.status}
                  mappedStatus={appointment.listing.mappedStatus}
                />
                <ListingCard.Compare
                  label="Beds"
                  value={
                    appointment.listing.beds
                      ? formatNumber(appointment.listing.beds)
                      : ''
                  }
                  highlight={hoveredRowIndex === 1}
                  onMouseEnter={() => setHoveredRowIndex(1)}
                  onMouseLeave={() => setHoveredRowIndex(undefined)}
                />
                <ListingCard.Compare
                  label="Baths"
                  value={
                    appointment.listing.baths
                      ? formatNumber(appointment.listing.baths)
                      : ''
                  }
                  highlight={hoveredRowIndex === 2}
                  onMouseEnter={() => setHoveredRowIndex(2)}
                  onMouseLeave={() => setHoveredRowIndex(undefined)}
                />
                <ListingCard.Compare
                  label="Sq. Ft. (Total)"
                  value={
                    appointment.listing.sqft
                      ? formatNumber(appointment.listing.sqft)
                      : ''
                  }
                  highlight={hoveredRowIndex === 3}
                  onMouseEnter={() => setHoveredRowIndex(3)}
                  onMouseLeave={() => setHoveredRowIndex(undefined)}
                />
                <ListingCard.Compare
                  label="Lot Size"
                  value={
                    appointment.listing.lotsize
                      ? `${formatNumber(appointment.listing.lotsize)} sq. ft.`
                      : ''
                  }
                  highlight={hoveredRowIndex === 4}
                  onMouseEnter={() => setHoveredRowIndex(4)}
                  onMouseLeave={() => setHoveredRowIndex(undefined)}
                />
                <ListingCard.Compare
                  label="Garage Spaces"
                  value={
                    appointment.listing.garages
                      ? formatNumber(Number(appointment.listing.garages))
                      : ''
                  }
                  highlight={hoveredRowIndex === 5}
                  onMouseEnter={() => setHoveredRowIndex(5)}
                  onMouseLeave={() => setHoveredRowIndex(undefined)}
                />
                <ListingCard.Compare
                  label="Year Built"
                  value={String(appointment.listing.yearBuilt || '')}
                  highlight={hoveredRowIndex === 6}
                  onMouseEnter={() => setHoveredRowIndex(6)}
                  onMouseLeave={() => setHoveredRowIndex(undefined)}
                />
                <ListingCard.Header>Pricing Details</ListingCard.Header>
                <ListingCard.Compare
                  label="List Price"
                  value={
                    appointment.listing.priceList
                      ? formatCurrency(appointment.listing.priceList)
                      : ''
                  }
                  highlight={hoveredRowIndex === 7}
                  onMouseEnter={() => setHoveredRowIndex(7)}
                  onMouseLeave={() => setHoveredRowIndex(undefined)}
                />
                <ListingCard.Compare
                  label="Price/SqFt"
                  value={
                    appointment.listing.pricePerSqft
                      ? formatCurrency(appointment.listing.pricePerSqft)
                      : ''
                  }
                  highlight={hoveredRowIndex === 8}
                  onMouseEnter={() => setHoveredRowIndex(8)}
                  onMouseLeave={() => setHoveredRowIndex(undefined)}
                />
                {[
                  appointment.listing.schoolElementary,
                  appointment.listing.schoolMiddle,
                  appointment.listing.schoolHigh
                ].some(Boolean) && (
                  <>
                    <ListingCard.Header>Schools</ListingCard.Header>
                    {!!appointment.listing.schoolElementary && (
                      <ListingCard.Compare
                        label="Elementary"
                        value={appointment.listing.schoolElementary}
                        highlight={hoveredRowIndex === 10}
                        onMouseEnter={() => setHoveredRowIndex(10)}
                        onMouseLeave={() => setHoveredRowIndex(undefined)}
                      />
                    )}
                    {appointment.listing.schoolMiddle && (
                      <ListingCard.Compare
                        label="Middle"
                        value={appointment.listing.schoolMiddle}
                        highlight={hoveredRowIndex === 11}
                        onMouseEnter={() => setHoveredRowIndex(11)}
                        onMouseLeave={() => setHoveredRowIndex(undefined)}
                      />
                    )}
                    {appointment.listing.schoolHigh && (
                      <ListingCard.Compare
                        label="High"
                        value={appointment.listing.schoolHigh}
                        highlight={hoveredRowIndex === 12}
                        onMouseEnter={() => setHoveredRowIndex(12)}
                        onMouseLeave={() => setHoveredRowIndex(undefined)}
                      />
                    )}
                  </>
                )}
              </ListingCard>
            </div>
          ))}
        </div>
        <div className="absolute bottom-6 left-1/2 -translate-x-1/2">
          <div className="flex w-24 items-center justify-between rounded-full bg-slate-900/50 shadow-lg">
            <button
              className="flex h-10 w-10 items-center justify-center text-white"
              onClick={() => handleScroll('prev')}>
              <ChevronRightIcon className="h-5 w-5 rotate-180" />
              <span className="sr-only">Slide left</span>
            </button>
            <button
              className="flex h-10 w-10 items-center justify-center text-white"
              onClick={() => handleScroll('next')}>
              <ChevronRightIcon className="h-5 w-5" />
              <span className="sr-only">Slide right</span>
            </button>
          </div>
        </div>
        <TourAppointmentDetails
          tourId={tourId}
          appointment={selectedAppointment}
          isFullscreen
          isOpen={!!selectedAppointment}
          onClose={() => setSelectedApppointment(undefined)}
        />
      </div>
    </Modal>
  )
}
