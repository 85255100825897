import {
  Alert,
  Button,
  Flash,
  formatNumber,
  Input,
  Modal,
  RichTextEditor,
  Select
} from '@cma/common'
import { CustomPagePartsFragment } from '@cma/generated/graphql'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useCreateCustomPage } from './useCreateCustomPage'
import { useCustomPages } from './useCustomPages'
import { useDestroyCustomPage } from './useDestroyCustomPage'
import { useUpdateCustomPage } from './useUpdateCustomPage'
import { isTemplateKey } from './utils'

type CustomPageWithKeyPresent = CustomPagePartsFragment & { key: string }
type CustomPageWithKeyAndIdPresent = CustomPagePartsFragment & {
  key: string
  id: string
}

interface CustomPageEditorProps {
  isOpen: boolean
  initialPageKey?: string
  onClose: () => void
}

const schema = yup.object({
  newPageTitle: yup.string().required('Page Title is required')
})

const NEW_PAGE_KEY = '__ADD_A_NEW_PAGE__'
export function CustomPageEditor({
  isOpen,
  initialPageKey = '',
  onClose
}: CustomPageEditorProps) {
  const [pageKey, setPageKey] = useState(initialPageKey)
  const [content, setContent] = useState('')
  const [isRenaming, setIsRenaming] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const { data: customPagesQuery } = useCustomPages()
  const customPages = customPagesQuery?.currentUser?.customPages

  const showMaxCountError = content.length > RichTextEditor.MAX_CHAR_COUNT

  const selectedPage = useMemo(() => {
    if (pageKey === '') {
      return null
    }
    return customPages?.find((page) => page.key === pageKey) ?? null
  }, [customPages, pageKey])

  const defaultPages = useMemo(
    () =>
      customPages?.filter(
        (page): page is CustomPageWithKeyPresent =>
          (page.isDefault && page.key !== null) || false
      ) ?? [],
    [customPages]
  )

  const myCustomPages = useMemo(
    () =>
      customPages?.filter(
        (
          page
        ): page is CustomPagePartsFragment & CustomPageWithKeyAndIdPresent =>
          (page.id !== null && page.key !== null && !page.isDefault) || false
      ) ?? [],
    [customPages]
  )

  const { register, handleSubmit, reset } = useForm<
    yup.InferType<typeof schema>
  >({
    resolver: yupResolver(schema)
  })

  const {
    mutate: updateCustomPage,
    isLoading: isUpdatingCustomPage,
    error: updateCustomPageError
  } = useUpdateCustomPage()

  const {
    mutate: createCustomPage,
    isLoading: isCreatingCustomPage,
    error: createCustomPageError
  } = useCreateCustomPage()

  const {
    mutate: destroyCustomPage,
    isLoading: isDestroyingCustomPage,
    error: destroyCustomPageError
  } = useDestroyCustomPage()

  useEffect(() => {
    setPageKey(initialPageKey)
  }, [initialPageKey, setPageKey])

  useEffect(() => {
    setContent(selectedPage?.content ?? '')
  }, [selectedPage, setContent])

  function handleRename({ newPageTitle }: yup.InferType<typeof schema>) {
    updateCustomPage(
      {
        id: selectedPage?.id ?? '',
        input: {
          key: newPageTitle,
          value: selectedPage?.content ?? ''
        }
      },
      {
        onSuccess: (data) => {
          const key = data.updateCustomPage?.customPage?.key ?? null
          setIsRenaming(false)
          if (key !== null) {
            setPageKey(key)
          }
          reset()
        }
      }
    )
  }

  function handleNew({ newPageTitle }: yup.InferType<typeof schema>) {
    createCustomPage(
      {
        input: {
          key: newPageTitle,
          value: ''
        }
      },
      {
        onSuccess: (data) => {
          const key = data.createCustomPage?.customPage?.key ?? null
          if (key !== null) {
            setPageKey(key)
          }
          reset()
        }
      }
    )
  }

  function handleSave() {
    const selectedId = selectedPage?.id ?? null
    const selectedKey = selectedPage?.key ?? null

    if (!selectedPage || selectedKey === null) {
      return
    }

    if (selectedId === null) {
      return createCustomPage({
        input: {
          key: selectedKey,
          value: content
        }
      })
    } else {
      return updateCustomPage({
        id: selectedId,
        input: {
          key: selectedKey,
          value: content
        }
      })
    }
  }

  const isCreating = pageKey === NEW_PAGE_KEY
  return (
    <Modal isOpen={isOpen} size="4xl" disableTrap onClose={onClose}>
      <div className="mb-4">
        <Modal.Title>Custom Pages</Modal.Title>
      </div>
      <div className="space-y-4" data-testid="custom-page-editor">
        {!isRenaming && !isCreating && (
          <div className="flex space-x-3">
            <Select
              value={pageKey}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setPageKey(e.target.value)
              }}>
              <option value="" disabled>
                Select an Option
              </option>
              <optgroup label="Create">
                <option value={NEW_PAGE_KEY}>Add a New Page</option>
              </optgroup>
              <optgroup label="Default Pages">
                {defaultPages.map((page) => (
                  <option key={page.key} value={page.key}>
                    {page.title}
                    {isTemplateKey(page.key) && ' (This Template*)'}
                  </option>
                ))}
              </optgroup>
              {myCustomPages.length && (
                <optgroup label="My Custom Pages">
                  {myCustomPages.map((page) => (
                    <option key={page.key} value={page.key}>
                      {page.title}
                    </option>
                  ))}
                </optgroup>
              )}
            </Select>
            {selectedPage !== null && !selectedPage.isDefault && (
              <>
                <button
                  className="p-2 text-sm text-green-500"
                  onClick={() => setIsRenaming(true)}>
                  Rename
                </button>
                <button
                  className="p-2 text-sm text-red-500"
                  onClick={() => setIsDeleting(true)}>
                  Delete
                </button>
              </>
            )}
          </div>
        )}
        {isRenaming && (
          <form onSubmit={handleSubmit(handleRename)}>
            <div className="mb-4 flex space-x-3">
              <Input
                autoFocus
                {...register('newPageTitle')}
                defaultValue={selectedPage?.title || ''}
              />
              <button
                className="p-2 text-sm text-green-500 disabled:opacity-50"
                disabled={isUpdatingCustomPage}>
                {isUpdatingCustomPage ? 'Updating...' : 'Update'}
              </button>
              <button
                className="p-2 text-sm text-green-500"
                onClick={(e) => {
                  e.preventDefault()
                  setIsRenaming(false)
                  reset()
                }}>
                Cancel
              </button>
            </div>
          </form>
        )}
        {isCreating && (
          <form onSubmit={handleSubmit(handleNew)}>
            <div className="mb-4 flex space-x-3">
              <Input autoFocus {...register('newPageTitle')} />
              <button
                className="p-2 text-sm text-green-500 disabled:opacity-50"
                disabled={isCreatingCustomPage}>
                {isCreatingCustomPage ? 'Adding...' : 'Add'}
              </button>
              <button
                className="p-2 text-sm text-green-500"
                onClick={(e) => {
                  e.preventDefault()
                  setPageKey('')
                  reset()
                }}>
                Cancel
              </button>
            </div>
          </form>
        )}
        {isTemplateKey(selectedPage?.key) && (
          <p className="text-sm italic text-purple-500">
            * A template is the starting point for this page in future reports.
          </p>
        )}
        <div className="relative h-[31rem]">
          {(pageKey === NEW_PAGE_KEY || pageKey === '') && (
            <div className="absolute top-0 left-0 z-20 flex h-full w-full items-center justify-center bg-white bg-opacity-60 text-sm text-gray-500">
              {pageKey === NEW_PAGE_KEY &&
                'Content editing is not available while creating a new page.'}
              {pageKey === '' && 'Select a page to edit content.'}
            </div>
          )}
          <RichTextEditor
            value={content}
            onChange={setContent}
            settings={{ auto_focus: true }}
          />
        </div>
        <div className="flex justify-end">
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={pageKey === NEW_PAGE_KEY || pageKey === ''}
            loading={
              (!isRenaming && isUpdatingCustomPage) || isCreatingCustomPage
            }>
            {(!isRenaming && isUpdatingCustomPage) || isCreatingCustomPage
              ? 'Saving...'
              : 'Save'}
          </Button>
        </div>
        {createCustomPageError && (
          <Flash variant="error">{createCustomPageError.message}</Flash>
        )}
        {updateCustomPageError && (
          <Flash variant="error">{updateCustomPageError.message}</Flash>
        )}
        {destroyCustomPageError && (
          <Flash variant="error">{destroyCustomPageError.message}</Flash>
        )}
        {showMaxCountError && (
          <Flash variant="error" closeable={true}>
            Your page content exceeds the maximum number of{' '}
            {formatNumber(RichTextEditor.MAX_CHAR_COUNT)} characters. Your
            changes will not be saved until you meet this limit.
          </Flash>
        )}
      </div>

      <Alert variant="danger" isOpen={isDeleting}>
        <Alert.Title>Delete Report</Alert.Title>
        <Alert.Content>
          Are you sure you want to delete this report (
          <strong className="font-medium text-gray-700">
            {selectedPage?.title}
          </strong>
          )? This action cannot be undone.
          {destroyCustomPageError && (
            <div className="text-red-500" role="alert" aria-live="polite">
              {destroyCustomPageError.message}
            </div>
          )}
        </Alert.Content>
        <Alert.Cancel onClick={() => setIsDeleting(false)}>Cancel</Alert.Cancel>
        <Alert.Confirm
          loading={isDestroyingCustomPage}
          onClick={() => {
            destroyCustomPage(
              { id: selectedPage?.id ?? '' },
              {
                onSuccess: () => {
                  setIsDeleting(false)
                }
              }
            )
          }}>
          Delete Forever
        </Alert.Confirm>
      </Alert>
    </Modal>
  )
}
