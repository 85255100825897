import { api } from '@cma/app'
import {
  DestroyOrderMutation,
  DestroyOrderMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useDestroyOrder() {
  const queryClient = useQueryClient()
  return useMutation<
    DestroyOrderMutation,
    Error,
    DestroyOrderMutationVariables
  >(
    ({ id }) => {
      const query = gql`
        mutation DestroyOrder($id: ID!) {
          destroyOrder(id: $id) {
            errors
            result
          }
        }
      `
      const variables = { id }
      return api<DestroyOrderMutation>({ query, variables })
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries(['billing'])
      }
    }
  )
}
