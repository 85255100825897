import {
  ArrowRightLongIcon,
  ChevronLeftIcon,
  CloudCmaVerticalIcon,
  CloudIcon
} from '@cma/icons'
import { Outlet } from 'react-router-dom'

export default function AuthLayout() {
  return (
    <div className="flex h-[100dvh] flex-grow flex-col items-center justify-center">
      <div className="flex w-full flex-col items-center justify-between space-y-4 p-6 sm:flex-row sm:space-y-0">
        <a
          href={process.env.VITE_APP_CAS_URL}
          target="_blank"
          rel="noreferrer"
          className="flex items-center space-x-2 text-xl font-medium text-suite-900 hover:no-underline">
          <ChevronLeftIcon className="mr-2 w-6 flex-shrink-0" />
          <CloudIcon className="w-8 flex-shrink-0" />
          <span>Cloud Agent Suite</span>
        </a>
        <a
          href={`${process.env.VITE_APP_CAS_URL}/tools/cma`}
          target="_blank"
          rel="noreferrer"
          className="flex items-center space-x-1 text-sm font-medium text-green-500 hover:no-underline">
          <span>Learn more about Cloud CMA</span>
          <ArrowRightLongIcon className="h-5 w-5" />
        </a>
      </div>
      <div className="flex w-full max-w-md flex-grow p-6">
        <div className="m-auto space-y-8">
          <div className="flex">
            <CloudCmaVerticalIcon className="mx-auto w-24 text-green-500"></CloudCmaVerticalIcon>
          </div>
          <Outlet />
          <nav className="w-full p-6">
            <ul className="flex justify-center space-x-6 text-xs">
              <li>
                <a
                  href={`${process.env.VITE_APP_CAS_URL}/tos`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-600 underline">
                  Terms of Service
                </a>
              </li>
              <li>
                <a
                  href={`${process.env.VITE_APP_CAS_URL}/privacy`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-600 underline">
                  Privacy
                </a>
              </li>
              <li>
                <a
                  href={`${process.env.VITE_APP_CAS_URL}/dmca`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-600 underline">
                  DMCA
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}
