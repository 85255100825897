import { api } from '@cma/app'
import {
  PartnershipUserConnectMutation,
  PartnershipUserConnectMutationVariables
} from '@cma/generated/graphql'
import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useSsoConnect(
  options?: Omit<
    UseMutationOptions<
      PartnershipUserConnectMutation,
      Error,
      PartnershipUserConnectMutationVariables
    >,
    'mutationFn'
  >
) {
  return useMutation<
    PartnershipUserConnectMutation,
    Error,
    PartnershipUserConnectMutationVariables
  >((variables) => {
    const query = gql`
      mutation PartnershipUserConnect($token: String!, $ouid: String!) {
        partnershipUserConnect(token: $token, ouid: $ouid) {
          redirectUrl
          errors
        }
      }
    `
    return api<PartnershipUserConnectMutation>({ query, variables })
  }, options)
}
