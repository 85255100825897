export function DirectionIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m9.238 11.012.841 5.046 6.097-11.983L4.192 10.17l5.046.841Zm-1.303 1.304.914 5.487c.159.952 1.44 1.15 1.878.29l7.482-14.707c.44-.863-.482-1.784-1.345-1.345L2.158 9.524c-.86.437-.663 1.719.289 1.877l5.488.915Z"
        fill="currentColor"
      />
    </svg>
  )
}
