import { api } from '@cma/app'
import { IntercomConfigQuery } from '@cma/generated/graphql'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useEffect } from 'react'
import {
  IntercomProps,
  useIntercom as useReactIntercom
} from 'react-use-intercom'

export function useIntercom() {
  const { boot, shutdown } = useReactIntercom()
  const { data: intercomConfig, isLoading } = useQuery<IntercomProps, Error>(
    ['intercomConfig'],
    async () => {
      const query = gql`
        query IntercomConfig {
          currentUser {
            name
            email
            createdAt
            deleted
            userHash
            mlsCredential {
              code
              name
            }
            account {
              name
              isSiteLicensed
              type
            }
            features {
              hasIntercomChat: intercomChat
              hasLuxePowerPack: luxePowerPack
              hasLuxePowerPackUpgrade: luxePowerPackUpgrade
              has2013MarketingPack: packMarketing2013
              hasTomFerryPack: tomFerryLpkt
            }
            stats {
              cmaCount
              buyerCount
              flyerCount
              propertyCount
            }
          }
        }
      `
      const res = await api<IntercomConfigQuery>({ query })

      // Layout taken from https://github.com/wrstudios/cloudcma/blob/master/app/models/user.rb#L1313 (intercom_custom_data)
      return {
        userId: res.currentUser?.email || 'INVALID_EMAIL@cloudcma.com',
        userHash: res.currentUser?.userHash || 'INVALID_USER_HASH',
        hideDefaultLauncher: true,
        email: res.currentUser?.email || 'INVALID_EMAIL@cloudcma.com',
        name: res.currentUser?.name || 'INVALID_NAME',

        customAttributes: {
          mls_code: res.currentUser?.mlsCredential?.code,
          mls_display_name: res.currentUser?.mlsCredential?.name,

          created_at: res.currentUser?.createdAt
            ? new Date(res.currentUser?.createdAt).valueOf() / 1000
            : res.currentUser?.createdAt,

          cma_created_at: res.currentUser?.createdAt
            ? new Date(res.currentUser?.createdAt).valueOf() / 1000
            : res.currentUser?.createdAt,
          cma_pack_marketing_2013:
            !!res.currentUser?.features?.has2013MarketingPack,
          cma_luxe_power_pack: !!res.currentUser?.features?.hasLuxePowerPack,
          cma_luxe_power_pack_upgrade:
            !!res.currentUser?.features?.hasLuxePowerPackUpgrade,
          cma_tom_ferry_lpkt: !!res.currentUser?.features?.hasTomFerryPack,
          cma_modern_ui: true,

          cma_cma_count: res.currentUser?.stats?.cmaCount || 0,
          cma_buyer_count: res.currentUser?.stats?.buyerCount || 0,
          cma_property_count: res.currentUser?.stats?.propertyCount || 0,
          cma_flyer_count: res.currentUser?.stats?.flyerCount || 0,

          cma_account: res.currentUser?.account?.name || '',
          cma_account_broker: !!res.currentUser?.account?.isSiteLicensed,
          cma_account_type: res.currentUser?.account?.type,

          cma_active: !res.currentUser?.deleted
        }
      }
    }
  )

  useEffect(() => {
    if (isLoading) return

    boot(intercomConfig)

    return () => {
      shutdown()
    }
  }, [isLoading, intercomConfig, boot, shutdown])
}
