export function IconLayoutFlyer1(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 233 303" {...props}>
      <path
        fill="currentColor"
        d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
      />
      <path
        fill="currentColor"
        d="M183.86 244.8h23.59v3h-23.59zm-92.89 0h79.98v3H90.97zm0 18.41h116.48v3H90.97zm0-36.75h116.48v3H90.97zM25.56 23.75h36.83v4H25.56zm56.98 0h124.9v4H82.54zM32.6 225.42h36.83v4H32.6zm-3.44 19.79h43.71v4H29.16zm8.48 17.53h26.75v4H37.64z"
      />
      <rect
        x="25.68"
        y="44.51"
        width="181.64"
        height="158.34"
        rx="5"
        ry="5"
        fill="#e1e5e9"
      />
      <path
        d="M202.32 204.34H30.68a6.5 6.5 0 0 1-6.5-6.5V49.51a6.5 6.5 0 0 1 6.5-6.5h171.64a6.5 6.5 0 0 1 6.5 6.5v148.33a6.5 6.5 0 0 1-6.5 6.5zM30.68 46a3.5 3.5 0 0 0-3.5 3.5v148.34a3.5 3.5 0 0 0 3.5 3.5h171.64a3.5 3.5 0 0 0 3.5-3.5V49.51a3.5 3.5 0 0 0-3.5-3.5z"
        fill="currentColor"
      />
      <path
        fill="#fff"
        d="m30.389 51.307 1.973-2.26 3.013 2.63-1.973 2.26zM189.38 190.1l-6-5.23 2-2.26 6 5.23zm-12-10.47-6-5.24 2-2.26 6 5.24zm-12-10.48-6-5.23 2-2.26 6 5.23zm-12-10.47-6-5.24 2-2.26 6 5.24zm-12-10.47-6-5.24 2-2.26 6 5.24zm-12-10.48-6-5.24 2-2.26 6 5.24zm-12-10.47-6-5.24 2-2.26 6 5.24zm-12-10.48-6-5.24 2-2.26 6 5.24zm-12-10.47-6-5.24 2-2.26 6 5.24zm-12-10.48-6-5.23 2-2.26 6 5.23zm-12-10.47-6-5.24 2-2.26 6 5.24zm-12-10.48-6-5.23 2-2.26 6 5.23zm-12-10.47-6-5.24 2-2.26 6 5.24zM195.383 195.34l1.973-2.26 3.014 2.631-1.973 2.26zM30.78 196.161l3-2.645 1.985 2.25-3 2.645z"
      />
      <path
        fill="#fff"
        d="m41.74 190.5-2-2.25 6-5.27 2 2.25zM53.68 180l-2-2.24 6-5.27 2 2.24zm11.94-10.54-2-2.25 6-5.26 2 2.25zm11.94-10.53-2-2.25 6-5.27 2 2.25zm11.94-10.58-2-2.25 6-5.27 2 2.25zm11.94-10.54-2-2.25 6-5.27 2 2.25zm11.94-10.54-2-2.25 6-5.27 2 2.25zm11.94-10.54-2-2.25 6-5.27 2 2.25zm12-10.54-2-2.25 6-5.27 2 2.25zm11.94-10.54-2-2.25 6-5.27 2 2.25zm11.94-10.54-2-2.25 6-5.27 2 2.25zm11.94-10.54-2-2.25 6-5.27 2 2.25zM185 64l-2-2.25 6-5.27 2 2.25zM194.987 51.236l3-2.646 1.984 2.25-3 2.646z"
      />
    </svg>
  )
}
