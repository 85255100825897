import { ChevronLeftIcon } from '@cma/icons'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import {
  Children,
  createContext,
  Fragment,
  PropsWithChildren,
  useContext
} from 'react'
import { Avatar } from './Avatar'
import { classNames } from './utils'

const ModalContext = createContext<{
  hideClose: boolean
  closeLabel: string
  disableTrap: boolean
  isFullScreen: boolean
  onClose: () => void
}>({
  hideClose: false,
  closeLabel: 'Exit',
  disableTrap: false,
  isFullScreen: false,
  onClose() {}
})

export interface ModalProps {
  size?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl'
    | '7xl'
    | 'max'
    | 'full-screen'
  fullHeight?: boolean
  isOpen: boolean
  hideClose?: boolean
  closeLabel?: string
  disableTrap?: boolean
  onClose: () => void
  onAfterClose?: () => void
}

export function Modal({
  size = 'lg',
  fullHeight = false,
  children,
  isOpen,
  hideClose = false,
  disableTrap = false,
  onClose: externalOnClose,
  closeLabel = 'Exit',
  onAfterClose
}: PropsWithChildren<ModalProps>) {
  const isFullScreen = size === 'full-screen'
  const childrenArray = Children.toArray(children)
  const title = childrenArray.find(
    (child) => (child as any).type.name === ModalTitle.name
  )
  const content = childrenArray.filter(
    (child) => (child as any).type.name !== ModalTitle.name
  )

  // Since we don't use transitions for full screen
  // we trigger on after close when we close the modal
  const onClose = () => {
    externalOnClose()
    if (isFullScreen) {
      onAfterClose?.()
    }
  }

  const body = (
    <ModalContext.Provider
      value={{ closeLabel, disableTrap, isFullScreen, onClose, hideClose }}>
      <div
        className={classNames('flex min-h-[100dvh] justify-center', {
          'items-center': !fullHeight,
          'p-4': size !== 'full-screen'
        })}>
        <Transition.Child
          as={Fragment}
          enter={classNames({ 'ease-out duration-300': !isFullScreen })}
          enterFrom={classNames({ 'opacity-0': !isFullScreen })}
          enterTo={classNames({ 'opacity-100': !isFullScreen })}
          leave={classNames({ 'ease-in duration-200': !isFullScreen })}
          leaveFrom={classNames({ 'opacity-100': !isFullScreen })}
          leaveTo={classNames({ 'opacity-0': !isFullScreen })}>
          <div>
            {disableTrap && (
              <div
                role="button"
                tabIndex={-1}
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                onClick={onClose}
                onKeyUp={(e) => {
                  if (e.key === 'Escape') {
                    onClose()
                  }
                }}
              />
            )}
            {!disableTrap && (
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            )}
          </div>
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter={classNames({ 'ease-out duration-300': !isFullScreen })}
          enterFrom={classNames({
            'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95':
              !isFullScreen
          })}
          enterTo={classNames({
            'opacity-100 translate-y-0 sm:scale-100': !isFullScreen
          })}
          leave={classNames({ 'ease-in duration-200': !isFullScreen })}
          leaveFrom={classNames({
            'opacity-100 translate-y-0 sm:scale-100': !isFullScreen
          })}
          leaveTo={classNames({
            'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95':
              !isFullScreen
          })}
          afterLeave={onAfterClose}>
          <div
            className={classNames('transform shadow-xl transition-all', {
              'max-w-xs': size === 'xs',
              'max-w-sm': size === 'sm',
              'max-w-md': size === 'md',
              'max-w-lg': size === 'lg',
              'max-w-xl': size === 'xl',
              'max-w-2xl': size === '2xl',
              'max-w-3xl': size === '3xl',
              'max-w-4xl': size === '4xl',
              'max-w-5xl': size === '5xl',
              'max-w-6xl': size === '6xl',
              'max-w-7xl': size === '7xl',
              'max-w-max': size === 'max',

              'w-full rounded-lg bg-white px-4 pb-4 pt-4 sm:my-8 sm:p-6':
                !isFullScreen,

              /**
               * We use a custom background color here to match legacy cma as we slowly migrate to this new project.
               * Once we've migrated the app over fully, we can switch this back to bg-gray-100
               */
              'flex h-[100dvh] w-screen flex-col  bg-[#f9fafb]': isFullScreen
            })}>
            {!hideClose && !isFullScreen && (
              <div className="absolute right-0 top-0 block pr-4 pt-4">
                <button
                  type="button"
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
                  onClick={() => onClose()}>
                  <span className="sr-only">Close Modal</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            )}
            {isFullScreen && (
              <>
                {title}
                <div className="flex-grow px-4 pb-4 pt-4 sm:p-6">{content}</div>
              </>
            )}
            {!isFullScreen && (
              <>
                {title}
                {content}
              </>
            )}
          </div>
        </Transition.Child>
      </div>
    </ModalContext.Provider>
  )

  if (disableTrap) {
    return (
      <Transition.Root show={isOpen} as={Fragment}>
        <div
          className={classNames('fixed inset-0 z-10', {
            'sm:overflow-y-auto': isFullScreen,
            'overflow-y-auto': !isFullScreen
          })}>
          {body}
        </div>
      </Transition.Root>
    )
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={classNames('fixed inset-0 z-10', {
          'sm:overflow-y-auto': isFullScreen,
          'overflow-y-auto': !isFullScreen
        })}
        open={isFullScreen ? isOpen : undefined}
        onClose={() => {
          // We don't want the user to close the modal when
          // they hit the escape key when in fullscreen.
          if (!isFullScreen) {
            onClose()
          }
        }}>
        {body}
      </Dialog>
    </Transition.Root>
  )
}

Modal.Title = ModalTitle

interface ModalTitleProps {
  agentName?: string
  agentAvatar?: string
  weight?: 400 | 600
}

function ModalTitle({
  children,
  agentName,
  agentAvatar,
  weight = 400
}: PropsWithChildren<ModalTitleProps>) {
  const { closeLabel, disableTrap, isFullScreen, onClose, hideClose } =
    useContext(ModalContext)

  return (
    <>
      {isFullScreen && (
        <nav className="sticky top-0 z-10">
          <div className="flex h-[3.75rem] items-center bg-white px-4 shadow-sm">
            <div className="flex w-1/12 items-center">
              {!hideClose && (
                <button
                  className="flex items-center space-x-2 text-sm text-gray-900"
                  onClick={onClose}>
                  <ChevronLeftIcon className="w-6 flex-shrink-0" />
                  <span>{closeLabel}</span>
                </button>
              )}
            </div>
            <div className="flex w-10/12 items-center justify-center">
              {disableTrap && <h3>{children}</h3>}
              {!disableTrap && <Dialog.Title as="h3">{children}</Dialog.Title>}
            </div>
            <div className="flex w-1/12 justify-end">
              <Avatar src={agentAvatar} name={agentName} size="sm" />
            </div>
          </div>
        </nav>
      )}
      {!isFullScreen && (
        <>
          {disableTrap && (
            <h3
              className={classNames('pr-6 text-2xl text-gray-700', {
                'font-normal': weight === 400,
                'font-semibold': weight === 600
              })}>
              {children}
            </h3>
          )}
          {!disableTrap && (
            <Dialog.Title
              as="h3"
              className={classNames('pr-6 text-2xl text-gray-700', {
                'font-normal': weight === 400,
                'font-semibold': weight === 600
              })}>
              {children}
            </Dialog.Title>
          )}
        </>
      )}
    </>
  )
}
