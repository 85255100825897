import {
  Button,
  classNames,
  getDateFromTime,
  Input,
  isSameDayMonthAndYear,
  NotFound,
  useQueryParams,
  Wrapper
} from '@cma/common'
import { ListingSearch, useListingSearch } from '@cma/features/listing'
import {
  TourAppointment,
  TourAppointmentDetails,
  TourDateSelector,
  TourHeader,
  TourMap,
  useCreateTour,
  useTour,
  useUpdateTour
} from '@cma/features/tour'
import { TourAppointment as ITourAppointment } from '@cma/generated/graphql'
import { ReorderIcon } from '@cma/icons'
import {
  AnimatePresence,
  motion,
  Reorder,
  useDragControls
} from 'framer-motion'
import {
  ChangeEvent,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState
} from 'react'
import { useMatch, useNavigate, useParams } from 'react-router-dom'
import { useMediaQuery } from 'usehooks-ts'

export default function TourDetails() {
  const { id } = useParams()
  const navigate = useNavigate()
  const isEditing = !!useMatch('/tours/:id/edit')
  const { data: { tour } = {}, isSuccess } = useTour({ id })
  const { mutate: createTour, isLoading: isCreating } = useCreateTour()
  const { mutate: updateTour, isLoading: isUpdating } = useUpdateTour()

  const queryParams = useQueryParams()
  const mlsNums = queryParams.get('mlsnums') || ''
  const { data: initialListings } = useListingSearch(
    { query: mlsNums },
    {
      enabled: !isEditing && !!mlsNums,
      suspense: true,
      staleTime: Infinity
    }
  )
  const _title = queryParams.get('title')
  const [title, setTitle] = useState(tour?.title || _title || '')
  const _date = queryParams.get('date')
  const [date, setDate] = useState<Date | undefined>(
    tour?.tourDate
      ? new Date(tour.tourDate)
      : _date
      ? new Date(_date)
      : undefined
  )
  const [drafts, setDrafts] = useState<Record<string, ITourAppointment>>({})
  const [appointments, setAppointments] = useState<ITourAppointment[]>(
    tour?.appointments
      ? tour.appointments // If we have a tour, use the tour appointments
      : initialListings // If we don't have a tour but have mls nums in the query string, create new appointments
      ? initialListings.map((listing, index) => ({
          id: Math.random().toString(16).substring(2),
          position: index,
          listing
        }))
      : [] // If we don't have a tour and we don't have mlsnums, default to empty array
  )
  const scheduledAppointments = appointments
    .filter((appointment) => appointment.startTime && appointment.endTime)
    .sort((a, b) => a.endTime?.localeCompare(b.endTime || '') || 0)
  const unscheduledAppointments = appointments
    .filter((appointment) => !appointment.startTime || !appointment.endTime)
    .sort((a, b) => {
      if ((a.position || 0) > (b.position || 0)) return 1
      if ((a.position || 0) < (b.position || 0)) return -1
      return 0
    })
  const [isDragging, setIsDragging] = useState(false)
  const [selectedAppointment, setSelectedAppointment] =
    useState<ITourAppointment>()
  const selectedAppointmentDraft = selectedAppointment
    ? drafts[selectedAppointment.id]
    : undefined

  const isAboveLargeBreakpoint = useMediaQuery('(min-width: 1024px)')
  const [showAppointmentDetails, setShowAppointmentDetails] = useState(false)

  if (
    isAboveLargeBreakpoint &&
    !!selectedAppointment &&
    !showAppointmentDetails
  ) {
    setShowAppointmentDetails(true)
  }

  if (isSuccess && !tour) {
    return (
      <Wrapper>
        <NotFound />
      </Wrapper>
    )
  }

  return (
    <>
      <TourHeader
        title={isEditing ? 'Update Tour' : 'Create Tour'}
        onBackClick={() => navigate('/tours')}
      />
      <div className="flex grow">
        <div className="flex h-[calc(100dvh-4rem)] w-full shrink-0 flex-col overflow-y-auto bg-gray-50 sm:max-w-[26.25rem] sm:shadow-raised">
          <div className="space-y-4 px-3 py-6 sm:p-8">
            <div className="space-y-1">
              <label htmlFor="-title">Title</label>
              <Input
                id="title"
                placeholder="Your Client's Name"
                value={title}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setTitle(e.target.value)
                }
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="date">Date</label>
              <TourDateSelector
                date={date}
                onSelect={(newDate) =>
                  setDate((currentDate) =>
                    isSameDayMonthAndYear(newDate, currentDate)
                      ? undefined
                      : newDate
                  )
                }
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="properties">Properties</label>
              <div className="space-y-4">
                <ListingSearch
                  placeholder="Search Address or MLS Number"
                  onSelect={(listing) => {
                    setAppointments((appointments) => {
                      const newAppointmentId = Math.random()
                        .toString(16)
                        .substring(2)

                      return [
                        ...appointments,
                        {
                          id: `new_appointment_${newAppointmentId}`,
                          listing,
                          position: appointments.length
                        }
                      ]
                    })
                  }}
                />
                <AnimatePresence initial={false}>
                  {scheduledAppointments.map((appointment) => (
                    <AnimatedAppointment
                      key={appointment.id}
                      appointment={appointment}
                      isSelected={selectedAppointment?.id === appointment.id}
                      isDragging={isDragging}
                      setAppointments={setAppointments}
                      setSelectedAppointment={setSelectedAppointment}
                      setDrafts={setDrafts}
                      onClick={() => {
                        if (isAboveLargeBreakpoint) {
                          setShowAppointmentDetails(true)
                        }
                        setSelectedAppointment(
                          selectedAppointment?.id !== appointment.id
                            ? appointment
                            : undefined
                        )
                      }}
                      onDoneClick={() => {
                        setShowAppointmentDetails(false)
                        setSelectedAppointment(undefined)
                      }}
                      onMoreClick={() => {
                        setShowAppointmentDetails(true)
                      }}
                    />
                  ))}
                </AnimatePresence>
                <Reorder.Group
                  axis="y"
                  layoutScroll
                  values={unscheduledAppointments}
                  onReorder={(reorderedAppointments: ITourAppointment[]) => {
                    setAppointments((appointments) => {
                      const scheduledAppointments = appointments.filter(
                        (appointment) =>
                          appointment.startTime && appointment.endTime
                      )

                      const unscheduledAppointments = reorderedAppointments.map(
                        (appointment, index) => ({
                          ...appointment,
                          position: index
                        })
                      )

                      return [
                        ...scheduledAppointments,
                        ...unscheduledAppointments
                      ]
                    })
                  }}>
                  <AnimatePresence initial={false}>
                    {unscheduledAppointments.map((appointment) => (
                      <ReorderableItem
                        key={appointment.id}
                        canDrag={
                          unscheduledAppointments.filter(
                            (appointment) =>
                              !appointment.startTime || !appointment.endTime
                          ).length > 1
                        }
                        appointment={appointment}
                        onDragStart={() => setIsDragging(true)}
                        onDragEnd={() => setIsDragging(false)}>
                        <AnimatedAppointment
                          appointment={appointment}
                          isSelected={
                            selectedAppointment?.id === appointment.id
                          }
                          isDragging={isDragging}
                          setAppointments={setAppointments}
                          setSelectedAppointment={setSelectedAppointment}
                          setDrafts={setDrafts}
                          onClick={() => {
                            if (isAboveLargeBreakpoint) {
                              setShowAppointmentDetails(true)
                            }
                            setSelectedAppointment(
                              selectedAppointment?.id !== appointment.id
                                ? appointment
                                : undefined
                            )
                          }}
                          onDoneClick={() => {
                            setShowAppointmentDetails(false)
                            setSelectedAppointment(undefined)
                          }}
                          onMoreClick={() => {
                            setShowAppointmentDetails(true)
                          }}
                        />
                      </ReorderableItem>
                    ))}
                  </AnimatePresence>
                </Reorder.Group>
              </div>
            </div>
          </div>
          <div className="sticky bottom-0 mt-auto space-y-2 bg-gray-50 p-8">
            <Button
              data-pendo-id={
                isEditing
                  ? 'update-live-tour-button'
                  : 'create-live-tour-button'
              }
              fullWidth
              loading={isCreating || isUpdating}
              disabled={!title || !appointments.length}
              onClick={() => {
                if (isEditing && id) {
                  updateTour(
                    {
                      id,
                      input: {
                        title,
                        date,
                        appointments: appointments.map(
                          // `listing` is only used when searching and displaying data initially
                          // eslint-disable-next-line @typescript-eslint/no-unused-vars
                          ({ id, listing, ...appointment }) => {
                            return {
                              ...appointment,
                              // If we are using a new appointment, omit id. The backend will create the id
                              // If we are using an existing appointment, inclide it so the backend can use
                              id: id.startsWith('new_appointment_')
                                ? undefined
                                : id,
                              // We remove the entire listing and instead send back an mls number
                              mlsNumber: listing.mlsnum,
                              startTime: appointment.startTime
                                ? getDateFromTime(appointment.startTime, date)
                                : null,
                              endTime: appointment.endTime
                                ? getDateFromTime(appointment.endTime, date)
                                : null
                            }
                          }
                        )
                      }
                    },
                    {
                      onSuccess({ updateTour }) {
                        navigate(`/tours/${updateTour?.tour?.id}`)
                      }
                    }
                  )
                } else {
                  createTour(
                    {
                      input: {
                        title,
                        date,
                        appointments: appointments.map(
                          // We remove id and listing before sending to the backend
                          // `id` is only used on the frontend to help with rendering
                          // `listing` is only used when searching and displaying data initially
                          // eslint-disable-next-line @typescript-eslint/no-unused-vars
                          ({ id, listing, ...appointment }) => {
                            return {
                              ...appointment,
                              // We remove the entire listing and instead send back an mls number
                              mlsNumber: listing.mlsnum,
                              startTime: appointment.startTime
                                ? getDateFromTime(appointment.startTime, date)
                                : undefined,
                              endTime: appointment.endTime
                                ? getDateFromTime(appointment.endTime, date)
                                : undefined
                            }
                          }
                        )
                      }
                    },
                    {
                      onSuccess({ createTour }) {
                        navigate(`/tours/${createTour?.tour?.id}`)
                      }
                    }
                  )
                }
              }}>
              {isEditing ? 'Update' : 'Create'} Tour
            </Button>
          </div>
        </div>
        <div className="relative hidden grow overflow-hidden bg-gray-100 sm:block">
          <TourMap
            appointments={[
              ...scheduledAppointments,
              ...unscheduledAppointments
            ]}
            onPinClick={(appointment) => setSelectedAppointment(appointment)}
          />
          <div className="hidden lg:block">
            <TourAppointmentDetails
              tourId={tour?.id}
              appointment={
                selectedAppointment
                  ? // Merge the draft with the appointment
                    { ...selectedAppointment, ...selectedAppointmentDraft }
                  : undefined
              }
              isPrivate
              isOpen={showAppointmentDetails}
              onClose={() => {
                setSelectedAppointment(undefined)
                setShowAppointmentDetails(false)
              }}
            />
          </div>
        </div>
        <div className="block lg:hidden">
          <TourAppointmentDetails
            tourId={tour?.id}
            isFullscreen
            isPrivate
            appointment={
              selectedAppointment
                ? // Merge the draft with the appointment
                  { ...selectedAppointment, ...selectedAppointmentDraft }
                : undefined
            }
            isOpen={showAppointmentDetails}
            onClose={() => setShowAppointmentDetails(false)}
          />
        </div>
      </div>
    </>
  )
}

interface AnimatedAppointmentProps {
  appointment: ITourAppointment
  isSelected: boolean
  isDragging: boolean
  setAppointments: Dispatch<SetStateAction<ITourAppointment[]>>
  setSelectedAppointment: Dispatch<SetStateAction<ITourAppointment | undefined>>
  setDrafts: Dispatch<SetStateAction<Record<string, ITourAppointment>>>
  onClick: () => void
  onDoneClick: () => void
  onMoreClick: () => void
}

function AnimatedAppointment({
  appointment,
  isSelected,
  isDragging,
  setAppointments,
  setSelectedAppointment,
  setDrafts,
  onClick,
  onDoneClick,
  onMoreClick
}: AnimatedAppointmentProps) {
  return (
    <motion.div
      layout
      initial={{ height: 0 }}
      animate={{ height: 'auto' }}
      exit={{ height: 0 }}
      transition={{ height: { ease: 'easeInOut', duration: 0.15 } }}
      className={classNames({ 'select-none': isDragging })}>
      <div className="relative py-1.5">
        <TourAppointment
          isOpen={isSelected}
          appointment={appointment}
          onClick={onClick}
          onDoneClick={({ startTime, endTime, note }) => {
            setAppointments((appointments) =>
              appointments.map((l) =>
                l.id === appointment.id ? { ...l, startTime, endTime, note } : l
              )
            )
            onDoneClick()
          }}
          onMoreClick={onMoreClick}
          onRemove={() => {
            setSelectedAppointment(undefined)
            setAppointments((appointments) =>
              appointments.filter((l) => l.id !== appointment.id)
            )
          }}
          onUpdate={({ startTime, endTime, note }) => {
            setDrafts((drafts) => ({
              ...drafts,
              [appointment.id]: { ...appointment, startTime, endTime, note }
            }))
          }}
        />
      </div>
    </motion.div>
  )
}

interface ReorderableItemProps {
  appointment: ITourAppointment
  canDrag: boolean
  onDragStart: () => void
  onDragEnd: () => void
}

function ReorderableItem({
  appointment,
  canDrag,
  children,
  onDragStart,
  onDragEnd
}: PropsWithChildren<ReorderableItemProps>) {
  const controls = useDragControls()

  return (
    <Reorder.Item
      value={appointment}
      dragListener={false}
      dragControls={controls}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}>
      <div
        className={classNames('relative', {
          'pl-6 sm:pl-0': canDrag
        })}>
        {canDrag && (
          <div
            className="absolute left-1 top-11 h-5 w-5 cursor-grab text-gray-600 sm:-left-5"
            onPointerDown={(e) => controls.start(e)}>
            <ReorderIcon className="h-5 w-5" />
          </div>
        )}
        {children}
      </div>
    </Reorder.Item>
  )
}
