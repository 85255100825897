import { api } from '@cma/app'
import {
  ListingPartsFragment,
  ReorderReportListingsMutation,
  ReorderReportListingsMutationVariables,
  ReportQuery
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { mapPosition, sortByPosition } from './utils'

interface ReorderReportListingsContext {
  previousReport?: ReportQuery
}

export function useReorderReportListings() {
  const queryClient = useQueryClient()
  return useMutation<
    ReorderReportListingsMutation,
    Error,
    Omit<
      ReorderReportListingsMutationVariables & {
        listings: ListingPartsFragment[]
        startIndex: number
        endIndex: number
      },
      'listingIds'
    >,
    ReorderReportListingsContext
  >(
    ({ reportId, listings, startIndex, endIndex }) => {
      const query = gql`
        mutation ReorderReportListings($reportId: ID!, $listingIds: [ID!]!) {
          sortListings(reportId: $reportId, ids: $listingIds) {
            errors
          }
        }
      `
      const variables = {
        reportId,
        listingIds: reorder(listings, startIndex, endIndex).map(
          (listing) => listing.id
        )
      }
      return api({ query, variables })
    },
    {
      onMutate: async ({ reportId, listings, startIndex, endIndex }) => {
        await queryClient.cancelQueries(['report', reportId])
        const previousReport = queryClient.getQueryData<ReportQuery>([
          'report',
          reportId
        ])

        if (previousReport?.report) {
          queryClient.setQueryData<ReportQuery>(['report', reportId], {
            ...previousReport,
            report: {
              ...previousReport.report,
              listings: reorder(listings, startIndex, endIndex)
            }
          })
        }

        return { previousReport }
      },
      onError(_, { reportId }, context) {
        if (context?.previousReport) {
          queryClient.setQueryData<ReportQuery>(
            ['report', reportId],
            context.previousReport
          )
        }
      }
    }
  )
}

function reorder(
  listings: ListingPartsFragment[],
  startIndex: number,
  endIndex: number
) {
  const reorderedListings = [...listings]
  const [removeListing] = reorderedListings.splice(startIndex, 1)
  reorderedListings.splice(endIndex, 0, removeListing)
  const reorderedListingsWithNewPosition = mapPosition(reorderedListings)
  const reorderedListingsByPosition = sortByPosition(
    reorderedListingsWithNewPosition
  )
  return reorderedListingsByPosition
}
