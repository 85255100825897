import { api } from '@cma/app'
import { LoginMutation, LoginMutationVariables } from '@cma/generated/graphql'
import {
  UseMutationOptions,
  useMutation,
  useQueryClient
} from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { setAccessTokens } from './utils'

export function useLogin(
  options?: Omit<
    UseMutationOptions<LoginMutation, Error, LoginMutationVariables>,
    'mutationFn'
  >
) {
  const queryClient = useQueryClient()
  return useMutation<LoginMutation, Error, LoginMutationVariables>(
    async ({ email, password, partnershipLogin = false }) => {
      const query = gql`
        mutation Login(
          $email: String!
          $password: String!
          $partnershipLogin: Boolean
        ) {
          authenticate(
            login: $email
            password: $password
            partnershipLogin: $partnershipLogin
          ) {
            user {
              casJwt
              features {
                offerRdcToolkit
              }
            }
          }
        }
      `
      const variables = { email, password, partnershipLogin }
      const res = await api<LoginMutation>({ query, variables })
      const user = res.authenticate?.user
      const isConnectedToLwa = false //user?.connectedToLwa

      if (!user) {
        throw new Error('Invalid username or password')
      }

      if (isConnectedToLwa) {
        window.location.href = `${process.env.VITE_APP_LW_PLATFORM_URL}/product/launch?a=cc`
      }

      return res
    },
    {
      ...options,
      async onSuccess(data, variables, context) {
        const jwt = data.authenticate?.user?.casJwt

        if (!jwt) {
          throw new Error('Error trying to log you in')
        }

        setAccessTokens(jwt)

        queryClient.setQueryData<string>(['accessToken'], jwt)

        await queryClient.invalidateQueries(['currentUser'])

        await options?.onSuccess?.(data, variables, context)
      }
    }
  )
}
