import { useCurrentUser } from '@cma/app'
import { Button, classNames, FormField, Input } from '@cma/common'
import { IntegrationTypeEnum } from '@cma/generated/graphql'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useAddZipForm } from './useAddZipForm'
import { useRemoveIntegration } from './useRemoveIntegration'

const schema = yup.object({
  name: yup.string().required('Your username is required'),
  password: yup.string().required('Your password is required')
})

export function IntegrationZipForm() {
  const {
    mutate: addZipForm,
    isLoading: isAdding,
    error: addError
  } = useAddZipForm()
  const {
    mutate: removeIntegration,
    isLoading: isRemoving,
    error: removeError
  } = useRemoveIntegration()
  const { data: { currentUser } = {} } = useCurrentUser()

  const initialValue = currentUser?.zipFormToken
  const hasInitialValue = !!initialValue

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: initialValue || ''
    }
  })

  const disable = !hasInitialValue && (!watch('name') || !watch('password'))
  const showFullForm = !hasInitialValue

  return (
    <div data-testid="zip_form-container">
      <h4 className="font-bold text-gray-600">
        Transactions (zipForm Edition)
      </h4>
      <form
        className="grid grid-cols-1 gap-3 lg:grid-cols-5"
        onSubmit={handleSubmit(({ name, password }) => {
          addZipForm({ username: name, password })
        })}>
        {showFullForm && (
          <>
            <div className="lg:col-span-2">
              <FormField label="Username" error={errors.name?.message}>
                <Input {...register('name')} readOnly={hasInitialValue} />
              </FormField>
            </div>
            <div className="lg:col-span-2">
              <FormField label="Password" error={errors.password?.message}>
                <Input type="password" {...register('password')} />
              </FormField>
            </div>
          </>
        )}
        <div
          className={classNames({
            'lg:col-span-5 lg:mt-2': hasInitialValue,
            'lg:col-span-1 lg:mt-5': showFullForm
          })}>
          <Button
            fullWidth
            variant={!hasInitialValue ? 'tertiary' : 'tertiary-danger'}
            loading={isAdding || isRemoving}
            disabled={disable}
            type={hasInitialValue ? 'button' : 'submit'}
            onClick={() => {
              if (!hasInitialValue) return
              removeIntegration(
                { key: IntegrationTypeEnum.ZipForm },
                {
                  onSuccess() {
                    setValue('name', '')
                    setValue('password', '')
                  }
                }
              )
            }}>
            {!hasInitialValue
              ? 'Connect'
              : 'Disconnect from Transactions (zipForm Edition)'}
          </Button>
        </div>
      </form>
      {(!!addError || !!removeError) && (
        <div className="mt-2 text-sm text-red-600">
          {addError?.message || removeError?.message}
        </div>
      )}
    </div>
  )
}
