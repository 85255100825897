import { formatCurrency } from '@cma/common'
import { ChangeDetail } from '@cma/generated/graphql'
import { CloseIcon, PhotoIcon, CardsIcon, MoneyIcon } from '@cma/icons'

function getPriceUpdate(oldValue?: string | null, newValue?: string | null) {
  const firstPriceNumber = Number(oldValue)
  const secondPriceNumber = Number(newValue)
  const firstPriceString = formatCurrency(firstPriceNumber)
  const secondPriceString = formatCurrency(secondPriceNumber)

  if (!firstPriceNumber) {
    return `Initial listing price set to ${secondPriceString}`
  }
  if (firstPriceNumber < secondPriceNumber) {
    return `Price increased from ${firstPriceString} to ${secondPriceString}`
  }
  return `Price decreased from ${firstPriceString} to ${secondPriceString}`
}

function getStatusUpdate(oldValue?: string | null, newValue?: string | null) {
  if (!oldValue) {
    return `Initial status set to ${newValue}`
  }
  return `Status changed from ${oldValue} to ${newValue}`
}

function getDefaultUpdate(
  field?: string | null,
  oldValue?: string | null,
  newValue?: string | null
) {
  if (!oldValue) {
    return `Initial ${field} set to ${newValue}`
  }
  return `${field?.toUpperCase()} changed from ${oldValue} to ${newValue}`
}

function getPhotosUpdate(oldValue?: string | null, newValue?: string | null) {
  if (Number(oldValue) < Number(newValue)) {
    return 'Photos were added'
  }
  return 'Photos were removed'
}

export function transformHistory(change: ChangeDetail) {
  switch (change?.field) {
    case 'price_list':
      return {
        icon: MoneyIcon,
        description: getPriceUpdate(change?.oldValue, change?.newValue)
      }
    case 'status':
      return {
        icon: CardsIcon,
        description: getStatusUpdate(change?.oldValue, change?.newValue)
      }
    case 'photos':
      return {
        icon: PhotoIcon,
        description: getPhotosUpdate(change?.oldValue, change?.newValue)
      }
    default:
      return {
        icon: CloseIcon,
        description: getDefaultUpdate(
          change?.field,
          change?.oldValue,
          change?.newValue
        )
      }
  }
}
