import { api } from '@cma/app'
import { BraintreeTokenQuery } from '@cma/generated/graphql'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { PropsWithChildren } from 'react'
import {
  Braintree as ReactBraintree,
  HostedField
  // @ts-ignore This package doesn't have types
} from 'react-braintree-fields'
import styles from './Braintree.module.css'

interface BraintreeProps {
  onInit: (getTokenFn: () => Promise<{ nonce: string }>) => void
  // This is really a BraintreeError but we don't have access to that
  onError: (error: any) => void
}

export function Braintree({
  children,
  onInit,
  onError
}: PropsWithChildren<BraintreeProps>) {
  const { data: { btGatewayToken } = {} } = useBraintreeToken()

  return (
    <ReactBraintree
      authorization={btGatewayToken}
      getTokenRef={onInit}
      onError={onError}>
      {children}
    </ReactBraintree>
  )
}

Braintree.Field = BraintreeField

interface BraintreeFieldProps {
  type:
    | 'number'
    | 'expirationDate'
    | 'expirationMonth'
    | 'expirationYear'
    | 'cvv'
    | 'postalCode'
    | 'cardholderName'
  id?: string | number
  placeholder?: string
  className?: string
  prefill?: string
  onCardTypeChange?: () => void
  onValidityChange?: () => void
  onNotEmpty?: () => void
  onFocus?: () => void
  onEmpty?: () => void
  onBlur?: () => void
}

function BraintreeField(props: BraintreeFieldProps) {
  return (
    <div className={styles.container}>
      <HostedField {...props} />
    </div>
  )
}

function useBraintreeToken() {
  return useQuery<BraintreeTokenQuery, Error>(
    ['braintreeToken'],
    () => {
      const query = gql`
        query BraintreeToken {
          btGatewayToken
        }
      `
      return api<BraintreeTokenQuery>({ query })
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: Infinity
    }
  )
}
