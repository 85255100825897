import { useCurrentUser } from '@cma/app'
import {
  Button,
  formatDate,
  formatTime,
  getDateFromTime,
  getDateWithoutTimezone,
  getGoogleAddress,
  Link,
  NotFound,
  Wrapper
} from '@cma/common'
import {
  TourAppointment,
  TourAppointmentDetails,
  TourCompare,
  TourHeader,
  TourMap,
  TourShare,
  useTour
} from '@cma/features/tour'
import { TourAppointment as ITourAppointment } from '@cma/generated/graphql'
import { CarIcon, CompareIcon, EditIcon, MapIcon, ShareIcon } from '@cma/icons'
import { differenceInMinutes } from 'date-fns'
import pluralize from 'pluralize'
import { Fragment, useState } from 'react'
import { useParams } from 'react-router-dom'

export default function TourLive() {
  const { id } = useParams()
  const { data: { currentUser } = {} } = useCurrentUser()
  const { data: { tour } = {} } = useTour({ id })
  const scheduledAppointments =
    tour?.appointments
      .filter((appointment) => appointment.startTime && appointment.endTime)
      .sort((a, b) => a.endTime?.localeCompare(b.endTime || '') || 0) || []
  const unscheduledAppointments =
    tour?.appointments
      .filter((appointment) => !appointment.startTime || !appointment.endTime)
      .sort((a, b) => {
        if ((a.position || 0) > (b.position || 0)) return 1
        if ((a.position || 0) < (b.position || 0)) return -1
        return 0
      }) || []
  const appointments: ITourAppointment[] = [
    ...scheduledAppointments,
    ...unscheduledAppointments
  ]
  const [showCompare, setShowCompare] = useState(false)
  const [showShare, setShowShare] = useState(false)
  const [selectedAppointment, setSelectedAppointment] =
    useState<ITourAppointment>()
  const appointmentStops = appointments
    .map(({ listing }) =>
      getGoogleAddress({
        address: listing.address,
        city: listing.city,
        state: listing.state,
        zip: listing.zip
      })
    )
    .join('/')
  const appointmentsWithTime = appointments.filter(
    (appointment) => appointment.startTime && appointment.endTime
  )
  const startTime = appointmentsWithTime?.[0]?.startTime
  const endTime =
    appointmentsWithTime?.[appointmentsWithTime.length - 1]?.endTime

  if (!id || !tour) {
    return (
      <Wrapper>
        <NotFound />
      </Wrapper>
    )
  }

  return (
    <>
      <div className="flex h-[100dvh] flex-col">
        <TourHeader
          title={tour.title}
          agentName={tour.agent?.name}
          agentPhone={tour.agent?.mobilePhone || tour.agent?.officePhone}
          agentAvatar={tour.agent?.avatar}
          companyLogo={tour.agent?.companyLogoUrl}
        />
        <div className="flex grow">
          <div className="flex h-[calc(100dvh-4rem)] w-full shrink-0 flex-col overflow-y-auto bg-gray-50 sm:max-w-[26.25rem] sm:shadow-raised">
            <div className="flex h-full flex-col px-3 py-6 sm:p-8">
              <div className="mb-6 space-y-0.5 rounded-2xl bg-white p-3 text-center shadow-raised sm:mb-2 sm:space-y-2 sm:rounded-none sm:bg-transparent sm:p-0 sm:text-left sm:shadow-none">
                <div className="block text-xl text-gray-500 sm:hidden">
                  {tour.title}
                </div>
                {!!tour.tourDate && (
                  <>
                    <strong className="text-2xl font-semibold sm:text-3xl">
                      {formatDate(
                        getDateWithoutTimezone(tour.tourDate),
                        'EEEE, MMMM do'
                      )}
                    </strong>
                    {(startTime || endTime) && (
                      <div className="font-medium text-gray-700">
                        {[startTime, endTime]
                          .filter((time): time is string => !!time)
                          .map(formatTime)
                          .join(' - ')}
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="mb-6 flex-grow space-y-3 sm:mb-7">
                <div className="flex items-center justify-between">
                  <h3 className="text-xl font-semibold">Properties</h3>
                  <button
                    className="inline-flex items-center space-x-2 rounded-md px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-100"
                    onClick={() => {
                      setSelectedAppointment(undefined)
                      setShowCompare(true)
                    }}>
                    <CompareIcon className="h-5 w-5" />
                    <span>Compare</span>
                  </button>
                </div>
                <div className="space-y-2 sm:space-y-3">
                  {appointments.map((appointment, index, appointments) => {
                    const currAppointment = appointments[index]
                    const nextAppointment = appointments[index + 1] as
                      | ITourAppointment
                      | undefined

                    const nextAppointmentStartDate =
                      nextAppointment?.startTime && tour.tourDate
                        ? getDateFromTime(
                            nextAppointment?.startTime,
                            tour.tourDate
                          )
                        : undefined

                    const currAppointmentEndDate =
                      currAppointment?.endTime && tour.tourDate
                        ? getDateFromTime(
                            currAppointment?.endTime,
                            tour.tourDate
                          )
                        : undefined

                    const freeTimeAvailable =
                      nextAppointmentStartDate && currAppointmentEndDate
                        ? differenceInMinutes(
                            nextAppointmentStartDate,
                            currAppointmentEndDate
                          )
                        : 0

                    return (
                      <Fragment key={appointment.id}>
                        <TourAppointment
                          isOpen={selectedAppointment?.id === appointment.id}
                          variant="preview"
                          appointment={appointment}
                          onClick={() =>
                            setSelectedAppointment(
                              selectedAppointment?.id === appointment.id
                                ? undefined
                                : appointment
                            )
                          }
                        />
                        {!!freeTimeAvailable && (
                          <div className="flex items-center justify-center gap-1 rounded-full bg-gray-200 py-0.5 text-xs text-gray-500">
                            <CarIcon className="h-4 w-4" />
                            <div>
                              {pluralize(
                                'Hours',
                                Number((freeTimeAvailable / 60).toFixed(2)),
                                true
                              )}
                            </div>
                          </div>
                        )}
                      </Fragment>
                    )
                  })}
                </div>
                <Button
                  outline
                  fullWidth
                  leftIcon={<MapIcon className="h-5 w-5" />}
                  as="a"
                  target="_blank"
                  href={`https://www.google.com/maps/dir/${appointmentStops}`}>
                  Route in Google Maps
                </Button>
              </div>
              <div className="flex items-center gap-4">
                {currentUser?.id === tour.agent?.id && (
                  <Button
                    data-pendo-id="edit-live-tour-button"
                    as={Link}
                    outline
                    fullWidth
                    leftIcon={<EditIcon className="h-5 w-5" />}
                    to={`/tours/${tour.id}/edit`}>
                    Edit
                  </Button>
                )}
                <Button
                  data-pendo-id="share-live-tour-button"
                  fullWidth
                  leftIcon={<ShareIcon className="h-5 w-5" />}
                  onClick={() => setShowShare(true)}>
                  Share
                </Button>
              </div>
            </div>
          </div>
          <div className="relative hidden grow overflow-hidden bg-gray-100 sm:block">
            <TourMap
              appointments={appointments}
              onPinClick={setSelectedAppointment}
            />
            <div className="hidden lg:block">
              <TourAppointmentDetails
                tourId={tour.id}
                appointment={selectedAppointment}
                isOpen={!!selectedAppointment}
                onClose={() => setSelectedAppointment(undefined)}
              />
            </div>
          </div>
          <div className="block lg:hidden">
            <TourAppointmentDetails
              tourId={tour.id}
              isFullscreen
              appointment={selectedAppointment}
              isOpen={!!selectedAppointment}
              onClose={() => setSelectedAppointment(undefined)}
            />
          </div>
        </div>
      </div>
      <TourCompare
        tourId={tour.id}
        isOpen={showCompare}
        onClose={() => setShowCompare(false)}
      />
      <TourShare
        tourId={tour.id}
        isOpen={showShare}
        onClose={() => setShowShare(false)}
      />
    </>
  )
}
