import { api } from '@cma/app'
import {
  AddZipFormMutation,
  AddZipFormMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useAddZipForm() {
  const queryClient = useQueryClient()
  return useMutation<AddZipFormMutation, Error, AddZipFormMutationVariables>(
    ({ username, password }) => {
      const query = gql`
        mutation AddZipForm($username: String, $password: String) {
          connectZipForm(username: $username, password: $password) {
            contextId
            errors
          }
        }
      `
      const variables = { username, password }
      return api<AddZipFormMutation>({ query, variables })
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries(['currentUser'])
      }
    }
  )
}
