import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { removeAccessTokens } from './utils'

interface LogoutArgs {
  shouldUseCmaToLogout?: boolean
  shouldNavigateToLogin?: boolean
}

export function useLogout() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  return useCallback(
    async (args?: LogoutArgs) => {
      const { shouldUseCmaToLogout = false, shouldNavigateToLogin = true } =
        args || {}

      removeAccessTokens()

      await queryClient.cancelQueries()

      queryClient.setQueryData(['accessToken'], undefined)

      if (shouldUseCmaToLogout) {
        window.open(`${process.env.VITE_APP_CMA_URL}/logout`, '_self')
        return
      }

      queryClient.clear()

      if (shouldNavigateToLogin) {
        navigate('/login')
      }
    },
    [queryClient, navigate]
  )
}
