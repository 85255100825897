import { ArrowNextIcon, ArrowPrevIcon } from '@cma/icons'
import Slider, { CustomArrowProps, Settings } from 'react-slick'
import 'slick-carousel/slick/slick.css'

export function ImageSlider(
  props: Omit<Settings, 'speed' | 'dots' | 'prevArrow' | 'nextArrow'>
) {
  return (
    <Slider
      {...props}
      className="group relative overflow-hidden bg-gray-50"
      speed={200}
      dots={false}
      prevArrow={<PreviousArrow />}
      nextArrow={<NextArrow />}
    />
  )
}

function PreviousArrow({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  currentSlide,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  slideCount,
  ...props
}: CustomArrowProps) {
  return (
    <button
      {...props}
      className="absolute top-0 left-0 z-10 flex h-full w-[3.125rem] items-center text-white opacity-0 group-hover:opacity-100">
      <ArrowPrevIcon className="mx-auto h-[2.8125rem] w-5" />
    </button>
  )
}

function NextArrow({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  currentSlide,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  slideCount,
  ...props
}: CustomArrowProps) {
  return (
    <button
      {...props}
      className="absolute top-0 right-0 z-10 flex h-full w-[3.125rem] items-center text-white opacity-0 group-hover:opacity-100">
      <ArrowNextIcon className="mx-auto h-[2.8125rem] w-5" />
    </button>
  )
}
