import { CloseIcon } from '@cma/icons'
import { PropsWithChildren } from 'react'

interface ListingHeaderProps {
  address?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
  icon?: JSX.Element
  onClose: () => void
}

export function ListingHeader({
  address,
  city,
  state,
  zip,
  icon = <CloseIcon />,
  children,
  onClose
}: PropsWithChildren<ListingHeaderProps>) {
  const cityStateZip = [city, [state, zip].filter(Boolean).join(' ')]
    .filter(Boolean)
    .join(', ')

  return (
    <div className="sticky top-0 z-10 flex h-[4.25rem] shrink-0 items-center justify-between space-x-2 bg-white px-4 shadow-raised">
      <div className="flex min-w-0 items-center gap-2">
        <button
          className="flex h-10 w-10 items-center justify-center text-gray-600"
          onClick={onClose}>
          <span className="h-5 w-5">{icon}</span>
        </button>
        {(address || cityStateZip) && (
          <div className="hidden min-w-0 items-baseline gap-2 font-semibold md:flex">
            {address && (
              <div className="truncate text-2xl text-gray-900" title={address}>
                {address}
              </div>
            )}
            {cityStateZip && (
              <div className="truncate text-gray-500" title={cityStateZip}>
                {cityStateZip}
              </div>
            )}
          </div>
        )}
      </div>
      <div className="shrink-0">{children}</div>
    </div>
  )
}
