export function EditIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.921 3.64a2.75 2.75 0 0 1 0 3.89l-8.485 8.485a.75.75 0 0 1-.383.205l-3.536.707a.75.75 0 0 1-.883-.883l.708-3.535a.75.75 0 0 1 .205-.383l8.485-8.486a2.75 2.75 0 0 1 3.889 0ZM15.86 6.47A1.25 1.25 0 0 0 14.093 4.7l-8.325 8.325-.442 2.21 2.21-.443L15.86 6.47Z"
        fill="currentColor"
      />
    </svg>
  )
}
