import { formatCurrency, formatDate, formatNumber } from '@cma/common'
import pluralize from 'pluralize'

interface ListingPriceProps {
  price?: string | number | null
  pricePerSqft?: string | number | null
  originalPriceList?: string | number | null
  priceList?: string | number | null
  priceSold?: string | number | null
  beds?: string | number | null
  baths?: string | number | null
  sqft?: string | number | null
  dateList?: string | null
  dateSold?: string | null
}

export function ListingPrice({
  price,
  pricePerSqft,
  originalPriceList,
  priceList,
  priceSold,
  beds,
  baths,
  sqft,
  dateList,
  dateSold
}: ListingPriceProps) {
  return (
    <div className="divide-y [&>div:first-child]:pt-0 [&>div:last-child]:pb-0">
      <div className="flex items-baseline gap-3 py-3">
        <div className="text-3xl font-semibold text-gray-900">
          {formatCurrency(price || 0)}
        </div>
        <div className="text-sm text-gray-700">
          {formatCurrency(pricePerSqft || 0)}/sqft
        </div>
      </div>
      <div className="flex gap-3 py-3">
        <div>
          <span className="font-semibold text-gray-900">
            {formatNumber(beds || 0)}
          </span>{' '}
          <span className="text-gray-700">
            {pluralize('beds', Number(beds || 0))}
          </span>
        </div>
        <div>
          <span className="font-semibold text-gray-900">
            {formatNumber(baths || 0)}
          </span>{' '}
          <span className="text-gray-700">
            {pluralize('baths', Number(baths || 0))}
          </span>
        </div>
        <div>
          <span className="font-semibold text-gray-900">
            {formatNumber(sqft || 0)}
          </span>{' '}
          <span className="text-gray-700">sqft</span>
        </div>
      </div>
      <div className="flex gap-3 py-3">
        <div className="text-xs text-gray-700">
          Listed: {dateList ? formatDate(dateList, 'MM/dd/yyyy') : 'N/A'}
        </div>
        {!!priceSold && !!dateSold && (
          <div className="text-xs text-gray-700">
            Sold: {dateSold ? formatDate(dateSold, 'MM/dd/yyyy') : 'N/A'}
          </div>
        )}
      </div>
      <div className="divide-y p-3 [&>div:first-child]:pt-0 [&>div:last-child]:pb-0">
        <div className="flex items-center justify-between gap-3 py-3">
          <div className="text-sm text-gray-800">Original List Price</div>
          <div className="font-bold text-gray-900">
            {originalPriceList ? formatCurrency(originalPriceList) : '-'}
          </div>
        </div>
        <div className="flex items-center justify-between gap-3 py-3">
          <div className="text-sm text-gray-800">List Price</div>
          <div className="font-bold text-gray-900">
            {priceList ? formatCurrency(priceList) : '-'}
          </div>
        </div>
        {!!priceSold && !!dateSold && (
          <div className="flex items-center justify-between gap-3 py-3">
            <div className="text-sm text-gray-800">Sold Price</div>
            <div className="font-bold text-gray-900">
              {priceSold ? formatCurrency(priceSold) : '-'}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
