import { api } from '@cma/app'
import {
  DestroyAlternateMlsCredentialMutation,
  DestroyAlternateMlsCredentialMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useDestroyAlternateMlsCredential() {
  const queryClient = useQueryClient()

  return useMutation<
    DestroyAlternateMlsCredentialMutation,
    Error,
    DestroyAlternateMlsCredentialMutationVariables
  >(
    (variables) => {
      const query = gql`
        mutation DestroyAlternateMlsCredential($id: ID!) {
          destroyAlternateMlsCredential(alternateMlsCredentialId: $id)
        }
      `
      return api<DestroyAlternateMlsCredentialMutation>({ query, variables })
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries(['alternateMlses'])
      }
    }
  )
}
