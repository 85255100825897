export function WarningIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.111 3.308c-.871-1.744-3.357-1.744-4.228 0l-5.63 11.269C1.467 16.149 2.61 18 4.367 18h11.26c1.757 0 2.9-1.85 2.114-3.423l-5.63-11.27Zm-2.107 9.594a.725.725 0 0 0 .723-.678l.335-5.16A1 1 0 0 0 10.064 6h-.12a1 1 0 0 0-.998 1.065l.335 5.16a.725.725 0 0 0 .723.677Zm-.777 1.246a1.067 1.067 0 0 0-.322.784c0 .308.108.57.322.784.215.215.472.322.77.322.308 0 .57-.107.784-.322.224-.215.336-.476.336-.784 0-.308-.112-.57-.336-.784a1.045 1.045 0 0 0-.784-.336 1.03 1.03 0 0 0-.77.336Z"
        fill="currentColor"
      />
    </svg>
  )
}
