import { api } from '@cma/app'
import {
  DestroyCustomPdfMutation,
  DestroyCustomPdfMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function useDestroyCustomPdf() {
  const queryClient = useQueryClient()
  return useMutation<
    DestroyCustomPdfMutation,
    Error,
    DestroyCustomPdfMutationVariables
  >(
    ({ id }) => {
      const query = gql`
        mutation DestroyCustomPdf($id: ID!) {
          destroyCustomPdf(id: $id) {
            customPdf {
              id
            }
            errors
          }
        }
      `
      const variables = { id }
      return api<DestroyCustomPdfMutation>({ query, variables })
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries(['customPdfs'])
      }
    }
  )
}
