export interface YoutubeProps {
  videoId: string
  width?: string | number
  height?: string | number
  autoplay?: boolean
}

export function Youtube({
  videoId,
  width = '100%',
  height = '100%',
  autoplay = true
}: YoutubeProps) {
  return (
    <iframe
      title="Youtube Video"
      width={width}
      height={height}
      src={`https://www.youtube.com/embed/${videoId}?autoplay=${
        autoplay ? 1 : 0
      }`}
      frameBorder="0"
      allowFullScreen
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      className="aspect-video"
    />
  )
}
