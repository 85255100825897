import { Pagination, usePaginate, Wrapper } from '@cma/common'
import { FlyerCard } from '@cma/features/flyer/FlyerCard'
import {
  CreateReport,
  ReportGettingStarted,
  ReportsHeader,
  useReports
} from '@cma/features/report'
import { ReportTypeEnum } from '@cma/generated/graphql'
import { Fragment, Suspense } from 'react'
import { Helmet } from 'react-helmet-async'

export default function Flyers() {
  const paginate = usePaginate({ limit: 27 })

  return (
    <Wrapper>
      <Helmet>
        <title>Flyers - Cloud CMA</title>
      </Helmet>
      <ReportsHeader
        title="Flyers"
        placeholder="Search Flyers"
        onSubmit={(data) => {
          paginate.setFirst(paginate.limit)
          paginate.setLast(undefined)
          paginate.setAfter(undefined)
          paginate.setBefore(undefined)
          paginate.setSearch(data.search)
        }}
      />
      <Suspense
        fallback={
          <>
            <div className="sr-only">Loading Flyers</div>
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              <CreateReport to="/flyers/new">Create Flyer</CreateReport>
              {new Array(5).fill('').map((_, index) => (
                <FlyerCard.Skeleton key={index} />
              ))}
            </div>
            <Pagination disablePrev={true} disableNext={true} />
          </>
        }>
        <FlyerCards paginate={paginate} />
      </Suspense>
    </Wrapper>
  )
}

interface FlyerCardsProps {
  paginate: ReturnType<typeof usePaginate>
}

function FlyerCards({ paginate }: FlyerCardsProps) {
  const { data: { reports } = {} } = useReports({
    types: [ReportTypeEnum.Flyer],
    first: paginate.first,
    last: paginate.last,
    after: paginate.after,
    before: paginate.before,
    search: paginate.search
  })

  return (
    <>
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        <CreateReport to="/flyers/new">Create Flyer</CreateReport>
        {reports?.nodes?.map((report) => (
          <Fragment key={report?.id}>
            {report && <FlyerCard report={report} />}
          </Fragment>
        ))}
        {(reports?.total ?? 0) <= 4 && (
          <ReportGettingStarted videoId="3elqu1qqwl">
            Getting Started with Flyers
          </ReportGettingStarted>
        )}
      </div>
      {(reports?.total ?? 0) > paginate.limit && (
        <Pagination
          disablePrev={!reports?.pageInfo.hasPreviousPage}
          disableNext={!reports?.pageInfo.hasNextPage}
          onPrevClick={() => {
            paginate.setBefore(reports?.pageInfo.startCursor ?? undefined)
            paginate.setAfter(undefined)
            paginate.setFirst(undefined)
            paginate.setLast(paginate.limit)
          }}
          onNextClick={() => {
            paginate.setBefore(undefined)
            paginate.setAfter(reports?.pageInfo?.endCursor ?? undefined)
            paginate.setFirst(paginate.limit)
            paginate.setLast(undefined)
          }}
        />
      )}
    </>
  )
}
