import { NotFound, classNames } from '@cma/common'
import {
  AccountLogIn,
  AccountSignUp,
  CmaRdcLogo,
  usePotentialUser,
  useSsoUser
} from '@cma/features/sso'
import { Tab } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import * as yup from 'yup'

const schema = yup.object({
  firstName: yup.string().required('Your first name is required'),
  lastName: yup.string().required('Your last name is required'),
  email: yup.string().email('Invalid email').required('Your email is required'),
  phoneNumber: yup.string().required('Your phone number is required'),
  mlsId: yup.string().required('Your mls id is required'),
  companyName: yup.string().required('Your company name is required'),
  onBoardingType: yup.string()
})

type SSoOnboardSchema = yup.InferType<typeof schema>

export const useOnboardingForm = () => useFormContext<SSoOnboardSchema>()

export default function SsoOnboarding() {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token') || ''

  const { data: { potentialUsers } = {} } = usePotentialUser({
    token
  })
  const { data: { partnershipUser } = {} } = useSsoUser(
    { token },
    {
      enabled: !!token
    }
  )

  const form = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      onBoardingType: potentialUsers?.length === 0 ? 'signup' : 'login',
      email: partnershipUser?.email || ''
    }
  })

  const name = partnershipUser?.firstName

  if (!token) {
    return (
      <div className="m-auto mt-16 w-4/5 py-8">
        <NotFound />
      </div>
    )
  }

  return (
    <FormProvider {...form}>
      <div className="w-full space-y-5 self-start">
        <div className="text-center">
          <div className="mx-auto h-[1.875rem] w-[7.75rem]">
            <CmaRdcLogo />
          </div>
          <h1 className="helix-display-small pb-1.5 pt-4">Hello {name}</h1>
          <p className="helix-heading helix-text-gray-600">
            Let's get your Cloud CMA account connected
          </p>
        </div>
        <div className="flex w-full flex-col items-center space-y-5">
          <Tab.Group
            selectedIndex={form.watch('onBoardingType') === 'signup' ? 1 : 0}
            onChange={(index) =>
              form.setValue('onBoardingType', index === 1 ? 'signup' : 'login')
            }>
            <Tab.List className="helix-bg-gray-100 flex w-full max-w-[24.75rem] items-stretch rounded-lg p-1">
              <Tab
                className={({ selected }) =>
                  classNames(
                    'helix-text-gray-600 flex w-1/2 flex-col items-start space-y-1 rounded-md px-4 py-2 shadow',
                    {
                      'shadow-transparent': !selected,
                      'helix-bg-white helix-text-gray-900': selected
                    }
                  )
                }>
                <div className="space-y-1 text-left">
                  <div className="helix-body--strong text-sm">Log In</div>
                  <div className="helix-small text-xs">
                    I've used Cloud CMA before
                  </div>
                </div>
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    'helix-text-gray-600 flex w-1/2 flex-col items-start space-y-1 rounded-md px-4 py-2 shadow',
                    {
                      'shadow-transparent': !selected,
                      'helix-bg-white helix-text-gray-900': selected
                    }
                  )
                }>
                <div className="helix-body--strong text-sm">Sign Up</div>
                <div className="helix-small text-xs">I'm new to Cloud CMA</div>
              </Tab>
            </Tab.List>
            <Tab.Panels className="w-full sm:w-fit">
              <Tab.Panel>
                <AccountLogIn />
              </Tab.Panel>
              <Tab.Panel>
                <AccountSignUp />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </FormProvider>
  )
}
