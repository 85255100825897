import { formatNumber, getTimeAgo } from '@cma/common'

interface ReportStatsProps {
  viewCount: number
  lastViewedAt: string
  updatedAt: string
}

export function ReportStats({
  viewCount,
  lastViewedAt,
  updatedAt
}: ReportStatsProps) {
  return (
    <div className="absolute inset-0 flex items-center rounded-tl-md rounded-tr-md bg-white">
      <div className="grid w-full grid-cols-3 gap-4 p-4">
        <div>
          <div className="truncate text-lg text-gray-800">
            {formatNumber(viewCount ?? 0)}
          </div>
          <div className="truncate text-xs text-gray-500">View Count</div>
        </div>
        <div>
          <div className="truncate text-lg text-gray-800">
            {lastViewedAt ? getTimeAgo(lastViewedAt) : 'N/A'}
          </div>
          <div className="truncate text-xs text-gray-500">Last Viewed</div>
        </div>
        <div>
          <div className="truncate text-lg text-gray-800">
            {updatedAt ? getTimeAgo(updatedAt) : 'N/A'}
          </div>
          <div className="truncate text-xs text-gray-500">Last Updated</div>
        </div>
      </div>
    </div>
  )
}
