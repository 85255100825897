import { Button, ErrorBoundary, Spinner, ToastProvider } from '@cma/common'
import { HelixThemeProvider } from '@helix/theming'
import { RefreshIcon } from '@heroicons/react/outline'
import {
  DefaultOptions,
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientConfig,
  QueryClientProvider
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import merge from 'deepmerge'
import { PropsWithChildren, Suspense, useRef, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'

interface AppQueryClientConfig extends QueryClientConfig {
  queryCache?: QueryCache
  mutationCache?: MutationCache
  defaultOptions?: DefaultOptions
}

interface AppProviderProps {
  queryClientConfig?: AppQueryClientConfig
}

export function AppProvider({
  children,
  queryClientConfig = {}
}: PropsWithChildren<AppProviderProps>) {
  const [isReloading, setIsReloading] = useState(false)
  const queryClientRef = useRef(
    new QueryClient(
      merge(
        {
          defaultOptions: {
            queries: {
              staleTime: 1000 * 30, // 30 seconds
              // There seems to be a bug with suspense and react-query / react 18
              // We need react 18 for concurrent mode and `useTransition`
              suspense: true,
              onError(error) {
                console.log(error)
              }
            }
          }
        },
        queryClientConfig
      )
    )
  )

  return (
    <Suspense
      fallback={
        <div className="p-5">
          <div className="h-5 w-5 text-green-500">
            <Spinner />
          </div>
        </div>
      }>
      <ErrorBoundary
        fallback={(error) => (
          <div className="p-5">
            <h1 className="text-2xl text-red-500">Error loading Cloud CMA</h1>
            <p className="mt-2 text-gray-600">{error.message}</p>
            <div className="mt-4">
              <Button
                variant="danger"
                leftIcon={<RefreshIcon />}
                loading={isReloading}
                onClick={() => {
                  setIsReloading(true)
                  window.location.reload()
                }}>
                Click here to reload the page and try again
              </Button>
            </div>
          </div>
        )}>
        <QueryClientProvider client={queryClientRef.current}>
          <HelmetProvider>
            <HelixThemeProvider>{children}</HelixThemeProvider>
            <ToastProvider />
          </HelmetProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ErrorBoundary>
    </Suspense>
  )
}
